/**
 * @copyright Elmelo Ltd.
 */

import { ICoreId } from "./core";
import { IMenuCat, IMenuSubcat, IMenuItem, IMenuItemPrice } from "./menu";

/**
 */
interface _ICartCore extends ICoreId {
	cnt: number;
	idx?: number;
	vIdx?: number;

	dtc?: number;
	dtm?: number;
} // _ICartCore

/**
 */
export interface ICartMod extends _ICartCore {} // ICartMod

/**
 */
export interface ICartSecMod extends ICartMod {
	idOpt: string;
	nameOpt?: string;
} // ICartSecMod

/**
 */
export interface ICartAddon extends _ICartCore {
	idOpt: string;
	nameOpt?: string;
} // ICartAddon

/**
 */
export interface ICartOpt extends _ICartCore {
	item: IMenuItem;
	cat: IMenuCat;
	subcat: IMenuSubcat;

	// price: IMenuItemPrice;

	mods?: ICartMod[] | undefined;
	secMods?: ICartSecMod[] | undefined;
	addons?: ICartAddon[] | undefined;
} // TCartOpt

/**
 */
export class CartMod implements ICartMod {
	id: string;
	name?: string | undefined;
	cnt: number;

	dtc?: number | undefined;
	dtm?: number | undefined;

	/**
	 */
	constructor(props: ICartMod) {
		this.id = props.id;
		this.name = props.name;
		this.cnt = props.cnt;

		const dt_now = Date.now();
		this.dtc = dt_now;
		this.dtm = dt_now;
	} // cstr

	/**
	 */
	Cnt(cnt: number) {
		this.cnt = cnt;
		this.dtm = Date.now();
	} // Cnt
} // CartMod

/**
 */
export class CartSecMod implements ICartSecMod {
	id: string;
	name?: string | undefined;
	idOpt: string;
	nameOpt: string | undefined;
	cnt: number;

	dtc?: number | undefined;
	dtm?: number | undefined;

	/**
	 */
	constructor(props: ICartSecMod) {
		this.id = props.id;
		this.name = props.name;
		this.idOpt = props.idOpt;
		this.nameOpt = props.nameOpt;
		this.cnt = props.cnt;

		const dt_now = Date.now();
		this.dtc = dt_now;
		this.dtm = dt_now;
	} // cstr

	/**
	 */
	Cnt(cnt: number) {
		this.cnt = cnt;

		this.dtm = Date.now();
	} // Cnt

	// Opt( idOpt: string, nameOpt: string )
	// {
	//   this.idOpt = idOpt
	//   this.nameOpt = nameOpt

	//   this.dtm = Date.now()
	// } // Opt

	// Upd( cnt: number, idOpt: string, nameOpt: string )
	// {
	//   this.cnt = cnt
	//   this.idOpt = idOpt
	//   this.nameOpt = nameOpt

	//   this.dtm = Date.now()
	// } // Upd
} // CartSecMod

/**
 */
export class CartAddon implements ICartAddon {
	id: string;
	name?: string | undefined;
	cnt: number;
	idOpt: string;
	nameOpt: string | undefined;

	dtc?: number | undefined;
	dtm?: number | undefined;

	/**
	 */
	constructor(props: ICartAddon) {
		this.id = props.id;
		this.name = props.name;
		this.cnt = props.cnt;
		this.idOpt = props.idOpt;
		this.nameOpt = props.nameOpt;

		const dt_now = Date.now();
		this.dtc = dt_now;
		this.dtm = dt_now;
	} // cstr

	/**
	 */
	Cnt(cnt: number) {
		this.cnt = this.cnt;
		this.dtm = Date.now();
	}
} // CartAddon

/**
 */
export class CartOpt implements ICartOpt {
	id: string;
	name?: string | undefined;
	cnt: number;

	item: IMenuItem;
	cat: IMenuCat;
	subcat: IMenuSubcat;

	mods?: ICartMod[] | undefined;
	secMods?: ICartSecMod[] | undefined;
	addons?: ICartAddon[] | undefined;

	dtc?: number | undefined;
	dtm?: number | undefined;

	constructor(props: ICartOpt) {
		// const id_mod: string | null = props.mods?.map(x => `${x.cnt}${x.id}`).join() || null
		// const id_secmod: string | null = props.secMods?.map(x => `${x.cnt}${x.id}`).join() || null
		// const id_addon: string | null = props.addons?.map(x => `${x.cnt}${x.id}`).join() || null

		// // console.log( 'id_mod: ', id_mod )
		// // console.log( 'id_secmod: ', id_secmod )
		// // console.log( 'id_addon: ', id_addon )

		// const id_all = [
		//   props.cat.id, props.subcat.id, props.item.id,
		//   id_mod, id_secmod, id_addon
		// ].filter(x => x).join('.')

		// // console.log( 'id_all: ', id_all )

		// empty string will generate a new id
		this.id = props.id.length ? props.id : this._GetId(props);
		this.name = props.name; //name
		this.cnt = props.cnt;

		this.item = props.item;
		this.cat = props.cat;
		this.subcat = props.subcat;

		this.mods = props.mods;
		this.secMods = props.secMods;
		this.addons = props.addons;

		const dt_now = Date.now();
		this.dtc = dt_now;
		this.dtm = dt_now;
	}

	_GetId(props: ICartOpt) {
		const id_mod: string | null = props.mods?.map(x => `${x.cnt}${x.id}`).join() || null;
		const id_secmod: string | null = props.secMods?.map(x => `${x.cnt}${x.id}${x.idOpt}`).join() || null;
		const id_addon: string | null = props.addons?.map(x => `${x.cnt}${x.id}${x.idOpt}`).join() || null;

		// console.log( 'id_mod: ', id_mod )
		// console.log( 'id_secmod: ', id_secmod )
		// console.log( 'id_addon: ', id_addon )

		return [props.cat.id, props.subcat.id, props.item.id, id_mod, id_secmod, id_addon].filter(x => x).join(".");
	}

	// /**
	//  */
	// constructor(
	//   cnt: number,
	//   item: IMenuItem, cat: IMenuCat, subcat: IMenuSubcat,
	//   mods?: ICartMod[], secmods?: ICartSecMod[],
	//   addons?: ICartAddon[],
	// )
	// {
	//   const id_mod: string | null = mods?.map(x => `${x.cnt}${x.id}`).join() || null
	//   const id_secmod: string | null = secmods?.map(x => `${x.cnt}${x.id}`).join() || null
	//   const id_addon: string | null = addons?.map(x => `${x.cnt}${x.id}`).join() || null

	//   // console.log( 'id_mod: ', id_mod )
	//   // console.log( 'id_secmod: ', id_secmod )
	//   // console.log( 'id_addon: ', id_addon )

	//   const id_all = [
	//     cat.id, subcat.id, item.id,
	//     id_mod, id_secmod, id_addon
	//   ].filter(x => x).join('.')

	//   // console.log( 'id_all: ', id_all )

	//   this.id = id_all
	//   this.name = '' //name
	//   this.cnt = cnt

	//   this.item = item
	//   this.cat = cat
	//   this.subcat = subcat

	//   this.mods = mods
	//   this.secMods = secmods
	//   this.addons = addons

	//   const dt_now = Date.now()
	//   this.dtc = dt_now
	//   this.dtm = dt_now
	// } // cstr

	/**
	 */
	Cnt(cnt: number) {
		this.cnt = cnt;
		this.dtm = Date.now();
	} // Cnt

	/**
	 */
	Name(name: string) {
		this.name = name;
	} // Name
} // CartOpt

// /**
//  */
// export const CartModCreate = ( id: string, cnt: number ): ICartMod =>
// {
//   return {
//     id, cnt,
//   }
// } // CartModCreate

// /**
//  */
// export const CartModName = ( cart_mod: ICartMod, name: string, ): ICartMod =>
// {
//   return {...cart_mod, name}
// } // CartModName

// /**
//  */
// export const CartModCnt = ( cart_mod: ICartMod, cnt: number, ): ICartMod =>
// {
//   return {...cart_mod, cnt}
// } // CartModCnt

// /**
//  */
// export const CartSecModCreate = ( id: string, idMod: string, cnt: number ): ICartMod =>
// {
//   return {
//     id, cnt,
//   }
// } // CartModCreate

// /**
//  */
// export const CartSecModName = ( cart_mod: ICartMod, name: string, ): ICartMod =>
// {
//   return {...cart_mod, name}
// } // CartModName

// /**
//  */
// export const CartSecModCnt = ( cart_mod: ICartMod, cnt: number, ): ICartMod =>
// {
//   return {...cart_mod, cnt}
// } // CartModCnt

// /**
//  */
// export const CartOptCreate = (
//   item: ICoreId, cat: ICoreId, subcat: ICoreId,
//   selMod?: ICartMod[], selSecMods?: ICartSecMod[],
//   selAddons?: ICartAddon[],
//   ): ICartOpt =>
// {
//   const dt_now = Date.now()

//   const id_mod: string | null = selMod?.map(x => x.id).join() || null
//   const id_secmod: string | null = selSecMods?.map(x => x.id).join() || null
//   const id_addon: string | null = selAddons?.map(x => x.id).join() || null

//   const id_all = [
//     item.id,
//     cat.id, subcat.id,
//     id_mod, id_secmod, id_addon
//   ].filter(x => x).join('')

//   return {
//     id: id_all,
//     cnt: 0,

//     item, cat, subcat,

//     selMod: selMod || undefined,
//     selSecMods: selSecMods || undefined, //arr_secmods,
//     selAddons: selAddons || undefined, //arr_addons,

//     dtc: dt_now,
//     dtm: dt_now,
//   } // TCartOpt
// } // CartOptCreate

// /**
//  */
// export const CartOptCnt = (cartOpt: ICartOpt, cnt: number) =>
// {
//   return {...cartOpt, cnt}
// } // CartOptCnt
