import { Lambda,
	// Utils
} from "../../api";

/**
 */
class Profile {
	/**
	 *    {
	 *      region: 'eu-west-1'         // optional
	 *  }
	 */
	// constructor(p) {}

	/**
	 */
	async GetAddressList(cfg) {
		try {
			const aws_lambda = new Lambda({});

			let params = {
				actType: "profile",
				act: "address:get",
				stage: cfg.stage,
				usr: "beta",
				b_shared: true
			};

			// // console.log("params", params)

			const resp_lambda = await aws_lambda.Invoke(params, cfg.lambda("beta", cfg.stage));

			if (resp_lambda.errorMessage) {
				throw new Error(resp_lambda.errorMessage);
			}

			return resp_lambda;
		} catch (err) {
			return Promise.reject(err);
		}
	}
	/**
	 */
	async GetCardList(cfg) {
		try {
			const aws_lambda = new Lambda({});

			const p_lambda = {
				usr: "beta",
				actType: "pay",
				act: "card:shared:list", //Utils.Beta_Act( 'card_list' ),
				stage: cfg.stage
			};

			console.log("Checkout_Payment: LoadCardList: p_lambda: ", p_lambda);

			const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));

			console.log("Checkout_Payment: LoadCardList: resp_lambda: ", resp_lambda);

			if (resp_lambda.errorMessage) {
				throw new Error(resp_lambda.errorMessage);
			}

			const card_list =
				resp_lambda.resp && resp_lambda.resp.data ? resp_lambda.resp.data : [];

			return card_list;
		} catch (err) {
			return Promise.reject(err);
		}
	}

	//
} // class AWS

export { Profile };
