/**
 * @copyright Elmelo Ltd.
 */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import reducers from "./rdx/reducers";

import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'

import "./index.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import { store, persistor } from "./rdx"
import {RdxWrapper} from './rdx/wrapper'

import Amplify from "aws-amplify";
import awsconfig from "./_config/aws_cfg";

// const aws_cfg = awsconfig("Email");

// console.log("aws_cfg: ", aws_cfg);

/**
 */
Amplify.configure(awsconfig("Email"));

/**
 */
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<RdxWrapper>
				<App />
			</RdxWrapper>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();




