/**
 *  @copyright  Elmelo Ltd
 */

import React from "react";
import { Container, Button, Form, Alert, Spinner, Image } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import { navigate } from "@reach/router";

import "../../css/_common.css";
import { Core, DDB, Utils } from "../../api";
import NavHdr from "../_common/nav_hdr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Checkout_Success extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		// Firebase Analytics event
		// try{
		//     Analytics.setAnalyticsCollectionEnabled(true);
		//     Analytics.setCurrentScreen('SuccessScreen', 'App');
		//     Analytics.logEvent('SuccessOpen', {
		//         'user' : 'BetaUser'
		//     });
		// } catch (err) {
		//     console.error('Error while Analytics event registery!!! : ',err);
		// }

		//
		// const shareLinkContent = {
		//     contentType: 'link',
		//     contentUrl: variables.social_share.link,
		//     contentDescription: variables.social_share.description
		// };

		// //
		// const photoUri = '../assets/img/logo_highstreet.png';
		// const sharePhotoContent = {
		//     contentType: 'photo',
		//     photos: [{ imageUrl: photoUri }],
		// };

		this.state = {
			loading: false,
			orderStatus: "new",
			mstStatus_H1: null,
			mstStatus_H2: null,
			mstStatus_H3: null
		};

		this._interval = null;
	} // cstr

	/**
	 */
	componentDidMount() {
		/**
		 * Analytics
		 */
		// Analytics.Enable().catch( err => {} );
		// Analytics.Page( 'lastorder' ).catch( err => {} );
		// Analytics.Event( 'page:lastorder' ).catch( err => {} );
		//
		// const aws_db = new DDB( {} );
		//
		//
		// // this.shareSuccess();
		// this._interval = setInterval( ()=>{
		//
		//     const cfg = this.props.__cfg
		//     const props_p = this.props.location.state;
		//
		//     const p_get = {
		//             TableName: cfg.db('order', cfg.stage)
		//         ,   Key: {
		//                 beta_user_id: props_p.beta_user_id
		//             ,   order_id    : props_p.order_id
		//             }
		//         // ,   ProjectionExpression: '#a'
		//         // ,   ExpressionAttributeNames: {'#a': 'respond'}
		//         };
		//
		//     aws_db.Get( p_get )
		//         .then( data => {
		//
		//             const order_obj = data.Item;
		//
		//             if( !order_obj ){
		//                 clearInterval( this._interval );
		//                 /// @todo   Report to core... Shouldn't happen.
		//                 return ;
		//             }
		//
		//             const inv_obj = Utils.Parse( order_obj.invoice );
		//
		//             if( !inv_obj ){
		//                 clearInterval( this._interval );
		//                 /// @todo   Report to core... Shouldn't happen.
		//                 return ;
		//             }
		//
		//             //
		//             const delivery_option = inv_obj.delivery_option.toLowerCase();
		//             const order_type = 'collection' === delivery_option ? 'collection' : 'delivery';
		//
		//             let msg_h1 = null, msg_h2 = null, msg_h3 = null;
		//
		//             if( 'new' === order_obj.respond )
		//             {
		//                 msg_h1 = 'Your order is placed successfully.';
		//                 msg_h2 = '(For card payments,  payments are only transfered once the order is accepted)';
		//                 msg_h3 = 'Order Status: Waiting to be acknowledged.';
		//             }
		//             else if( 'accepted' === order_obj.respond )
		//             {
		//                 const dt_accepted = order_obj.dt_accepted ? order_obj.dt_accepted : order_obj.respond_dt;
		//                 // const dt_accepted_duration = ord
		//
		//                 if( !dt_accepted || !order_obj.dt_accepted_duration )
		//                     return ;
		//
		//                 //
		//                 msg_h1 = 'Thank you for your order.';
		//                 msg_h2 = ['We expect your order to be ready for', order_type, 'in', order_obj.dt_accepted_duration, 'minutes'].join(' ');
		//                 msg_h3 = 'Order Status: In the kitchen.';
		//             }
		//             else if( 'declined' === order_obj.respond )
		//             {
		//                 msg_h1 = 'We are really sorry to say NO this time.';
		//                 msg_h2 = '(For card payments,  payments are reversed and should be back to you soon)';
		//                 msg_h3 = 'Order Status: Sorry :(';
		//
		//                 clearInterval( this._interval );
		//             }
		//             else if( 'ready' === order_obj.respond )
		//             {
		//                 msg_h1 = ['Your order is ready'].join(' ');
		//                 msg_h2 = 'collection' === order_type
		//                     ? 'Please come and collect your order'
		//                     : 'Your order is on its way to be delivered'
		//                     ;
		//                 msg_h3 = 'collection' === order_type
		//                     ? 'Order Status: Ready for collection'
		//                     : 'Order Status: On its way.'
		//                     ;
		//             }
		//             else if( 'released' === order_obj.respond )
		//             {
		//                 msg_h1 = 'Thank you for your order today.';
		//                 msg_h2 = 'Looking forward to your next order.';
		//                 msg_h3 = 'Order Status: Complete.';
		//
		//                 clearInterval( this._interval );
		//             }
		//
		//             if( this.state.orderStatus === order_obj.respond )
		//                 return ;
		//
		//             this.setState( {
		//                     orderStatus: order_obj.respond
		//                 ,   msgStatus_H1: msg_h1
		//                 ,   msgStatus_H2: msg_h2
		//                 ,   msgStatus_H3: msg_h3
		//                 } );
		//         } )
		//         .catch( err => {
		//             console.warn( 'SuccessScreen: OrderUpd: _ddb.Get: err', err )
		//         } )
		//         ;
		//
		//     // this._ddb.Get( p_get, (err, data) => {
		//     //     if(err){
		//     //         // console.error( err );
		//     //         return ;
		//     //     }
		//
		//     //     //
		//     // } );
		// }, /*1000*60*2*/20000 );
	}

	/**
	 */
	componentWillUnmount() {
		clearInterval(this._interval);
	}

	refreshPage = () => {
		navigate("order");
		window.location.reload();
	};

	/**
	 */
	render() {
		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr />
				<div>
					<div className="successContainer">
						<div>
							<div className="infoTitle" style={{ textAlign: "center", color: this.props.__biz.site_settings.base.colors.primary }}>
								{this.state.msgStatus_H1 ? this.state.msgStatus_H1 : this.props.__biz.biz_title + " has received your order."}
							</div>

							<div style={{ textAlign: "center" }}>
								{this.state.msgStatus_H2 ? this.state.msgStatus_H2 : "(Check your inbox and spam to confirm that your order has been accepted by the kitchen.)"}
							</div>

							{/* <div style={{ textAlign: "center" }}>
								 {this.state.msgStatus_H3 ? this.state.msgStatus_H3
									 : 'Payment is only taken once the kitchen has accepted your order.'}
							 </div> */}
						</div>
						<FontAwesomeIcon icon={faShoppingBasket} size="10x" style={{ paddingVertical: 24 }} color={"#e2e5e5"} />

						{/* <div>
							{"new" === this.state.orderStatus ? (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							) : "accepted" === this.state.orderStatus ? (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							) : "declined" === this.state.orderStatus ? (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							) : "ready" === this.state.orderStatus ? (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							) : "released" === this.state.orderStatus ? (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							) : (
								<Image className="orderCompleteImage" src={require("../assets/img/placeholder/orderComplete.png")} rounded />
							)}
						</div> */}
						<Button onClick={this.refreshPage} style={{ marginVertical: 16, background: this.props.__biz.site_settings.base.colors.primary }} className="confirmButton">
							Go Back to Menu
						</Button>
					</div>
				</div>
			</div>
		);
	}
} // render

/**
 */
const mapStateToProps = state => {
	return state;
};

/**
 */
export default connect(mapStateToProps, actions)(Checkout_Success);
