/**
 *	@copyright	Elmelo Ltd
 */

import React from "react";

import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye } from "@fortawesome/free-solid-svg-icons";

/**
 */
class TextInput extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		return (
			<Form.Control
				as={this.props.as}
				size={this.props.size}
				type={this.props.type}
				placeholder={this.props.placeholder}
				value={this.props.val}
				defaultValue={this.props.defaultValue}
				onChange={val => this.props.onChange(val)}
				className={this.props.className}
				disabled={this.props.disabled ? this.props.disabled : false}
			/>
		);
	}
} // class PhoneNumber

/**
 */
const Password_SignUp = ({ OnChange }) => {
	const [isHide, IsHide] = React.useState(true)
	const [password, Password] = React.useState('')
	const [is8Ch, Is8Ch] = React.useState(false)
	const [is1Cap, Is1Cap] = React.useState(false)
	const [is1Low, Is1Low] = React.useState(false)
	const [is1Num, Is1Num] = React.useState(false)

	/**
	 */
	React.useEffect(() => {
		// val = val.target.value.trim();
		// this.setState({ password: val, bAccept_8ch: val.length >= 8, bAccept_1Cap: /[A-Z]/.test(val), bAccept_1Low: /[a-z]/.test(val), bAccept_1N: /\d/.test(val) });

		const is_8ch = password.length >= 8
		const is_1cap = /[A-Z]/.test(password)
		const is_1low = /[a-z]/.test(password)
		const is_1num = /\d/.test(password)

		Is8Ch(is_8ch)
		Is1Cap(is_1cap)
		Is1Low(is_1low)
		Is1Num(is_1num)

		if (is_8ch && is_1cap && is_1low && is_1num) {
			OnChange(password)
		}

		//
	}, [password])

	/**
	 */
	return (
		<div>
			<Form.Group style={{ flexDirection: "row", display: "flex", position: "relative" }}>
				<div style={{ flex: 1 }}>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<TextInput
							placeholder={"Enter password"}
							type={!isHide ? "text" : "password"}
							val={password}
							size="lg"
							onChange={val => Password(val.target.value.trim())}
						/>
						<div className="hideShow">
							<div onClick={() => IsHide(!isHide)}>
								{!isHide ? "HIDE" : "SHOW"}
							</div>
						</div>
					</div>
				</div>
			</Form.Group>

			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", flex: 1 }}>
				<div style={{ display: "flex", flexDirection: "row", marginRight: 8 }}>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<FontAwesomeIcon
							icon={faCheck}
							size="sm"
							color={is8Ch ? "#159957" : "#ddd"}
						/>
						<p style={{ fontSize: 12, color: is8Ch ? "green" : "#aaa" }}>At least 8 characters</p>
					</div>
				</div>
				<div style={{ flexDirection: "row" }}>
					<div style={{ display: "flex", flexDirection: "row", marginRight: 8 }}>
						<FontAwesomeIcon
							icon={faCheck}
							size="sm"
							color={is1Cap ? "#159957" : "#ddd"}
						/>
						<p style={{ fontSize: 12, color: is1Cap ? "green" : "#aaa" }}>At least one uppercase character</p>
					</div>
				</div>
				<div style={{ flexDirection: "row" }}>
					<div style={{ display: "flex", flexDirection: "row", marginRight: 8 }}>
						<FontAwesomeIcon
							icon={faCheck}
							size="sm"
							color={is1Low ? "#159957" : "#ddd"}
						/>
						<p style={{ fontSize: 12, color: is1Low ? "green" : "#aaa" }}>At least one lowercase character</p>
					</div>
				</div>
				<div style={{ flexDirection: "row" }}>
					<div style={{ display: "flex", flexDirection: "row", marginRight: 8 }}>
						<FontAwesomeIcon
							icon={faCheck}
							size="sm"
							color={is1Num ? "#159957" : "#ddd"}
						/>
						<p style={{ fontSize: 12, color: is1Num ? "green" : "#aaa" }}>At least one number</p>
					</div>
				</div>
			</div>
		</div>
	)
}

/**
 */
export { TextInput, Password_SignUp };
