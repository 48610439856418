/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import HomeHome from './home'

import P404 from '../_common/404'

/**
 */
class App_Home extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <HomeHome path='home'/>

                <P404 default />
            </Router>
            )
    }   // render
}   // class App_Home

/**
 */
export {App_Home, HomeHome}
