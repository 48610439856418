/**
 *	@copyright	Elmelo Ltd
 */

import * as dotenv from "dotenv";

dotenv.config();

const biz_id = process.env.REACT_APP_BIZ_ID.split(" :: ");

///
const elml_biz = {
	biz_id: process.env.REACT_APP_BIZ_ID,
	service_name: biz_id[0],
	cuisine_name: biz_id[1],
	biz_name: biz_id[2],

	subTitle: "",
	desc: ["Ratatouille is the place to be if you are looking to experience the very best Indian cuisine. Ratatouille has established itself as a bustling and vibrant place."],
	homePageMsg: [],
	showAllergenMsg: "",
	fsOrg: "890AT"
};

///
export default elml_biz;
