/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Button, ButtonGroup, Image, Row, Col, Card, Modal, Carousel, Container } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import color from '../_common/colors.json'
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
import { Profile_Head, Opening_Time } from "../profile/_common";

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faMapMarked, faPhoneVolume} from '@fortawesome/free-solid-svg-icons'

// import {Offer} from './_common'
// import Info_Offer from './offer'
import Info_Award from "./award";
import ContentLoader from "react-content-loader";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight, faMapMarked, faPhone, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Info_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		// // console.log( 'Info_Home : render: props: ', this.props )

		// console.log( 'Info_Home.render: this.props: ', this.props )

		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr information={true} />

				{this.props.__biz.initStatus === "inprogress" ? (
					<div className="centerScreen" style={{ marginTop: 32 }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={200} width={200} timeout={30000000} />
					</div>
				) : (
					<div className="screenWrapper">
						<Info_Restaurant {...this.props} />
						{/* <Info_Offer {...this.props}/> */}
						{/* <Info_Gallery {...this.props}/> */}
						<Info_Award {...this.props} />
					</div>
				)}
				<div className="footer2">
					Online ordering by{" "}
					<a style={{ color: "#22c55e", fontSize: 24, fontWeight: 700, fontFamily: "Open Sans" }} href="https://quickfood.io/">
						QuickFood
					</a>
				</div>
			</div>
		);
	} // render

	// /**
	//  */
	// componentDidMount()
	// {
	// }
} // class Info_Home

class Info_Restaurant extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		const { contact, addr, ot } = this.props.__biz;
		const phoneNumber = contact.phone ? (contact.phone?.dis ? contact.phone.dis : contact.phone.def) : "";

		const addrShow =
			addr?.addr?.line1 +
			(addr?.addr?.line2 ? ", " + addr.addr.line2 : "") +
			(addr?.addr?.city ? ", " + addr.addr.city : "") +
			(addr?.addr?.county ? ", " + addr.addr.county : "") +
			", " +
			addr?.addr?.postcode +
			(addr?.addr?.country ? ", " + addr.addr.country : "");

		return (
			<div className="infoSection">
				<div style={{ flex: 1 }}>{ot.days ? <Opening_Time ot={ot} /> : null}</div>

				<div style={{ flex: 1 }}>
					<div className="infoContainer" style={{ flexDirection: "column", position: "relative" }}>
						<div className="categoryItemTitle" style={{ paddingBottom: 10 }}>
							Address
						</div>
						<a style={{ flexDirection: "row", display: "flex", alignItems: "center" }} href={addr?.map_url} target="_blank">
							<div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
								<FontAwesomeIcon style={{ marginRight: 8, color: "#2e3333" }} icon={faMapMarked} size="lg" />
								<div className="textLink">{addrShow}</div>
							</div>
							<FontAwesomeIcon style={{ color: this.props.__biz.site_settings.base.colors.primary }} icon={faChevronRight} size="lg" />
						</a>
					</div>
					<div className="infoContainer footerPadding" style={{ flexDirection: "column", position: "relative" }}>
						<div className="categoryItemTitle" style={{ paddingBottom: 10 }}>
							Got questions?
						</div>
						<a style={{ display: "flex" }} href={"tel:" + phoneNumber}>
							<div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
								<FontAwesomeIcon style={{ marginRight: 8, color: "#2e3333" }} icon={faPhone} size="lg" />
								<div className="textLink">{contact.phone ? (contact.phone.dis ? contact.phone.dis : contact.phone.def) : ""}</div>
							</div>
							<FontAwesomeIcon style={{ color: this.props.__biz.site_settings.base.colors.primary }} icon={faChevronRight} size="lg" />
						</a>
					</div>
					{this.props.__biz?.site_settings?.info?.allergen_info && (
						<div className="infoContainer footerPadding" style={{ flexDirection: "column", position: "relative" }}>
							<div className="categoryItemTitle" style={{ paddingBottom: 10 }}>
								{this.props.__biz.site_settings.info.allergen_info.title}
							</div>
							<a style={{ display: "flex" }} href={"tel:" + phoneNumber}>
								<div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
									<FontAwesomeIcon style={{ marginRight: 8, color: "#2e3333" }} icon={faAsterisk} size="lg" />
									<div className="textLink">{this.props.__biz.site_settings.info.allergen_info.text}</div>
								</div>
							</a>
						</div>
					)}
				</div>
			</div>
		);
	} // render

	/**
	 */
	componentDidMount() { }
}

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Info_Home);
