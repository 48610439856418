/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { useSelector, useDispatch, } from "react-redux";

import { ICartOpt } from "../_types/cart";

import {CartCalcAsync} from './cart.slice'

interface IRdxWrapper
{
  children: any,
}

export const RdxWrapper = ( props: IRdxWrapper ) =>
{
  const dispatch = useDispatch();

  const rdxCartItems: ICartOpt[] = useSelector( (state: any) => state.__cart.items )
  const rdxCheckout = useSelector((state: any) => state.__checkout);

  /**
   */
  React.useEffect( () => {
    // calculates total when any change occurs
    dispatch( CartCalcAsync( rdxCartItems ) )
  }, [rdxCartItems, rdxCheckout.delivery.deliveryType] )

  /**
   */
  return (
    <>
    {
      props.children
    }
    </>
  )
}

