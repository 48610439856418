/**
 * @copyright Elmelo Ltd.
 */
import React from "react";

import { Container, Form, Button, Alert } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import color from "../_common/colors.json";
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "@reach/router";
import { TextInput, Password_SignUp } from "../_common/components";
import { Core } from "../../api";
// import elml_cfg from '../../_config/elml_cfg'
import elml_biz from "../../_config/elml_biz";
import countryCode from "./CountryCode";
import { PickerEx } from "../_common/picker";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Auth_Verification extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			verificationView: false,
			password: "",
			errMsg: "",
			b_loading: false,
			f_success: false,
			code: ""
		};
	}
	username(event) {
		this.setState({ username: event.target.value });
	}

	/**
	 */
	render() {
		if (this.state.f_success) {
			return (
				<div>
					<NavHdr signin={true} />

					<div className="authContainer" style={{ textAlign: "center" }}>
						<FontAwesomeIcon style={{ marginTop: 16, marginBottom: 16 }} icon={faCheckCircle} size="5x" color={"green"} />
						<p style={{ color: "green" }}>You have changed your password successfully </p>
						<div className="authLittle">
							<i onClick={() => navigate("signin")} variant="light" className="highlight" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
								Sign In Now
							</i>
						</div>
					</div>
				</div>
			);
		}

		if (this.state.verificationView) {
			return (
				<div>
					<NavHdr signin={true} />
					<div className="authContainer">
						<div className="categoryItemTitle">Reset Password</div>
						<div className="authLittle">
							To continue, complete this verification step. We've sent a verification code to the email <b>{this.state.username}.</b>
						</div>
						<div className="authLittle">Not received code? Check spam/junk box.</div>

						<Form.Group style={{ flexDirection: "row", display: "flex", marginTop: 16 }}>
							{/*<div className="mobileCode"> +440</div>*/}
							<div style={{ flex: 1 }}>
								<TextInput placeholder="Enter Code" type="number" size="lg" val={this.state.code} onChange={val => this.setState({ code: val.target.value })} className="numberHide" />
							</div>
						</Form.Group>

						<Password_SignUp
							OnChange={val => {
								this.setState({ password: val });
							}}
						/>
						{this.state.errMsg ? (
							<Alert variant="danger" onClose={() => this.setState({ errMsg: "" })} dismissible>
								{this.state.errMsg}
							</Alert>
						) : null}
						<Form.Group>
							<Button
								onClick={this.changePass}
								style={{
									width: "100%",
									background: "rgb(50, 161, 110)",
									display: "flex",
									justifyContent: "center"
								}}
								size="lg"
								variant="info">
								Confirm
							</Button>
						</Form.Group>
					</div>
				</div>
			);
		}

		return (
			<div>
				<NavHdr signin={true} />

				<div className="signinContainer">
					<div className="authContainer">
						<Form.Group>
							<div className="categoryItemTitle">Reset Password</div>
							<div className="authLittle">Please enter your email address below and we'll email you instructions to reset it.</div>
						</Form.Group>
						<Form.Group style={{ flexDirection: "row", display: "flex" }}>
							<TextInput
								placeholder="someone@example.com"
								type="text"
								size="lg"
								val={this.state.username}
								onChange={event => this.setState({ username: event.target.value })}
								className="numberHide"
							/>
						</Form.Group>
						{this.state.errMsg ? (
							<Alert variant="danger" onClose={() => this.setState({ errMsg: "" })} dismissible>
								{this.state.errMsg}
							</Alert>
						) : null}
						<Form.Group>
							<Button
								onClick={this.reqforgetPass}
								style={{
									width: "100%",
									background: "rgb(50, 161, 110)",
									display: "flex",
									justifyContent: "center"
								}}
								size="lg"
								variant="info">
								Send Code
							</Button>
						</Form.Group>

						{/* @todo implement callback later */}
						{/* <Form.Group>
							<Button
								onClick={this.reqforgetPass}
								style={{
									width: "100%",
									background: "linear-gradient(to right, #155799, #466099)",
									display: "flex",
									justifyContent: "center"
								}}
								size="lg"
								variant="info">
								Request Callback
							</Button>
						</Form.Group> */}
					</div>
				</div>
			</div>
		);
	}

	/**
	 */
	reqforgetPass = () => {
		try {
			this.setState({ b_loading: true });

			const aws_core = new Core({});

			const { username } = this.state;
			const _username = "usr:" + elml_biz.biz_id + ":" + username;

			if (!username) throw new Error("Please enter email");

			aws_core
				.Request_Forgot_Password({ email: _username })
				.then(data => {
					if (data) this.setState({ verificationView: true });
				})
				.catch(err => {
					this.setState({ errMsg: err.message });
				});
		} catch (err) {
			console.warn("Forgotten : Auth_Verification : reqforgetPass : err : ", err);
			this.setState({ b_loading: false, errMsg: err.message });
		}
	};

	/**
	 */
	changePass = () => {
		try {
			this.setState({ b_loading: true });

			const { username, code, password } = this.state;
			const _username = "usr:" + elml_biz.biz_id + ":" + username;
			const aws_core = new Core({});

			aws_core
				.Submit_Forgot_Password({
					username: _username,
					auth_code: code,
					password: password
				})
				.then(data => {
					this.setState({ f_success: true });
				})
				.catch(err => {
					this.setState({ loading: false, errMsg: err.message });
				});
		} catch (err) {
			console.warn("Forgotten : Auth_Verification : ChangePass : err : ", err);
			this.setState({ b_loading: false, errMsg: err.message });
		}
	};
} // class Auth_Verification

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Auth_Verification);
