/**
 * @copyright Elmelo Ltd.
 */
import React from "react";
import { Container, Button, Image, Form } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import NavHdr from "../_common/nav_hdr";
import color from "../_common/colors.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faTruckMoving, faShoppingBag, faMoneyBill, faArrowDown, faCreditCard, faClock, faMapMarked, faPhone, faList } from "@fortawesome/free-solid-svg-icons";
import "../../css/_common.css";

// import {
//     Checkout_Personal, Checkout_Payment,Checkout_Summary, Checkout_Delivery
// }from './index'
// import Checkout_Personal from "./personal";
import Checkout_Delivery from "./delivery";
import Checkout_Payment from "./payment";
import Checkout_Summary from "./summary_2";
// import Checkout_Success from './success'
import Coupon_Code from "../wallet/coupon";

import { navigate } from "@reach/router";

import * as dotenv from "dotenv";
dotenv.config();

const windowWidth = window.innerWidth;
/**
 */
class Checkout_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		this.state = {
			// customer:true,
			delivery: true,
			payment: false,
			customerCom: false,
			deliveryCom: false,
			paymentCom: false,
			comment: "",
			coupon: false
		};

		this.myRef = React.createRef();
	}

	/**
	 */
	componentDidMount() {}

	showCustomer = val => {
		this.setState({ customer: val, delivery: true, customerCom: true });
	};

	showDelivery = (val, h_p) => {
		this.myRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});

		this.setState({ delivery: val, payment: this.state.paymentCom ? false : h_p ? false : true, deliveryCom: h_p ? false : true });
	};

	showPayment = (val, h_d) => {
		this.myRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});

		this.setState({ payment: val, paymentCom: h_d ? false : true });

		if (h_d) this.setState({ delivery: false });
	};

	onClose = () => {
		document.body.classList.remove("bodyOf");
		this.props.Rdx_Checkout_Clear_Without_Coupon();
		navigate("order");
	};

	showCoupon = val => {
		this.setState({ coupon: val });
	};

	onComment = e => {
		this.setState({ comment: e.target.value });
		this.props.Rdx_Checkout_comment(e.target.value);
	};
	/**
	 */
	render() {
		let ct = this.props.__checkout.delivery_time;
		let ctDate = new Date(ct * 1000);
		let ctHours = ctDate.getHours();
		let ctMinutes = "0" + ctDate.getMinutes();
		let collect_time = ctHours + ":" + ctMinutes.substr(-2);

		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr />
				<div className="overlay" style={{ background: this.props.__biz.site_settings.base.colors.secondary }}>
					<div className="orderProcessHeader" style={{ backgroundColor: this.props.__biz.site_settings.base.colors.secondary }}>
						<h3 style={{ flex: 1 }}>Checkout</h3>
						<Button onClick={() => this.onClose()} variant="light">
							<FontAwesomeIcon style={{ width: 50 }} icon={faTimes} size="lg" color="#ef5350" />
						</Button>
					</div>
					<div className="orderProcessContainer" style={{ backgroundColor: this.props.__biz.site_settings.base.colors.secondary }}>
						<div className="orderInfo">
							<div ref={this.myRef}></div>
							<div className="processBox" style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}>
								{!this.state.deliveryCom ? (
									<div className="processTabSelect" style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }} onClick={() => this.showDelivery(true, true)}>
										<FontAwesomeIcon
											icon={this.props.__checkout.delivery.deliveryType === "collection" ? faShoppingBag : faTruckMoving}
											size="lg"
											color={this.props.__biz.site_settings.base.colors.primary}
										/>
										<div className="processText" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
											{this.props.__checkout.delivery.deliveryType === "collection" ? "Collection" : "Delivery"} {"Details"}
										</div>
									</div>
								) : (
									<div className="processTab" onClick={() => this.showDelivery(true, true)}>
										<FontAwesomeIcon icon={faCheck} size="lg" color="green" />
										<div className="processText">
											{this.props.__checkout.delivery.deliveryType === "collection" ? "Collection" : "Delivery"} {"Details"}
										</div>
									</div>
								)}
								{this.state.delivery ? (
									<div className="processTab">
										<FontAwesomeIcon icon={faMoneyBill} size="lg" color={color.colors.light} />
										<div className="processText">Payment Details</div>
									</div>
								) : !this.state.paymentCom ? (
									<div className="processTabSelect" style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }} onClick={() => this.showPayment(true, true)}>
										<FontAwesomeIcon icon={faMoneyBill} size="lg" color={this.props.__biz.site_settings.base.colors.primary} />
										<div className="processText" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
											Payment Details
										</div>
									</div>
								) : (
									<div className="processTab" onClick={() => this.showPayment(true, true)}>
										<FontAwesomeIcon icon={faCheck} size="lg" color="green" />
										<div className="processText">Payment Details</div>
									</div>
								)}

								{this.state.deliveryCom && this.state.paymentCom ? (
									<div className="processTabSelect" style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}>
										<FontAwesomeIcon icon={faList} size="lg" color={this.props.__biz.site_settings.base.colors.primary} />
										<div className="processText" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
											Summary
										</div>
									</div>
								) : (
									<div className="processTab">
										<FontAwesomeIcon icon={faList} size="lg" />
										<div className="processText">Summary</div>
									</div>
								)}
							</div>

							{/* new ui commentout */}
							{/* <Checkout_Personal showCustomer={this.showCustomer}/>
                            <Checkout_Delivery showDelivery={this.showDelivery}/>
                            <Checkout_Payment showPayment={this.showPayment}/> */}

							{/* {
                                this.state.customer ?
                                    <Checkout_Personal showCustomer={this.showCustomer}/>
                                    : null
                            } */}
							{this.state.delivery ? (
								<div>
									<Checkout_Delivery showDelivery={this.showDelivery} onClose={this.onClose} />
								</div>
							) : null}
							{this.state.payment ? (
								<div>
									<Checkout_Payment showPayment={this.showPayment} showDelivery={this.showDelivery} />
								</div>
							) : null}
							{this.state.deliveryCom && this.state.paymentCom ? (
								<div className="orderSuccessContainer">
									{/*<Image src={require('../assets/img/opc.png')} style={{height:300,width:"auto"}}/>*/}
									{/* <div className="infoDescription" style={{marginVertical: 16}}>
                                            Order Process Completed
                                        </div> */}

									<div className="singleItem2" style={{ borderBottom: "none", display: "flex", flexDirection: "column" }}>
										<div className="commonFlex">
											<div className="itemName">{this.props.__checkout.delivery.deliveryType === "collection" ? "Collection details" : "Delivery details"}</div>
											<div onClick={() => this.showDelivery(true, true)} className="processButtonText" style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
												Edit
											</div>
										</div>
										{this.props.__checkout.delivery.deliveryType === "collection" ? (
											<div>
												<div className="commonFlex p4">
													<FontAwesomeIcon icon={faClock} size="lg" color={color.colors.light} />
													<span className="zistText">Collection time: {isNaN(this.props.__checkout.delivery_time) ? "ASAP" : collect_time}</span>
												</div>
												<div className="commonFlex p4">
													<FontAwesomeIcon icon={faMapMarked} size="lg" color={color.colors.light} />
													<span className="zistText">
														Collect from:
														<span> </span>
														{this.props.__biz.addr.addr
															? this.props.__biz.addr.addr.line1 +
															  (this.props.__biz.addr.addr.line2 ? ", " + this.props.__biz.addr.addr.line2 : "") +
															  (this.props.__biz.addr.addr.city ? ", " + this.props.__biz.addr.addr.city : "") +
															  (this.props.__biz.addr.addr.postcode ? ", " + this.props.__biz.addr.addr.postcode : "") +
															  (this.props.__biz.addr.addr.county ? ", " + this.props.__biz.addr.addr.county : "") +
															  (this.props.__biz.addr.addr.country ? ", " + this.props.__biz.addr.addr.country : "")
															: // ? [
															  // 		this.props.__biz.addr.addr_reg.line1,
															  // 		this.props.__biz.addr.addr_reg.line2,
															  // 		this.props.__biz.addr.addr_reg.city,
															  // 		this.props.__biz.addr.addr_reg.postcode,
															  // 		this.props.__biz.addr.addr_reg.county,
															  // 		this.props.__biz.addr.addr_reg.country
															  //   ].join(", ")
															  ""}
													</span>
												</div>
											</div>
										) : (
											<div>
												<div className="commonFlex p4">
													<FontAwesomeIcon icon={faPhone} size="lg" color={color.colors.light} />
													<span className="zistText">{this.props.__checkout.info.phone}</span>
												</div>
												<div className="commonFlex2 p4">
													<FontAwesomeIcon icon={faMapMarked} size="lg" color={color.colors.light} />
													<span className="zistText">
														Delivery address:{" "}
														{this.props.__checkout.delivery.deliveryAddress
															? this.props.__checkout.delivery.deliveryAddress.line1 +
															  (this.props.__checkout.delivery.deliveryAddress.line2 ? ", " + this.props.__checkout.delivery.deliveryAddress.line2 : "") +
															  (this.props.__checkout.delivery.deliveryAddress.city ? ", " + this.props.__checkout.delivery.deliveryAddress.city : "") +
															  (this.props.__checkout.delivery.deliveryAddress.postal_code ? ", " + this.props.__checkout.delivery.deliveryAddress.postal_code : "") +
															  (this.props.__checkout.delivery.deliveryAddress.county ? ", " + this.props.__checkout.delivery.deliveryAddress.county : "") +
															  (this.props.__checkout.delivery.deliveryAddress.country ? ", " + this.props.__checkout.delivery.deliveryAddress.country : "")
															: //   [
															  // 		(this.props.__checkout.delivery.deliveryAddress.line1,
															  // 		this.props.__checkout.delivery.deliveryAddress.line2,
															  // 		this.props.__checkout.delivery.deliveryAddress.city,
															  // 		this.props.__checkout.delivery.deliveryAddress.postcode,
															  // 		this.props.__checkout.delivery.deliveryAddress.county,
															  // 		this.props.__checkout.delivery.deliveryAddress.country)
															  //   ].join(", ")
															  ""}
													</span>
												</div>
											</div>
										)}
									</div>

									<div>
										<div className="singleItem2" style={{ borderBottom: "none", display: "flex", flexDirection: "column" }}>
											<div className="commonFlex">
												<div className="itemName">Payment details</div>
												<div
													onClick={() => this.showPayment(true, true)}
													className="processButtonText"
													style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
													Edit
												</div>
											</div>

											{this.props.__checkout.payment.creditInfo ? (
												<div className="commonFlex p4">
													<FontAwesomeIcon icon={faCreditCard} size="lg" color={color.colors.light} />
													<span className="zistText">{"ends with " + this.props.__checkout.payment.creditInfo.card.last4}</span>
												</div>
											) : null}
										</div>
										{/* <div className='singleItem' style={{borderBottom:"none"}}>{this.props.__checkout.payment.paymentType}</div> */}
									</div>
									<div>
										<Form.Group>
											<div className="extraDivText"> Comments for kitchen/driver</div>
											<Form.Control style={{ resize: "none" }} as="textarea" rows={2} value={this.state.comment} onChange={e => this.onComment(e)} />
										</Form.Group>
									</div>
									<div>
										{this.state.coupon ? (
											<Coupon_Code showCoupon={this.showCoupon} />
										) : (
											<div
												onClick={() => this.showCoupon(true)}
												style={{ marginBottom: 24, color: this.props.__biz.site_settings.base.colors.primary, fontSize: 18, fontWeight: 700, cursor: "pointer" }}>
												+Add coupon code
											</div>
										)}
									</div>
									{/* <div className="infoTitle" style={{marginVertical: 16}}>
                                            Place your order from the Summary Tab
                                            <div className="infoDescription" style={{marginVertical: 16,textAlign:"center"}}>
                                                and enjoy your Meal
                                            </div>

                                        </div> */}
								</div>
							) : null}
						</div>
						{/* {
							windowWidth < 993 && this.state.deliveryCom && this.state.paymentCom ?
								<div className="summery">
									<Checkout_Summary showDelivery={this.showDelivery} showPayment={this.showPayment} confirmPayment={this.state.deliveryCom && this.state.paymentCom ? true : false} />
								</div> :
								null
						} */}
						<div className="summery">
							<Checkout_Summary showDelivery={this.showDelivery} showPayment={this.showPayment} confirmPayment={this.state.deliveryCom && this.state.paymentCom ? true : false} />
						</div>
					</div>
				</div>
			</div>
		);
	}
} // class Info_Home
/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Checkout_Home);
