import React from 'react';
import {Map,
    // InfoWindow,
    Marker, GoogleApiWrapper} from 'google-maps-react';
export class MapContainer extends React.PureComponent 
{
    render() 
    {
        // // console.log( "_common : MapContainer : render : props : ", this.props );
        
        return (
            <Map
                containerStyle={{position:'relative',width: '100%',minHeight: 200,}}
                google={this.props.google} zoom={15}
                initialCenter={{
                    lat: this.props.geocode.lat,
                    lng: this.props.geocode.lng
                }}
            >
            
                <Marker onClick={this.onMarkerClick}
                    name={'Current location'} />
    
                {/* <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                        <h1>{"Hello world"}</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M")
})(MapContainer)