/**
 *	@copyright	Elmelo Ltd
 */

import { Lambda } from "../AWS";
import ChatIoT from "./chat_iot";

/**
 */
export const Msg_Send = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_send = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "msg",
			act: "send:msg",

			topic: p.topic ? p.topic : "chat",
			ch_id: p.ch_id,
			usr_list: p.usr_list,
			pub_id_list: p.pub_id_list,
			sender: p.sender,

			msg: p.msg,
			type: p.type,
			meta: p.meta,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush,

			dev_id: p.dev_id,
			l_id: p.l_id
		};

		// console.log( 'chat/engine: Msg_Send: p_send: ', p_send )

		const resp_send = await aws_lambda.Invoke(p_send, cfg.lambda("chat", cfg.stage));

		// console.log( 'chat/engine: Msg_Send: resp_send: ', resp_send )

		if (resp_send.errorMessage) {
			throw new Error(resp_send.errorMessage);
		}

		return resp_send.resp;
	} catch (err) {
		console.warn("chat/engine: Msg_Send: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_Send

/**
 */
export const Msg_Get = async (ch_obj, p_msg, cfg) => {
	try {
		return {};
	} catch (err) {
		console.warn("chat/engine: Msg_Get: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_Get

/**
 */
export const Msg_Last = async (ch_obj, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "msg",
			act: "last:msg",

			ch_id: ch_obj.ch_id
			// b_ascending: p_msg.b_ascending ? true : false,
		};

		// // console.log( 'chat/engine: Msg_Last: p_lambda: ', p_lambda )

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Msg_Last: resp_lambda: ', resp_lambda )

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Msg_Last: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_Last

/**
 */
export const Msg_List = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "msg",
			act: "list:msg",

			ch_id: p.ch_id,
			n_msg: p.n_msg ? p.n_msg : 20
			// b_ascending: p_msg.b_ascending ? true : false,
		};

		if (p.start_key) p_lambda.start_key = p.start_key;
		// // console.log( 'chat/engine: Msg_List: p_lambda: ', p_lambda )

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Msg_List: resp_lambda: ', resp_lambda )

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda;
	} catch (err) {
		console.warn("chat/engine: Msg_List: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_List

/**
 */
export const Msg_Edit = async (ch_obj, p_msg, cfg) => {
	try {
		return {};
	} catch (err) {
		console.warn("chat/engine: Msg_Edit: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_Edit

/**
 */
export const Msg_Rem = async (ch_obj, p_msg, cfg) => {
	try {
		return {};
	} catch (err) {
		console.warn("chat/engine: Msg_Rem: err: ", err);

		return Promise.reject(err);
	}
}; // Msg_Rem

/**
 */
export const Mark_Read = async (ch_obj, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "msg",
			act: "reset:cnt",

			usr_id: ch_obj.usr_id,
			ch_id: ch_obj.ch_id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		// // console.log( 'chat/engine: Mark_Read: p_lambda: ', p_lambda )

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Mark_Read: resp_lambda: ', resp_lambda )

		if (resp_lambda) {
			//
		}

		return {};
	} catch (err) {
		console.warn("chat/engine: Mark_Read : err: ", err);

		return Promise.reject(err);
	}
}; // Mark_Read

/**
 */
export const User_GetUnread = async (ch_obj, cfg) => {
	try {
		const aws_lambda = new Lambda();

		// // console.log( "Chat_Engine : User_GetUnread : ch_obj : ", ch_obj )

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "usr",
			act: "getUnread:usr",

			usr_id: ch_obj.usr_id,
			ch_id: ch_obj.ch_id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		// // console.log( "Chat_Engine : User_GetUnread : p_lambda : ", p_lambda );

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));
		// // console.log( "Chat_Engine : User_GetUnread : resp_lambda : ", resp_lambda );

		return resp_lambda;
	} catch (err) {
		console.warn("Chat_Engine : User_Get : err : ", err);
		return Promise.reject(err);
	}
};

/**
 */
export const User_GetTotUnread = async cfg => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "usr",
			act: "getTotUnread:usr"
		};

		// // console.log( "Chat_home : User_GetTotUnread : p_lambda : ", p_lambda );

		const unreadCount = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		return unreadCount;
	} catch (err) {
		console.warn("Chat_Engine : User_GetTotUnread : err : ", err);
		return Promise.reject(err);
	}
};

/**
 */
export const Channel_List = async (p, cfg) => {
	try {
		// const aws_core = new Core( {} )
		const aws_lambda = new Lambda(/* aws_core */);

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "list:chnl",

			limit: p.limit,
			start_key: p.start_key
		};

		// // console.log( 'chat_home : Init : p_lambda : ', p_lambda );

		const channel_list = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		return channel_list;
	} catch (err) {
		console.warn("chat/engine: Channel_List: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_List

/**
 */
export const Channel_Get = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		// get ...
		const p_get = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "get:chnl",

			ch_t: "grp",
			ch_id: p.ch_id
		};

		// // console.log( 'chat/engine: Channel_Get: p_get: ', p_get )

		const resp_get = await aws_lambda.Invoke(p_get, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Channel_Get: resp_get: ', resp_get )

		if (resp_get && resp_get.errorMessage) {
			throw new Error(resp_get.errorMessage);
		}

		return resp_get.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Get: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Get

/**
 */
export const Channel_Grp = async (p, cfg) => {
	try {
		const ch_get = await Channel_Get(p, cfg);

		if (ch_get && ch_get.resp) {
			// // console.log( 'chat/engine: Channel_Grp: exit get...' )

			return ch_get.resp;
		}

		const aws_lambda = new Lambda();

		// set ...
		const p_set = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "new:chnl",

			ch_t: "grp",
			name: p.name,
			ch_id: p.ch_id,
			grp_t: p.grp_t,

			userIds: p.userIds,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		// // console.log( 'chat/engine: Channel_Grp: p_set: ', p_set )

		const resp_set = await aws_lambda.Invoke(p_set, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Channel_Grp: resp_set: ', resp_set )

		if (resp_set.errorMessage) {
			throw new Error(resp_set.errorMessage);
		}

		return resp_set.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Grp: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Grp

/**
 */
export const Channel_Ind = async (p, cfg) => {
	try {
		const ch_get = await Channel_Get(p, cfg);

		if (ch_get && ch_get.resp) {
			// // console.log( 'chat/engine: Channel_Ind: exit get...' )

			return ch_get.resp;
		}

		const aws_lambda = new Lambda();

		// set ...
		const p_set = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "get:chnl",

			ch_t: "ind",
			name: p.name,
			id: p.id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		const resp_set = await aws_lambda.Invoke(p_set, cfg.lambda("chat", cfg.stage));

		if (resp_set.errorMessage) {
			throw new Error(resp_set.errorMessage);
		}

		return resp_set.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Ind: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Ind

/**
 */
export const Channel_Edit = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "edit:chnl",
			// name: p.name,
			// id: p.id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Edit: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Edit

/**
 */
export const Channel_Rem = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "rem:chnl",

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Rem: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Rem

/**
 */
export const Channel_Markers = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "markers:chnl",

			ch_id: p.ch_id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		// // console.log( 'chat/engine: Channel_Markers: p_lambda: ', p_lambda )
		// // console.log( 'chat/engine: Channel_Markers: fnname: ', cfg.lambda('chat', cfg.stage) )

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Channel_Markers: resp_lambda: ', resp_lambda )

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Markers: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Markers

/**
 */
export const Channel_UsrList = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "usr_list:chnl",

			ch_id: p.ch_id,

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		// // console.log( 'chat/engine: Channel_Markers: p_lambda: ', p_lambda )
		// // console.log( 'chat/engine: Channel_Markers: fnname: ', cfg.lambda('chat', cfg.stage) )

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// // console.log( 'chat/engine: Channel_Markers: resp_lambda: ', resp_lambda )

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Usr_List: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Usr_List

/**
 */
export const Channel_Usr_Invite = async cfg => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "usr:invite",

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Usr_Invite: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Usr_Invite

/**
 */
export const Channel_Usr_Rem = async cfg => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "channels",
			act: "usr:rem",

			b_db: cfg.chat.bDb,
			b_iot: cfg.chat.bIoT,
			b_push: cfg.chat.bPush
		};

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	} catch (err) {
		console.warn("chat/engine: Channel_Usr_Rem: err: ", err);

		return Promise.reject(err);
	}
}; // Channel_Usr_Rem

/**
 */
export const Channel_Id_FromUsrIds = usr_id_list => {
	const id_list = usr_id_list.map(x => x.id);

	// // console.log( 'chat/engine: Channel_Usr_Rem: id_list: ', id_list )

	id_list.sort();

	// // console.log( 'chat/engine: Channel_Usr_Rem: id_list (sort): ', id_list )

	const id_tmp = id_list.join("<>");

	// // console.log( 'chat/engine: Channel_Usr_Rem: id_tmp: ', id_tmp )
	return id_tmp;
};

/**
 */
export const Init = async (p, cfg) => {
	try {
		const aws_lambda = new Lambda();

		const p_lambda = {
			stage: cfg.stage,
			pf: cfg.platform,
			usr: cfg.usr_t ? cfg.usr_t : "beta",

			actType: "usr",
			act: "init"
		};

		if (p.email) p_lambda.email = p.email;

		// console.log("chat/engine: Init: p_lambda: ", p_lambda);

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("chat", cfg.stage));

		// console.log("chat/engine: Init: resp_lambda: ", resp_lambda);

		if (resp_lambda.errorMessage) {
			throw new Error(resp_lambda.errorMessage);
		}

		return resp_lambda;
	} catch (err) {
		console.warn("chat/engine: Init: err: ", err);

		return Promise.reject(err);
	}
}; // Init

/**
 */
// exports = {
//         Msg_Send, Msg_Get, Msg_Last, Msg_List,
//         Channel_List, Channel_Grp, Channel_Ind,
//         Channel_Id_FromUsrIds,
//     }
//
// exports.Init = Init;
//
// exports.Channel_List = Channel_List;
// exports.Channel_Get = Channel_Get;
// exports.Channel_Grp = Channel_Grp;
// exports.Channel_Ind = Channel_Ind;
// exports.Channel_Edit = Channel_Edit;
// exports.Channel_Rem = Channel_Rem;
//
// exports.Channel_UsrList = Channel_UsrList;
// exports.Channel_Usr_Invite = Channel_Usr_Invite;
// exports.Channel_Usr_Rem = Channel_Usr_Rem;
//
// exports.Channel_Markers = Channel_Markers;
//
// exports.Msg_Get = Msg_Get;
// exports.Msg_Last = Msg_Last;
// exports.Msg_List = Msg_List;
// exports.Msg_Send = Msg_Send;
// exports.Msg_Edit = Msg_Edit;
// exports.Msg_Rem = Msg_Rem;
//
// exports.Mark_Read = Mark_Read;
//
// exports.User_GetUnread = User_GetUnread;
//
// exports.User_GetTotUnread = User_GetTotUnread;
//
// exports.Channel_Id_FromUsrIds = Channel_Id_FromUsrIds;
