/**
 * Created by kkmeg on 1/8/2020.
 */
/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
	// Button, ButtonGroup,
	// Image,
	// Row,
	Col,
	Card
	// Modal,
	// Carousel,
	// Container,
} from "react-bootstrap";
import "../../css/_common.css";
import * as dotenv from "dotenv";
dotenv.config();

class Offer extends React.PureComponent {
	// constructor( props )
	// {
	//     super( props )
	// }
	render() {
		return (
			<Col key={this.props.key} xs="12" md="12" lg="12">
				<Card style={{ marginBottom: 16 }}>
					<div className="commonFlex">
						<div style={{ flex: 1 }}>
							<Card.Img variant="top" style={{ height: "100%", objectFit: "cover" }} src={this.props.img} />
						</div>
						<div style={{ flex: 2 }}>
							<Card.Body style={{ padding: 32 }}>
								<Card.Title className="infoTitle" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
									{this.props.title}
								</Card.Title>
								<Card.Text className="infoSubTitle">{this.props.price}</Card.Text>
								<Card.Text className="infoSubTitle">{this.props.subTitle}</Card.Text>
								<Card.Text className="offerDetails">{this.props.description}</Card.Text>
							</Card.Body>
						</div>
					</div>
				</Card>
			</Col>
		);
	}
}

export { Offer };
