/**
 *	@copyright	Elmelo Ltd
 */

// import elml_cfg from '../../_appConfig/elml_exports'

import {Lambda, DDB, Core, AStorage} from '../../api'
import {Time} from '../../api'

/**
 *	DDB structure:
 *		Key
 *			1. beta_user_id (STRING)
 *			2. rsrv_dt_st (NUMBER)
 *		Attributes:
 *			0. rsrv_id (STRING)
 *			1. rsrv_event_id (STRING)
 *			2. rsrv_custom (MAP)
 *			3. rsrv_dt_create (NUMBER)
 *			4. rsrv_dt_mod (NUMBER)
 */
class Rsrv
{
	/**
	 */
	constructor( cfg )
	{
		// this.tbl = 'test' === elml_cfg.stage ? 'test_'+elml_cfg.ddb.soc_rsrv : elml_cfg.ddb.soc_rsrv;
		this.tbl = cfg.db( 'soc_rsrv',cfg.stage )
	}

	/**
	 */
	Waiting = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();

			const params_s = {
		            TableName: this.tbl
		        ,   FilterExpression: "#a = :a and #b >= :b and #c = :c"
		        ,   ExpressionAttributeNames: {
			        		'#a': 'beta_user_id',
			        		'#b': 'rsrv_dt_st',
			        		'#c': 'rsrv_status',
		        		}
		        ,   ExpressionAttributeValues: {
		        			':a': beta_user_id,
		        			':b': Time.Epoch( Date.now() ),
		        			':c': 'req_new'
		        		}
		        };

		    // // console.log( 'Rsrv: Waiting: params_s: ', params_s );

		    const data = await aws_ddb.Scan( params_s );

		    // // console.log( 'Rsrv: Waiting: data: ', data );

		    if( !data.Count )
		    	return [];

		    // data.Items.sort( (a, b) => {} );
			// return data.Items.filter( x => x.rsrv_status === 'req_new' );
		    return data.Items;
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Waiting: err: ', err );
			return Promise.reject( err );
		}
	}	// Waiting

	/**
	 */
	Upcoming = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();

			const params_q = {
		            TableName: this.tbl
		        ,   KeyConditionExpression: "#a = :a and #b >= :b "
		        ,   ExpressionAttributeNames: {
		            	'#a': 'beta_user_id',
						'#b': 'rsrv_dt_st'

					}
		        ,   ExpressionAttributeValues: {
		            	':a': beta_user_id,
						':b': Time.Epoch( Date.now() )


					}
		        };

		    // // console.log( 'Rsrv: Upcoming: params_q: ', params_q );

		    const data = await aws_ddb.Query( params_q );

		    // // console.log( 'Rsrv: Upcoming: data: ', data );

		    // return data.Items;
		    return data.Items.filter( x => x.rsrv_status === 'req_accepted' );
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Upcoming: err: ', err );
			return Promise.reject( err );
		}
	}	// Upcoming

    DayRsrvCheck = async ( params ) =>
    {
        try
        {
            const aws_core = params.core ? params.core : new Core( {} );
            const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );


            const params_s = {
            		TableName: this.tbl
                ,   FilterExpression: "#a = :a and #b between :b and :c "
                ,   ExpressionAttributeNames: {
                    '#a': 'rsrv_biz_id',
                    '#b': 'rsrv_dt_st'

                }
                ,   ExpressionAttributeValues: {
                    ':a': params.biz_id,
                    ':b': Time.Epoch( params.dt_start ),
                    ':c': Time.Epoch( params.dt_end )

                }
            };

            // // console.log( 'Rsrv: Upcoming: params_q: ', params_q );
            const data = await aws_ddb.Scan( params_s );
            // console.log( 'Rsrv: Upcoming: data: ', data );
            // return data.Items;
            return data;
        }
        catch( err )
        {
            console.warn( 'Social: Rsrv: Upcoming: err: ', err );
            return Promise.reject( err );
        }
    }	// Upcoming
	/**
	 */

	List = async ( params ) =>
	{
		try
		{
			// // console.log( 'Rsrv: List: params: ', params );

			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();

			const params_q = {
		            TableName: this.tbl
		        ,   KeyConditionExpression: "#a = :a and #b between :b1 and :b2"
		        ,   ExpressionAttributeNames: { '#a': 'beta_user_id', '#b': 'rsrv_dt_st' }
		        ,   ExpressionAttributeValues: { ':a': beta_user_id
		        		, ':b1': params.dt_range_st, ':b2': params.dt_range_end
		        		}
		        };

		    // // console.log( 'Rsrv: List: params_q: ', params_q );
		 //    // console.log( 'params_q' );
			// // console.log( params_q );

			const data = await aws_ddb.Query( params_q );

			// console.log( 'Rsrv: List: data: ', data );

			return data.Items;
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: List: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove

	/**
	 */
	Request = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();

			const dt_create = Time.Epoch( Date.now() );
			const rsrv_id = beta_user_id + '_' + dt_create;

			const upd_exp = 'set'
				+ '  #a = if_not_exists(#a, :a)'
				+ ', #b = if_not_exists(#b, :b)'
				// + ', #c = if_not_exists(#c, :c)'
				+ ', #d = if_not_exists(#d, :d)'
				+ ', #e = if_not_exists(#e, :e)'
				+ ', #f = if_not_exists(#f, :f)'
				+ ', #g = if_not_exists(#g, :g)'

				+ ', #attr1 = if_not_exists(#attr1, :attr1)'
				+ ', #attr2 = if_not_exists(#attr2, :attr2)'
				+ ', #attr3 = if_not_exists(#attr3, :attr3)'
				+ ', #attr4 = if_not_exists(#attr4, :attr4)'
				+ ', #attr5 = if_not_exists(#attr5, :attr5)'
				+ ', #attr6 = if_not_exists(#attr6, :attr6)'
				+ ', #attr7 = if_not_exists(#attr7, :attr7)'
				+ ', #attr8 = if_not_exists(#attr8, :attr8)'
				+ ', #attr9 = if_not_exists(#attr9, :attr9)'
				+ ', #attr10 = if_not_exists(#attr10, :attr10)'
				+ ', #attr11 = if_not_exists(#attr11, :attr11)'
				+ ', #attr12 = if_not_exists(#attr12, :attr12)'
				+ ', #attr13 = if_not_exists(#attr13, :attr13)'
				+ ', #attr14 = if_not_exists(#attr14, :attr14)'
				// + ', #attr15 = if_not_exists(#attr15, :attr15)'
				// + ', #attr16 = if_not_exists(#attr16, :attr16)'
				// + ', #attr17 = if_not_exists(#attr17, :attr17)'
				;

			const params_u = {
		            TableName: this.tbl
		        ,   Key: {
			                'beta_user_id': beta_user_id
			            ,	'rsrv_dt_st': params.keys.rsrv_dt_st
			            }
		        ,	UpdateExpression: upd_exp
		        ,	ExpressionAttributeNames: {
				        	'#a': 'rsrv_id'
				        ,	'#b': 'rsrv_event_id'
				        // ,	'#c': 'rsrv_cal_id'
				        ,	'#d': 'rsrv_dt_end'
				        ,	'#e': 'rsrv_status'
				        ,	'#f': 'rsrv_biz_id'	// [restaurant_name+cuisine_name] + [service_name]
				        ,	'#g': 'rsrv_custom'

				        ,	'#attr1': 'rsrv_duration'
				        ,	'#attr2': 'rsrv_nppl'
				        ,	'#attr3': 'rsrv_title'
				        ,	'#attr4': 'rsrv_subtitle'
				        ,	'#attr5': 'rsrv_desc'

				        ,	'#attr6': 'rsrv_type'
				        ,	'#attr7': 'rsrv_typeid'

				        ,	'#attr8': 'rsrv_name'
				        ,	'#attr9': 'rsrv_email'
				        ,	'#attr10': 'rsrv_phone'
				        ,	'#attr11': 'rsrv_bizname'
				        ,	'#attr12': 'rsrv_created'
				        ,	'#attr13': 'reservation_data'
				        ,	'#attr14': 'pub_id'
				        // ,	'#attr15': 'section'
				        // ,	'#attr16': 'block'
				        // ,	'#attr17': 'rsrv_time'
				        }
		        ,	ExpressionAttributeValues: {
		        			':a': rsrv_id
				        ,	':b': params.attrs.rsrv_event_id
				        // ,	':c': params.attrs.rsrv_cal_id
				        ,	':d': params.attrs.rsrv_dt_end
				        ,	':e': 'req_new'
				        ,	':f': params.attrs.rsrv_bizid
				        ,	':g': params.attrs.rsrv_custom

				        ,	':attr1': params.attrs.rsrv_duration
				        ,	':attr2': params.attrs.rsrv_nppl
				        ,	':attr3': params.attrs.rsrv_title
				        ,	':attr4': params.attrs.rsrv_subtitle
						,	':attr5': params.attrs.rsrv_desc
						
				        ,	':attr6': params.attrs.rsrv_type
						,	':attr7': params.attrs.rsrv_typeid
						
				        ,	':attr8': params.attrs.rsrv_name
				        ,	':attr9': params.attrs.rsrv_email
				        ,	':attr10': params.attrs.rsrv_phone
				        ,	':attr11': params.attrs.rsrv_bizname
				        ,	':attr12': dt_create
				        ,	':attr13': params.attrs.reservation_data
				        ,	':attr14': params.attrs.pub_id
				        // ,	':attr15': params.attrs.section
				        // ,	':attr16': params.attrs.block
				        // ,	':attr17': params.attrs.rsrv_time
				        }
		        ,	ReturnValues: 'UPDATED_OLD'
		        };

		    // console.log( 'Social: Rsrv: Request: params_u: ', params_u );

		    const data = await aws_ddb.Upd( params_u );

		    if( !data.Items )
		    {
		    	//
		    }

		    return {id: rsrv_id};
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Request: err: ', err );
			return Promise.reject( err );
		}
	}	// Request

	/**
	 */
	Remove = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();

			const params_d = {
					TableName: this.tbl
				,	Key: {
						'beta_user_id': beta_user_id
		            ,   'rsrv_id': params.rsrv_id
					}
				};

			// // console.log( 'params_d' );
			// // console.log( params_d );

			/*const data =*/ await aws_ddb.Del( params_d );

			return {msg: 'OK'};
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Remove: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove

	/**
	 */
	Invite = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Invite: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove

	/**
	 */
	Accept = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Accept: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove

	/**
	 */
	Decline = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Decline: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove

	/**
	 */
	Leave = async ( params ) =>
	{
		try
		{
			const aws_core = params.core ? params.core : new Core( {} );
			const aws_ddb = params.ddb ? params.ddb : new DDB( aws_core );

			const beta_user_id = await aws_core.Id();
		}
		catch( err )
		{
			console.warn( 'Social: Rsrv: Leave: err: ', err );
			return Promise.reject( err );
		}
	}	// Remove
}	// class Rsrv

/**
 */
export default Rsrv;






