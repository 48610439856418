/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Button, ButtonGroup, Image, Row, Col, Card, Modal, Carousel, Container, Alert } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import color from '../_common/colors.json'
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
import Loader from "react-loader-spinner";
import Info_Offer from "../info/offer";

import { Lambda } from "../../api";
import { Time } from "../../api/Utils";
import { Currency } from "../../api/currency";
// import {Profile_Head , Opening_Time} from '../profile/_common'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Offer_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		this.state = {
			openOffer: false,

			imageLink: null,

			discount: [],
			selectedDiscount: null,
			couponEntered: "corona30",
			errorMessage: "",
			couponValid: "",
			bCApply: false,
			bCAlert: false,
			coupon_apply: {},
			bLoading: false
		};
	}

	applyCoupon = () => {
		console.log("applyCoupon");
	};

	/**
	 */
	submitCouponNew = async () => {
		console.log("CouponDiscount : submitCoupon : state : ", this.state);
		console.log("CouponDiscount : submitCoupon : props : ", this.props);

		try {
			this.setState({ bLoading: true });
			const coupon_name = this.state.couponEntered.toLowerCase();

			const { __biz } = this.props;
			const cfg = this.props.__cfg;
			const aws_lambda = new Lambda({});

			const params_lambda = {
				stage: cfg.stage,
				usr: "beta",
				actType: "promo",
				act: "coupon:get",
				alpha: { biz_id: __biz.biz_id },
				cpn_code: coupon_name
			};

			// // console.log('CouponDiscount: submitCouponNew : params_lambda', params_lambda);

			const coupon_obj = await aws_lambda.Invoke(params_lambda, cfg.lambda("beta", cfg.stage));
			// // console.log('CouponDiscount: submitCouponNew : coupon_obj', coupon_obj.resp);

			if (await this.verifyCoupon(coupon_obj.resp)) {
				// // console.log("Coupon verified");
				const { cpn_type } = coupon_obj.resp;
				const p_lambda = {
					stage: cfg.stage,
					usr: "beta",
					actType: "promo",
					act: [cpn_type.type, "get"].join(":"),
					alpha: { biz_id: __biz.biz_id },
					cpn_type: cpn_type
				};

				// // console.log('submitCouponNew: verified : p_lambda', p_lambda);

				const offer_obj = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));
				// // console.log('submitCouponNew: verified : offer_obj', offer_obj);
				if (await this.verifyOffer(offer_obj.resp)) {
					// const {amount,type} = offer_obj.resp;
					if (!offer_obj.resp.b_accept /* && this.props.__checkout.ListDiscounts().filter(x => x.amount).length */) {
						// this.ApplyCoupon({coupon: coupon_obj.resp, offer: offer_obj.resp});
						this.setState({
							coupon_apply: { coupon: coupon_obj.resp, offer: offer_obj.resp },
							bCAlert: true,
							couponValid: true,
							errorMessage: "",
							bLoading: false
						});
					} else {
						this.ApplyCoupon({
							coupon: coupon_obj.resp,
							offer: offer_obj.resp
						});
					}
				} else {
					// this.props.updatePopup({type: "error", msg: "Coupon is not available!"});
					this.setState({ bCAlert: true, bLoading: false });
				}
			} else {
				// this.props.updatePopup({type: "error", msg: "Coupon is not available!"});
				this.setState({ bCAlert: true, bLoading: false });
			}
		} catch (e) {
			console.warn(e);
		}
	}; // submitCouponNew
	/**
	 */
	verifyCoupon = async resp => {
		// // console.log('verifyCoupon', resp);
		if (resp) {
			return this.authorized(resp) && this.timeMatch(resp) && (await this.usgCount(resp));
		} else {
			this.setState({
				errorMessage: "Coupon is not available!",
				bCAlert: true,
				bLoading: false
			});
			return false;
		}
	}; //verifyCoupon
	/**
	 */
	verifyOffer = async resp => {
		// // console.log('verifyOffer', resp);
		if (resp) {
			return this.authorized(resp) && this.timeMatch(resp) /*&& await this.usgCount(resp)*/;
		} else {
			this.setState({
				errorMessage: "Offer is not available!",
				bCAlert: true,
				bLoading: false
			});
			return false;
		}
	}; //verifyOffer

	authorized = resp => {
		if (resp.b_public) {
			return true;
		} else {
			this.setState({
				errorMessage: "Coupon is not authorized!",
				bCAlert: true,
				bLoading: false
			});
			return false;
		}
	}; //authorized

	usgCount = async resp => {
		const { __biz } = this.props;
		const cfg = this.props.__cfg;
		const aws_lambda = new Lambda({});

		const params_lambda = {
			stage: cfg.stage,
			usr: "beta",
			actType: "promo",
			act: "coupon:get_usage",
			alpha: { biz_id: __biz.biz_id },
			cpn_code: resp.cpn_code
		};

		// // console.log('verifyCoupon: usgCount : params_lambda', params_lambda);

		const usage_obj = await aws_lambda.Invoke(params_lambda, cfg.lambda("beta", cfg.stage));

		// // console.log('verifyCoupon: usgCount : usage_obj', usage_obj);

		if (usage_obj.resp) {
			if (parseInt(usage_obj.resp.cnt_use) < parseInt(resp.max_usage)) {
				return true;
			} else {
				this.setState({
					errorMessage: "Coupon Already Used!",
					bCAlert: true,
					bLoading: false
				});
				return false;
			}
		} else {
			return true;
		}
	}; //authorized

	timeMatch = resp => {
		const dt_now = Time.Epoch();
		if (dt_now > resp.dt_st && dt_now < resp.dt_end) {
			if (resp.dt_available_type && resp.dt_avail && resp.dt_avail.days && resp.dt_available_type !== "flat" && resp.dt_avail.days.length > 0) {
				const day = resp.dt_avail.days[0];
				// // console.log('verifyCoupon: timeMatch : day', day);
				if (day) {
					let ret = false;
					day.forEach(val => {
						const dt_st = Time.Epoch(new Date().setHours(val.open.h, val.open.m, 0, 0));
						const dt_end = Time.Epoch(new Date().setHours(val.close.h, val.close.m, 0));
						if (dt_now > dt_st && dt_now < dt_end) {
							ret = true;
						}
					});
					if (!ret)
						this.setState({
							errorMessage: "Time:Coupon is not available!",
							bCAlert: true,
							bLoading: false
						});
					return ret;
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			this.setState({
				errorMessage: "Time:Coupon is not available!",
				bCAlert: true,
				bLoading: false
			});
			return false;
		}
	}; //timeMatch

	ApplyCoupon = param => {
		// this.setState({bCAlert:false,coupon_apply:{}});
		const ts_date = new Date().getTime();
		let disc_to_apply = 0.0;
		let b_applyDis = true;

		const c_sign = Currency.Sign(this.props.__biz.biz_currency);

		if (b_applyDis) {
			const disc_obj = this.CalcDisc(this.props.__checkout.charges.subtotal.amount, param.offer);
			disc_to_apply = disc_obj.amount;
			// // console.log("tets:",disc_to_apply);
			//
			// this.props.Rdx_Checkout_SetCouponDiscount({...disc_applied});

			// this.props.Rdx_Checkout_SetCouponDiscount({
			this.props.Rdx_Checkout_SetCoupon({
				...param,
				amount: disc_to_apply /*, target: coupon.target @todo what is target? */
			});
			// this.props.showCoupon(false);
			this.setState({ couponValid: false });

			return {};
		}

		return {};
	}; // ApplyCoupon

	/**
	 */
	CalcDisc = (amount, coupon) => {
		// // console.log("tets:",parseInt(coupon.amount));
		let disc_amount = "percentage" === coupon.type ? (amount * parseInt(coupon.amount)) / 100.0 : parseInt(coupon.amount);
		//@TODO: Max Limit check ;
		// disc_amount = coupon.dis_limit && disc_amount > coupon.dis_limit
		//     ? coupon.dis_limit
		//     : disc_amount
		// ;

		disc_amount = disc_amount > amount ? amount : disc_amount;

		return { amount: disc_amount, msg: null };
	}; // CalcDisc

	confirmCoupon = b => {
		if (b) {
			this.ApplyCoupon(this.state.coupon_apply);
			this.setState({ bCAlert: false, coupon_apply: {} });
		} else {
			this.setState({ bCAlert: false, coupon_apply: {}, couponValid: false });
			// this.props.showCoupon(false);
		}
	}; // CalcDisc

	/**
	 */
	render() {
		// // console.log( 'Info_Home : render: props: ', this.props )

		console.log("Offer_Home : render : props : ", this.props);

		if (this.state.couponValid) {
			return (
				<Alert variant="success">
					<div>Coupon Applied. Applying this Coupon will remove Other discounts Do you wish to proceed?</div>

					<Modal.Footer>
						<Button onClick={() => this.confirmCoupon(false)} variant="danger">
							Cancel
						</Button>
						<Button onClick={() => this.confirmCoupon(true)} variant="success">
							Apply
						</Button>
					</Modal.Footer>
				</Alert>
			);
		}

		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr offer={true} />

				{this.state.openOffer ? (
					<Modal show={true} animation={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => this.setState({ openOffer: false })}>
						<Image style={{ height: "100%", width: "100%" }} src={this.state.imageLink} />
					</Modal>
				) : null}

				{this.props.__biz.initStatus === "inprogress" ? (
					<div className="centerScreen" style={{ marginTop: 32 }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={200} width={200} timeout={30000000} />
					</div>
				) : (
					<div className="screenWrapper">
						<div style={{ margin: 16 }}>
							<div className="headerTitle">Current Offers</div>
						</div>
						<div>
							<Row>
								<Col xs="12" md="12" lg="12" key={this.props.key}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center"
										}}>
										<div style={{ marginRight: 16 }}>
											<FontAwesomeIcon icon={faUtensils} size="4x" />
										</div>
										<div classname="hudacss">
											<div className="infoTitle" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
												Oxford Launch Party
											</div>
											<div className="otTime">60% off all Food & Drink + free delivery!</div>
											<div className="otTime">Use coupon code at checkout:</div>
											<div className="categoryItemTitle">TIKKALAUNCH60</div>
											<div className="otSession">Offer ends 11pm today.</div>
											<button type="button" className="cartButton btn btn-info btn-lg" onClick={() => this.submitCouponNew()}>
												ORDER NOW!
											</button>
										</div>
									</div>
								</Col>
							</Row>
							{/* <div className="col-lg-12 tikkaofferDiv">
                                    <div className="tikkaImage">
                                        <i className="fas fa-tags" style="color:#c1311a;font-size: 42px"></i>
                                    </div>
                                    <div style="flex:1">
                                        <div className="tikkaText">Oxford Launch Party</div>
                                        <br />
                                        <div className="tikkaText"><span style="color: red">60% off all Food & Drink + free delivery! </span>
                                            <br />
                                            <br />
                                            Use coupon code at checkout:</div>
                                        <div className="tikkaText">TIKKALAUNCH60</div>
                                        <div className="tikkaCondition">Offer ends 11pm today.</div>
                                        <a className="btn btn-orange" href="item-page.html">Order now!</a>
                                    </div>
                                </div> */}
							{/* <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer1.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer1.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Complimentary Cans and Lager
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer2.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer2.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Take Away Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer3.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer3.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Easter Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer4.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer4.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Tuesday Banquet Night Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer5.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer5.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Welcome Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer6.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer6.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Lovers Weekend Offer
                                    </div>
                                </div>
                                <div
                                    onClick={()=>this.setState({openOffer:true, imageLink:'https://ovisher.co.uk/images/offers/offer7.jpg'})}
                                    style={{margin:16}}>
                                    <Image
                                        className="offerImage"
                                        src={'https://ovisher.co.uk/images/offers/offer7.jpg'}
                                    />
                                    <div className="offerTitle">
                                        Festive Feast Offer
                                    </div>
                                </div> */}
						</div>
					</div>
				)}
			</div>
		);
	} // render

	// /**
	//  */
	// componentDidMount()
	// {
	// }
} // class Info_Home

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Offer_Home);
