import React from "react";
import { Image, Button, Row, Col, Form, Modal } from "react-bootstrap";
import "../../css/_common.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Core, Lambda, DDB } from "../../api";
import ContentLoader from "react-content-loader";
import { History_Details_Box } from "./_common";
import Loader from "react-loader-spinner";
import * as dotenv from "dotenv";
dotenv.config();

class Order_History extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isRefreshing: false,
			orders: [],
			modalVisible: false,
			ts_load: 0,
			data: [],
			lastEvalKey: null
			// orderModal: false,
		};
	}

	/**
	 */
	render() {
		// console.log("data", this.state)

		if (this.state.isLoading) {
			return (
				<div className="centerScreen" style={{ marginTop: 32 }}>
					<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={200} width={200} timeout={30000000} />
				</div>
			);
		}

		return (
			<div className="profileBodyDiv">
				<div>
					<div style={{ flexDirection: "row", display: "flex" }}>
						<h3 style={{ flex: 1 }}> Order History</h3>
					</div>
					<div>
						<Row>
							{this.state.data.length === 0 ? (
								<div className="imageText">
									<Image className="orderCompleteImage" style={{ height: 250, width: "auto" }} src={require("../assets/img/placeholder/orderHistory.png")} rounded />
									<div className="alertMessage">No Order History Found</div>
								</div>
							) : (
								this.state.data.map((order, idx) => (
									<History_Details_Box
										key={idx}
										item={order}
										date={
											new Date(order.dt_create * 1000).getDay() +
											"-" +
											parseInt(new Date(order.dt_create * 1000).getMonth() + 1) +
											"-" +
											new Date(order.dt_create * 1000).getFullYear()
										}
									/>
								))
							)}
						</Row>
					</div>
				</div>
			</div>
		);
	}

	LoadData = async b_new => {
		// console.log( 'History: LoadData' );

		try {
			const cfg = this.props.__cfg;

			const tbl_name = cfg.db("order", cfg.stage);

			const aws_ddb = new DDB({});

			this.setState({ isLoading: true });

			//
			let p_q = {
				TableName: tbl_name,
				IndexName: "beta_user_id-respond_dt-index",
				KeyConditionExpression: "#a = :beta_id",
				ExpressionAttributeNames: {
					"#a": "beta_user_id"
				},
				ExpressionAttributeValues: {
					":beta_id": await aws_ddb._core.Id()
				},
				Limit: 30,
				ScanIndexForward: false
			};

			if (!b_new) p_q.ExclusiveStartKey = this.state.lastEvalKey;

			// // console.log( 'History: LoadData: p_q: ', p_q );

			// Query data
			const data_q = await aws_ddb.Query(p_q);

			// // console.log( 'History: LoadData: data_q: ', data_q );

			if (!data_q.Count) return this.setState({ isLoading: false });

			// Batch Get
			let p_tbl = {
				name: tbl_name,
				keys: []
				// ProjectionExpression: '#a.#a1, #a.#a2', ExpressionAttributeNames: {'#a': 'invoice', '#a1': 'order_amount_info', '#a2': 'Total',}
			};

			let p_get = { tables: [p_tbl] };

			p_tbl.keys = data_q.Items.map(x => {
				return { beta_user_id: x.beta_user_id, order_id: x.order_id };
			});

			// // console.log( 'History: LoadData: p_get: ', p_get );

			const data_b_get = await aws_ddb.BatchGet(p_get);

			// // console.log( 'History: LoadData: data_b_get: ', data_b_get );

			const data_tmp_ = data_b_get.Responses[tbl_name];

			const orders_arr = data_q.Items.map(x => {
				return data_tmp_.find(y => x.beta_user_id === y.beta_user_id && x.transid === y.transid);
			}).filter(x => x);

			// // console.log( 'History: LoadData: orders_arr: ', orders_arr );

			this.setState({
				isLoading: false,
				ts_load: Date.now(),
				data: b_new ? orders_arr : [...this.state.data, ...orders_arr],
				lastEvalKey: data_q.LastEvaluatedKey
			});
		} catch (err) {
			console.warn("History: LoadData: err: ", err);

			this.setState({ isLoading: false, isRefreshing: false });
		}
	}; // LoadData
	/**
	 */
	componentDidMount() {
		// console.log( 'History: componentDidMount' );

		this.LoadData()
			.then(data => {})
			.catch(err => console.error("History: componentDidMount: err: ", err));
	}
}

export default Order_History;
