/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Router } from "@reach/router";
// import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
// import './css/main.css'
// import './css/responsive.css'

import { connect } from "react-redux";
import * as actions from "./rdx/actions";
import ChatIoT from "./api/chat/chat_iot";

// import elml_biz from './_config/elml_biz'
// import FullStory from 'react-fullstory'

import {
	// App_Auth,
	AuthLogin,
	AuthSignIn,
	AuthSignUp,
	AuthVerification,
	AuthForgotten,
	AuthLogout
} from "./components/auth";

import {
	// App_Home,
	HomeHome
} from "./components/home";

import {
	// App_Info,
	InfoHome
} from "./components/info";

import {
	// App_Gallery,
	GalleryHome
} from "./components/gallery";

import {
	// App_Menu,
	MenuHome
} from "./components/menu";

import {
	// App_Rsrv,
	RsrvHome
} from "./components/reserve";

import {
	// App_Order,
	OrderHome,
	// OrderInfo,
	OrderCart
	// OrderDetails,
} from "./components/order";

import { CheckoutHome, CheckoutSuccess } from "./components/checkout";
// import {
//   App_Rsrv,
//   Rsrv_Home, Rsrv_Hist, Rsrv_New, Rsrv_Details
// } from './components/reserve'

import {
	// App_Profile,
	ProfileHome
} from "./components/profile";

import {
	// App_Chat,
	ChatHome
} from "./components/chat";
import {
	// App_Chat,
	OfferHome
} from "./components/offer";

import P404 from "./components/_common/404";

import {
	PaymentRedirect
} from "./pages/payment_redirect";

import { Core } from "./api/AWS";

import * as dotenv from "dotenv";

import Smartlook from 'smartlook-client'
dotenv.config();
// import logo from './logo.svg';
// import './App.css';

/**
 */
class App extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			idInt: -1
		};

		this._iot = null;
	}

	/**
	 */
	render() {
		return (
			<>
				{/* <title>Title from Helmet</title> */}
				{/* <meta></meta> */}
				{/* <script type="text/javascript">{`
						window['_fs_debug'] = false;
						window['_fs_host'] = 'fullstory.com';
						window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
						window['_fs_org'] = 'FGW0B';
						window['_fs_namespace'] = 'FS';
						(function(m,n,e,t,l,o,g,y){
								if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
								g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
								o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
								y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
								g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
								g.anonymize=function(){g.identify(!!0)};
								g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
								g.log = function(a,b){g("log",[a,b])};
								g.consent=function(a){g("consent",!arguments.length||a)};
								g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
								g.clearUserCookie=function(){};
								g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
								if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
								g._v="1.2.0";
						})(window,document,window['_fs_namespace'],'script','user');
				`}</script> */}

				{/* <FullStory org={elml_biz.fsOrg} /> */}
				<Router>
					{/* <HomeHome path='/'/> */}
					<HomeHome path="/home" />
					<OrderHome path="/" />
					<InfoHome path="/information" />
					<OfferHome path="/offer" />
					<GalleryHome path="/gallery" />
					<MenuHome path="/menu" />
					<RsrvHome path="/reserve" />
					<OrderHome path="/order" />
					<OrderCart path="/cart" />
					<CheckoutHome path="/checkout" />
					<CheckoutSuccess path="/success" />
					<ProfileHome path="/profile" />
					<ChatHome path="/chat" />

					{/* <App_Checkout path='/checkout'>
            <CheckoutHome path='/checkout' />
            </App_Checkout> */}

					{/* <App_Rsrv path='/reserve'>
            <RsrvHome path='/reserve' />
            </App_Rsrv> */}

					{/* <App_Chat path='/chat'>
                        <Chat_Home path='/chat'/>
                    </App_Chat> */}

					{/* <App_Profile path='/profile'>
                        <Profile_Home path='/profile'/>
                    </App_Profile> */}

					<PaymentRedirect path="/paymentredirect" />

					<AuthLogin path="/login" />
					<AuthSignIn path="/signin" />
					<AuthSignUp path="/signup" />
					<AuthVerification path="/verification" />
					<AuthForgotten path="/forgotten" />
					<AuthLogout path="/logout" />
					<P404 default />
				</Router>
			</>
		);
	} // render

	/**
	 */
	componentDidMount() {
		Smartlook.init('761048290035ad88c8ebbcd18fbe42db47105be3');
		Smartlook.record({ forms: true, numbers: true, emails: false, ips: true })
		// console.log("called")
		this.props.Rdx_Biz_Init();
		this.props.Rdx_Core_Init();

		this.VerifyAuth()
			.then(data => { })
			.catch(err => { });

		this.NetworkStatus()
			.then(data => { })
			.catch(err => { });

		window.addEventListener("online", this.NetworkStatus_Wrapper);
		window.addEventListener("offline", this.NetworkStatus_Wrapper);
	}

	/**
	 */
	componentWillUnmount() {
		window.removeEventListener("online", this.NetworkStatus_Wrapper);
		window.removeEventListener("offline", this.NetworkStatus_Wrapper);
	}

	/**
	 */
	NetworkStatus_Wrapper = () => {
		this.NetworkStatus()
			.then(data => { })
			.catch(err => { });
	};

	/**
	 */
	NetworkStatus = async () => {
		try {
			// // console.log( 'App.NetworkStatus: st' )

			const condition = navigator.onLine ? "online" : "offline";

			// // console.log( 'App.NetworkStatus: condition: ', condition )

			if (condition === "online") {
				this.props.Rdx_Core_bNetwork(true);

				// this._idInt = setInterval( async () => {
				//   try
				//   {
				//     // // console.log( 'App.NetworkStatus: st' )

				//     await fetch( '//google.com', {node: 'no-cors',} )

				//     // console.log( 'App.NetworkStatus: Internet available' )

				//     this.props.Rdx_Core_bInternet( true )
				//   }
				//   catch( err )
				//   {
				//     console.warn( 'App.NetworkStatus: No Internet' )

				//     this.props.Rdx_Core_bInternet( false )
				//   }
				// }, 10000)   // 10 sec
			} else {
				console.warn("App.NetworkStatus: No Network");

				this.props.Rdx_Core_bNetwork(false);
				this.props.Rdx_Core_bInternet(false);

				if (-1 !== this._idInt) {
					clearInterval(this._idInt);
				}
			}

			return { msg: "OK" };
		} catch (err) {
			return Promise.reject(err);
		}
	}; // NetworkStatus

	/**
	 * @todo fix for email verify
	 */
	VerifyAuth = async () => {
		try {
			const aws_core = new Core({});

			const current_cred = await aws_core.Credentials();

			if (current_cred.authenticated) {
				const current_user = await aws_core.currentuser();

				if (current_user.attributes && current_user.attributes.email_verified) {
					this.props.Rdx_Core_AuthStatus("auth");

					this.props.Rdx_Pubid_Init();

					this.props.Rdx_BetaId(await aws_core.Id());

					// if(!this.props.__chat.elml)
					//     await this.InitChat()
				} else {
					this.props.Rdx_Core_AuthStatus("veri_reqd");
				}
			} else {
				this.props.Rdx_Core_AuthStatus("unauth");
			}

			return { msg: "OK" };
		} catch (err) {
			console.warn("App.VerifyAuth: err: ", err);
			return Promise.reject(err);
		}
	}; // VerifyAuth
	/**
	 */
	InitChat = async () => {
		try {
			this.props.Rdx_ChatInit_InProgress();

			const p_iot = {
				beta_id: this.props.__core.betaId,
				cfg: this.props.__cfg,
				OnMsg: this.OnMsg,
				OnEvent: this.OnEvent
			};

			this._iot = new ChatIoT(p_iot);

			const resp_init_iot = await this._iot.Init();

			// // console.log( 'AppMenu_Custom : InitChat : resp_init_iot: ', resp_init_iot )

			if (resp_init_iot) {
				//
			}

			const resp_conn = await this._iot.Connect("app");

			await this._iot.Subscribe("chat", "__all__");

			// // console.log( 'AppMenu_Custom : InitChat : resp_conn: ', resp_conn )

			if (resp_conn) {
				//
			}

			this.props.Rdx_Chat(this._iot);

			this.props.Rdx_ChatInit_Complete();

			this.props.Rdx_Chat_totUnRead_Req();

			return {};
		} catch (err) {
			this.props.Rdx_ChatInit_Complete();

			console.warn("AppMenu_Custom : InitChat : err: ", err);

			return Promise.reject(err);
		}
	}; // InitChat

	/**
	 */
	OnMsg = async (topic, msg) => {
		try {
			// console.log('AppMenu_Custom: OnMsg: topic: ', topic)
			// console.log('AppMenu_Custom: OnMsg: msg: ', msg)
			// console.log('AppMenu_Custom: OnMsg: msg: ', this.props.__chat)

			this.props.Rdx_Chat_totUnRead_Req();

			return {};
		} catch (err) {
			console.warn("AppMenu_Custom: OnMsg: err: ", err);

			return { err };
		}
	}; // OnEvent
} // class App

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(App);
