/**
 * @copyright Elmelo Ltd.
 */

import React from "react";
import { navigate } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faMinus, faPlus,
	faTimes,
	faTrashAlt,
	faArrowLeft,
	faShoppingBasket,
	faClock
} from "@fortawesome/free-solid-svg-icons";
import { Button, Alert, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

// import * as actions from "../../rdx/actions";
// import { Rdx_Checkout_SetSubtotal, Rdx_Checkout_SetItemCount } from "../../rdx/actions/checkout";
import { ICartState, CartClear } from "../../rdx/cart.slice";

// import { Time } from "../../api";
// import { AStorage } from "../../api/Utils";

import { ICartOpt } from "../../_types/cart";

import color from "../_common/colors.json";

import { GetSubTotal } from "./ont_2";

// import { ItemPrice } from "./itemPrice";
// import { PickerEx } from "../_common/picker";

// import { CheckVisAvail } from "./_common";
import { CartItem } from "./cart_item_2";

import * as dotenv from "dotenv";
dotenv.config();

// constants that are not updated now
const height = window.innerHeight - (70 + 260);
const heightMobile = window.innerHeight - 180;
// const catheight = window.innerHeight - 70
// const width = window.innerWidth

interface IOrderCart {
	showCart: any;
	mCart: boolean;

	// showCart: any;
} // IOrderCart

/**
 */
const OrderCart = (props: IOrderCart) => {
	const dispatch = useDispatch();

	const rdxCore = useSelector((state: any) => state.__core);
	const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxCart: ICartState = useSelector((state: any) => state.__cart);
	// const rdxOrder = useSelector((state: any) => state.__order);
	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [showModal, ShowModal] = React.useState(false);
	const [errMsg, ErrMsg] = React.useState("");
	const [otArr, OtArr] = React.useState([]);
	const [selTime, SelTime] = React.useState("now");

	/**
	 */
	React.useEffect(() => {
		InitOt()
			.then((data: any) => {
				OtArr(data);
			})
			.catch(err => {});

		const _interval = setInterval(() => {
			InitOt()
				.then((data: any) => {
					OtArr(data);
				})
				.catch(err => {});
		}, 5 * 60 * 1000);

		/**
		 */
		return () => {
			clearInterval(_interval);
		};
	}, []);

	/**
	 */
	const InitOt = async (date?: any) => {
		try {
			let ot_arr: any[] = [];

			let _date = date ? date : new Date();

			const ot_obj = rdxBiz.ot;

			ot_obj?.days?.length &&
				ot_obj.days[_date.getDay()].map((x: any) => {
					if (x.if_open) {
						// let count = 0;
						let start = new Date(),
							end = new Date();

						start.setHours(x.open[0], x.open[1]);
						end.setHours(x.close[0], x.close[1]);

						while (end > start) {
							if (start >= _date) {
								const interval_time = {
									label: [start.getHours(), String(start.getMinutes()).padStart(2, "0")].join(":"),
									val: [start.getHours(), String(start.getMinutes()).padStart(2, "0")].join(":"),
									key: [start.getHours(), start.getMinutes()].join(":")
								};
								ot_arr.push(interval_time);
							}
							start = new Date(start.getTime() + 15 * 60 * 1000);
							// ++count;
						}
					}
				});

			if (ot_arr.length) {
				ot_arr.splice(0, 0, {
					label: "Now",
					val: "Now",
					key: "Now"
				});
			}
			// else
			// {
			//     ot_arr.push({
			//         label: "Now",
			//         val: "Now",
			//         key: "Now"
			//     })
			// }

			return ot_arr;
		} catch (err) {
			console.warn("Res_Today: InitOt: err: ", err);
			return Promise.reject(err);
		}
	}; // InitOt

	/**
	 */
	const SelectTime = (val: any) => {
		try {
			SelTime(val);

			if (val === "Now") {
				// this.props.Rdx_Set_Delivery_Time({ adv_order: false });
			} else {
				let today = new Date();

				const obj = val.split(":");

				today.setHours(obj[0], obj[1], 0);

				// this.props.Rdx_Set_Delivery_Time({ adv_order: true, delivery_time: Time.Epoch(today) });
			}
		} catch (err) {
			console.warn("EPOS_Cart SelectTime: err:", err);
			return { err };
		}
	};

	/**
	 */
	const getOT = async () => {
		const dt = new Date();
		const day = dt.getDay();

		const ot_day = rdxBiz.ot.days[day].map((x: any) => {
			x.open = x.open.map((val: any) => {
				try {
					val = val.toString();
				} catch (err) {
					console.warn("ot_day : open : err : ", err);
				}

				return val.padStart(2, "0");
			});

			x.close = x.close.map((val: any) => {
				try {
					val = val.toString();
				} catch (err) {
					console.warn("ot_day : close : err : ", err);
				}

				return val.padStart(2, "0");
			});

			const open_n = parseInt(x.open.join(""), 10);
			const close_n = parseInt(x.close.join(""), 10);

			return { ...x, open_n, close_n };
		});

		const hours = dt.getHours().toString().padStart(2, "0");
		const mins = dt.getMinutes().toString().padStart(2, "0");
		const secs = dt.getSeconds().toString().padStart(2, "0");
		const t_in_number = parseInt([hours, mins, secs].join(""), 10);
		const ot_cur = ot_day.find((x: any) => t_in_number >= x.open_n && t_in_number < x.close_n);

		if (ot_cur) {
			const ot_str = [ot_cur.open[0], ot_cur.open[1]].join(":") + " - " + [ot_cur.close[0], ot_cur.close[1]].join(":");
			return { open: true, msg: "" };
		}

		let ot_day_sorted = [...ot_day];

		ot_day_sorted.sort((a, b) => a.open_n - b.open_n);
		ot_day_sorted = ot_day_sorted.map(x => {
			x._diff = t_in_number - x.open_n;

			return x;
		});

		ot_day_sorted = ot_day_sorted.filter(x => x._diff <= 0);

		if (ot_day_sorted.length) {
			ot_day_sorted.sort((a, b) => b._diff - a._diff);
			const ot_next = ot_day_sorted[0];
			const ot_next_str = [ot_next.open[0], ot_next.open[1]].join(":") + " - " + [ot_next.close[0], ot_next.close[1]].join(":");

			return { open: false, msg: "We are closed right now. Will start service from " + ot_next_str };
		} else {
			return { open: false, msg: "We are closed for today." };
		}
	};

	const placeOrder = async () => {
		// if (this.props.__cfg.stage === "test") {
		//     navigate("checkout");
		//     return
		// }
		const ifOpen = await getOT();

		if (rdxBiz.suspend.alpha === "true") {
			alert(rdxBiz.suspend.alpha_msg);
		} else if (rdxBiz.suspend.beta === "true") {
			alert(rdxBiz.suspend.beta_msg);
		} else if (!ifOpen.open) {
			// this.setState({ errMsg: ifOpen.msg, bModal: true });
			ErrMsg(ifOpen.msg);
			ShowModal(true);
			// alert(ifOpen.msg);
		} else if (!rdxCart.items.length) {
			// this.setState({ errMsg: "Please add an item" });
			ErrMsg("Please add an item");
			alert("Add an Item to Proceed.");
		} else {
			navigate("checkout");
		}
	};

	/**
	 */
	const clearCart = () => {
		dispatch(CartClear());
		// this.props.Rdx_Order_ClearCart();

		// this.props.Rdx_Checkout_SetSubtotal(0);
		// this.props.Rdx_Checkout_SetItemCount(0);
	};

	return (
		<div>
			<div className="innerCart">
				<>
					{props.showCart && (
						<div className="cartbackBtn" style={{ marginRight: 8, cursor: "pointer" }} onClick={() => props.showCart(false)}>
							<FontAwesomeIcon icon={faArrowLeft} size="lg" color={rdxBiz.site_settings.base.colors.primary} />
						</div>
					)}
				</>
				<div className="categoryItemTitle onlyMobile">
					Your order for <span>{props.showCart ? rdxCheckout.delivery.deliveryType : ""}</span>
				</div>
				<div className="categoryItemTitle onlyDesktop">Basket</div>

				<div className="cartDelete" onClick={clearCart}>
					<FontAwesomeIcon icon={faTrashAlt} size="lg" color={rdxBiz.site_settings.base.colors.primary} />
				</div>
			</div>
			<div className="cartItemsContainer" style={{ height: props.mCart ? heightMobile : height, borderColor: color.colors.borderLight }}>
				{/*<Cart_Items {...this.props} />*/}
				{!rdxCart.items.length ? (
					<div className="imageText">
						{/* <Image
                            className="orderCompleteImage"
                            style={{width:250}}
                            src={require('../assets/img/placeholder/noItem.png')}
                            rounded
                        /> */}
						<FontAwesomeIcon icon={faShoppingBasket} size="7x" color={"#e2e5e5"} />
						<div className="emptyBasket">Your basket is empty</div>
					</div>
				) : (
					// rdxCart.items.map((sect: any, idx: number) => {
					// 	return sect.data.map(
					// 		(item: any) => <CartItem key={idx} item={item} sect={sect} />
					// 	);
					// })
					rdxCart.items.map((cart_item: ICartOpt, idx: number) => (
						// <CartItem key={idx} cart={cart_item} />
						<CartItem key={cart_item.id} cart={cart_item} />
					))
					// null
				)}
			</div>

			<div className="cartBottom">
				{/* <div className="commonFlexMobile">
						<div className="belowText">
							<div>Subtotal</div>
						</div>

						<h3 style={{ fontSize: 22 }}>£{this.props.__checkout.charges.subtotal.amount.toFixed(2)}</h3>
					</div> */}
				{showModal && (
					<Modal centered size="sm" aria-labelledby="contained-modal-title-vcenter" show={true} onHide={() => ShowModal(false)} animation={true}>
						<div style={{ flex: 1 }}>
							<div onClick={() => ShowModal(true)}>
								<div style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)" }}></div>
							</div>
							<div style={{ height: "80%", backgroundColor: "#fff", padding: 16 }}>
								<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
									<FontAwesomeIcon onClick={() => ShowModal(false)} style={{ cursor: "pointer" }} icon={faTimes} size="lg" color="#ef5350" />
								</div>
								<div style={{ padding: 20, textAlign: "center" }}>
									<FontAwesomeIcon icon={faClock} size="5x" color={"#5a5a5a"} />
								</div>
								<Alert variant="danger" style={{ textAlign: "center" }}>
									{errMsg}
								</Alert>
							</div>
						</div>
					</Modal>
				)}
				<SubTotal />
				{
					// !rdxCart.items.length ? (
					!rdxCart.cntItems ? (
					<Button disabled className="cartButton" size="lg" variant="secondary">
						Go to checkout
					</Button>
				) : "auth" === rdxCore.authStatus ? (
					<Button onClick={() => placeOrder()} className="cartButton" style={{ backgroundColor: rdxBiz.site_settings.base.colors.primary }} size="lg" variant="info">
						Go to checkout
					</Button>
				) : (
					<Button onClick={() => navigate("signin")} className="cartButton" style={{ backgroundColor: rdxBiz.site_settings.base.colors.primary }} size="lg" variant="info">
						Go to checkout
					</Button>
				)}
			</div>
		</div>
	);
}; // OrderCart

/**
 */
export default OrderCart;

/**
 */
const SubTotal = () => {
	const rdxCart: any = useSelector((state: any) => state.__cart);

	const [basketTotal, BasketTotal] = React.useState("0.00");

	React.useEffect(() => {
		BasketTotal(rdxCart.subTotal.toFixed(2));
	}, [rdxCart]);

	/**
	 */
	return (
		<div className="commonFlexMobile">
			<div className="belowText">
				<div>Subtotal</div>
			</div>

			<h3 style={{ fontSize: 22 }}>£{basketTotal}</h3>
		</div>
	);
}; // SubTotal
