/**
 *  @copyright  Elmelo Ltd
 */

/**
───────────────────────────────────────────────────────────────────────────────────────────────
─██████████████─██████████████─██████─────────██████████████─██████──██████─████████████████───
─██░░░░░░░░░░██─██░░░░░░░░░░██─██░░██─────────██░░░░░░░░░░██─██░░██──██░░██─██░░░░░░░░░░░░██───
─██░░██████████─██░░██████░░██─██░░██─────────██░░██████░░██─██░░██──██░░██─██░░████████░░██───
─██░░██─────────██░░██──██░░██─██░░██─────────██░░██──██░░██─██░░██──██░░██─██░░██────██░░██───
─██░░██─────────██░░██──██░░██─██░░██─────────██░░██──██░░██─██░░██──██░░██─██░░████████░░██───
─██░░██─────────██░░██──██░░██─██░░██─────────██░░██──██░░██─██░░██──██░░██─██░░░░░░░░░░░░██───
─██░░██─────────██░░██──██░░██─██░░██─────────██░░██──██░░██─██░░██──██░░██─██░░██████░░████───
─██░░██─────────██░░██──██░░██─██░░██─────────██░░██──██░░██─██░░██──██░░██─██░░██──██░░██─────
─██░░██████████─██░░██████░░██─██░░██████████─██░░██████░░██─██░░██████░░██─██░░██──██░░██████─
─██░░░░░░░░░░██─██░░░░░░░░░░██─██░░░░░░░░░░██─██░░░░░░░░░░██─██░░░░░░░░░░██─██░░██──██░░░░░░██─
─██████████████─██████████████─██████████████─██████████████─██████████████─██████──██████████─
───────────────────────────────────────────────────────────────────────────────────────────────
*/
/**
 */
class Colour
{
    static Invert = ( col_hex ) =>
    {
        const colour_ = (Number('0x'+col_hex) ^ Number(0xFFFFFF)).toString(16);
        return ('000000'.substring(0,6-colour_.length)+colour_);
    }

    static FromStr = ( str, saturation, lightness ) =>
    {
        const hash_ = Colour.Hash( str );

        const colour_ = Math.abs( hash_ ).toString(16).substring(0, 6);

        return ('000000'.substring(0,6-colour_.length)+colour_);
    }

    static Hash = ( str ) =>
    {
        let hash_ = 0;

        for( let i = 0; i < str.length; ++i )
            hash_ = str.charCodeAt(i) + ((hash_<<5) - hash_);

        return hash_;
    }
}   // class Colour

//
export { Colour };

