/**
 *  @copyright  Elmelo Ltd
 */

import React from "react";
import {
  // Image,
  Button,
  // Row, Col, Form, Modal
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import { Core, DDB } from "../../api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSquare, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import color from "../_common/colors.json";
// import { navigate } from "@reach/router";

import * as dotenv from "dotenv";
dotenv.config();

interface ILoyalty
{
  ShowLoyalty: any,
}

/**
 */
const Loyalty = ( props: ILoyalty ) =>
{
  const dispatch = useDispatch();

  const rdxCfg = useSelector((state: any) => state.__cfg);
  const rdxBiz = useSelector((state: any) => state.__biz);

  const [isLoading, IsLoading] = React.useState(false)
  const [loyaltyList, LoyaltyList] = React.useState<any[]>([])
  const [schemeList, SchemeList] = React.useState<any[]>([])

  //
  React.useEffect( () => {
    // this.setState({ loading: true });

		_Loyalty_Get().catch( console.warn );
  }, [] )

  /**
	 */
	async function _Loyalty_Get() {
		try {
			// const cfg = this.props.__cfg;

			const aws_core = new Core({});
			const aws_ddb = new DDB({});

			// this.setState({ loading: true });

      IsLoading( true )

			const p_query = {
				TableName: rdxCfg.db("wallet", rdxCfg.stage),
				KeyConditionExpression: "#a = :a",
				ExpressionAttributeNames: { "#a": "beta_user_id" },
				ExpressionAttributeValues: { ":a": await aws_core.Id() }
			};

			const resp_query = await aws_ddb.Query(p_query);
			// // console.log("loyalty data", resp_query);
			// this.setState({ loyalty_list: resp_query.Items, loading: false });
      LoyaltyList( resp_query.Items )
      IsLoading( false )
		} catch (err) {
      IsLoading( false )
			return Promise.reject(err);
		}
	} // _Loyalty_Get

	/**
	 */
	const selectLoyalty = (loyalty: any) => {
		let _index: number = schemeList.findIndex(item => item.id === loyalty.wallet_id);

		if (_index != -1) {
			loyalty.check = false;
			schemeList.splice(_index, 1);
		} else {
			loyalty.check = true;
			schemeList.push({ id: loyalty.wallet_id, amount: loyalty.available });
		}

		// console.log( 'schemes', this.state.schemes )
		// this.setState({ ts: Date.now() });
	};

	const _ApplyLoyalty = () => {
		// this.props.Rdx_Checkout_SetLoyalty(this.state.schemes);
    dispatch(actions.Rdx_Checkout_SetLoyalty(schemeList))
		props.ShowLoyalty(false);
	};

  //
  return (
    <div className="extraDiv">
      <div onClick={() => props.ShowLoyalty(false)} className="buttonAuthDiv">
        <FontAwesomeIcon className="extraButtonAuth" icon={faTimes} size="lg" color={"red"} />
      </div>

      <>
      {
        !isLoading ?
        loyaltyList.length === 0 ?
        <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <div style={{ color: "rgba(0,0,0,0.25)" }}>No Loyalty Points are available!</div>
        </div>  : (
        <div>
          <div className="extraDivText">Select Special Discount</div>
          <div>
            {loyaltyList.map((wallet, index) => {
              return (
                <div key={index} onClick={() => selectLoyalty(wallet)}>
                  {
                    <div className="addressSelect" style={{ color: color.colors.light }}>
                      <FontAwesomeIcon
                        style={{ marginRight: 8 }}
                        icon={wallet.check ? faCheckSquare : faSquare}
                        size="lg" color={wallet.check ? "green" : "#C4C4C4"}
                      />
                      <div>
                        {wallet.lt_name ? wallet.lt_name : wallet.wallet_id}( {wallet.available} )
                      </div>
                    </div>
                  }
                </div>
              );
            })}
          </div>
          <div>
            <Button
              onClick={() => _ApplyLoyalty()}
              className="cartButton"
              style={{ background: rdxBiz.site_settings.base.colors.primary }}
              size="lg" variant="info"
            >
              Apply
            </Button>
          </div>
        </div>
      ) :
      
      <div>Loading...</div>
      }
      </>
    </div>
  )
} // Loyalty

// //
// const mapStateToProps = state => {
// 	return state;
// };

//
// export default connect(mapStateToProps, actions)(Loyalty);
export default Loyalty



