/**
 *	@copyright	Elmelo Ltd.
 */

// import elml_cfg from '../../_config/elml_cfg'
import {AStorage} from '../../api/Utils'

import * as checkout from './checkout'

import ONT from '../../components/order/ont'//'./ont'

// /**
//  */
// export const Rdx_Order_MenuReq = ( cfg ) =>
// {
//     return async (dispatch, gs) =>
//     {
//         try
//         {
//             if( 'inprogress' === gs().__order.menuReqStatus )
//                 return {msg: 'OK'}

//             dispatch( Rdx_Order_MenuReqStatus('inprogress') )

//             //

//             dispatch( Rdx_Order_MenuReqStatus('stale') )

//             return {msg: 'OK'}
//         }
//         catch( err )
//         {
//             dispatch( Rdx_Order_MenuReqStatus('stale') )

//             console.warn( 'reducers/core: Rdx_Core_CfgReq: err: ', err )

//             return {err}
//         }
//     }   // return ...
// }   // Rdx_Order_MenuReq

// /**
//  */
// const Rdx_Order_MenuReqStatus = (cfg_status) =>
// {
//     return {
//         type: 'order:menu-req-status'
//     ,   payload: cfg_status
//     }
// }

/**
 */
export const Rdx_Order_AddToCart = ( item, sect, cnt_change ) =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            // console.log( 'Rdx_Order_AddToCart: item: ', item )
            // console.log( 'Rdx_Order_AddToCart: sect: ', sect )
            // console.log( 'Rdx_Order_AddToCart: cnt_change: ', cnt_change )

            // console.log( 'Rdx_Order_AddToCart: 1' )

            dispatch( Rdx_Order_UpdateCart( item, sect, cnt_change ) )

            // console.log( 'Rdx_Order_AddToCart: 2' )

            // //
            // const total_obj = ONT.GetTotal(gs().__order.cart_sects, gs().__checkout.delivery.deliveryType);

            // // console.log( 'Rdx_Order_AddToCart: total_obj: ', total_obj )

            // dispatch( checkout.Rdx_Checkout_SetSubtotal( total_obj.total ) )

            // // console.log( 'Rdx_Order_AddToCart: 3' )

            // dispatch( checkout.Rdx_Checkout_SetItemCount( total_obj.item_cnt ) )

            // // console.log( 'Rdx_Order_AddToCart: 4: gs().__order.cart_sects: ', gs().__order.cart_sects )

            // console.log( 'gs().__order.cart_sects: ', gs().__order.cart_sects )

            // //
            // await AStorage.Set( "cart", gs().__order.cart_sects )
            AStorage.Set( "cart", gs().__order.cart_sects ).catch( console.warn )

            // console.log( 'before debounce' )

            // debounce( () => {
            //     // console.log( 'inside debounced func' )
            //     // console.log( 'gs().__order.cart_sects: ', gs().__order.cart_sects )
            //     AStorage.Set( "cart", gs().__order.cart_sects ).catch( console.warn )
            // } )()

            //
            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'reducers/core: Rdx_Order_AddToCart: err: ', err )

            return {err}
        }
    }   //
}   // Rdx_Order_AddToCart

// function debounce(func, timeout = 300)
// {
//     // console.log( 'inside debounce 1' )
//     let timer;

//     return (...args) => {
//         // console.log( 'inside debounce 2: timeout: ', timeout )

//         clearTimeout(timer);

//         // console.log( 'inside debounce 3' )

//         timer = setTimeout(() => {
//             // console.log( 'inside debounce 4' )

//             func.apply(this, args);
//         }, timeout)
//     };
// }

/** order:Old
 */
export const Rdx_Old_Order = ( obj ) =>
{
    return {
        type: 'order:old-order',
        payload: obj
    }
}

/**
 */
export const Rdx_Order_UpdateCart = (item, sect, cnt_change) =>
{
	return {
		type: 'order:cart-upd'
		,   payload: {item, sect, cnt_change}
	};
}

// /**
//  */
// export const Rdx_Order_ClearCart = payload =>
// {
//     return ( dispatch, gs ) => {
//         console.warn( 'reducers/core: Rdx_Order_ClearCart: gs(): ', gs() )

//         gs().__order.cart_sects.forEach( sect => {
//             sect.data.forEach( item => {
//                     // gs().__core.menu_prep.items[item._id].
//                     // // console.log( 'reducers/core: Rdx_Core_Init: item: ', item );
    
//                     // const obj = gs().__core.menu.menu_prep.items[item._id]
    
//                     // fullMenu_prep.items[item._id]._cnt = item._cnt
    
//                     // gs().__core.menu.menu_prep.items[item._id]._cnt = 0
//                     // gs().__core.menu.menu_prep.items[item._id]._mods = []
//                     // gs().__core.menu.menu_prep.items[item._id]._modex = []
//                     // gs().__core.menu.menu_prep.items[item._id]._opts = []
//                     // item._cnt = 0
    
//                     // item._mods = null
//                     // item._modex = null
//                     // item._opts = null
//                     // if( item._mods )
//                     //     fullMenu_prep.items[item._id]._mods = item._mods
    
//                     // if( item._modex )
//                     //     fullMenu_prep.items[item._id]._modex = item._modex
    
//                     // if( item._opts )
//                     //     fullMenu_prep.items[item._id]._opts = item._opts
    
//                     // // console.log( 'reducers/core: Rdx_Core_Init: obj: ', obj )
//                     // gs().
//                     // dispatch( order.Rdx_Order_AddToCart( item, sect, item._cnt ) )
//                 } )
//         } )

//         dispatch( ClearCart( payload ) )
//     }   // renturn ...
// }   // Rdx_Order_ClearCart

/**
 */
export const Rdx_Order_ClearCart = ( payload ) =>
{
    return async (dispatch, gs) => {
        try
        {
            dispatch( ClearCart( payload ) )

            await AStorage.RemoveItem( "cart")
        }
        catch( err )
        {
            console.warn( 'reducers/core: Rdx_Order_ClearCart: err: ', err )

            return {err}
        }
    }
    // return {
    //     type: 'order:clear-cart'
    //     ,   payload: payload
    // }
}

/**
 */
function ClearCart( payload )
{
    return {
        type: 'order:clear-cart'
        ,   payload: payload
    }
}

