/**
 *	@copyright	Elmelo Ltd.
 */

import elml_cfg from '../../_config/elml_cfg'

export default ( state = elml_cfg, action ) =>
{
    return state
}



