/**
 * @copyright Elmelo Ltd.
 */

import React from "react";
import {
	// Container,
	Button,
	// Form,
	Alert,
	Spinner
} from "react-bootstrap";

import { navigate } from "@reach/router";

import cuid from "cuid";

import { useSelector, useDispatch } from "react-redux";
// import * as actions from "../../rdx/actions";
import { Rdx_Core_Init } from "../../rdx/actions/core";
import { Rdx_Order_ClearCart } from "../../rdx/actions/order";
import { Rdx_Checkout_SetSubtotal, Rdx_Checkout_SetItemCount, Rdx_Checkout_Clear } from "../../rdx/actions/checkout";

import { CartClear } from "../../rdx/cart.slice";

import { Rdx_Pubid_Init, Rdx_Chat_Channel } from "../../rdx/actions/chat";

import { ICartOpt } from "../../_types/cart";

// import elml_cfg from '../../_config/elml_cfg'
import "../../css/_common.css";
import {
	Core,
	// DDB,
	Lambda
	// Utils, Common, Time
} from "../../api";

// import NavHdr from '../_common/nav_hdr'
import color from "../_common/colors.json";
// import ONT from "../order/ont";

import CouponCode from "../wallet/coupon_2";
import Loyalty from "./loyalty_2";

import { Invoice_Save, Invoice_Process } from "./checkout_invoice_2";
import CheckoutConfirmation from "./confirmation_2";
import {
	// Init,
	Msg_Send
} from "../../api/chat/chat_engine";
// import { AStorage } from "../../api/Utils";

// import { ItemPrice } from "../order/itemPrice";

// import { Cart_Item_Basic, Cart_Item_Mod, Cart_Item_ModEx, Cart_Item_SetMeal } from "../order/cart";
import { CartItem } from "../order/cart_item_2";
import { GetSubTotal } from "../order/ont_2";

import * as dotenv from "dotenv";
dotenv.config();

interface ICheckoutSummary {
	confirmPayment: boolean;
} // ICheckoutSummary

// let _charge: any | null = null;
// let _order: any | null = null;
// let _orderId: any | null = null;

/**
 */
const Checkout_Summary = (props: ICheckoutSummary) => {
	const dispatch = useDispatch<any>();

	const rdxCfg = useSelector((state: any) => state.__cfg);
	const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxChat = useSelector((state: any) => state.__chat);
	const rdxCoreMenu: any[] = useSelector((state: any) => state.__core.menu.menu);
	const rdxOrder = useSelector((state: any) => state.__order);
	const rdxCart = useSelector((state: any) => state.__cart);
	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [coupon, Coupon] = React.useState(false);
	const [sLoyalty, SLoyalty] = React.useState(false);
	const [ipAddr, IpAddr] = React.useState("0.0.0.0");
	const [geoCoord, GeoCoord] = React.useState({ lat: 1, lan: 1 });
	const [errMsg, ErrMsg] = React.useState("");
	const [buttonActive, ButtonActive] = React.useState(true);

	const [isModalConfirmation, IsModalConfirmation] = React.useState(false);
	const [comment, Comment] = React.useState("");

	const [paymData, PaymData] = React.useState<any>(null);
	const [orderObj, OrderObj] = React.useState<any>(null);
	const [orderId, OrderId] = React.useState<any>(null);

	/**
	 */
	const Order_Status_Update = async () => {
		try {
			const aws_core = new Core({});

			const aws_lambda = new Lambda({});

			const order_obj = orderObj;
			const dt_now = Date.now();

			let p_order: any = {
				usr: "beta",
				actType: "order",
				act: "order:update",
				stage: rdxCfg.stage,
				key: {
					beta_user_id: await aws_core.Id(),
					order_id: orderObj?.order_id
				},
				data: {
					paid_info: rdxCheckout.payment.paymentType.toLowerCase() === "cash" ? "not-paid" : "paid",
					respond: "new",
					// ch_id: this.props.__chat.chat_channel.ch_id, //  @todo iot fix
					usr_id: rdxChat.user_id
				},
				ses: {
					dt: dt_now,
					trans_id: order_obj.order_id,
					email_from: rdxBiz.contact.email.def, // @todo check if contact email exist first
					order_from: "web",
					email_to: [rdxCheckout.info.email],
					msg_sub: rdxBiz.biz_title + " Order Confirmation " + order_obj.dt_create,
					alpha: {
						biz_id: rdxBiz.biz_id,
						biz_title: rdxBiz.biz_title
					},
					msg_body: await Invoice_Process(order_obj, { rdxBiz })
				}
			};

			if (rdxCheckout.payment.paymentType.toLowerCase() === "card") {
				p_order.data.paid_by = [
					{
						paid_by: "card",
						amount: orderObj.invoice.Total,
						cardno: rdxCheckout.payment.creditInfo.card.last4
					}
				];
			}

			if (rdxCheckout.payment.paymentType.toLowerCase() === "cash") {
				p_order.data.paid_by = [{ paid_by: "cash", amount: orderObj?.invoice?.Total }];
			}

			const resp_order = await aws_lambda.Invoke(p_order, rdxCfg.lambda("beta", rdxCfg.stage));

			if (resp_order.errorMessage) {
				throw new Error(resp_order.errorMessage);
			}
			return { msg: "OK" };
		} catch (err) {
			console.warn("Checkout_Summary: Order_Status_Update: err: ", err);

			return Promise.reject(err);
		}
	}; // Order_Status_Update

	/**
	 */
	const Beta_Order_Complete = async () => {
		try {
			// const rdx_biz = this.props.__core.bizInfo;
			// const cfg = this.props.__cfg;

			const aws_lambda = new Lambda({});

			const p_charge: any = {
				stage: rdxCfg.stage,
				usr: "beta",
				actType: "pay",
				act: "card:charge:upd",
				trans_id: paymData.success.trans_id,
				charge: paymData.charge
			};

			const resp_charge = await aws_lambda.Invoke(p_charge, rdxCfg.lambda("beta", rdxCfg.stage));

			if (resp_charge.errorMessage) {
				throw new Error(resp_charge.errorMessage);
			}

			return { msg: "OK" };
		} catch (err) {
			console.warn("Checkout_Summary: Beta_Order_Complete: err: ", err);

			return Promise.reject(err);
		}
	}; // Beta_Order_Complete

	/**
	 */
	const FinaliseOrder = async () => {
		try {
			/**trans Update */

			if (paymData) {
				await Beta_Order_Complete();
			}

			/// @todo for later
			if (!rdxChat.chat_channel) {
				await ChatInit();
			}

			/**Order Status Update */
			await Order_Status_Update();

			/** Coupon Useage Update*/
			if (rdxCheckout.discounts.coupon.length > 0) {
				await CouponUsage();
			}

			/**Send Email */

			// const SES_Email =  await this.SES_Email()

			// if (SES_Email.errorMessage /*|| SES_Email.message */ )
			// {
			//     throw new Error( SES_Email.errorMessage )
			// }

			/**Send Chat */
			const send_chat = await ChatSendMsg();

			if (send_chat) {
				//
			}

			dispatch(CartClear());

			dispatch(Rdx_Order_ClearCart());
			dispatch(Rdx_Checkout_SetSubtotal(0));
			dispatch(Rdx_Checkout_SetItemCount(0));
			dispatch(Rdx_Checkout_Clear());
			dispatch(Rdx_Core_Init());

			const aws_core = new Core({});

			navigate("success", {
				state: {
					beta_user_id: await aws_core.Id(),
					order_id: orderId
				}
			});

			return { msg: "OK" };
		} catch (err) {
			// this.setState({ buttonActive: true });
			ButtonActive(true);

			console.warn("Checkout_Summary: FinaliseOrder: err: ", err);

			return Promise.reject(err);
		}
	}; // FinaliseOrder

	/**
	 */
	const CouponUsage = async () => {
		try {
			// const { __checkout, __cfg, __biz } = this.props;
			const coupon_object = rdxCheckout.discounts.coupon[0].coupon;
			const aws_lambda = new Lambda({});

			const params_usage = {
				stage: rdxCfg.stage,
				usr: "beta",
				actType: "promo",
				act: "coupon:usage",
				alpha: {
					biz_id: rdxBiz.biz_id
				},
				cpn_code: coupon_object.cpn_code
			};

			const resp_lambda = await aws_lambda.Invoke(params_usage, rdxCfg.lambda("beta", rdxCfg.stage));

			if (resp_lambda) {
				//
			}

			return { msg: "OK" };
		} catch (err) {
			console.warn("Checkout_Summary : CouponUsage : err : ", err);

			return { err };
		}
	};

	/**
	 */
	const Beta_Order = async () => {
		try {
			const invoice_obj: any = await Invoice_Save({
				rdxCfg: rdxCfg,
				rdxCoreMenu: rdxCoreMenu,
				rdxOrder: rdxOrder,
				rdxCart: rdxCart,
				rdxCheckout: rdxCheckout
			});

			// console.log( 'Beta_Order: invoice_obj: ', invoice_obj )

			// throw new Error( 'testing' )

			const order_items = invoice_obj.invoice_data.map((cat: any) => {
				return { cat_id: cat.cat_id, cat_idx: cat.cat_idx, cat: cat.cat, data: cat.data };
			});

			let p_order: any = {
				usr: "beta",
				actType: "order",
				act: "create",
				order_from: "web",
				alpha: {
					biz_id: rdxBiz.biz_id,
					biz_title: rdxBiz.biz_title || rdxBiz.biz_name
				},

				user_info: invoice_obj.user_info,
				delivery_option: invoice_obj.delivery_option,
				items: order_items,
				items_history: [],
				invoice: {
					...invoice_obj.order_amount_info,
					save_status: invoice_obj.save_status
				},
				paid_info: "not_paid",

				stage: rdxCfg.stage
			};

			if (rdxCheckout.comment) {
				p_order.comments = rdxCheckout.comment;
			}

			// console.log("!!!!!!!!!!!!!!!!!!!! Checkout_Summary : Beta_Order : p_order : ", p_order);
			// return;

			console.log( 'Beta_Order: p_order: ', p_order )

			// throw new Error( 'testing' )

			const aws_lambda = new Lambda({});

			const resp_lambda = await aws_lambda.Invoke(p_order, rdxCfg.lambda("beta", rdxCfg.stage));

			if (resp_lambda.err) {
				//
				throw new Error(resp_lambda.err);
			}

			// _orderId = ;
			OrderId(resp_lambda.order_id);

			return { ...p_order, order_id: resp_lambda.order_id, order_uid: resp_lambda.order_uid, dt_create: resp_lambda.dt_create };
		} catch (err) {
			console.error("Checkout_Summary: Beta_Order: err: ", err);

			return Promise.reject(err);
		}
	}; // Beta_Order

	/**
	 */
	const Beta_Charge = async (order_obj: any) => {
		try {
			const aws_lambda = new Lambda({});

			// if (!orderId) {
			if (!order_obj.order_id) {
				throw new Error("New order was not created.");
			}

			// const order_obj = orderObj;

			const dt_now = Date.now();
			const totalAmount = Math.round(rdxCheckout.Total() * 100);

			let payment_type = rdxCheckout.payment.paymentType;

			let pay_param: any = {
				amount: totalAmount,
				dt_now: dt_now,
				payment_type: payment_type
			};

			if (payment_type === "card" || payment_type === "credit card") {
				pay_param.card = rdxCheckout.payment.creditInfo;
			}

			pay_param.pay = {
				loyalty: {
					schemes: rdxCheckout.discounts.loyalty
				}
			};

			let params_charge: any = {
				stage: rdxCfg.stage,
				usr: "beta",
				actType: "pay",
				act: "charge_2s_shared",

				dt_origin: Date.now(),
				order_id: order_obj.order_id,

				alpha: {
					biz_id: rdxBiz.biz_id,
					biz_title: rdxBiz.biz_title
				},

				trans: {
					amount: pay_param.amount,
					currency: rdxBiz.biz_currency ? rdxBiz.biz_currency : "GBP",
					desc: rdxBiz.biz_title
				},

				payment_type: pay_param.payment_type,
				invoice: order_obj.invoice,
				ext: {
					ip: ipAddr,
					location: geoCoord,
					device: "mob"
				},

				pay: pay_param.pay
			};

			if (pay_param.card) {
				params_charge.card = pay_param.card;
			}

			const resp_lambda = await aws_lambda.Invoke(params_charge, rdxCfg.lambda("beta", rdxCfg.stage));

			if (resp_lambda.errorMessage) {
				// console.warn(JSON.parse(pay_data.errorMessage))

				throw new Error(resp_lambda.errorMessage);
			}

			//
			return resp_lambda; //.resp
		} catch (err) {
			console.error("Checkout_Summary: Beta_Charge: err: ", err);

			return Promise.reject(err);
		}
	}; // Beta_Charge

	/**
	 */
	const ChatSendMsg = async () => {
		try {
			// const cfg = this.props.__cfg;

			// const ch_channel = JSON.parse( localStorage.getItem( 'channel:'+ cfg ) )
			//
			// let pubId = localStorage.getItem( 'pubId:'+cfg.stage )

			if (!rdxChat.chat_channel) {
				return;
			}

			const order_data = orderObj;

			const aws_core = new Core({});

			const name_obj = await aws_core.Name();

			if (!order_data.beta_user_id) order_data.beta_user_id = await aws_core.Id();

			const p_msg = {
				msg: "Place a New order from " + rdxBiz.biz_title,
				ch_id: rdxChat.chat_channel.ch_id,
				meta: {
					order_uid: order_data.order_uid,
					order_id: order_data.order_id,
					Total: order_data.invoice.Total,
					dt_create: order_data.dt_create,
					biz_id: order_data.alpha.biz_id,
					status: "Order Placed"
				},
				type: "order",
				pub_id_list: Object.keys(rdxChat.chat_channel.meta),

				sender: [/* name_obj.title, */ name_obj.first, name_obj.last].join(" "),
				usr_id: rdxChat.user_id,
				dev_id: cuid(),
				l_id: Date.now()
			};

			const msg_obj = await Msg_Send(p_msg, rdxCfg);

			return { msg: "OK" };
		} catch (err) {
			// this.setState({ bInit: true, bRefreshing: false });

			console.warn("Checkout_Summary: send_chat_msg: err: ", err);

			// return Promise.reject( err )
			return { err };
		}
	}; // ChatSendMsg

	/**
	 */
	const ChatInit = async () => {
		try {
			const aws_core = new Core({});
			// const cfg = this.props.__cfg;

			if (!rdxChat.user_id) {
				await dispatch(Rdx_Pubid_Init());
			}

			const aws_lambda = new Lambda();

			const { biz_title, biz_id } = rdxBiz;

			let ch_id = [biz_id, rdxChat.user_id].join("<>");

			const name_obj = await aws_core.Name();
			const name_str = [name_obj.first, name_obj.last].join(" ");

			const ch_t = "ind";

			let p_new: any = {
				stage: rdxCfg.stage,
				pf: rdxCfg.platform,
				usr: rdxCfg.usr_t || "beta",

				actType: "channels",
				act: "new:chnl",

				topic: "chat",
				ch_id: ch_id,
				ch_t: ch_t,
				// ch_grp_t: ch_grp_t,

				//
				b_db: rdxCfg.chat.bDb,
				b_iot: rdxCfg.chat.bIoT,
				b_push: rdxCfg.chat.bPush
			};

			if (ch_t === "ind") {
				p_new.meta = {
					[rdxChat.user_id]: { name: name_str },
					[biz_id]: { name: biz_title ? biz_title : "N/A" }
				};

				p_new.usr_list = [
					{
						ch_id: ch_id,
						usr_id: rdxChat.user_id,
						usr_t: "beta",
						mship_t: "admin"
					},
					{
						ch_id: ch_id,
						usr_id: biz_id,
						usr_t: "alpha",
						mship_t: "admin"
					}
				];
			} else {
				p_new.meta = {};
				p_new.usr_list = [];
			}

			const resp_get = await aws_lambda.Invoke(p_new, rdxCfg.lambda("chat", rdxCfg.stage));

			// localStorage.setItem( 'channel:'+cfg.stage+user.attributes.phone_number , JSON.stringify(resp_get.resp) )

			dispatch(Rdx_Chat_Channel(resp_get.resp));

			return { msg: "OK" };
		} catch (err) {
			// this.setState({ bInit: true, bRefreshing: false });

			console.warn("Checkout_Summary: ChatInit: err: ", err);

			// return Promise.reject( err )
			return { err };
		}
	}; // ChatInit

	/**
	 */
	const OnConfirm = async (charge: any) => {
		try {
			// _charge.charge = charge;
			PaymData({ ...paymData, charge });

			if ("succeeded" === charge.status) {
				await FinaliseOrder();
			} else {
				alert("Your card payment failed. Please try again with another card.");
				ButtonActive(true);
				navigate("order");
			}

			return {};
		} catch (err) {
			return { err };
		}
	}; // OnConfirm

	/**
	 */
	const UI_PlaceOrder = async () => {
		try {
			if (!rdxCart.items.length) {
				return ErrMsg("Please select an item.");
			}

			if (!rdxCheckout.delivery.deliveryType) {
				return ErrMsg("Please select delivery type.");
			}

			if (rdxCheckout.delivery.deliveryType.toLowerCase() === "home" && !rdxCheckout.delivery.deliveryAddress) {
				return ErrMsg("Please select a delivery address.");
			}

			if (!rdxCheckout.payment.paymentType) {
				return ErrMsg("Please select payment type.");
			}

			if (rdxCheckout.payment.paymentType === "card" && !rdxCheckout.payment.creditInfo) {
				return ErrMsg("Please select a card.");
			}

			ButtonActive(false);

			const order_obj = await Beta_Order();

			// return;

			OrderObj(order_obj);

			const pay_data = await Beta_Charge(order_obj);

			if (pay_data?.charge?.status === "requires_confirmation") {
				//@todo
				PaymData(pay_data);
				IsModalConfirmation(true);
				// _charge = pay_data;
			} else {
				//
			}

			if ("succeeded" === pay_data.charge.status || "successful" === pay_data.charge.status) {
				await FinaliseOrder();
			} else if ("requires_confirmation" === pay_data.charge.status) {
			} else if ("failed" === pay_data.charge.status) {
				alert("Card Payment failed.");
			}

			return {};
		} catch (err) {
			console.error("Checkout_Summary: UI_PlaceOrder: err: ", err);

			ButtonActive(true);
			return { err };
		}
	}; // UI_PlaceOrder

	// /**
	//  */
	// const applyCoupon = () => {
	// 	//
	// };

	/**
	 */
	const showCoupon = (val: boolean) => {
		// this.setState({ coupon: val, s_loyalty: false });
		Coupon(val);
		SLoyalty(false);
	};

	/**
	 */
	const showLoyalty = (val: any) => {
		// this.setState({ s_loyalty: val, coupon: false });
		SLoyalty(val);
		Coupon(false);
	};

	/**
	 */
	return (
		<div>
			<div className="processTitle">
				<div className="categoryItemTitle">Basket</div>
			</div>

			<div className="summeryInfo">
				<>
					{errMsg && (
						<Alert variant="danger" onClose={() => ErrMsg("")} dismissible>
							{errMsg}
						</Alert>
					)}
				</>

				<div>
					<>
						{rdxCart.items.map((cart_item: ICartOpt, idx: number) => (
							<CartItem key={idx} cart={cart_item} summary={true} />
						))}
					</>
					{/* {rdxOrder.cart_sects.map((sect: any, is: number) => {
            return sect.data.map( (item: any, ii: number) => (
                <CartItem summary={true} item={item} sect={sect} key={item._id} />
              )
            );
          })} */}
				</div>
				<div>
					{rdxCheckout.ListCharges().map((ch: any) =>
						!ch.amount ? null : (
							<div className="summeryDiv" key={ch._idx}>
								<div className="summeryItem" style={{ color: color.colors.light }}>
									{ch.name ? ch.name : ch.type}
								</div>
								<div className="summeryValue" style={{ color: color.colors.black }}>
									£{ch.amount.toFixed(2)}
								</div>
							</div>
						)
					)}

					<>
						{rdxCheckout.ListDiscounts().map((disc: any, i: number) =>
							!disc.amount ? null : (
								<div className="summeryDiv" key={i}>
									<div className="summeryItem" style={{ color: color.colors.light }}>
										Discount ({disc.name ? disc.name : disc.type})
									</div>
									<div className="summeryValue" style={{ color: color.colors.black }}>
										(-) £{parseFloat(disc.amount).toFixed(2)}
									</div>
								</div>
							)
						)}
					</>

					{/* <div className="summeryDiv" style={{ borderTop: "1px solid", borderColor: color.colors.light }}>
            <div className="summeryItem" style={{ color: color.colors.light }}>
              Total
            </div>
            <div className="summeryValue" style={{ color: color.colors.black, fontWeight: 700 }}>
              £{parseFloat(__checkout.Total()).toFixed(2)}
            </div>
          </div> */}
					<CheckoutTotal />
				</div>

				<>
					{/* {isModalConfirmation && _charge ? ( */}
					{isModalConfirmation ? (
						<CheckoutConfirmation
							// pi={_charge?.charge}
							pi={paymData?.charge}
							OnConfirm={OnConfirm}
							OnClose={() => {
								IsModalConfirmation(false);
								// proceed with payment ...
							}}
							ShowError={(err_msg: string) => {
								// this.setState({ errMsg: errMsg, buttonActive: true });
								ErrMsg(err_msg);
								ButtonActive(true);
								alert(errMsg);
							}}
						/>
					) : null}
				</>

				{!buttonActive ? (
					<div
						style={{
							backgroundColor: "#BDBDBD",
							display: "flex",
							justifyContent: "center",
							width: "100%",
							padding: 12,
							color: rdxBiz.site_settings.base.colors.primary
						}}>
						Processing
						<span style={{ marginLeft: 8 }}>
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="primary" />
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="secondary" />
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="success" />
						</span>
					</div>
				) : !props.confirmPayment ? (
					<div>
						{/* <Alert variant="danger">
              Provide
              <Alert.Link href="#"> {__checkout.delivery.deliveryType === "collection" ? "Collection" : "Delivery"} and Payment</Alert.Link> details to order
            </Alert> */}
						<Button disabled className="orderButtonDiv" style={{ cursor: "no-drop", background: "#b7b7b7" }}>
							<div>Place your Order</div>
						</Button>
					</div>
				) : (
					<div>
						{/* <Form.Group >
                                  <div className="extraDivText">Any Special Comment</div>
                                  <Form.Control style={{resize: "none"}} as="textarea" rows={3} value={this.state.comment} onChange={ e => this.setState({comment: e.target.value}) }/>
                              </Form.Group> */}
						<Button onClick={() => UI_PlaceOrder()} className="orderButtonDiv" style={{ background: rdxBiz.site_settings.base.colors.primary }}>
							<div>Place your Order</div>
						</Button>
					</div>
				)}

				{/* <div className="otherOffers">
                      <div className="offerDecs">
                          Check If You Have Special Discount Or Add Coupon Code
                      </div>
                      <div className="offerDecsButton">
                          <div onClick={()=>this.showLoyalty(true)}>Special Discount</div>
                          <div onClick={()=>this.showCoupon(true)}>Coupon Code</div>
                      </div>
                  </div> */}

				<>
					{coupon && (
						<CouponCode
							ShowCoupon={showCoupon}
							// applyCoupon={applyCoupon}
						/>
					)}
				</>

				<>{sLoyalty && <Loyalty ShowLoyalty={showLoyalty} />}</>
			</div>
		</div>
	);
}; // Checkout_Summary

/**
 */
export default Checkout_Summary;

/**
 */
const CheckoutTotal = () => {
	const dispatch = useDispatch();

	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [checkTotal, CheckTotal] = React.useState("0.00");

	//
	React.useEffect(() => {
		const check_tot = parseFloat(rdxCheckout.Total()).toFixed(2);

		CheckTotal(check_tot);
	}, [rdxCheckout]);

	//
	return (
		<div className="summeryDiv" style={{ borderTop: "1px solid", borderColor: color.colors.light }}>
			<div className="summeryItem" style={{ color: color.colors.light }}>
				Total
			</div>
			<div className="summeryValue" style={{ color: color.colors.black, fontWeight: 700 }}>
				£{checkTotal}
			</div>
		</div>
	);
}; // CheckoutTotal
