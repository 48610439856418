/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import NavHdr from '../_common/nav_hdr'

/**
 */
class Auth_LogIn extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div>
                <NavHdr />
                <h1>Authenticating ...</h1>
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
        this.VerifyAuth()
            .then( data => {} )
            .catch( data => {} )
    }

    /**
     */
    VerifyAuth = async () =>
    {
        try
        {
            return {msg: 'OK'}
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }
}   // class Auth_LogIn

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Auth_LogIn )


