/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
import { Form } from "react-bootstrap";
import Chat_Inst from "./inst";
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faBirthdayCake} from '@fortawesome/free-solid-svg-icons'
import ChatIoT from "../../api/chat/chat_iot";
import { Msg_Send, Mark_Read, Msg_List, Channel_UsrList } from "../../api/chat/chat_engine";
import { Core, Lambda } from "../../api";
import cuid from "cuid";
import { animateScroll as scroll } from "react-scroll";
import { navigate } from "@reach/router";
import color from "../_common/colors.json";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Chat_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			msgList: [],
			msgList_LastKey: null,
			msgList_Ts: 0,
			_ch: this.props.__chat.chat_channel,

			bInit: false,
			bRefreshing: true,
			bLoading: false,
			bMore: false,

			bizId: null, //this.props.bizId
			bizName: null, //this.props.bizName

			f_pubId: this.props.pubId,

			inviteIds: this.props.inviteIds,
			channelUrl: this.props.channelUrl,
			chName: this.props.__biz.title,

			usrList: [],
			hasScrolled: false,
			msg: ""
		};

		const p_iot = {
			cfg: this.props.__cfg,
			beta_id: this.props.__core.betaId,

			OnMsg: this.OnMsg,
			OnErr: this.OnErr,
			OnEvent: this.OnEvent
		};

		// // console.log( 'Chat_Inst: cstr: p_iot: ', p_iot )

		this._chIoT = new ChatIoT(p_iot);
	}

	/**
	 */
	render() {
		if (this.props.__core.authStatus && this.props.__core.authStatus === "unauth") navigate("signin");

		return (
			<div>
				<NavHdr chat={true} style={{ position: "fixed" }} />
				<div className="screenWrapper" style={{ marginHorizontal: 16, flex: 1 }}>
					{/*<div className="header">
                        <div className="commonFlex">
                            <FontAwesomeIcon icon={faBirthdayCake} className="chatIcon" color="#b7b7b7"/>
                            <div className="chatName">
                                {this.state.chName}
                            </div>
                        </div>
                    </div>*/}
					<div className="messageBody">
						<Chat_Inst {...this.state} hasScrolled={this.state.hasScrolled} fetchMoreData={this.fetchMoreData} bInit={this.state.bInit} />
					</div>
					<div className="footer">
						<div className="commonFlex">
							<Form.Control
								className="textBox"
								type="text"
								placeholder="Enter Message"
								value={this.state.msg}
								onClick={() => scroll.scrollToBottom()}
								onChange={val => this.setState({ msg: val.target.value })}
							/>
							<div className="sendBtn" style={{ background: this.props.__biz.site_settings.base.colors.primary }} onClick={this.Send}>
								Send
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	/**
	 */
	componentDidMount() {
		if (this.state._ch) {
			this._chIoT
				.Connect(["inst", this.state._ch.ch_id].join(":"))
				.then(data => {
					this._chIoT
						.Subscribe("chat", this.state._ch.ch_id)
						.then(data => {})
						.catch(err => {});
				})
				.catch(err => {});

			this.Msg_List(true)
				.then(data => {})
				.catch(err => {});

			this.Mark_Read()
				.then(data => {})
				.catch(err => {});
		} else {
			// // console.log("new channel")

			this.Init()
				.then(data => {
					// // console.log('data: ', data)

					this._chIoT
						.Connect(["inst", this.state._ch.ch_id].join(":"))
						.then(data => {
							this._chIoT
								.Subscribe("chat", this.state._ch.ch_id)
								.then(data => {})
								.catch(err => {});
						})
						.catch(err => {});

					this.Msg_List(true)
						.then(data => {})
						.catch(err => {});

					this.Mark_Read()
						.then(data => {})
						.catch(err => {});
				})
				.catch(err => {});
		}
	} // componentDidMount

	/**
	 */
	componentWillUnmount() {
		// // console.log( 'Chat_Inst: componentWillUnmount: remove channel handler' )

		// if( !this._chHandler )
		if (!this._chIoT) return;

		this._chIoT
			.Disconnect()
			.then(data => {
				this._chIoT = null;
			})
			.catch(err => {});

		// this._sb.ChannelHandler_Rem( this._sbHandler_Id )

		// this._chHandler = null
		// this._sbHandler_Id = null
	} // componentWillUnmount

	/**
	 */
	Init = async () => {
		try {
			if (!this.props.__chat.user_id) await this.props.Rdx_Pubid_Init();

			const aws_lambda = new Lambda();
			const cfg = this.props.__cfg;

			let ch_id = [this.props.__biz.biz_id, this.props.__chat.user_id].join("<>");

			const aws_core = new Core({});
			const name_obj = await aws_core.Name();
			const name_str = [name_obj.first, name_obj.last].join(" ");

			const ch_t = this.state.ch_t ? this.state.ch_t : "ind";

			const p_new = {
				stage: cfg.stage,
				pf: cfg.platform,
				usr: cfg.usr_t ? cfg.usr_t : "beta",

				actType: "channels",
				act: "new:chnl",

				topic: "chat",
				ch_id: ch_id,
				ch_t: ch_t,
				// ch_grp_t: ch_grp_t,

				//
				b_db: cfg.chat.bDb,
				b_iot: cfg.chat.bIoT,
				b_push: cfg.chat.bPush
			};

			if (ch_t === "ind") {
				p_new.meta = {
					[this.props.__chat.user_id]: { name: name_str },
					[this.props.__biz.biz_id]: { name: this.props.__biz.title }
				};

				p_new.usr_list = [
					{
						ch_id: ch_id,
						usr_id: this.props.__chat.user_id,
						usr_t: "beta",
						mship_t: "admin"
					},
					{
						ch_id: ch_id,
						usr_id: this.props.__biz.biz_id,
						usr_t: "alpha",
						mship_t: "admin"
					}
				];
			} else {
				p_new.meta = {};

				p_new.usr_list = [];
			}

			// console.log( "Chat_Inst: Init: p_get: ", p_new )

			const resp_get = await aws_lambda.Invoke(p_new, cfg.lambda("chat", cfg.stage));

			// console.log( "Chat_Inst: Init: resp_get: ", resp_get )

			this.props.Rdx_Chat_Channel(resp_get.resp);

			this.setState({ _ch: resp_get.resp, chName: this.props.__biz.title });

			return { msg: "OK" };
		} catch (err) {
			console.warn("Chat_Inst: Init: err: ", err);

			this.setState({ bInit: true, bRefreshing: false });

			return Promise.reject(err);
		}
	}; // Init

	/**
	 */
	Send = async () => {
		try {
			// console.log("send msg", this.state._ch , this.state.msg)

			if (!this.state._ch) return false;

			const msg_send = this.state.msg.trim();

			if (!msg_send.length) return { msg: "Empty" };

			this.setState({ bSending: true, msg: "" });

			const msg_obj = {
				msg: msg_send,
				meta: {},
				usr_id: this.props.__chat.user_id,
				dev_id: cuid(),
				l_id: Date.now()
			};

			const aws_core = new Core({});
			const name_obj = await aws_core.Name();

			const name_str = [name_obj.first, name_obj.last].join(" ");

			const p_msg = {
				...msg_obj,

				ch_id: this.state._ch.ch_id,
				pub_id_list: Object.keys(this.state._ch.meta),

				sender: this.state._ch.meta[msg_obj.usr_id].name,

				name: name_str
			};

			this.AddMsg([p_msg]);

			const chat_msg = await Msg_Send(p_msg, this.props.__cfg);

			// // console.log( 'Chat_Inst: Send: chat_msg: ', chat_msg )

			if (chat_msg) {
				//
			}
			scroll.scrollToBottom();

			return true;
		} catch (err) {
			console.warn("Chat_Inst: Send: err: ", err);
			return false;
		}
	}; // Send

	/**
	 */
	AddMsg = msg_arr => {
		// // console.log( 'Chat_Inst: AddMsg: msg_arr: ', msg_arr )

		const msg_list = [...msg_arr, ...this.state.msgList];

		msg_list.sort((a, b) => (a.dt_create > b.dt_create ? -1 : a.dt_create < b.dt_create ? 1 : 0));

		this.setState({ msgList: msg_list.reverse(), msgList_Ts: Date.now() });

		// this.props.Rdx_Chat_MsgLast( this.state._ch.url, msg_arr[0] )
	}; // AddMsg

	/**
	 */
	fetchMoreData = async () => {
		try {
			if (this.state.hasScrolled) {
				// console.log('onload' , this.state)

				this.setState({ hasScrolled: false });
				await this.Msg_List();
			}
		} catch (err) {
			this.setState({ bLoading: false });

			console.warn("Chat_Inst: OnLoad: err: ", err);

			return { err: err };
		}
	}; // OnLoad

	/**
	 */
	OnRefresh = async () => {
		try {
			// const pr_chat = this.props.__chat

			this.setState({ bRefreshing: true });

			// const msq_q_obj = await pr_chat.sb.GrpChannel_Pre( this.state._ch, this._qMsg, true )

			// const msg_list = msq_q_obj.list
			// this._qMsg = msq_q_obj.qMsg

			const msg_list = [];

			this.setState({ msgList: msg_list.reverse(), bRefreshing: false });
		} catch (err) {
			this.setState({ bRefreshing: false });

			console.warn("Chat_Inst: OnRefresh: err: ", err);

			return { err: err };
		}
	}; // OnRefresh

	/**
	 */
	OnMsg = (topic, obj) => {
		// console.log( 'Chat_Inst: OnMsg: topic: ', topic )
		// console.log( 'Chat_Inst: OnMsg: obj: ', obj )

		console.log("Chat_Inst: OnMsg: this.state.msgList: ", this.props);

		if (this.props.location.pathname === "/chat") {
			this.Mark_Read()
				.then(data => {})
				.catch(err => {});
		}

		this.props.Rdx_Chat_totUnRead_Req();

		const msg_idx = this.state.msgList.findIndex(x => {
			if (x.msg_id === obj.msg_id) {
				// // console.log( 'Chat_Inst: OnMsg: x: true: ', x )
				return true;
			}

			if (x.dev_id === obj.dev_id && x.l_id === obj.l_id) {
				// // console.log( 'Chat_Inst: OnMsg: x: true: ', x )
				return true;
			}

			return false;
		});

		// // console.log( 'Chat_Inst: OnMsg: msg_idx: ', msg_idx )
		// // console.log( 'Chat_Inst: OnMsg: msg_obj: ', this.state.msgList[msg_idx] )

		if (0 > msg_idx) {
			// // console.log( 'Chat_Inst: OnMsg: msg_obj: not found. adding new.' )

			this.AddMsg([obj]);
		} else {
			// // console.log( 'Chat_Inst: OnMsg: msg_obj: found. exiting.' )

			this.state.msgList[msg_idx] = obj;

			this.setState({ msgList_Ts: Date.now() });
		}

		scroll.scrollToBottom();

		// this.AddMsg( [msg] )
	}; // OnMsg

	/**
	 */
	OnEvent = (topic, obj) => {
		// // console.log( 'Chat_Inst: OnEvent: topic: ', topic )
		// // console.log( 'Chat_Inst: OnEvent: obj: ', obj )

		this.props.Rdx_Chat_totUnRead_Req();

		if ("msg:read" === obj.e) {
		} // msg:read
		else {
			//
		}
	}; // OnEvent

	/**
	 */
	OnErr = err => {
		//
	}; // OnErr
	/**
	 */
	Mark_Read = async () => {
		try {
			// // console.log( 'Chat_Inst: Mark_Read: this.props: ', this.props )

			if (!this.state._ch.cnt) {
				return {};
			}

			// // console.log( 'Chat_Inst: Mark_Read: 2' )

			//
			const pr_core = this.props.__core;

			const p_ch = {
				usr_id: this.props.__chat.user_id,
				ch_id: this.state._ch.ch_id
			};

			// // console.log( 'Chat_Inst: Mark_Read: 3' )

			await Mark_Read(p_ch, this.props.__cfg);

			// // console.log( 'Chat_Inst: Mark_Read: 4' )

			//
			this.props.Rdx_Chat_totUnRead_Req();

			//
			return {};
		} catch (err) {
			console.warn("Chat_Inst: Mark_Read: err: ", err);

			return Promise.reject(err);
		}
	}; // Mark_Read

	/**
	 */
	Msg_List = async b_refresh => {
		try {
			// // console.log( "Chat_Inst: Msg_List: this.props: ", this.props )
			// // console.log( "Chat_Inst: Msg_List: this.state: ", this.state )

			const p_list = {
				ch_id: this.state._ch.ch_id,
				start_key: b_refresh ? null : this.state.msgList_LastKey,
				n_msg: 25
			};

			const resp_msg_list = await Msg_List(p_list, this.props.__cfg);

			// // console.log('resp_msg_list',resp_msg_list)

			const msg_list = resp_msg_list.resp;

			// const msg_list = resp_msg_list.resp.sort( (a, b) => {
			// 		return (a.dt_create < b.dt_create) ? 1 : -1
			// 	} )

			// // console.log( "Chat_Inst : Init : msg_list : ", resp_msg_list )

			this.setState({
				hasScrolled: resp_msg_list.last_key ? true : false,
				msgList: [...this.state.msgList, ...msg_list].reverse(),
				msgList_LastKey: resp_msg_list.last_key,
				bInit: true,
				bRefreshing: false
			});
			if (b_refresh) scroll.scrollToBottom();
			if (!b_refresh && resp_msg_list.last_key) window.scrollTo(0, 200);

			return {};
		} catch (err) {
			console.warn("Chat_Inst: Init: err: ", err);

			this.setState({ bInit: true, bRefreshing: false });

			return Promise.reject(err);
		}
	}; // Msg_List

	/**
	 */
	UsrList = async () => {
		try {
			const p_list = {
				ch_id: this.state._ch.ch_id
			};

			// // console.log( "this.state: p_list: ", this.state );
			// // console.log( "Chat_Inst: UsrList: p_list: ", p_list );

			const usr_list = await Channel_UsrList(p_list, this.props.__cfg);

			// // console.log( "Chat_Inst: UsrList: usr_list: ", usr_list );

			this.setState({ usrList: usr_list });

			return {};
		} catch (err) {
			console.warn("Chat_Inst: UsrList: err: ", err);

			return Promise.reject(err);
		}
	}; // UsrList
} // class Chat_Home

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Chat_Home);
