/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import { Core, Lambda, Time } from "../../api";

import Rsrv from "../../api/social/reserve";

import {
	// Form,
	Row,
	Col,
	Image
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faSearch,
	faLocationArrow,
	// faClock, faStar, faUser,
	faPhone
} from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import "../../css/_common.css";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Rsrv_Hist extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			height: window.innerHeight - 240,
			width: window.innerWidth,
			waiting: true,
			upcoming: false,
			invitations: false,
			history: false,

			listWaiting: [
				/*
                {
                    bizInfo: {},
                    type: 'Dine In',
                    type_id: 'dienin',
                    bConfirmed: false,
                    opentype: 'closed',
                    title: "Bipu's Birthday",
                    subTitle: "",
                    msg: "Everyone is invited.",
                    nPpl: 1,
                    dt_st: 0,
                    dt_duration: 0,
                },
                */
			],
			listInvitation: [
				/*
                {
                    bizInfo: {},
                    type: 'Dine In',
                    type_id: 'dienin',
                    bConfirmed: false,
                    opentype: 'closed',
                    title: "Bipu's Birthday",
                    subTitle: "",
                    msg: "Everyone is invited.",
                    nPpl: 1,
                    dt_st: 0,
                    dt_duration: 0,
                },
                */
			],
			listUpcoming: [
				/*
                {
                    bizInfo: {},
                    type: 'Dine In',
                    type_id: 'dienin',
                    bConfirmed: false,
                    opentype: 'closed',
                    title: "Bipu's Birthday",
                    subTitle: "",
                    msg: "Everyone is invited.",
                    nPpl: 1,
                    dt_st: 0,
                    dt_duration: 0,
                },
                */
			],

			view: "waiting",

			bOffer_Choice: false,
			bRetr_Waiting: false,
			bRetr_Upcoming: true,
			bRetr_Invitation: false,
			current_user: {},
			loading: true,

			bModal_New: false,
			bModal_Details: false,
			upcomingShow: false,
			waitingShow: true,
			invitationShow: false,
			historyShow: false
		};
	}

	/**
	 */
	async _DidMount() {
		// // console.log("asdadasd");
		const aws_core = new Core({});

		const current_user = await aws_core.Credentials();

		// // console.log("user", current_user);

		if (current_user.authenticated) {
			await this.Waiting();
			await this.Upcoming();
			// await this.Invitations();
		}

		this.setState({ loading: false });
		this.setState({ current_user: current_user });
	}

	/**
	 */
	componentDidMount() {
		// load future reservations ...
		this._DidMount()
			.then(data => {})
			.catch(err => {});
	}

	/**
	 */
	render() {
		// // console.log( "Rsrv_Hist : render : props : ", this.props );
		// // console.log( "Rsrv_Hist : render : state : ", this.state );

		return (
			<div>
				<div className="commonFlex">
					<div
						onClick={() =>
							this.setState({
								waiting: true,
								upcoming: false,
								invitations: false,
								history: false
							})
						}
						className={this.state.waiting ? "rvSelect" : "rvNotSelect"}>
						Waiting
					</div>
					<div
						onClick={() =>
							this.setState({
								waiting: false,
								upcoming: true,
								invitations: false,
								history: false
							})
						}
						className={this.state.upcoming ? "rvSelect" : "rvNotSelect"}>
						Upcoming
					</div>
					{/* <div onClick={()=>this.setState({waiting:false,upcoming:false,invitations:true,history:false})} className={this.state.invitations ? "rvSelect" : "rvNotSelect"}>Invitations</div> */}
					<div
						onClick={() =>
							this.setState({
								waiting: false,
								upcoming: false,
								invitations: false,
								history: true
							})
						}
						className={this.state.history ? "rvSelect" : "rvNotSelect"}>
						History
					</div>
				</div>
				{this.state.loading ? (
					<div className="centerScreen" style={{ height: this.state.height }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={this.state.height / 3} width={this.state.width / 3} timeout={30000000} />
					</div>
				) : null}
				{!this.state.loading && this.state.waiting ? (
					this.state.listWaiting.length === 0 ? (
						<div className="imageText">
							<Image className="orderCompleteImage" style={{ height: 250, width: "auto" }} src={require("../assets/img/placeholder/waitingConfirmation.png")} rounded />
							<div className="alertMessage">No list Found</div>
						</div>
					) : (
						<>
							<Row>
								{this.state.listWaiting.map(item => (
									<HistItem item={item} />
								))}
							</Row>
						</>
					)
				) : null}
				{!this.state.loading && this.state.upcoming ? (
					this.state.listUpcoming.length === 0 ? (
						<div className="imageText">
							<Image className="orderCompleteImage" style={{ height: 250, width: "auto" }} src={require("../assets/img/placeholder/upcomingReservation.png")} rounded />
							<div className="alertMessage">No list Found</div>
						</div>
					) : (
						<>
							<Row>
								{this.state.listUpcoming.map(item => (
									<HistItem item={item} />
								))}
							</Row>
						</>
					)
				) : null}
				{/* {
                        !this.state.loading && this.state.invitations
                        ?
                            this.state.listInvitation.length === 0
                            ?
                            "No Waiting reservation"
                            :
                            <>
                                {
                                    this.state.listInvitation.map( item => (
                                        <HistItem item={item}/>
                                    ) )
                                }
                            </>
                        :
                        null                                    
                    } */}
				{!this.state.loading && this.state.history ? <HistHistory {...this.props} /> : null}
			</div>
		);
	}

	/**
	 */
	Waiting = async () => {
		try {
			// this.setState({bRetr_Waiting: true});

			const cfg = this.props.__cfg;

			const aws_lambda = new Lambda({});

			const params_rsrv = {
				stage: cfg.stage,
				usr: "beta",
				actType: "reservation",
				act: "rsrv:waiting"
			};

			// // console.log('Res_New: Res_Rsrv: params_rsrv: ', params_rsrv);

			const data = await aws_lambda.Invoke(params_rsrv, cfg.lambda("beta", cfg.stage));
			let list = data.resp;
			// // console.log('Res_New: Res_Rsrv: data: ', data);

			if (this.props.__biz && this.props.__biz.biz_id) {
				const tar_id = this.props.__biz.biz_id;

				list = list.filter(x => x.rsrv_biz_id === tar_id);
			}

			this.setState({ listWaiting: list /* , bRetr_Waiting: false */ });
		} catch (err) {
			this.setState({ bRetr_Waiting: false });
			this.setState({ bRetr_Upcoming: false });
			console.warn("Res_Home: Waiting: err: ", err);
			return Promise.reject(err);
		}
	}; // Waiting

	/**
	 */
	Upcoming = async () => {
		try {
			// this.setState({bRetr_Upcoming: true});
			const cfg = this.props.__cfg;

			const aws_lambda = new Lambda({});

			const params_rsrv = {
				stage: cfg.stage,
				usr: "beta",
				actType: "reservation",
				act: "rsrv:upcoming"
			};

			// // console.log('Res_New: Res_Rsrv: params_rsrv: ', params_rsrv);

			const data = await aws_lambda.Invoke(params_rsrv, cfg.lambda("beta", cfg.stage));
			let list = data.resp;
			// // console.log( 'Res_Home: Upcoming: list: ', list );

			if (this.props.__biz && this.props.__biz.biz_id) {
				const tar_id = this.props.__biz.biz_id;

				list = list.filter(x => x.rsrv_biz_id === tar_id);
			}

			this.setState({ listUpcoming: list /* , bRetr_Upcoming: false */ });
		} catch (err) {
			this.setState({ bRetr_Upcoming: false });

			console.warn("Res_Home: Upcoming: err: ", err);
			return Promise.reject(err);
		}
	}; // Upcoming

	/**
	 */
	// Invitations = async () =>
	// {
	//     try
	//     {
	//         this.setState({bRetr_Invitation: true});

	//         const rsrv_man = new Rsrv(this.props.__cfg);

	//         const list = await rsrv_man.Upcoming( {} );
	//         // console.log( 'Res_Home: Invitation: list: ', list );

	//         this.setState({listInvitation: /*list*/[], bRetr_Invitation: false});
	//     }
	//     catch (err)
	//     {
	//         this.setState({bRetr_Invitation: false});

	//         console.warn('Res_Home: Invitation: err: ', err);
	//         return Promise.reject(err);
	//     }
	// }	// Upcoming
} // class Rsrv_Hist

/**
 */
class HistItem extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			date: "xx.xx.xx",
			time: "xx : xx"
		};

		const dt_obj = new Date(Time.Epoch2Time(this.props.item.rsrv_dt_st));

		this.state.date = Time.PrintDate(dt_obj);
		this.state.time = Time.PrintTime(dt_obj);
	}

	render() {
		return (
			<Col lg="4" md="6" sm="12" xs="12">
				<div className="reservationContainer">
					<div className="commonFlex">
						<div className="resLogoDiv">
							<Image className="resLogo" fluid src={require("../assets/img/placeholder/reservationIcon.png")} />
						</div>
						<div>
							<div style={{ flex: 1 }}>
								<div className="resTitle">Reservation for{this.props.item.rsrv_type}</div>
							</div>
							<div style={{ marginTop: 4 }}>
								<div className="commonFlex">
									<FontAwesomeIcon className="resSmallIcon" icon={faLocationArrow} color={"#333"} />
									<div className="resSmall">
										{this.state.date} {this.state.time}
									</div>
								</div>
								<div className="commonFlex">
									<FontAwesomeIcon className="resSmallIcon" icon={faPhone} color={"#333"} />
									<div className="resSmall">total Guest {this.props.item.rsrv_nppl}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Col>
		);
	}
}

/**
 */
class HistHistory extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		let dt_from = new Date();

		dt_from.setMonth(dt_from.getMonth() - 1);

		this.state = {
			from: dt_from,
			to: new Date(),
			list: [],
			list_ts: 0,
			bLoading: true
		};
	}

	/**
	 */
	componentDidMount() {
		this.setState({ bLoading: true });

		this.Search()
			.then(data => {})
			.catch(err => {});
	}

	render() {
		// console.log("HistHistory : render : props : ", this.props);
		// console.log("HistHistory : render : state : ", this.state);

		return (
			<>
				{this.state.bLoading ? (
					<div className="centerScreen" style={{ height: this.state.height }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={this.state.height / 3} width={this.state.width / 3} timeout={30000000} />
					</div>
				) : this.state.list.length === 0 ? (
					<div className="imageText">
						<Image className="orderCompleteImage" style={{ height: 250, width: "auto" }} src={require("../assets/img/placeholder/reservationHistory.png")} rounded />
						<div className="alertMessage">No list Found</div>
					</div>
				) : (
					<>
						<Row>
							{this.state.list.map(item => (
								<HistItem item={item} />
							))}
						</Row>
					</>
				)}
			</>
		);
	}

	/**
	 */
	Search = async () => {
		try {
			const rsrv_man = new Rsrv(this.props.__cfg);

			const rsrv_list = await rsrv_man.List({
				dt_range_st: Time.Epoch(this.state.from.getTime()),
				dt_range_end: Time.Epoch(this.state.to.getTime())
			});

			// console.log('Res_Hist: Search: rsrv_list: ', rsrv_list)

			this.setState({ list: rsrv_list, list_ts: Date.now(), bLoading: false });
		} catch (err) {
			this.setState({ bLoading: false });

			console.warn("Res_Hist: Search: err: ", err);
		}
	};
}

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Rsrv_Hist);
