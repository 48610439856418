/**
 *	@copyright	Elmelo Ltd.
 */

import React from "react";
import { Image, Button, Row, Col, Form, Modal, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Geocode from "react-geocode"
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import color from "../_common/colors.json";
import "../../css/_common.css";

import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
// import {Lambda, Utils} from "../../api"
// import elml_cfg from "../../_config/elml_cfg"

import { TextInput } from "../_common/components";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
const AddNew_Address = ({ HideModal, ListAddr }) => {
	const dispatch = useDispatch();
	const RdxBiz = useSelector(state => state.__biz);

	const [line1, Line1] = React.useState("");
	const [line2, Line2] = React.useState("");
	const [city, City] = React.useState("");
	const [county, County] = React.useState("");
	const [postcode, Postcode] = React.useState("");
	const [errMsg, ErrMsg] = React.useState("");
	const [address, setAddress] = React.useState("");

	/**
	 */
	const IsValidPostcode = p => {
		const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;

		return postcodeRegEx.test(p);
	}; // IsValidPostcode

	const OnFill = addr => {
		try {
			Line1(addr.line1);
			Line2(addr.line2);
			City(addr.city);
			County(addr.county);
			Postcode(addr.postcode);
		} catch (err) {
			console.warn("OnFill : err : ", err);
			return { err };
		}
	};
	/**
	 */
	const OnAddressAdd = e => {
		e.preventDefault();
		e.stopPropagation();

		AddressAdd().catch(console.warn);
	}; // OnAddressAdd

	const handleSelect = async value => {
		const result = await geocodeByAddress(value);
		setAddress(value);
		try {
			const _addr = {
				line1: "",
				line2: "",
				city: "",
				county: "",
				postcode: "",
				country: ""
			};

			result[0].address_components.forEach(addr_details => {
				addr_details.types.forEach(details_type => {
					if (details_type === "premise" || details_type === "street_number") {
						// 7
						_addr.line1 = addr_details.long_name;
					} else if (details_type === "route") {
						// Crundel Rise
						_addr.line2 = addr_details.long_name;
					} else if (details_type === "postal_town" || details_type === "locality") {
						// Witney
						_addr.city = addr_details.long_name;
					} else if (details_type === "administrative_area_level_2") {
						// Oxfordshire
						_addr.county = addr_details.long_name;
					} else if (details_type === "postal_code") {
						// OX286LY
						_addr.postcode = addr_details.long_name;
					} else {
						/// not necessary ...
					}
				});
			});

			return OnFill(_addr);
		} catch (err) {
			console.warn("_common : handleSelect : err : ", err);
			return { err };
		}
	};
	/**
	 */
	const AddressAdd = async () => {
		try {
			// const aws_lambda = new Lambda({})

			// const {line1, line2, city, county, postcode} = this.state;
			// const {__cfg} = this.props;

			// if( !line1 || !city || !county || !postcode )
			if (!line1 || !city || !postcode) {
				throw new Error("Required Fields can not be empty");
			}

			if (!IsValidPostcode(postcode)) {
				throw new Error("Enter a valid postcode.");
			}

			const _postcode = (() => {
				const postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
				return postcode.replace(postcodeRegEx, "$1 $2");
			})();

			// const addressString = line1 + " " + line2 + ", " + city + ", " + county + ", " + _postcode;

			// Geocode.setApiKey("AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M")
			// Geocode.enableDebug()

			// const addr_longlat = await Geocode.fromAddress( addressString )
			// const { lat, lng } = addr_longlat.results[0].geometry.location

			const address = {
				line1: line1,
				line2: line2.length ? line2 : null,
				city: city,
				county: county.length ? county : null,
				postal_code: _postcode.toUpperCase()
				// longlat: {
				//   latitude: lat,
				//   longitude: lng
				// }
			};

			const resp_add = await dispatch(actions.Rdx_AddAddress(address));

			if (resp_add?.data?.Attributes?.addresses && ListAddr) {
				const parsed_list = resp_add.data.Attributes.addresses
					.map(x => {
						try {
							return JSON.parse(x);
						} catch (err) {
							return null;
						}
					})
					.filter(x => x);

				ListAddr(parsed_list);
			} // if ...

			// const params = {
			//   actType: 'profile'
			// ,	act: 'address:add'
			// ,	stage: __cfg.stage
			// ,	usr: 'beta'
			// ,	address: address
			// ,	b_shared: true
			// }

			// const data =  await aws_lambda.Invoke( params, __cfg.lambda( 'beta', __cfg.stage ) );

			// if (data.errorMessage)
			// {
			//   this.setState({errMsg:data.errorMessage})
			//   return;
			// }

			if (resp_add === "Already exists.") {
				throw new Error("Already Exists!");
			}

			// if(LoadUserAddress)
			// {
			//   await LoadUserAddress();
			// }

			HideModal();

			return {};
		} catch (err) {
			ErrMsg(err.message || err);

			return Promise.reject(err);
		}
	}; // AddressAdd

	/**
	 */
	return (
		<div className="overlayModal">
			<Modal size="lg" centered aria-labelledby="contained-modal-title-vcenter" show={true} onHide={() => HideModal()} animation={true}>
				<div style={{ padding: 32 }}>
					<div>
						<h4 style={{ color: RdxBiz.site_settings.base.colors.primary, flex: 1 }}>Add Address</h4>
						<div onClick={() => HideModal(false)} className="overlayClose">
							<FontAwesomeIcon style={{ marginLeft: 4, marginBottom: 4 }} icon={faTimes} size="lg" color="#ef5350" />
						</div>
					</div>
					<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div>
								<input
									{...getInputProps({
										placeholder: "Search Places ...",
										className: "location-search-input"
									})}
								/>
								<div className="autocomplete-dropdown-container addressBox">
									{loading && <div>Loading...</div>}
									{suggestions.map(suggestion => {
										return (
											<div {...getSuggestionItemProps(suggestion)} className="addressSingle">
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>
					<div>
						<Form.Group>
							<TextInput placeholder="Line 1*" type="text" size="lg" val={line1} onChange={val => Line1(val.target.value)} className="" />
						</Form.Group>
						<Form.Group>
							<TextInput placeholder="Line 2" type="text" size="lg" val={line2} onChange={val => Line2(val.target.value)} className="" />
						</Form.Group>
						<Form.Group>
							<TextInput placeholder="City*" type="text" size="lg" val={city} onChange={val => City(val.target.value)} className="" />
						</Form.Group>
						<Form.Group>
							<TextInput placeholder="County" type="text" size="lg" val={county} onChange={val => County(val.target.value)} className="" />
						</Form.Group>
						<Form.Group>
							<TextInput placeholder="Postcode*" type="text" size="lg" val={postcode} onChange={val => Postcode(val.target.value)} className="" />
						</Form.Group>
						{errMsg && (
							<Alert variant="danger" onClose={() => ErrMsg("")} dismissible>
								{errMsg}
							</Alert>
						)}
						<Form.Group>
							<Button variant="success" onClick={OnAddressAdd}>
								Add Address
							</Button>
						</Form.Group>
					</div>
				</div>
			</Modal>
		</div>
	);
}; // AddNew_Address

/**
 */
export default AddNew_Address;
