/**
 * @copyright Elmelo Ltd.
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	// Modal, Accordion, Card,
	Button,
	// Form, Alert,
	Image
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faArrowUp,
	faSquare,
	faCheckSquare,
	// faPlusCircle,
	faCheckCircle,
	faTimes,
	// faTrashAlt, faCheck,
	faCircle,
	// faCircleNotch,
	faMinus,
	faPlus
} from "@fortawesome/free-solid-svg-icons";

import "../../css/_common.css";

// import * as actions from "../../rdx/actions";
import {
	// ICartState,
	CartUpdateAsync
} from "../../rdx/cart.slice";

// import { CoreCat, CoreItem, CoreSubcat } from "../../_types/core";
import {
	MenuCat,
	MenuSubcat,
	MenuItem //EItemType,
} from "../../_types/menu";

import {
	ICartMod,
	ICartSecMod,
	ICartAddon, //ICartOpt,
	// CartOptCreate, CartOptCnt
	CartMod,
	CartSecMod,
	CartAddon,
	CartOpt
} from "../../_types/cart";

import color from "../_common/colors.json";

// import {
// 	MenuCatId, MenuSubcatId, MenuItemId,
// } from '../../_types/menu'

// import { Item_Box } from "./_common";
import { ItemPrice2 } from "./ont_2";

import * as dotenv from "dotenv";
import { EOrderTtype } from "../../_types/menu";

dotenv.config();

// const Timeouit = ms => new Promise(sol => setTimeout(sol, ms));

/**
 */
interface IModExModal {
	colors: any;
	item: any;
	sect: any;
	priceItem: any;

	OnClose: any;
}

/**
 */
const ModEx_Modal = (props: IModExModal) => {
	const dispatch = useDispatch();
	const rdxCheckout = useSelector((state: any) => state.__checkout);
	// const rdxCart: ICartState = useSelector((state: any) => state.__cart);

	const [cntItem, CntItem] = React.useState(1);
	const [showCartBtn, ShowCartBtn] = React.useState(false);

	const [selMod, SelMod] = React.useState<ICartMod[]>([]);
	const [selSecMods, SelSecMods] = React.useState<ICartSecMod[]>([]);
	const [selAddons, SelAddons] = React.useState<ICartAddon[]>([]);

	// const [itemSelect, ItemSelect] = React.useState(null)

	const [priceTot, PriceTot] = React.useState(0.0);
	const [priceMod, PriceMod] = React.useState(0.0);
	const [priceSecmod, PriceSecmod] = React.useState(0.0);
	const [priceAddon, PriceAddon] = React.useState(0.0);

	// this.m_p = 0;
	// this.s_m_p = 0;
	// this.a_p = 0;

	// const { item } = this.props;

	// console.log("modex item", item);

	// const tot_price = this.props.bPrice + this.m_p + this.s_m_p + this.a_p;

	/**
	 */
	React.useEffect(() => {
		const price_item = ItemPrice2(props.item.price, rdxCheckout.delivery.deliveryType);
	}, [props.item, rdxCheckout.delivery.deliveryType]);

	// /**
	//  */
	// React.useEffect(() => {
	// 	console.log( 'rdxCart: ', rdxCart )
	// }, [rdxCart]);

	// /**
	//  */
	// React.useEffect(() => {
	// 	console.log( 'selAddons: ', selAddons )
	// }, [selAddons]);

	/**
	 */
	React.useEffect(() => {
		// console.log( 'props.priceItem: ', props.priceItem )
		// console.log( 'priceMod: ', priceMod )
		// console.log( 'priceSubmod: ', priceSubmod )
		// console.log( 'priceAdd: ', priceAdd )

		PriceTot(props.priceItem + priceMod + priceSecmod + priceAddon);
	}, [props.priceItem, priceMod, priceSecmod, priceAddon]);

	/**
	 */
	React.useEffect(() => {
		const tot_mod = selMod.reduce((a: any, c: ICartMod) => {
			const mod_obj = props.item._mods.opts.find(x => x._id === c.id);

			/// shouldn't happen, but for safety
			if (!mod_obj) {
				return a;
			}

			return a + ItemPrice2(mod_obj.price, rdxCheckout.delivery.deliveryType);
		}, 0.0);

		PriceMod(tot_mod);
	}, [selMod]);

	/**
	 */
	React.useEffect(() => {
		// console.log( 'selSecMods: ', selSecMods )

		const tot_secmod = selSecMods.reduce((a: any, c: ICartSecMod) => {
			const secmod_obj = props.item._secmods.find(x => x._id === c.id);

			/// shouldn't happen, but for safety
			if (!secmod_obj) {
				return a;
			}

			const opt_obj = secmod_obj.opts.find(x => x._id === c.idOpt);

			/// shouldn't happen, but for safety
			if (!opt_obj) {
				return a;
			}

			return a + ItemPrice2(opt_obj.price, rdxCheckout.delivery.deliveryType);
		}, 0.0);

		PriceSecmod(tot_secmod);
	}, [selSecMods]);

	/**
	 */
	React.useEffect(() => {
		// console.log( 'selAddons: ', selAddons )

		const tot_addon = selAddons.reduce((a: any, c: ICartAddon) => {
			const addon_obj = props.item._addons.find(x => x._id === c.id);

			/// shouldn't happen, but for safety
			if (!addon_obj) {
				return a;
			}

			const opt_obj = addon_obj.opts.find(x => x._id === c.idOpt);

			/// shouldn't happen, but for safety
			if (!opt_obj) {
				return a;
			}

			return a + ItemPrice2(opt_obj.price, rdxCheckout.delivery.deliveryType);
		}, 0.0);

		PriceAddon(tot_addon);
	}, [selAddons]);

	/**
	 */
	React.useEffect(() => {
		UpdateCartBtn();
	}, [props.item, selMod, selSecMods]);

	/**
	 */
	const UpdateCartBtn = () => {
		let chkMod = false;
		let chkSecMod = false;
		// let chkAddon = false;

		if (!props.item._mods) {
			chkMod = true;
		} else if (props.item._mods && selMod.length) {
			chkMod = true;
		} else {
			chkMod = false;
		}

		if (!props.item._secmods) {
			chkSecMod = true;
		} else if (selSecMods && Object.keys(selSecMods).length === props.item._secmods.length) {
			chkSecMod = true;
		} else {
			chkSecMod = false;
		}

		// if (!props.item._mods && !props.item._secmods) {
		// 	chkAddon = true;
		// }

		/**
		 */
		if (chkMod && chkSecMod) {
			ShowCartBtn(true);
		} else {
			ShowCartBtn(false);
		}
	}; // UpdateCartBtn

	/**
	 */
	// const OnPress_Mod = (x: any, idx: number, parent: any) => {
	// 	PriceMod(ItemPrice(x, rdxCheckout.delivery.deliveryType));
	// 	++x._cnt; /// quickfix to make it work
	// 	SelMod({
	// 		_id: parent._id,
	// 		idx: idx,
	// 		_cnt: 1,
	// 		obj: x,
	// 		parent: parent,
	// 		str: ""
	// 	});
	// }; // OnPress_Mod
	const OnPress_Mod = (mod: any, idx: number, parent: any) => {
		// console.log( 'OnPress_Mod: mod: ', mod )

		SelMod((prev_list: ICartMod[]) => {
			const tmp_list = [...prev_list];
			const f_idx = tmp_list.findIndex(x => x.id === mod.id);

			if (0 <= f_idx) {
				return [];
			}

			// const tmp_mod = new CartMod( 1, mod._id, mod.name )
			const tmp_mod = new CartMod({ cnt: 1, id: mod._id, name: mod.name });

			// ...

			return [tmp_mod];
		});
	}; // OnPress_Mod

	/**
	 */
	// const OnPress_SecMod = (x: any, idx: number, parent: any) => {
	// 	let sel_sec_mods: any = selSecMods ? { ...selSecMods } : {};

	// 	sel_sec_mods[parent._id] = { _id: x._id, idx: idx, _cnt: 1, obj: x, parent: parent };

	// 	const s_m_p = Object.keys(sel_sec_mods).reduce((acc, cur) => {
	// 		return acc + ItemPrice(sel_sec_mods[cur].obj, rdxCheckout.delivery.deliveryType);
	// 	}, 0);

	// 	PriceSubmod(s_m_p);

	// 	// this.setState({ selSecMods: sel_sec_mods });
	// 	SelSecMods(sel_sec_mods);
	// }; // OnPress_SecMod
	const OnPress_SecMod = (option: any, optionIdx: number, secmod: any) => {
		// console.log( 'OnPress_Mod: x: ', x )
		// console.log( 'OnPress_Mod: idx: ', idx )
		// console.log( 'OnPress_Mod: parent: ', parent )

		SelSecMods((prev_list: ICartSecMod[]) => {
			// console.log( 'OnPress_SecMod: option: ', option )
			// console.log( 'OnPress_SecMod: idx: ', optionIdx )
			// console.log( 'OnPress_SecMod: secmod: ', secmod )

			const tmp_list = [...prev_list];

			const f_idx: number = tmp_list.findIndex(x => x.id === secmod._id);

			// console.log( 'OnPress_SecMod: f_idx: ', f_idx )

			if (-1 === f_idx) {
				// const secmod_obj = new CartSecMod(
				// 	1,
				// 	secmod._id, option._id,
				// 	secmod.name, option.name,
				// )
				const secmod_obj = new CartSecMod({
					cnt: 1,
					id: secmod._id,
					name: secmod.name,
					idOpt: option._id,
					nameOpt: option.name
				});

				// console.log( 'OnPress_SecMod: secmod_obj: ', secmod_obj )

				return [...tmp_list, secmod_obj];
			}
			// else if()
			// {
			// 	//
			// }
			else {
				// console.log( 'OnPress_SecMod: sec_mod: ', sec_mod )

				if (tmp_list[f_idx].idOpt === option._id) {
					/// required for addon
					// tmp_list.splice( f_idx, 1 )

					return tmp_list;
				} else {
					// const secmod_obj = new CartSecMod(
					// 	1,
					// 	secmod._id, option._id,
					// 	secmod.name, option.name,
					// )
					const secmod_obj = new CartSecMod({
						cnt: 1,
						id: secmod._id,
						name: secmod.name,
						idOpt: option._id,
						nameOpt: option.name
					});

					tmp_list[f_idx] = secmod_obj;
					return tmp_list;
				}
			}
		});
	}; // OnPress_SecMod

	/**
	 */
	// const OnPress_Addon = (x: any, idx: number) => {
	// 	console.log( 'OnPress_Addon: x: ', x )
	// 	console.log( 'OnPress_Addon: idx: ', idx )

	// 	let sel_addons = { ...selAddons };

	// 	// console.log( 'sel_addons: ', sel_addons )

	// 	if (sel_addons[x._id])
	// 	{
	// 		// console.log( 'deleting sel_addons: x._id: ', x._id )
	// 		delete sel_addons[x._id];
	// 		PriceAdd(priceAdd - ItemPrice(x, rdxCheckout.delivery.deliveryType));
	// 	}
	// 	else
	// 	{
	// 		// console.log( 'adding sel_addons: x._id: ', x._id )
	// 		sel_addons[x._id] = { _id: x._id, idx: idx, _cnt: 1, obj: x };
	// 		PriceAdd(priceAdd + ItemPrice(x, rdxCheckout.delivery.deliveryType));
	// 	}

	// 	// console.log( 'sel_addons: ', sel_addons )

	// 	SelAddons(sel_addons);
	// }; // OnPress_Addon
	const OnPress_Addon = (option: any, idx: number, addon: any) => {
		// console.log( 'OnPress_Addon: option: ', option )
		// console.log( 'OnPress_Addon: idx: ', idx )
		// console.log( 'OnPress_Addon: addon: ', addon )

		SelAddons((prev_list: ICartAddon[]) => {
			const tmp_list = [...prev_list];

			const f_idx: number = tmp_list.findIndex(x => x.id === addon._id && x.idOpt === option._id);

			// console.log( 'OnPress_Addon: f_idx: ', f_idx )

			if (-1 === f_idx) {
				// console.log( 'OnPress_Addon: addon: ', addon )
				// console.log( 'OnPress_Addon: option: ', option )

				try {
					// const addon_obj = new CartAddon(
					// 	1,
					// 	addon._id, option._id,
					// 	addon.name, option.name,
					// )
					const addon_obj = new CartAddon({
						cnt: 1,
						id: addon._id,
						name: addon.name,
						idOpt: option._id,
						nameOpt: option.name
					});

					// console.log( 'OnPress_Addon: addon_obj 1: ', addon_obj )
					// console.log( 'OnPress_Addon: tmp_list: ', tmp_list )

					return [...tmp_list, addon_obj];
				} catch (err) {
					console.warn(err);
					return tmp_list;
				}

				// const addon_obj = new CartAddon(
				// 	1,
				// 	addon._id, option._id,
				// 	addon.name, option.name,
				// )

				// console.log( 'OnPress_Addon: addon_obj 1: ', addon_obj )
				// console.log( 'OnPress_Addon: tmp_list: ', tmp_list )

				// return [...tmp_list, addon_obj]
			}
			// else if()
			// {
			// 	//
			// }
			else {
				// console.log( 'OnPress_Addon: option: ', option )

				// if( tmp_list[f_idx].idOpt === option._id )
				{
					/// required for addon
					tmp_list.splice(f_idx, 1);

					return tmp_list;
				}
				// else
				// {
				// 	const addon_obj = new CartAddon(
				// 		1,
				// 		addon._id, option._id,
				// 		addon.name, option.name,
				// 	)

				// 	console.log( 'OnPress_Addon: addon_obj 2: ', addon_obj )
				// 	console.log( 'OnPress_Addon: tmp_list: ', tmp_list )

				// 	// tmp_list[f_idx] = addon_obj

				// 	// return tmp_list
				// 	return [...tmp_list, addon_obj]
				// }
			}

			// const tmp_list = [...prev_list]

			// const f_idx: number = tmp_list.findIndex( x => x.id === addon._id )

			// // console.log( 'OnPress_Addon: f_idx: ', f_idx )

			// if( -1 === f_idx )
			// {
			// 	const addon_obj = new CartAddon(1, addon._id, addon.name)

			// 	// console.log( 'OnPress_Addon: addon_obj: ', addon_obj )

			// 	return [...tmp_list, addon_obj]
			// }
			// else
			// {
			// 	tmp_list.splice( f_idx, 1 )
			// 	return tmp_list
			// }
			// // else
			// // {
			// // 	// console.log( 'OnPress_Addon: sec_mod: ', sec_mod )

			// // 	if( tmp_list[f_idx].idOpt === addon._id )
			// // 	{
			// // 		tmp_list.splice( f_idx, 1 )

			// // 		return tmp_list
			// // 	}
			// // 	else
			// // 	{
			// // 		const secmod_obj = new CartSecMod(
			// // 			1,
			// // 			secmod._id, option._id,
			// // 			secmod.name, option.name,
			// // 		)

			// // 		tmp_list[f_idx] = secmod_obj
			// // 		return tmp_list
			// // 	}
			// // }
		});
	}; // OnPress_Addon

	/**
	 */
	const AddToCart = () => {
		try {
			// const cart_opt = new CartOpt(
			// 	cntItem,
			// 	new CoreItem(props.item._id, props.item.name),
			// 	new CoreCat(props.item.cat.id, props.item.cat.name),
			// 	new CoreSubcat(props.item.subcat.id, props.item.subcat.name),
			// 	selMod, selSecMods, selAddons,
			// )
			const cart_opt = new CartOpt({
				id: "", // empty string will generate a new id
				cnt: cntItem,
				item: new MenuItem({ id: props.item._id, name: props.item.name, type: props.item._type }),
				cat: new MenuCat({ id: props.item.cat.id, name: props.item.cat.name }),
				subcat: new MenuSubcat({ id: props.item.subcat.id, name: props.item.subcat.name }),
				mods: selMod,
				secMods: selSecMods,
				addons: selAddons
			});

			dispatch(CartUpdateAsync(cart_opt));

			SelMod([]);
			SelSecMods([]);
			SelAddons([]);

			props.OnClose();
		} catch (err) {
			console.warn(err);
		}
	}; // AddToCart
	// const AddToCart_ = () => {
	// 	try {
	// 		// for (let i = 0; i < cntItem; ++i) {
	// 			// get mod array
	// 			const arr_mods = selMod ? [{ ...selMod }] : [];

	// 			// get sec_mod array
	// 			let arr_secmods = selSecMods
	// 				? Object.keys(selSecMods).reduce((acc: any[], cur_k: any) => {
	// 						return [...acc, { ...selSecMods[cur_k] }];
	// 				  }, [])
	// 				: [];

	// 			arr_secmods.sort((a, b) => a.idx - b.idx);

	// 			// get addon array
	// 			let arr_addons = selAddons
	// 				? Object.keys(selAddons).reduce((acc: any[], cur_k: any) => {
	// 						return [...acc, { ...selAddons[cur_k] }];
	// 				  }, [])
	// 				: [];

	// 			// arr_addons.sort((a, b) => a.idx - b.idx);

	// 			// create opt_obj id
	// 			const arr_opts_obj = [...arr_mods, ...arr_secmods, ...arr_addons];

	// 			// console.log( 'arr_opts_obj: ', arr_opts_obj )

	// 			const arr_opts_str = arr_opts_obj.map(x => {
	// 				let objStr = Object.keys(x.obj).map(objKey => {
	// 					const _objObj = x.obj[objKey];
	// 					return _objObj.name;
	// 				});

	// 				objStr = objStr.sort();
	// 				// objStr = objStr.join(":");

	// 				// return x._id + ":" + x.idx + ":" + x._cnt + ":" + objStr.join(":");
	// 				return [x._id, x.idx, x._cnt, objStr.join(":"),].join(':')
	// 			} );

	// 			// console.log( 'arr_opts_str: ', arr_opts_str )

	// 			const opt_id = props.item._id + ";" + arr_opts_str.join(";");

	// 			console.log( 'AddToCart: opt_id: ', opt_id )

	// 			// add to _opts array and update _cnt
	// 			// if( true )
	// 			// {
	// 			// 	if (!props.item._opts) {
	// 			// 		props.item._opts = {};
	// 			// 	}

	// 			// 	if (!props.item._opts[opt_id])
	// 			// 	{
	// 			// 		props.item._opts[opt_id] = {
	// 			// 			_cnt: 0,
	// 			// 			_id: opt_id,

	// 			// 			selMod: arr_mods,
	// 			// 			selSecMods: arr_secmods,
	// 			// 			selAddons: arr_addons,
	// 			// 		};
	// 			// 	}

	// 			// 	++props.item._opts[opt_id]._cnt;
	// 			// 	++props.item._cnt;
	// 			// }	// if true

	// 			// dispatch(actions.Rdx_Order_AddToCart(item, sect, item._cnt));

	// 			console.log( 'AddToCart: props.item: ', props.item )
	// 			console.log( 'AddToCart: props.sect: ', props.sect )

	// 			// const opt_obj = {
	// 			// 	selMod: arr_mods,
	// 			// 	selSecMods: arr_secmods,
	// 			// 	selAddons: arr_addons,
	// 			// 	cnt: cntItem,
	// 			// }
	// 			// const opt_obj: ICartOpt = CartOptCreate(
	// 			// 	{id: ''}, {id: ''}, {id: ''},
	// 			// 	// selMod: arr_mods,
	// 			// 	// selSecMods: arr_secmods,
	// 			// 	// selAddons: arr_addons,
	// 			// )

	// 			// const opt_obj_2 = CartOptCnt( opt_obj, cntItem )

	// 			// console.log( 'AddToCart: opt_obj: ', opt_obj )

	// 			const opt_obj = new CartOpt(
	// 				1,
	// 				new MenuItemId('', ''), new MenuCatId('', ''), new MenuSubcatId('', ''),
	// 				selMod, selSecMods, selAddons,
	// 			)

	// 			dispatch( CartUpdateAsync(opt_obj) )

	// 			// await Timeouit( 2 )
	// 		// } // for i

	// 		SelMod([]);
	// 		SelSecMods([]);
	// 		SelAddons([]);

	// 		props.OnClose();

	// 		return {};
	// 	} catch (err) {
	// 		console.warn("AddToCart: err: ", err);

	// 		// alert( 'error _opts' )

	// 		return {};
	// 	}
	// }; // AddToCart

	/**
	 */
	return (
		<>
			<div>
				<FontAwesomeIcon className="modalClose" icon={faTimes} size="lg" color={"red"} onClick={() => props.OnClose(false)} />

				<>
					{props.item.img_url ? (
						<img style={{ width: "100%", height: 300, objectFit: "cover" }} src={props.item.img_url} />
					) : (
						<Image src={require("../assets/img/placeholder/skewer.jpg")} style={{ width: "100%", height: 300, objectFit: "cover" }} />
					)}
				</>

				<div style={{ paddingTop: 16, paddingBottom: 16, borderBottom: "1px solid #ddd", marginBottom: 16 }}>
					<div className="commonFlex">
						<div className="categoryItemTitle">{props.item.name}</div>
					</div>
					<div className="infoDescription">{props.item.desc}</div>
				</div>

				<>
					{props.item._mods && (
						<React.Fragment>
							{/* <div className="itemName">Primary Modifier</div> */}
							<div className="itemName"> Choose one | Required </div>
							<div className="itemElements">
								{props.item._mods.opts.map((mod: any, modIdx: number) => (
									// <div className="mainMod" key={modIdx}
									// 	onClick={() => OnPress_Mod(mod, modIdx, props.item._mods)}
									// >
									// 	{selMod?.idx === modIdx ? (
									// 		<div
									// 			className="modItemText"
									// 			style={{
									// 				color: color.colors.black,
									// 				fontWeight: 600
									// 				// border: "1px solid",
									// 				// borderRadius: 10,
									// 				// borderColor: colors.primary
									// 			}}>
									// 			<FontAwesomeIcon
									// 				style={{ marginRight: 8 }}
									// 				icon={faCheckCircle}
									// 				// size="lg"
									// 				color={props.colors.primary}
									// 			/>
									// 			<div style={{ flex: 1 }}>{mod.name}</div>
									// 			{"£" + ItemPrice(mod, rdxCheckout.delivery.deliveryType).toFixed(2)}
									// 		</div>
									// 	) : (
									// 		<div className="modItemText" style={{ color: color.colors.light }}>
									// 			<FontAwesomeIcon
									// 				style={{ marginRight: 8 }}
									// 				icon={faCircle}
									// 				// size="lg"
									// 				color="#f3f3f3"
									// 			/>
									// 			<div style={{ flex: 1 }}>{mod.name}</div>
									// 			{"£" + ItemPrice(mod, rdxCheckout.delivery.deliveryType).toFixed(2)}
									// 		</div>
									// 	)}
									// </div>
									<ModListView
										key={modIdx}
										mod={mod}
										modIdx={modIdx}
										deliveryType={rdxCheckout.delivery.deliveryType}
										item={props.item}
										colors={props.colors}
										selMods={selMod}
										OnPress_Mod={OnPress_Mod}
									/>
								))}
							</div>
						</React.Fragment>
					)}
				</>

				<>
					{props.item._secmods?.length && (
						<React.Fragment>
							{props.item._secmods.map((sec_mod: any, sec_mod_idx: number) => (
								<React.Fragment key={sec_mod._id}>
									<div className="itemName">{sec_mod.name}</div>
									<div className="categoryItemLittle"> Choose one | Required </div>
									<div className="itemElements">
										{sec_mod.opts.map((option: any, optionIdx: number) => (
											// <div className="mainMod" key={option.name} onClick={() => OnPress_SecMod(option, optionIdx, sec_mod)}>
											// 	{selSecMods?.[sec_mod._id]?._id === option._id ? (
											// 		<div
											// 			className="modItemText"
											// 			style={{
											// 				color: color.colors.black,
											// 				fontWeight: 700
											// 			}}>
											// 			<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCheckCircle} color={props.colors.primary} />
											// 			<div style={{ flex: 1 }}>{option.name}</div>
											// 			{"£" + ItemPrice(option, rdxCheckout.delivery.deliveryType).toFixed(2)}
											// 		</div>
											// 	) : (
											// 		<div
											// 			className="modItemText"
											// 			style={{
											// 				color: color.colors.light
											// 			}}>
											// 			<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCircle} color="#f3f3f3" />
											// 			<div style={{ flex: 1 }}>{option.name}</div>
											// 			{"£" + ItemPrice(option, rdxCheckout.delivery.deliveryType).toFixed(2)}
											// 		</div>
											// 	)}
											// </div>
											<SecmodListView
												key={option.name}
												secmod={sec_mod}
												option={option}
												optionIdx={optionIdx}
												deliveryType={rdxCheckout.delivery.deliveryType}
												colors={props.colors}
												selSecMods={selSecMods}
												OnPress_SecMod={OnPress_SecMod}
											/>
										))}
									</div>
								</React.Fragment>
							))}
						</React.Fragment>
					)}
				</>

				<>
					{props.item._addons?.length && (
						<React.Fragment>
							{props.item._addons.map((addon: any, addonIdx: number) => (
								<React.Fragment key={addon.name}>
									<div className="itemName">{addon.name}</div>
									<div className="categoryItemLittle"> Choose multiple | Optional </div>
									<div className="itemElements">
										{addon.opts.map((option: any, optionIdx: number) => (
											// <div className="mainMod" key={option.name} onClick={() => OnPress_Addon(option, optionIdx)}>
											// 	{selAddons?.[option._id] ? (
											// 		<div
											// 			className="modItemText"
											// 			style={{
											// 				color: color.colors.black,
											// 				fontWeight: 700
											// 			}}>
											// 			<FontAwesomeIcon
											// 				style={{ marginRight: 8 }}
											// 				icon={faCheckSquare}
											// 				// size="lg"
											// 				color={props.colors.primary}
											// 			/>
											// 			<div style={{ flex: 1 }}>{option.name}</div>
											// 			{"+£" + ItemPrice(option, rdxCheckout.delivery.deliveryType).toFixed(2)}
											// 		</div>
											// 	) : (
											// 		<div
											// 			className="modItemText"
											// 			style={{
											// 				color: color.colors.light
											// 			}}>
											// 			<FontAwesomeIcon
											// 				style={{ marginRight: 8 }}
											// 				icon={faSquare}
											// 				// size="lg"
											// 				color="#f3f3f3"
											// 			/>
											// 			<div style={{ flex: 1 }}>{option.name}</div>
											// 			{"+£" + ItemPrice(option, rdxCheckout.delivery.deliveryType).toFixed(2)}
											// 		</div>
											// 	)}
											// </div>
											<AddonListView
												key={option.name}
												addon={addon}
												option={option}
												optionIdx={optionIdx}
												deliveryType={rdxCheckout.delivery.deliveryType}
												colors={props.colors}
												selAddons={selAddons}
												OnPress_Addon={OnPress_Addon}
											/>
										))}
									</div>
								</React.Fragment>
							))}
						</React.Fragment>
					)}
				</>
			</div>

			<div className="cartCrossDiv">
				{showCartBtn && (
					<>
						<>
							{cntItem > 0 ? (
								<FontAwesomeIcon
									className="cartPlus"
									style={{ borderColor: props.colors.primary }}
									// onClick={RemoveFromCart}
									onClick={() => CntItem(cntItem - 1)}
									icon={faMinus}
									color={props.colors.primary}
								/>
							) : (
								<FontAwesomeIcon className="cartPlus" style={{ borderColor: props.colors.primary }} icon={faMinus} color={"#ddd"} />
							)}
						</>

						<div className="cartItemCount" style={{ fontSize: 20, fontWeight: "700" }}>
							{`${cntItem}`}
						</div>

						<FontAwesomeIcon
							className="cartPlus"
							style={{ borderColor: props.colors.primary }}
							// onClick={OnPress}
							onClick={() => CntItem(cntItem + 1)}
							icon={faPlus}
							color={props.colors.primary}
						/>
					</>
				)}
			</div>

			<>
				{showCartBtn && cntItem ? ( // @todo for local price
					// showCartBtn ? (
					<div className="buttonPadding">
						<Button
							onClick={() => AddToCart()}
							// onClick={() => AddToCart().catch( console.warn )}
							variant="success"
							style={{ width: "100%", background: props.colors.primary }}>
							Add to basket ( £{(priceTot * cntItem).toFixed(2)} ){/* Add to basket ( £{parseFloat(priceTot).toFixed(2)} ) */}
						</Button>
					</div>
				) : (
					<div className="buttonPadding">
						<Button disabled variant="secondary" style={{ width: "100%", cursor: "no-drop" }}>
							Add to basket ( £{priceTot.toFixed(2)} )
						</Button>
					</div>
				)}
			</>

			{/* Show basket items */}
			{/* <div>
				<ModEx_Cart item={props.item} sect={sect} />
			</div> */}
		</>
	);
}; // ModEx_Modal

// interface IModExCart
// {
// 	item: any,
// 	sect: any,
// }	// IModExCart

// /**
//  */
// const ModEx_Cart = (props: IModExCart) => {
// 	const rdxOrder = useSelector(state => state.__order);

// 	const [listCart, ListCart] = React.useState([]);

// 	/**
// 	 */
// 	React.useEffect(() => {
// 		GetItems();
// 	}, []);

// 	/**
// 	 */
// 	const GetItems = () => {
// 		// get cart items
// 		const cat_obj = rdxOrder.cart_sects.find(x => x.cat_id === props.item.cat.id);

// 		if (!cat_obj) {
// 			return {};
// 		}

// 		const filter_list = cat_obj.data.filter(x => x.resource_id === props.item.resource_id);

// 		ListCart(filter_list);
// 	}; // GetItems

// 	/**
// 	 */
// 	return (
// 		<div>
// 			{
// 				// listCart.map( x => (
// 				// 	<>
// 				// 	{
// 				// 		x.name
// 				// 	}
// 				// 	</>
// 				// ) )
// 			}
// 		</div>
// 	);
// }; // ModEx_Cart

/**
 */
export default ModEx_Modal;

interface IModListView {
	mod: any;
	modIdx: number;

	deliveryType: EOrderTtype;
	item: any;
	colors: any;

	selMods: ICartMod[];

	OnPress_Mod: any;
} // IModListView

const ModListView = (props: IModListView) => {
	const [isSelected, IsSelected] = React.useState(false);

	/**
	 */
	React.useEffect(() => {
		const secmod = props.selMods.find(x => x.id === props.mod._id);

		// console.log( 'props.selMods: ', props.selMods )

		IsSelected(secmod ? true : false);
	}, [props.selMods]);

	/**
	 */
	return (
		<div className="mainMod" key={props.modIdx} onClick={() => props.OnPress_Mod(props.mod, props.modIdx, props.item._mods)}>
			{isSelected ? (
				<div
					className="modItemText"
					style={{
						color: color.colors.black,
						fontWeight: 600
						// border: "1px solid",
						// borderRadius: 10,
						// borderColor: colors.primary
					}}>
					<FontAwesomeIcon
						style={{ marginRight: 8 }}
						icon={faCheckCircle}
						// size="lg"
						color={props.colors.primary}
					/>
					<div style={{ flex: 1 }}>{props.mod.name}</div>
					{"£" + ItemPrice2(props.mod.price, props.deliveryType).toFixed(2)}
				</div>
			) : (
				<div className="modItemText" style={{ color: color.colors.light }}>
					<FontAwesomeIcon
						style={{ marginRight: 8 }}
						icon={faCircle}
						// size="lg"
						color="#f3f3f3"
					/>
					<div style={{ flex: 1 }}>{props.mod.name}</div>
					{"£" + ItemPrice2(props.mod.price, props.deliveryType).toFixed(2)}
				</div>
			)}
		</div>
	);
}; // ModListView

/**
 */
interface ISecmodListView {
	secmod: any;
	option: any;
	optionIdx: number;

	deliveryType: EOrderTtype;
	colors: any;

	selSecMods: ICartSecMod[];

	OnPress_SecMod: any;
} // ISecmodListView

const SecmodListView = (props: ISecmodListView) => {
	const [isSelected, IsSelected] = React.useState(false);

	/**
	 */
	React.useEffect(() => {
		// original logic
		// selSecMods?.[sec_mod._id]?._id === option._id

		// console.log( 'SecmodListView: props.selSecMods: ', props.selSecMods )
		// console.log( 'SecmodListView: props.secmod: ', props.secmod )
		// console.log( 'SecmodListView: props.option: ', props.option )

		const sel_secmod = props.selSecMods.find(x => x.id === props.secmod._id);

		// console.log( 'SecmodListView: sel_secmod: ', sel_secmod )

		if (!sel_secmod) {
			IsSelected(false);
			return undefined;
		}

		IsSelected(sel_secmod.idOpt === props.option._id ? true : false);

		// const sel_option = props.secmod.opts.find( (x: any) => x._id === sel_secmod.idOpt )

		// console.log( 'SecmodListView: sel_option: ', sel_option )

		// IsSelected( sel_option ? true : false )
	}, [props.selSecMods]);

	/**
	 */
	return (
		<div className="mainMod" key={props.option.name} onClick={() => props.OnPress_SecMod(props.option, props.optionIdx, props.secmod)}>
			{isSelected ? (
				<div
					className="modItemText"
					style={{
						color: color.colors.black,
						fontWeight: 700
					}}>
					<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCheckCircle} color={props.colors.primary} />
					<div style={{ flex: 1 }}>{props.option.name}</div>
					{"£" + ItemPrice2(props.option.price, props.deliveryType).toFixed(2)}
				</div>
			) : (
				<div
					className="modItemText"
					style={{
						color: color.colors.light
					}}>
					<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCircle} color="#f3f3f3" />
					<div style={{ flex: 1 }}>{props.option.name}</div>
					{"£" + ItemPrice2(props.option.price, props.deliveryType).toFixed(2)}
				</div>
			)}
		</div>
	);
}; // SecmodListView

/**
 */
interface IAddonListView {
	addon: any;
	option: any;
	optionIdx: number;

	deliveryType: EOrderTtype;
	colors: any;

	selAddons: ICartAddon[];

	OnPress_Addon: any;
} // IAddonListView

const AddonListView = (props: IAddonListView) => {
	const [isSelected, IsSelected] = React.useState(false);

	/**
	 */
	React.useEffect(() => {
		// console.log( 'props.addon: ', props.addon )
		// console.log( 'props.option: ', props.option )
		// console.log( 'props.selAddons: ', props.selAddons )

		// const addon = props.selAddons.find( x => x.id === props.option._id )

		// // console.log( 'addon: ', addon )

		// IsSelected( addon ? true : false )

		const sel_addon = props.selAddons.find(x => x.id === props.addon._id && x.idOpt === props.option._id);

		// console.log( 'AddonListView: sel_addon: ', sel_addon )

		IsSelected(sel_addon ? true : false);

		// if( !sel_addon )
		// {
		// 	IsSelected( false )
		// 	return undefined
		// }

		// IsSelected( sel_addon.idOpt === props.option._id ? true : false )

		// const sel_option = props.secmod.opts.find( (x: any) => x._id === sel_secmod.idOpt )

		// console.log( 'SecmodListView: sel_option: ', sel_option )

		// IsSelected( sel_option ? true : false )
	}, [props.selAddons]);

	/**
	 */
	return (
		<div className="mainMod" key={props.option.name} onClick={() => props.OnPress_Addon(props.option, props.optionIdx, props.addon)}>
			{isSelected ? (
				<div
					className="modItemText"
					style={{
						color: color.colors.black,
						fontWeight: 700
					}}>
					<FontAwesomeIcon
						style={{ marginRight: 8 }}
						icon={faCheckSquare}
						// size="lg"
						color={props.colors.primary}
					/>
					<div style={{ flex: 1 }}>{props.option.name}</div>
					{"+£" + ItemPrice2(props.option.price, props.deliveryType).toFixed(2)}
				</div>
			) : (
				<div
					className="modItemText"
					style={{
						color: color.colors.light
					}}>
					<FontAwesomeIcon
						style={{ marginRight: 8 }}
						icon={faSquare}
						// size="lg"
						color="#f3f3f3"
					/>
					<div style={{ flex: 1 }}>{props.option.name}</div>
					{"+£" + ItemPrice2(props.option.price, props.deliveryType).toFixed(2)}
				</div>
			)}
		</div>
	);
}; // AddonListView
