/**
 *    @copyright    Elmelo Ltd
 */

// import { Currency } from "../../api";
// import ONT from "../order/ont";
// import { ItemPrice } from "../order/itemPrice";

// import Social_Core from "../../api/social/core"

import { ICartOpt } from "../../_types/cart";
import { EOrderTtype } from "../../_types/menu";

import { CheckVisAvail } from "../order/_utils_2";

enum EStage {
	Live = "live",
	Test = "test"
} // EStage

interface IOrderAmountInfo {
	Total: number;
	SubTotal: number;
	vat: string;
	charge: number;
	discount: number;
} // IOrderAmountInfo

interface IUsername {
	first: string;
	last: string;
} // IUsername

interface IUserInfo {
	username: IUsername;
	mobile: string;
	email: string;
	Address?: string;
} // IUserInfo

interface IInvoice {
	invoice_data: any;

	order_amount_info: IOrderAmountInfo;

	delivery_option: EOrderTtype;

	user_info: IUserInfo;

	stage: EStage;

	save_status: boolean;
}

interface IInvoiceSave {
	rdxCfg: any;
	rdxCoreMenu: any;
	rdxOrder: any;
	rdxCart: any;
	rdxCheckout: any;

	vatRate?: number;
} // IInvoiceSave

/**
 */
const GenerateCartSects = (menu_core: any, cart_items: ICartOpt[], delivery_type: EOrderTtype) => {
	let cart_obj: any = {};
	let cart_sects: any[] = [];

	// console.log("!!!!!!!!!!!!!!!!!! GenerateCartSects : menu_core : ", menu_core);
	// console.log("!!!!!!!!!!!!!!!!!! GenerateCartSects : cart_items : ", cart_items);

	cart_items.forEach((cart_item: ICartOpt) => {
		// find cat obj
		const cat_obj = menu_core.find((x: any) => x.cat_id === cart_item.cat.id);

		if (!cat_obj) {
			/// shouldn't happen though
			return {};
		}

		// find item
		const item_obj = cat_obj.data.find((x: any) => x._id === cart_item.item.id);

		if (!item_obj) {
			/// shouldn't happen though
			return {};
		}

		if (!CheckVisAvail(item_obj.vis, delivery_type) || !CheckVisAvail(item_obj.avail, delivery_type)) {
			return {};
		}

		/// prepare cart_sect
		if (!cart_obj[cat_obj.cat_id]) {
			//
			cart_obj[cat_obj.cat_id] = {
				cat_id: cat_obj.cat_id,
				cat_idx: cat_obj.cat_idx,
				cat: {
					///  @todo Fill up this if used later
				},
				data: [],
				data_obj: {}
			};

			// add to array
			cart_sects.push(cart_obj[cat_obj.cat_id]);

			// sort
			cart_sects.sort((a, b) => a.cat_idx - b.cat_idx);
		} // if undefined

		// handle new menu item in cart_sect
		if (!cart_obj[cat_obj.cat_id].data_obj[item_obj._id]) {
			// cart_obj[sect.cat_id].data_obj[item._id] = item;
			const {
				addons,
				mod,
				secmods,
				sku,
				cat,
				subcat,
				desc,
				name,
				price,
				resource_id,
				idx,
				_cnt,
				_id,
				_t,
				_type,
				_uid,
				_v_idx,

				_mods

				// _opts,
			} = item_obj;

			const tmp_obj = {
				addons,
				mod,
				secmods,
				sku,
				cat,
				subcat,
				desc,
				name,
				price,
				// price: cart_item.price,
				resource_id,
				idx,
				_cnt: cart_item.cnt,
				_id,
				_t,
				_type,
				_uid,
				_v_idx,

				_mods,

				_opts: {}
			};

			// console.log("!!!!!!!!!!!!!!!!! : item_obj : ", item_obj);
			// console.log("!!!!!!!!!!!!!!!!! : tmp_obj : ", tmp_obj);

			cart_obj[cat_obj.cat_id].data_obj[item_obj._id] = tmp_obj;

			// cart_obj[sect.cat_id].data.push( tmp_obj );
			cart_obj[cat_obj.cat_id].data.push(cart_obj[cat_obj.cat_id].data_obj[item_obj._id]);
		}
		// console.log("!!!!!!!!!!!!!!!!! : cart_obj before : ", cart_obj);

		// ryan code
		// cart_obj[cat_obj.cat_id].data_obj[item_obj._id]._mods.opts.map(mod => {
		// 	const mod_idx = item_obj._mods.opts.findIndex((opt: any) => mod.id === opt._id);

		// 	console.log("!!!!!!!!!!!!!!!!! : mod : ", mod);
		// 	console.log("!!!!!!!!!!!!!!!!! : cart_item : ", cart_item);
		// 	console.log("!!!!!!!!!!!!!!!!! : mod[mod_idx] : ", mod[mod_idx]);

		// 	if ("4KTTq8Zh2aaS+84P3bSbTg==" === mod._id) {
		// 		mod._cnt = 1;
		// 	}

		// 	// mod[mod_idx]._cnt = 1;
		// });

		/// manage option for the item
		cart_obj[cat_obj.cat_id].data_obj[item_obj._id]._opts[cart_item.id] = {
			_cnt: cart_item.cnt,
			_id: cart_item.id,

			selMod: cart_item.mods
				?.map(mod => {
					// find mod
					const mod_idx = item_obj._mods.opts.findIndex((opt: any) => mod.id === opt._id);

					if (-1 === mod_idx) {
						return null;
					}

					const mod_obj = item_obj._mods.opts[mod_idx];

					return {
						_id: item_obj._mods._id, //parent._id,
						idx: mod_idx, //idx,
						_cnt: 1,
						obj: mod_obj, //x,
						// parent: item_obj._mods, //parent,
						str: ""
					};
				})
				// .filter(Boolean)?.[0],
				.filter(Boolean),

			selSecMods: cart_item.secMods
				?.map(secmod => {
					// find secmod
					const secmod_idx = item_obj._secmods.findIndex((opt: any) => opt._id === secmod.id);

					if (-1 === secmod_idx) {
						return null;
					}

					const secmod_obj = item_obj._secmods[secmod_idx];

					const opt_idx = secmod_obj.opts.findIndex((opt: any) => opt._id === secmod.idOpt);

					if (-1 === opt_idx) {
						return null;
					}

					const opt_obj = secmod_obj.opts[opt_idx];

					return {
						_id: opt_obj._id, //x._id,
						idx: opt_idx, //idx,
						_cnt: 1,
						obj: opt_obj //x,
						// parent: secmod_obj,//parent
					};
				})
				.filter(Boolean),

			selAddons: cart_item.addons
				?.map(addon => {
					// find addon
					const addon_idx = item_obj._addons.findIndex((opt: any) => opt._id === addon.id);

					if (-1 === addon_idx) {
						return null;
					}

					const addon_obj = item_obj._addons[addon_idx];

					const opt_idx = addon_obj.opts.findIndex((opt: any) => opt._id === addon.idOpt);

					if (-1 === opt_idx) {
						return null;
					}

					const opt_obj = addon_obj.opts[opt_idx];

					return {
						_id: opt_obj._id, //x._id,
						idx: opt_idx, //idx,
						_cnt: 1,
						obj: opt_obj //x,
					};
				})
				.filter(Boolean)
		};
		// console.log("!!!!!!!!!!!!!!!!! : cart_obj after : ", cart_obj);
	});
	// console.log("!!!!!!!!!!!!!!!!!! GenerateCartSects : cart_sects : ", cart_sects);/

	return cart_sects;

	// const menu_cart_1 = menu_core.filter( ( x: any ) => x.cat_id )

	// const filtered_sects = cart_sects
	// 	.map(sect => {
	// 		sect.data = sect.data.filter(item => CheckVisAvail(item.vis, delivery_type) && CheckVisAvail(item.avail, delivery_type));
	// 		return sect;
	// 	})
	// 	.filter(sect => sect.data.length);

	// return filtered_sects;
}; // GenerateCartSects

/**
 */
async function Invoice_Save(props: IInvoiceSave) {
	try {
		// menu deep copy
		const menu_core: any = structuredClone(props.rdxCoreMenu);

		// generate invoice data / item list
		const invoice_data = GenerateCartSects(menu_core, props.rdxCart.items, props.rdxCheckout.delivery.deliveryType); //__order.cart_sects,

		// Calculate VAT
		const _vat = (props.rdxCheckout.charges.subtotal.amount * (props.vatRate || 0.1667)).toFixed(2);

		let invoice: IInvoice = {
			invoice_data,

			order_amount_info: {
				Total: props.rdxCheckout.Total(), //this.getTotal(),
				SubTotal: props.rdxCheckout.charges.subtotal.amount.toFixed(2),
				vat: _vat,
				charge: props.rdxCheckout.ListCharges().filter((x: any) => "subtotal" != x.type && x.amount),
				discount: props.rdxCheckout.ListDiscounts().filter((x: any) => x.amount)
			},

			delivery_option: props.rdxCheckout.delivery.deliveryType,
			user_info: {
				username: { first: props.rdxCheckout.info.firstName, last: props.rdxCheckout.info.lastName },
				mobile: props.rdxCheckout.info.phone,
				email: props.rdxCheckout.info.email
			},
			// pub_id:  await Social_Core.PubId( cfg ),
			stage: props.rdxCfg.stage,

			save_status: true
		};

		if (invoice.delivery_option.toLowerCase() === "delivery") {
			invoice.user_info.Address = props.rdxCheckout.delivery.deliveryAddress;
		}

		return invoice;
	} catch (err) {
		console.warn("checkout_invoice/Invoice_Save: err: ", err);
		return Promise.reject(err);
	}
} // Invoice_Save

/**
 */
async function Invoice_Process(order_obj: any, props: any) {
	try {
		const transid = order_obj.order_id.split("_")[3];
		const del_type = order_obj.delivery_option === "collection" ? "Collection" : "Delivery";

		let del_addr = "";
		if (del_type === "Delivery") {
			del_addr =
				order_obj.user_info.Address.line1 +
				(order_obj.user_info.Address.line2 ? ", " + order_obj.user_info.Address.line2 : "") +
				(order_obj.user_info.Address.city ? ", " + order_obj.user_info.Address.city : "") +
				(order_obj.user_info.Address.county ? ", " + order_obj.user_info.Address.county : "") +
				", " +
				order_obj.user_info.Address.postcode +
				(order_obj.user_info.Address.country ? ", " + order_obj.user_info.Address.country : "");
		}

		let col_addr = "";
		if (del_type === "Collection") {
			col_addr =
				props.rdxBiz.addr.addr.line1 +
				(props.rdxBiz.addr.addr.line2 ? ", " + props.rdxBiz.addr.addr.line2 : "") +
				(props.rdxBiz.addr.addr.city ? ", " + props.rdxBiz.addr.addr.city : "") +
				(props.rdxBiz.addr.addr.county ? ", " + props.rdxBiz.addr.addr.county : "") +
				", " +
				props.rdxBiz.addr.addr.postcode +
				(props.rdxBiz.addr.addr.country ? ", " + props.rdxBiz.addr.addr.country : "");
		}

		let dt = new Date(order_obj.dt_create * 1000);
		let month = dt.getMonth() + 1;
		let date = dt.getDate();
		let year = dt.getFullYear();
		let day = dt.getDay();
		let week = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

		const order_date = week[day] + " " + date + "/" + month + "/" + year;

		const invoice_data = order_obj.items;

		let html_body = "";
		html_body += "<body>";
		html_body += "    <div id='wrapper'>";
		html_body += "        <div class='one-col'>";
		html_body += "            <p>";
		html_body += "                Hi " + order_obj.user_info.username.first;
		html_body += "            </p>";
		html_body += "            <p>";
		html_body += "                Your order has been received.";
		html_body += "            </p>";
		html_body += "            <p>";
		html_body += "                We hope you enjoy, and we would love to see you again soon!";
		html_body += "            </p>";
		html_body += "            <p>";
		html_body += "                For full ingredients, nutritional and allergen information, please call the restaurant.";
		html_body += "            </p>";
		html_body += "            <div id='divGap'>";
		html_body += "                <h5>Order Number:</h6>";
		html_body += "                    <h6>";
		html_body += "                        " + transid;
		html_body += "                    </h6>";
		html_body += "</div>";
		html_body += "            <div id='divGap'>";
		html_body += "                <h5>Order Type:</h6>";
		html_body += "                    <h6>";
		html_body += "                        " + del_type;
		html_body += "                    </h6>";
		html_body += "            </div>";

		if (del_type === "Delivery") {
			html_body += "            <div id='divGap'>";
			html_body += "                <h5>Delivery address:</h5>";
			html_body += "                <h6>";
			html_body += "                    " + del_addr;
			html_body += "                </h6>";
			html_body += "            </div>";
		}
		if (del_type === "Collection") {
			html_body += "            <div id='divGap'>";
			html_body += "                <h5>Collection address:</h5>";
			html_body += "                <h6>";
			html_body += "                    " + col_addr;
			html_body += "                </h6>";
			html_body += "            </div>";
		}

		html_body += "            <div id='divGap'>";
		html_body += "                <h5>Order date:</h6>";
		html_body += "                    <h6>";
		html_body += "                        " + order_date;
		html_body += "                    </h6>";
		html_body += "            </div>";

		if (order_obj.advanced_order) {
			html_body += "            <div id='divGap'>";
			html_body += "                <h5>Requested " + order_obj.delivery_option + " time:";
			html_body += "                    </h6>";
			html_body += "                    <h6>";
			html_body += "                        ASAP"; //	@todo advanced order
			html_body += "                    </h6>";
			html_body += "            </div>";
		}

		html_body += "            <div id='divGap'>";
		html_body += "                <h5>Items Ordered:</h6>";

		// console.log( 'invoice_data: Invoice_Process: ', invoice_data )

		invoice_data.map((sect_data: any) => {
			sect_data.data.map((item: any) => {
				// console.log("!!!!!!!!!!!!!!!!!!!!!!! item : ", item);

				if (item._type === "mod") {
					item._mods.opts.map((x: any) => (html_body += "<h6> " + x._cnt + " x " + item.name + " - " + x.name + "</h6>"));
				}

				if (item._type === "mod_ex")
				{
					// let _mod = "";

					// console.log( 'invoice_data: item: ', item )
					// console.log( 'invoice_data: item._opts: ', item._opts )

					Object.keys(item._opts).map((opt_key, idx) => {
						const opt_obj = item._opts[opt_key];

						// console.log( 'invoice_data: opt_obj._cnt: ', opt_obj._cnt )

						let _mod = "";

						if (opt_obj._cnt)
						{
							// _mod += "&emsp;" + " - " + opt_obj.selMod.obj.name + "<br>"
							opt_obj.selMod.map((s_m, idx) => (s_m._cnt ? (_mod += "&emsp;" + " - " + s_m.obj.name + "<br>") : null));

							opt_obj.selSecMods?.map((s_m: any, idx: number) => (s_m._cnt ? (_mod += "&emsp;" + " - " + s_m.obj.name + "<br>") : null));

							opt_obj.selAddons?.map((s_m: any, idx: number) => (s_m._cnt ? (_mod += "&emsp;" + " - " + s_m.obj.name + "<br>") : null));
						}

						html_body += "<h6> " + item._cnt + " x " + item.name + _mod + "</h6>";
					});
				}

				if (item._type === "basic") {
					html_body += "<h6> " + item._cnt + " x " + item.name + "</h6>";
				}
			});
		});

		html_body += "            </div>";

		html_body += "            <div id='divGap'>";
		html_body += "                <h5>Total:</h6>";
		// html_body += "                    <h6>";
		// html_body += "                        Items: £" + order_obj.invoice.SubTotal;
		// html_body += "                    </h6>";

		// order_obj.invoice.charge.map(_charge => {
		// 	html_body += "<h6>" + _charge.name + ": £" + _charge.amount.toFixed(2) + "</h6>";
		// });

		// order_obj.invoice.discount.map(_discount => {
		// 	html_body += "<h6>" + _discount.name + ": - £" + _discount.amount + "</h6>";
		// });

		html_body += "                    <h6>";
		html_body += "                        £" + order_obj.invoice.Total.toFixed(2);
		html_body += "                    </h6>";
		html_body += "            </div>";

		if (order_obj.comments) {
			html_body += "            <div id='divGap'>";
			html_body += "                <h5>Comments for kitchen" + del_type === "Delivery" ? "/driver" : "" + ":</h6>";
			html_body += "                    <h6>";
			html_body += "                        " + order_obj.comments;
			html_body += "                    </h6>";
			html_body += "            </div>";
		}

		html_body += "            <div id='logo'>";
		html_body += "                " + props.rdxBiz.biz_title;
		html_body += "            </div>";
		html_body += "            <footer>";
		html_body += "                <div class='qft'> Online Ordering by</div>";
		html_body += "                <div class='qf'>QuickFood</div>";
		html_body += "            </footer>";
		html_body += "        </div>";
		html_body += "    </div>";
		html_body += "</body>";

		let html_head =
			"<head>" +
			"    <meta charset='UTF-8' />" +
			"    <meta name='viewport' content='width=device-width, initial-scale=1.0' />" +
			"    <title>Document</title>" +
			"    <style>" +
			"        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');" +
			"        * {" +
			"            margin: 0;" +
			"            padding: 0;" +
			"            border: 0;" +
			"        }" +
			"        body {" +
			"            font-family: 'Open Sans', sans-serif;" +
			"            background-color: #d8dada;" +
			"            font-size: 19px;" +
			"            max-width: 800px;" +
			"            margin: 0 auto;" +
			"            padding: 3%;" +
			"        }" +
			"        img {" +
			"            max-width: 100%;" +
			"        }" +
			"        header {" +
			"            width: 98%;" +
			"        }" +
			"        #logo {" +
			"            max-width: 120px;" +
			"            padding: 3%;" +
			"        }" +
			"        #wrapper {" +
			"            background-color: #f9fafa;" +
			"        }" +
			"        #social {" +
			"            float: right;" +
			"            margin: 3% 2% 4% 3%;" +
			"            list-style-type: none;" +
			"        }" +
			"        #social>li {" +
			"            display: inline;" +
			"        }" +
			"        #social>li>a>img {" +
			"            max-width: 35px;" +
			"        }" +
			"        h1," +
			"        p {" +
			"            margin: 3%;" +
			"        }" +
			"        h5 {" +
			"            font-size: 14px;" +
			"            font-weight: 700;" +
			"        }" +
			"        h6 {" +
			"            font-size: 14px;" +
			"            font-weight: 400;" +
			"        }" +
			"        .btn {" +
			"            float: right;" +
			"            margin: 0 2% 4% 0;" +
			"            background-color: #303840;" +
			"            color: #f6faff;" +
			"            text-decoration: none;" +
			"            font-weight: 800;" +
			"            padding: 8px 12px;" +
			"            border-radius: 8px;" +
			"            letter-spacing: 2px;" +
			"        }" +
			"        hr {" +
			"            height: 1px;" +
			"            background-color: #303840;" +
			"            clear: both;" +
			"            width: 96%;" +
			"            margin: auto;" +
			"        }" +
			"        #contact {" +
			"            text-align: center;" +
			"            padding-bottom: 3%;" +
			"            line-height: 16px;" +
			"            font-size: 12px;" +
			"            color: #303840;" +
			"        }" +
			"        p {" +
			"            font-size: 14px;" +
			"        }" +
			"		 #divGap {" +
			"            margin: 3%;" +
			"        }" +
			"        .one-col {" +
			"            padding-top: 3%;" +
			"        }" +
			"        .qft {" +
			"            font-size: 12px;" +
			"            text-align: center;" +
			"        }" +
			"        .qf {" +
			"            text-align: center;" +
			"            color: #32a16e;" +
			"            padding-bottom: 3%;" +
			"            font-size: 32px;" +
			"            font-weight: 700;" +
			"        }" +
			"    </style>" +
			"</head>";

		let html = "<html lang='en'>";
		html += html_head;
		html += html_body;
		html += "</html>";

		return html;
	} catch (err) {
		console.warn("checkout_invoice/Invoice_Process: err: ", err);
		return Promise.reject(err);
	}
} // Invoice_Process

export { Invoice_Save, Invoice_Process };
