/**
 *	@copyright	Elmelo Ltd.
 */

// import elml_cfg from '../../_config/elml_cfg'

import { DDB } from "../../api/AWS";

import { AStorage, Utils } from "../../api/Utils";

/**
 */
export const Rdx_Biz_Init = () => {
	return async (dispatch, gs) => {
		try {
			if ("inprogress" === gs().__biz.initStatus) return { msg: "OK" };

			dispatch(Rdx_Biz_InitStatus("inprogress"));

			const biz_stored = await AStorage.Get("biz");

			if (biz_stored) {
				dispatch(Rdx_Biz_InitStatus("stale"));

				if (biz_stored.biz_title) dispatch(Biz_BizTitle(biz_stored.biz_title));

				if (biz_stored.biz_images) dispatch(Biz_BizImages(biz_stored.biz_images));

				if (biz_stored.biz_images && biz_stored.biz_images.images_gallery) dispatch(Biz_ImagesGallery(biz_stored.biz_images.images_gallery));

				if (biz_stored.biz_images && biz_stored.biz_images.images_featured) dispatch(Biz_ImagesFeatured(biz_stored.biz_images.images_featured));

				if (biz_stored.biz_suspend) dispatch(Biz_Suspend(biz_stored.biz_suspend));

				if (biz_stored.biz_ot) dispatch(Biz_OT(Utils.ParseString(biz_stored.biz_ot)));

				if (biz_stored.biz_contact) dispatch(Biz_Contact(biz_stored.biz_contact));
				if (biz_stored.biz_addr) dispatch(Biz_Addr(biz_stored.biz_addr));

				if (biz_stored.biz_delivery) dispatch(Biz_Delivery(biz_stored.biz_delivery));
				if (biz_stored.biz_settings) dispatch(Biz_Settings(biz_stored.biz_settings));
				if (biz_stored.discount) dispatch(Biz_Discount(biz_stored.discount));

				if (biz_stored.offers_featured) dispatch(Biz_OffersFeatured(biz_stored.offers_featured));

				if (biz_stored.offers) dispatch(Biz_Offers(biz_stored.offers));

				if (biz_stored.biz_awards) dispatch(Biz_Awards(biz_stored.biz_awards));

				if (biz_stored.site_settings) dispatch(Biz_SiteSettings(biz_stored.site_settings));
			}

			const biz_retr = await RetrBiz(dispatch, gs);

			if (biz_retr.biz_title) dispatch(Biz_BizTitle(biz_retr.biz_title));

			if (biz_retr.biz_images) dispatch(Biz_BizImages(biz_retr.biz_images));
			if (biz_retr.biz_images && biz_retr.biz_images.images_gallery) dispatch(Biz_ImagesGallery(biz_retr.biz_images.images_gallery));

			if (biz_retr.biz_images && biz_retr.biz_images.images_featured) dispatch(Biz_ImagesFeatured(biz_retr.biz_images.images_featured));

			if (biz_retr.biz_suspend) dispatch(Biz_Suspend(biz_retr.biz_suspend));

			if (biz_retr.biz_ot) dispatch(Biz_OT(Utils.ParseString(biz_retr.biz_ot)));

			if (biz_retr.biz_contact) dispatch(Biz_Contact(biz_retr.biz_contact));
			if (biz_retr.biz_addr) dispatch(Biz_Addr(biz_retr.biz_addr));

			if (biz_retr.biz_delivery) dispatch(Biz_Delivery(biz_retr.biz_delivery));
			if (biz_retr.biz_settings) dispatch(Biz_Settings(biz_retr.biz_settings));

			if (biz_retr.discount) dispatch(Biz_Discount(biz_retr.discount));

			if (biz_retr.offers_featured) dispatch(Biz_OffersFeatured(biz_retr.offers_featured));

			if (biz_retr.offers) dispatch(Biz_Offers(biz_retr.offers));

			if (biz_retr.biz_awards) dispatch(Biz_Awards(biz_retr.biz_awards));

			if (biz_retr.site_settings) dispatch(Biz_SiteSettings(biz_retr.site_settings));

			dispatch(Rdx_Biz_InitStatus("stale"));

			await AStorage.Set("biz", biz_retr);

			return { msg: "OK" };
		} catch (err) {
			dispatch(Rdx_Biz_InitStatus("stale"));

			console.error("reducers/biz: Rdx_Biz_Init: err: ", err);

			return { err };
		}
	}; // return ...
}; // Rdx_Biz_Init

/**
 */
const Rdx_Biz_InitStatus = init_status => {
	return {
		type: "biz:init-status",
		payload: init_status
	};
};

const Biz_BizTitle = title => {
	return {
		type: "biz:biz_title",
		payload: title
	};
};

/**
 */
const Biz_Suspend = biz_suspend => {
	return {
		type: "biz:suspend",
		payload: biz_suspend
	};
};

/**
 */
const Biz_OT = biz_ot => {
	return {
		type: "biz:ot",
		payload: biz_ot
	};
};

/**
 */
const Biz_Contact = biz_contact => {
	return {
		type: "biz:contact",
		payload: biz_contact
	};
};

/**
 */
const Biz_Addr = biz_addr => {
	return {
		type: "biz:addr",
		payload: biz_addr
	};
};

/**
 */
const Biz_Delivery = biz_delivery => {
	return {
		type: "biz:delivery",
		payload: biz_delivery
	};
};

/**
 */
const Biz_Settings = biz_settings => {
	return {
		type: "biz:settings",
		payload: biz_settings
	};
};

/**
 */
const Biz_Discount = biz_discount => {
	return {
		type: "biz:discount",
		payload: biz_discount
	};
};

/**
 */
const Biz_OffersFeatured = offers_arr => {
	return {
		type: "biz:offers-featured",
		payload: offers_arr
	};
};

/**
 */
const Biz_Offers = offers_arr => {
	return {
		type: "biz:offers",
		payload: offers_arr
	};
};

/**
 */
const Biz_Awards = awards_arr => {
	return {
		type: "biz:awards",
		payload: awards_arr
	};
};

/**
 */
const Biz_SiteSettings = site_settings => {
	return {
		type: "biz:site-settings",
		payload: site_settings
	};
};

/**
 */
const Biz_ImagesFeatured = img_arr => {
	return {
		type: "biz:images-featured",
		payload: img_arr
	};
};

/**
 */
const Biz_ImagesGallery = img_arr => {
	return {
		type: "biz:images-gallery",
		payload: img_arr
	};
};
const Biz_BizImages = img_arr => {
	return {
		type: "biz:biz_images",
		payload: img_arr
	};
};

/**
 */
const RetrBiz = async (dispatch, gs) => {
	try {
		const aws_ddb = new DDB({});

		const p_get = {
			TableName: gs().__cfg.db("biz"),
			Key: { biz_id: gs().__biz.biz_id }
		};

		const resp_get = await aws_ddb.Get(p_get);

		return resp_get.Item;
	} catch (err) {
		console.error("reducers/biz: RetrBiz: err: ", err);

		return Promise.reject(err);
	}
}; // RetrBiz
