/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
  // Container, 
  Button, Form, Alert,
  // Spinner,
  Modal,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faPlusSquare,
	// faTrashAlt,
	// faCheck,
	// faCircle,
	// faAlignJustify,
	faTimes,
	// faTruckMoving,
	// faShoppingBag,
	// faMoneyBill,
	// faArrowDown,
	// faCreditCard,
	// faPlus,
	// faMinus,
	// faTicketAlt
} from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch, } from "react-redux";
// import * as actions from "../../rdx/actions";
import {Rdx_Checkout_SetCouponDiscount} from '../../rdx/actions/checkout'

// import color from '../_common/colors.json'
import { Lambda } from "../../api";
import { Time } from "../../api/Utils";
// import { Currency } from "../../api/currency";
import Loader from "react-loader-spinner";
// import color from "../_common/colors.json";

import * as dotenv from "dotenv";
dotenv.config();

interface ICouponCode
{
  ShowCoupon: any,
} // ICouponCode

const Coupon_Code = (props: ICouponCode) =>
{
  const dispatch = useDispatch();
  
  const rdxCfg = useSelector((state: any) => state.__cfg);
  const rdxBiz = useSelector((state: any) => state.__biz);
  const rdxCheckout = useSelector((state: any) => state.__checkout);

  const [discount, Discount] = React.useState<any []>( [] )
  const [selectedDiscount, SelectedDiscount] = React.useState<any>( null )
  const [couponEntered, CouponEntered] = React.useState( "" )
  const [couponValid, CouponValid] = React.useState( false )
  const [couponApply, CouponApply] = React.useState<any>( {} )

  const [isCApply, IsCApply] = React.useState( false )
  const [isCAlert, IsCAlert] = React.useState( false )
  const [isLoading, IsLoading] = React.useState( false )

  const [errorMessage, ErrorMessage] = React.useState( "" )

  // /**
	//  */
	// const ApplyCouponDiscount = () => {
	// 	let disc_obj = rdxBiz.discount.coupon;

	// 	if (disc_obj.length === 0)
  //   {
  //     ErrorMessage( "No Coupon available right now!" )
	// 	}
  //   else
  //   {
	// 		const subtotal = rdxCheckout.SubTotal();
	// 		const coupon_entered = couponEntered.toLowerCase();
	// 		let couponFound = "";

	// 		let disc_applied: any = {
	// 			_idx: 0,
	// 			disc_type: "coupon",
  //       type: 'coupon',
	// 			name: "",
	// 			amount: 0.0,
	// 			target: "",
	// 			b_accept_others: false
	// 		};

	// 		for (let i = 0; i < disc_obj.length; i++) {
	// 			couponFound = disc_obj[i].coupon.find((val: any) => val.toLowerCase() === coupon_entered);

	// 			if (couponFound) {
	// 				disc_obj = disc_obj[i];
	// 				break;
	// 			}
	// 		}

	// 		if (!couponFound)
  //     {
  //       ErrorMessage( "Coupon not found!" )

	// 			return;
	// 		}
  //     else
  //     {
	// 			if (subtotal > disc_obj.minRequirement) {
	// 				if (disc_obj.type === "percent" && disc_obj.discount > 0) {
	// 					// disc_applied.amount = parseFloat(subtotal * (disc_obj.discount / 100)).toFixed(2);
  //           disc_applied.amount = (subtotal * (disc_obj.discount / 100)).toFixed(2);

	// 					if (disc_obj.discountLimit > 0 && disc_obj.discountLimit < disc_applied.amount)
  //           {
	// 						disc_applied.amount = disc_obj.discountLimit;
	// 					}
	// 				}
  //         else if (disc_obj.type === "amount")
  //         {
	// 					disc_applied.amount = disc_obj.discount;
	// 				}

	// 				disc_applied._idx = 1;
	// 				disc_applied.name = couponEntered;
	// 				disc_applied.type = "coupon";
	// 				disc_applied.b_accept_others = disc_obj.acceptOther;
	// 			}

	// 			// this.props.Rdx_Checkout_SetCouponDiscount({ ...disc_applied });
  //       dispatch( Rdx_Checkout_SetCouponDiscount({ ...disc_applied }) )
	// 		}
	// 	}
	// };

	/**
	 */
	const SubmitCouponNew = async () => {
		try {
      IsLoading( true )

      const coupon_obj = await VerifyCoupon()

      const offer_obj = await VerifyOffer(coupon_obj)

      ApplyCoupon({ coupon: coupon_obj, offer: offer_obj });
		}
    catch (e: any)
    {
			console.warn(e);

      ErrorMessage( e.message )
		}
    finally
    {
      IsCAlert( true )
      IsLoading( false )
    }
	}; // submitCouponNew

	/**
	 */
	const VerifyCoupon = async () => {
    try
    {
      const coupon_name = couponEntered.toLowerCase();

			// const { __biz } = this.props;
			// const cfg = this.props.__cfg;
			const aws_lambda = new Lambda({});

			const params_lambda = {
				stage: rdxCfg.stage,
				usr: "beta",
				actType: "promo",
				act: "coupon:get",
				alpha: { biz_id: rdxBiz.biz_id },
				cpn_code: coupon_name
			};

			const coupon_obj = await aws_lambda.Invoke(params_lambda, rdxCfg.lambda("beta", rdxCfg.stage));

      if( !Authorized(coupon_obj.resp) )
      {
        throw new Error( 'Coupon is not valid' )
      }

      if( !TimeMatch(coupon_obj.resp) )
      {
        throw new Error( 'Coupon is not available now' )
      }

      await UsgCount(coupon_obj.resp)

      return coupon_obj.resp
    }
    catch( err )
    {
      // console.warn( err )

      // throw new Error( "Coupon is not valid! Enter a valid coupon." )
      return Promise.reject( err )
    }
	}; //verifyCoupon

	/**
	 */
	const VerifyOffer = async (coupon_obj: any) => {
    try
    {
      const aws_lambda = new Lambda({});

      const { cpn_type } = coupon_obj;

      const p_lambda = {
        stage: rdxCfg.stage,
        usr: "beta",
        actType: "promo",
        act: [cpn_type.type, "get"].join(":"),
        alpha: { biz_id: rdxBiz.biz_id },
        cpn_type: cpn_type
      };

      const offer_resp = await aws_lambda.Invoke(p_lambda, rdxCfg.lambda("beta", rdxCfg.stage));

      if( !offer_resp.resp )
      {
        throw new Error( "Offer is not available!" )
      }

      if( !Authorized(offer_resp.resp) )
      {
        throw new Error( "Offer not authorised" )
      }

      if( !TimeMatch(offer_resp.resp) )
      {
        throw new Error( "Offer is not available!" )
      }

      return offer_resp.resp
    }
    catch( err )
    {
      return Promise.reject( err )
    }
	}; //verifyOffer

  /**
   */
	const Authorized = (resp: any): boolean => {
		if (resp.b_public) {
			return true;
		} else {
			return false;
		}
	}; //Authorized

  /**
   */
	const UsgCount = async (cpn_obj: any) => {
    try
    {
      const aws_lambda = new Lambda({});

      const params_lambda = {
        stage: rdxCfg.stage,
        usr: "beta",
        actType: "promo",
        act: "coupon:get_usage",
        alpha: { biz_id: rdxBiz.biz_id },
        cpn_code: cpn_obj.cpn_code
      };

      const usage_obj = await aws_lambda.Invoke(params_lambda, rdxCfg.lambda("beta", rdxCfg.stage));

      if( !usage_obj.resp )
      {
        throw new Error( "Coupon not available!" )
      }

      if (parseInt(usage_obj.resp.cnt_use) < parseInt(cpn_obj.max_usage))
      {
        // return true;
        return usage_obj.resp
      }
      else
      {
        throw new Error( "Coupon is used already!" )
      }
    }
    catch( err )
    {
      return Promise.reject( err )
    }
	}; // UsgCount

  /**
	 */
	const TimeMatch = (resp: any) =>
  {
		const dt_now = Time.Epoch();
		if (dt_now > resp.dt_st && dt_now < resp.dt_end) {
			if (resp.dt_available_type && resp.dt_avail && resp.dt_avail.days && resp.dt_available_type !== "flat" && resp.dt_avail.days.length > 0) {
				const day = resp.dt_avail.days[0];
				if (day) {
					let ret = false;
					day.forEach((val: any) => {
						const dt_st = Time.Epoch(new Date().setHours(val.open.h, val.open.m, 0, 0));
						const dt_end = Time.Epoch(new Date().setHours(val.close.h, val.close.m, 0));
						if (dt_now > dt_st && dt_now < dt_end) {
							ret = true;
						}
					});
					// if (!ret) this.setState({ errorMessage: "Time:Coupon is not available!", bCAlert: true, bLoading: false });
					return ret;
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			// this.setState({ errorMessage: "Time:Coupon is not available!", bCAlert: true, bLoading: false });
			return false;
		}
	}; //timeMatch

  /**
	 */
	const ApplyCoupon = (param: any) =>
  {
		// const ts_date = new Date().getTime();
		let disc_to_apply = 0.0;
		let b_applyDis = true;

		// const c_sign = Currency.Sign(rdxBiz.biz_currency);

		if (b_applyDis)
    {
			const disc_obj = CalcDisc(rdxCheckout.charges.subtotal.amount, param.offer);

			disc_to_apply = disc_obj.amount;

      dispatch( Rdx_Checkout_SetCouponDiscount({
        ...param,
				amount: disc_to_apply /*, target: coupon.target @todo what is target? */
      }) )

			props.ShowCoupon(false);

			return {};
		}

		return {};
	}; // ApplyCoupon

	/**
	 */
	const CalcDisc = (amount: number, coupon: any) => {
		let disc_amount = "percentage" === coupon.type ? (amount * parseInt(coupon.amount)) / 100.0 : parseInt(coupon.amount);
		//@TODO: Max Limit check ;
		// disc_amount = coupon.dis_limit && disc_amount > coupon.dis_limit
		//     ? coupon.dis_limit
		//     : disc_amount
		// ;

		disc_amount = disc_amount > amount ? amount : disc_amount;

		return { amount: disc_amount, msg: null };
	}; // CalcDisc

  /**
	 */
	const ConfirmCoupon = (b: boolean) => {
		if (b)
    {
      ApplyCoupon( couponApply )

      IsCAlert( false )
      CouponApply({})
		}
    else
    {
      IsCAlert( false )
      CouponApply({})

			props.ShowCoupon(false);
		}
	}; // ConfirmCoupon

  /**
	 */
  return (
    <>
    {
      isLoading
    ? <div className="centerScreen">
        <Loader
          type="Bars"
          color={rdxBiz.site_settings.base.colors.primary}
          height={100} width={100}
          timeout={30000000}
        />
      </div>

    : <div className="singleItem">
        <div onClick={() => props.ShowCoupon(false)} className="buttonAuthDiv">
          <FontAwesomeIcon className="extraButtonAuth" icon={faTimes} size="lg" color={"red"} />
        </div>
        <Form.Group>
          <div className="extraDivText">Add Coupon/Voucher</div>
          <Form.Control type="text" placeholder="Enter Code" value={couponEntered}
            onChange={event => CouponEntered(event.target.value)}
          />
        </Form.Group>
        <div className="processButtons">
          <Button className="confirmButton" style={{ background: rdxBiz.site_settings.base.colors.primary }}
            onClick={() => SubmitCouponNew()}
          >
            Apply
          </Button>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered
          show={isCAlert}
          onHide={() => IsCAlert(false)}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Coupon Entered</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              {couponValid && (
                <div
                  // @todo commented out for typescript. check if it works fine now.
                  // variant="success"
                >
                  <div>Coupon code is valid. Applying this coupon will remove other discounts. Do you wish to proceed?</div>

                  <Modal.Footer>
                    <Button onClick={() => ConfirmCoupon(false)} variant="danger">
                      Cancel
                    </Button>
                    <Button onClick={() => ConfirmCoupon(true)} variant="success">
                      Apply
                    </Button>
                  </Modal.Footer>
                </div>
              )}
              {errorMessage && (
                <Alert variant="danger">
                  {errorMessage}
                </Alert>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    }
    </>
  )
} // Coupon_Code


/**
 */
// export default connect(mapStateToProps, actions)(Coupon_Code);
export default Coupon_Code

