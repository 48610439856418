/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
	// Container,
	Form,
	Button,
	Alert
} from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import color from "../_common/colors.json";
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "@reach/router";
import { Core } from "../../api";
import { TextInput } from "../_common/components";
// import elml_cfg from '../../_config/elml_cfg'

import Countdown from "react-countdown";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Auth_Verification extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		this.state = {
			code: "",
			errMsg: "",
			v_success: false,
			bLoading: false,
			resendButton: false,
			resendWaiting: true
		};
	}

	/**
	 */
	render() {
		if (this.state.v_success) {
			return (
				<div>
					<NavHdr signin={true} />
					<div className="authContainer">
						<p style={{ color: "green" }}>Congratulations! You have signed up successfully </p>
						<div className="authLittle">
							<i onClick={() => navigate("signin")} variant="light" className="highlight" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
								Click here to sign in.
							</i>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div>
				<NavHdr signin={true} />
				<div>
					<div className="authContainer">
						<Form.Group>
							<div className="categoryItemTitle">Verification required</div>
							<div className="authLittle">We have sent a verification code to your email. Please enter the code to verify your account.</div>
							<div className="authLittle">Not received code? Check spam/junk box.</div>
						</Form.Group>
						<Form.Group>
							<TextInput placeholder="Enter Code" type="number" size="lg" val={this.state.code} onChange={val => this.setState({ code: val.target.value })} className="numberHide" />
						</Form.Group>
						{this.state.errMsg ? (
							<Alert variant="danger" onClose={() => this.setState({ errMsg: "" })} dismissible>
								{this.state.errMsg}
							</Alert>
						) : null}
						{this.state.bLoading ? (
							<Form.Group>
								<Button
									style={{
										width: "100%",
										background: "#aaa",
										display: "flex",
										justifyContent: "center"
									}}
									size="lg"
									variant="info">
									Verifying...
								</Button>
							</Form.Group>
						) : (
							<Form.Group>
								<Button
									onClick={this.ConfirmUser}
									style={{
										width: "100%",
										background: "rgb(50, 161, 110)",
										display: "flex",
										justifyContent: "center"
									}}
									size="lg"
									variant="info">
									Submit Code
								</Button>
							</Form.Group>
						)}
						{this.state.resendButton ? (
							<Form.Group>
								<Button className="resendButton" style={{ backgroundColor: "rgb(50, 161, 110)" }} onClick={this.ResendCode}>
									Resend Code
								</Button>
							</Form.Group>
						) : this.state.resendWaiting ? (
							<Form.Group>
								<div className="authLittle">
									<i variant="light" className="highlight" style={{ color: "#b7b7b7" }}>
										{" "}
										Press resend button if you don't receive the verification code within
									</i>
								</div>
								<div className="highlight" style={{ color: "#155799", textAlign: "center" }}>
									<Countdown
										date={Date.now() + 120000}
										onComplete={() => {
											this.setState({
												resendWaiting: false,
												resendButton: true
											});
										}}
									/>
									,
								</div>
								<Button className="resendButton" disabled variant="secondary">
									Resend
								</Button>
								<div className="authLittle"> **Resend button is disabled now. It will be active after the timer ends. </div>
							</Form.Group>
						) : (
							<Form.Group>
								<div className="authLittle">
									<i variant="light" className="highlight" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
										Sending Code
									</i>
								</div>
							</Form.Group>
						)}
					</div>
				</div>
			</div>
		);
	}

	/**
	 */
	ConfirmUser = async () => {
		try {
			this.setState({ bLoading: true });
			if (!this.state.code) {
				this.setState({ errMsg: "Please enter the verification code", bLoading: false });
				return;
			}

			const aws_core = new Core({});

			const { code } = this.state;

			const data = await aws_core.SignUp_Confirm({
				username: this.props.location.state.username,
				auth_code: code
			});

			const _username = this.props.location.state.username;
			const _password = this.props.location.state.password;

			if (data === "SUCCESS") {
				const user = await aws_core.SignIn({ username: _username, password: _password });

				if (!user) {
					console.warn("SignIn_View: SignIn: user is null");
					this.setState({
						errMsg: "Oops, something went wrong",
						signinButton: true
					});
					return;
				}

				this.props.Rdx_Core_AuthStatus("auth");

				this.props.Rdx_BetaId(await aws_core.Id());

				// if (this.props.location.state.fromPage) {
				// 	navigate(this.props.location.state.fromPage);
				// } else {
				navigate("order");
				// }

				this.setState({ bLoading: false, v_success: true });
			} else {
				this.setState({ bLoading: false });
				console.error("Verification : ConfirmUser : data : Not SUCCESS");
			}
		} catch (err) {
			console.warn("Verification: ConfirmUser: err: ", JSON.stringify(err));

			this.setState({ errMsg: err.message, bLoading: false });

			// return Promise.reject( err )
		}
	};

	/**
	 */
	ResendCode = async () => {
		try {
			this.setState({ resendButton: false });
			const aws_core = new Core({});

			let user_name = "";

			// if( this.props.location.state )
			user_name = this.props.location.state.username;

			// const current_user = await aws_core.currentuser()

			const resp_resend = await aws_core.SignUp_Resend({ username: user_name });

			if (resp_resend) {
				this.setState({ resendButton: false, resendWaiting: true });
			} else {
				console.warn("Verification : ResendCode : resp_resend failed.");
			}

			return {};
		} catch (err) {
			console.warn("Auth_Verification: ResendCode: err: ", JSON.stringify(err));

			this.setState({ errMsg: err.message ? err.message : err });
		}
	};

	/**
	 */
	Init = async () => {
		try {
			const aws_core = new Core({});

			const current_cred = await aws_core.Credentials();

			if (current_cred.authenticated) {
				const current_user = await aws_core.currentuser();

				if (current_user.attributes && current_user.attributes.email_verified) {
					if (this.props.location.state.fromPage) {
						navigate(this.props.location.state.fromPage);
					} else {
						navigate("order");
					}
				} else {
					//
				}
			} else {
				if (!this.props.location.state) {
					navigate("signin");
					return;
				}
			}

			if (this.props.location.state.fromPage && this.props.location.state.fromPage === "signup") {
				this.setState({ resendButton: false, resendWaiting: true });
				return;
			} else {
				const user_name = this.props.location.state.username;

				const resp_resend = await aws_core.SignUp_Resend({ username: user_name });

				if (resp_resend) {
					this.setState({ resendButton: false, resendWaiting: true });
				} else {
					console.warn("Verification : Init : resp_resend failed.");
				}
			}
			return { msg: "OK" };
		} catch (err) {
			console.warn("Verification: Init: err: ", JSON.stringify(err));

			if (err.code === "UserNotConfirmedException") this.setState({ errMsg: "User not verified." });
			else this.setState({ errMsg: err.message ? err.message : err });

			// this.setState( {showing: 'signup'} )

			return Promise.reject(err);
		}
	};

	/**
	 */
	componentDidMount() {
		this.Init().then().catch();
	}
} // class Auth_Verification

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Auth_Verification);
