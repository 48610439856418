/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Modal, Button, Image } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMinus,
	faPlus,
	// faPlusCircle,
	faTimes
	// faTimes,faTrashAlt,faCheck
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";
// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'

import {
	// ICartState,
	CartUpdateAsync,
} from '../../rdx/cart.slice'

// import { CoreCat, CoreItem, CoreSubcat } from "../../_types/core";
import {
	MenuCat, MenuSubcat, MenuItem, //EItemType,
} from "../../_types/menu";

import {
	// ICartMod, ICartSecMod, ICartAddon, //ICartOpt,
	// CartOptCreate, CartOptCnt
	// CartMod, CartSecMod, CartAddon,
	CartOpt,
} from '../../_types/cart'

import "../../css/_common.css";

// import NavHdr from '../_common/nav_hdr'
import color from "../_common/colors.json";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
interface IMenuPageBox
{
  id?: string,
  title: string,
  item: string,
  desc: string,
  className: string,
  style: any,

  onPress: any,
}

/**
 */
export const MenuPage_Box = ( props: IMenuPageBox ) =>
{
  const [items, Items] = React.useState('')

  React.useEffect( () => {
    try
    {
      Items(props.title.split(":")[1])
    }
    catch( err )
    {
      Items('')
    }
  }, [] )

  return (
    <div
      className={props.className}
      style={props.style}
      // key={this.props.key}
      onClick={props.onPress}
      id={props.id}>
      {props.id ? (
        <div style={{ marginBottom: 32 }}>
          <div className="categoryItemTitle">
            {items}
          </div>
          {/* <div className="categoryItemLittle">{main}</div> */}
          <div className="itemDescription">{props.desc}</div>
        </div>
      ) : (
        <div>
          <div className="categoryTitle" style={{ color: color.colors.light }}>
            {items}
          </div>
          <div className="itemDescription">{props.desc}</div>
        </div>
      )}
      <div>{props.item}</div>
    </div>
  );
}
// export class MenuPage_Box extends React.PureComponent {
// 	constructor(props) {
// 		super(props);
// 	}

// 	render() {
// 		const items = this.props.title.split(":")[1];
// 		// const main = this.props.title.split(":")[0];
// 		// // console.log("aaaaa",a);
// 		// // console.log("bbbbb",b);
// 		return (
// 			<div
// 				className={this.props.className}
// 				style={this.props.style}
// 				// key={this.props.key}
// 				onClick={this.props.onPress}
// 				id={this.props.id}>
// 				{this.props.id ? (
// 					<div style={{ marginBottom: 32 }}>
// 						<div className="categoryItemTitle">
// 							{items}
// 						</div>
// 						{/* <div className="categoryItemLittle">{main}</div> */}
// 						<div className="itemDescription">{this.props.desc}</div>
// 					</div>
// 				) : (
// 					<div>
// 						<div className="categoryTitle" style={{ color: color.colors.light }}>
// 							{items}
// 						</div>
// 						<div className="itemDescription">{this.props.desc}</div>
// 					</div>
// 				)}
// 				<div>{this.props.item}</div>
// 			</div>
// 		);
// 	}
// }

interface IItemBox
{
	item: any,
  colors: any,
	itemName: string,
	description: string,
	count: number,
	itemPrice: number,
	img_url: string,
  modItem?: any,

	// OnPress: any,
	// RemoveFromCart: any,
} // IItemBox

/**
 */
export const Item_Box = (props: IItemBox) =>
{
	const [isModal, IsModal] = React.useState(false);

	/**
	 */
	const handelModal = () => {
		IsModal(true);
		// onPress();
	}

	// React.useEffect( () => {
	// 	console.log( 'item: ', item )
	// } )

	/**
	 */
	return (
		<div>
			<div
				className="singleItem"
				style={{
					border: props.count ? "2px solid" : 0,
					borderColor: props.count ? props.colors.primary : undefined
				}}
				onClick={handelModal}>
				<div className="commonFlex">
					<div style={{ flex: 1 }}>
						<div className="commonFlex">
							<div className="itemName">
								<>
									{props.count !== 0 && (
										<span className="itemName" style={{ color: props.colors.primary, marginRight: 8 }}>
                      {`${props.count} x`}
										</span>
									)}
								</>

								<>{props.itemName}</>
							</div>
						</div>

						<div className="itemDescription">{props.description}</div>

						<>{props.itemPrice && <div className="itemPrice">£{props.itemPrice.toFixed(2)}</div>}</>
					</div>

					<>{props.modItem && <div>{props.modItem}</div>}</>

					<div className="menuRight">
						<div className="imageContainer">
							{props.img_url ? <img className="imageStyle" src={props.img_url} /> : <Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />}
						</div>
					</div>
					<div className="addtoCart" style={{ background: props.colors.primary }}>
						<span style={{ marginLeft: 6, fontWeight: 700 }}>+</span>
					</div>
				</div>
			</div>
			<>
				{isModal && (
					<Modal size="lg" show={true} centered onHide={() => IsModal(false)} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Body>
							<Item_Box_Modal
								item={props.item}
								colors={props.colors}
								itemName={props.itemName}
								description={props.description}
								count={props.count}
								itemPrice={props.itemPrice}
								img_url={props.img_url}

								// OnPress={props.OnPress}
								// RemoveFromCart={props.RemoveFromCart}
								IsModal={IsModal}
							/>
						</Modal.Body>
					</Modal>
				)}
			</>
		</div>
	);
}; // Item_Box

interface IItemBoxModal extends IItemBox
{
	item: any,
  IsModal: any,
}

/**
 */
const Item_Box_Modal = (props: IItemBoxModal) =>
{
	const dispatch = useDispatch<any>()

	const [cntLocal, CntLocal] = React.useState(props.count || 1);

	/**
	 */
	const AddToCart = () => {
		// // OnPress();
		// if (cntLocal > props.count) {
		// 	for (let i = props.count; i < cntLocal; ++i) {
		// 		props.OnPress();
		// 	}
		// } else if (cntLocal < props.count) {
		// 	for (let i = cntLocal; i < props.count; ++i) {
		// 		props.RemoveFromCart();
		// 	}
		// } else {
		// 	// not needed
		// }

		const cart_opt = new CartOpt( {
			id: '',	// empty string will generate a new id
			cnt: cntLocal,
			item: new MenuItem({id: props.item._id, name: props.item.name, type: props.item._type}),
			cat: new MenuCat({id: props.item.cat.id, name: props.item.cat.name}),
			subcat: new MenuSubcat({id: props.item.subcat.id, name: props.item.subcat.name}),
			// mods: selMod,
			// secMods: selSecMods,
			// addons: selAddons,
		} )

		// console.log( 'cart_opt: ', cart_opt )

		dispatch( CartUpdateAsync(cart_opt) )

		props.IsModal(false);
	}; // AddToCart

	/**
	 */
	return (
		<div>
			<FontAwesomeIcon onClick={() => props.IsModal(false)} className="modalClose" icon={faTimes} size="lg" color={"red"} />

			<>
				{props.img_url ? (
					<img style={{ width: "100%", height: 200, objectFit: "cover" }} src={props.img_url} />
				) : (
					<Image src={require("../assets/img/placeholder/skewer.jpg")} style={{ width: "100%", height: 250, objectFit: "cover" }} />
				)}
			</>

			<div
				className="singleItem"
			// onClick={addToCart}
			>
				<div className="commonFlex">
					<div style={{ flex: 1 }}>
						<div className="commonFlex">
							<div className="itemName">
								<>
									{props.count !== 0 && (
										<span className="itemName" style={{ color: props.colors.primary, marginRight: 8 }}>
											{props.count}x
										</span>
									)}
								</>

								<>{props.itemName}</>
							</div>
						</div>

						<div className="itemDescription2">{props.description}</div>

						<>{props.itemPrice && <div className="itemPrice">£{props.itemPrice}</div>}</>
					</div>
				</div>
			</div>

			<div className="cartCrossDiv">
				<>
					{cntLocal > 0 ? (
						<FontAwesomeIcon
							className="cartPlus"
							style={{ borderColor: props.colors.primary }}
							// onClick={RemoveFromCart}
							onClick={() => CntLocal(cntLocal - 1)}
							icon={faMinus}
							color={props.colors.primary}
						/>
					) : (
						<FontAwesomeIcon className="cartPlus" style={{ borderColor: props.colors.primary }} icon={faMinus} color={"#ddd"} />
					)}
				</>

				<div className="cartItemCount" style={{ fontSize: 20, fontWeight: "700" }}>
					{cntLocal}
				</div>

				<FontAwesomeIcon
					className="cartPlus"
					style={{ borderColor: props.colors.primary }}
					// onClick={OnPress}
					onClick={() => CntLocal(cntLocal + 1)}
					// onClick={AddItem}
					icon={faPlus}
					color={props.colors.primary}
				/>
			</div>

			<div>
				<div className="buttonPadding">
					<Button
						disabled={props.count === cntLocal}
						onClick={AddToCart}
						variant={props.count === cntLocal ? "secondary" : "success"}
						style={{ width: "100%", background: props.count === cntLocal ? undefined : props.colors.primary }}>
						{props.count ? "Update basket " : "Add to basket "}
						(£{(props.itemPrice * cntLocal).toFixed(2)})
					</Button>
				</div>
				{/* <button
          disabled={count === cntLocal}
          onClick={AddToCart}
        >
          {
            count ? 'Update basket' : 'Add to basket'
          }
        </button> */}
			</div>
		</div>
	);
}; // Item_Box_Modal

/**
 */
// export {MenuPage_Box,Item_Box}



