/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Button, ButtonGroup, Image, Row, Col, Card, Modal, Carousel, Container } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import color from '../_common/colors.json'
// import {Offer} from './_common'

/**
 */
class Info_Award extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			openOffer: false,

			imageLink: null
		};
	}

	/**
	 */
	render() {
		if (!this.props.__biz.awards) return null;

		return (
			<div className="offerContainer">
				{this.state.openOffer ? (
					<Modal show={true} animation={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => this.setState({ openOffer: false })}>
						<Image style={{ height: "100%", width: "100%" }} src={this.state.imageLink} />
					</Modal>
				) : null}

				{/* <div>
                    <div style={{margin: 10}} className="infoTitle">Awards</div>
                </div>
                <div className="offerDiv">
                        
                    {
                        this.props.__biz.awards.map( award => (
                            <div
                                onClick={()=>this.setState({openOffer:true, imageLink: award.img_front})}
                                style={{margin:16}}>
                                <Image
                                    className="offerImage"
                                    src={award.img_front}
                                />
                                <div className="offerTitle">
                                    {award.award_for + " - " + award.awarding_body}
                                </div>
                            </div>
                        ) )
                    }

                </div> */}
			</div>
		);
	} // render
} // Info_Offer

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Info_Award);
