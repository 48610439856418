/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
class Order {
	/**
	 */
	constructor() {
		this.cart_obj = {};
		this.cart_sects = [];
		this.ts_change = Date.now();
		this.email = "";
		this.name = {};
		this.phone = "";
		this.type = "";
		this.addr = "";
	} // cstr

	/**
	 */
	updateCart = (item, sect, cnt_change) => {
		// add to cart
		let cart_obj = this.cart_obj,
			cart_sects = this.cart_sects;
		if (!cart_obj[sect.cat_id]) {
			cart_obj[sect.cat_id] = {
				cat_id: sect.cat_id,
				cat_idx: sect.cat_idx,
				cat: sect.cat,
				data: [],
				data_obj: {}
			};

			// add to array
			cart_sects.push(cart_obj[sect.cat_id]);

			// sort
			cart_sects.sort((a, b) => a.cat_idx - b.cat_idx);
		}

		if (!cart_obj[sect.cat_id].data_obj[item._id]) {
			// cart_obj[sect.cat_id].data_obj[item._id] = item;
			const { addons, mod, secmods, sku, cat, subcat, desc, name, price, resource_id, idx, _cnt, _id, _t, _type, _opts, _uid, _v_idx } = item;

			const tmp_obj = {
				addons,
				mod,
				secmods,
				sku,
				cat,
				subcat,
				desc,
				name,
				price,
				resource_id,
				idx,
				_cnt,
				_id,
				_t,
				_type,
				_opts,
				_uid,
				_v_idx
			};

			cart_obj[sect.cat_id].data_obj[item._id] = tmp_obj;

			// cart_obj[sect.cat_id].data.push( tmp_obj );
			cart_obj[sect.cat_id].data.push(cart_obj[sect.cat_id].data_obj[item._id]);
		}

		// check for zeros in cart items
		if (!cnt_change) {
			// const idx_item = cart_obj[sect.cat_id].data.find( x => x._id === item._id );

			const idx_item = cart_obj[sect.cat_id].data.findIndex(function (_item) {
				return item._id === _item._id;
			});

			cart_obj[sect.cat_id].data.splice(idx_item, 1);

			cart_sects = cart_sects.filter(x => x.data.length);

			delete cart_obj[sect.cat_id].data_obj[item._id];

			if (!cart_obj[sect.cat_id].data.length) {
				delete cart_obj[sect.cat_id];
			}

			// if( !cart_obj[sect.cat_id].data.length )
			// if( Object.entries(cart_obj[sect.cat_id].data_obj).length === 0 )
			// {
			//
			//     delete cart_obj[sect.cat_id];
			// }
		}

		/// @note asif commenting out _opts
		// if(item._type === "mod_ex" || item._type === "mod")
		// {
		// 	if(item._opts)
		// 	{
		// 		Object.keys(item._opts).map((key, idx) => {
		// 				const opt_obj = item._opts[key];

		// 				if (!opt_obj._cnt) {

		// 					delete item._opts[key];
		// 				}
		// 			});
		// 	}
		// }

		/// @note asif commenting out _opts
		// if(item._type === "setmeal")
		// {
		// 	if(item._setm)
		// 	{
		// 		Object.keys(item._setm).map((key, idx) => {
		// 				const opt_obj = item._setm[key];

		// 				if (!opt_obj._cnt)
		// 				{
		// 					delete item._setm[key];
		// 				}
		// 			});
		// 	}
		// }

		// sort out items in cart
		if (cart_obj[sect.cat_id]) {
			cart_obj[sect.cat_id].data.sort((a, b) => {
				// if (a.Cat_Idx < b.Cat_Idx)
				// 	return -1;
				// else if (a.Cat_Idx > b.Cat_Idx)
				// 	return 1;
				// else if (a.Sub_Cat_Idx < b.Sub_Cat_Idx)
				// 	return -1;
				// else if (a.Sub_Cat_Idx > b.Sub_Cat_Idx)
				// 	return 1;

				if (a.idx < b.idx) return -1;
				else if (a.idx > b.idx) return 1;
				else if (a._v_idx < b._v_idx) return -1;
				else if (a._v_idx > b._v_idx) return 1;
				else return 0;
			});
		}

		this.cart_obj = cart_obj;
		this.cart_sects = cart_sects;
	}; // addToCart

	/**
	 */
	clearCart = () => {
		try {
			let cart_sects = this.cart_sects;

			cart_sects.forEach(sect => {
				sect.data.forEach(item => {
					// this.updateCart( item, sect, 0 )
					item._cnt = 0;

					/// @note asif commenting out _opts
					if (item._mods) {
						item._mods.opts.forEach(opt => {
							opt._cnt = 0;
						});
					}

					/// @note asif commenting out _opts
					// if( item._opts )
					// {
					// 	item._opts = []
					// }

					/// @note asif commenting out _opts
					// if( item._setm )
					// {
					// 	item._setm = {}
					// }
				});
			});

			this.cart_obj = {};
			this.cart_sects = [];
		} catch (err) {
			console.warn("Rdx_Order : clearCart: err: ", err);
		}
	};

	/**
	 */
	Update = () => {
		return { ...this, ts_change: Date.now() };
	};

	/**
	 */
	ClearAll = () => {
		this.type = "";
		this.addr = "";
	};
	/**
	 */
	SetOldOrder = payload => {
		this.cart_obj = payload.cart_obj;
		this.cart_sects = payload.cart_sects;
	};
} // Order

/**
 */
export default (state = new Order(), action) => {
	switch (action.type) {
		case "core:menu":
			return { ...state, menu: action.payload.menu, menuTs: action.payload.menu_ts };

		case "order:menu-req-status":
			return { ...state, menuReqStatus: action.payload };

		// case 'order:add-to-cart':
		//     state.updateCart(action.payload.item, action.payload.sect, action.payload.cnt_change);
		//     return state.Update();

		case "order:cart-upd":
			try {
				state.updateCart(action.payload.item, action.payload.sect, action.payload.cnt_change);
				return state.Update();
			} catch (err) {
				console.error(err);
				return state;
			}
		// state.updateCart(action.payload.item, action.payload.sect, action.payload.cnt_change);
		// return state.Update();

		case "order:clear-cart":
			try {
				state.clearCart();
				return state.Update();
			} catch (err) {
				console.error(err);
				return state;
			}
		// state.clearCart();
		// return state.Update();;

		case "order:clear-info":
			try {
				state.ClearAll();
				return state.Update();
			} catch (err) {
				console.error(err);
				return state;
			}
		//

		case "order:old-order":
			try {
				state.SetOldOrder(action.payload);
				return state.Update();
			} catch (err) {
				console.error(err);
				return state;
			}
		// state.SetOldOrder( action.payload )
		// return state.Update()

		default:
			return state;
	} // switch action.type
};
