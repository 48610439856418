/**
 * @copyright    Elmelo Ltd.
 */

// import React from 'react'
// import { useSelector } from "react-redux";

/**
 */
export function ItemPrice(item, order_type) {
	let item_price = 0;

	const tmp_type = order_type?.trim().toLowerCase();

	// if (tmp_type === "instore")
	// {
	// 	item_price = item.price.in ? item.price.in : item.price.def ? item.price.def : 0.0;
	// }
	// else
	if (tmp_type === "collection") {
		item_price = item.price?.coll && item.price?.coll !== "0" ? item.price.coll : item.price.def ? item.price.def : 0.0;
	} else if (tmp_type === "delivery") {
		item_price = item.price?.del && item.price?.del !== "0" ? item.price.del : item.price.def ? item.price.def : 0.0;
	} else {
		item_price = item.price && item.price.def ? item.price.def : 0.0;
	}

	return Number(item_price);
}
