/**
 * @copyright Elmelo Ltd.
 */

import { EOrderTtype } from "../../_types/menu";
import { ICartOpt, } from "../../_types/cart";

import {GetItem, CheckVisAvail} from './_utils_2'

interface ITotal
{
  total: number,
  cnt: number,
} // ITotal

//

/**
 */
export function GetSubTotal( cart_list: ICartOpt[], order_type: EOrderTtype, menu: any ): ITotal
{
  return cart_list.reduce( (a: ITotal, c: ICartOpt) => {
    const item_obj: any = GetItem( menu, c.cat.id, c.item.id )

    const is_vis = CheckVisAvail( item_obj.vis, order_type )
    const is_avail = CheckVisAvail( item_obj.avail, order_type )

    if( !is_vis || !is_avail )
    {
      return a
    }

    let tot_item = 0

    switch( item_obj._type )
    {
      case 'basic':
        tot_item = ItemPrice2(item_obj.price, order_type) * c.cnt
        break

      case 'mod':
      case 'mod_ex':
        tot_item = GetTotal_ModEx( item_obj, c, order_type )
        break

      default:
        break
    }

    return {cnt: a.cnt+c.cnt, total: a.total+tot_item,}
  }, {total: 0.0, cnt: 0,} )

  // let _total = 0 ;
  // let itemCount = 0;
  // //
  // cart_sects.map( _sec => {
  //   _sec.data.map( item => {
  //     const is_vis = CheckVisAvail( item.vis, order_type )
  //     const is_avail = CheckVisAvail( item.avail, order_type )

  //     if( !is_vis || !is_avail )
  //     {
  //       return {}
  //     }

  //     itemCount += item._cnt ;

  //     // // console.log( '************* item: ', item );

  //     if(item._type === "basic")
  //     {
  //       _total += Number(item._cnt * ItemPrice(item, order_type));
  //     }
  //     // if(item._type === "custom_item")
  //     // {
  //     // 	_total += parseFloat(item._cnt * item.price );
  //     // }

  //     /// @note calculated with modex
  //     // if(item._type === "mod")
  //     // {
  //     // 	_total += item._mods.opts.reduce( (acc, cur) => {
  //     // 		// // console.log( '************* item: ', cur );
  //     // 		// const mod_obj = item._mods.Options[];
  //     // 		// acc += ItemPrice(item)

  //     // 		return acc + Number(cur._cnt * (ItemPrice(item) + ItemPrice(cur) ) );
  //     // 	}, 0 );
  //     // }

  //     /// @note asif commenting out _opts
  //     // if( item._type === "mod_ex" || item._type === "mod" )
  //     // {
  //     // 	_total += Object.keys(item._opts).reduce( (acc_opt, cur_key) => {
  //     // 		const opt_obj = item._opts[cur_key];

  //     // 		return acc_opt + ONT.GetTotal_ModEx(item, opt_obj, order_type );
  //     // 	}, 0 );
  //     // }	// if mod_ex

  //     //
  //     if( item._type === "setmeal" && item._setm )
  //     {
  //       --itemCount;

  //       const tot_setm = Object.keys(item._setm).reduce( (acc, cur_key) => {
  //         const opt_obj = item._setm[cur_key];

  //         acc.total += Number(opt_obj._cnt *  ItemPrice(item, order_type));
  //         acc.n_items += opt_obj._cnt;

  //         return acc;
  //       }, {total: 0, n_items: 0} );

  //       _total += tot_setm.total;
  //       itemCount += tot_setm.n_items;
  //     }	// if ...
  //   });
  // });

  // // // console.log("itemCount", itemCount);

  // return {total: _total, item_cnt: itemCount};
}   // function GetTotal

/**
 */
export function GetTotal_ModEx( item: any, cart_opt: ICartOpt, order_type: EOrderTtype ): number
{
  // console.log( 'item: ', item )
  // console.log( 'cart_opt: ', cart_opt )
  // console.log( 'order_type: ', order_type )

  let tot_opt: number = ItemPrice2(item.price, order_type);

  // console.log( '************* tot_opt: ', tot_opt );

  // // let tot_opt = opt_obj.selMod.length ? 0 : ItemPrice2(item , order_type);

  // console.log( '************* tot_opt 1: ', tot_opt );

  tot_opt = cart_opt.mods?.reduce( (acc: number, cur: any) => {
    // find mod
    const mod_obj = item._mods.opts.find( (x: any) => x._id === cur.id )

    if(!mod_obj)
    {
      return acc
    }

    // get price
    return acc + ItemPrice2(mod_obj.price, order_type);
  }, tot_opt ) || tot_opt;

  // console.log( '************* tot_opt 2: ', tot_opt );

  tot_opt = cart_opt.secMods?.reduce( (acc, cur) => {
    // find mod
    const secmod_obj = item._secmods.find( (x: any) => x._id === cur.id )

    // console.log( 'secmod_obj: ', secmod_obj )

    if(!secmod_obj)
    {
      return acc
    }

    // find opt
    const opt_obj = secmod_obj.opts.find( (x: any) => x._id === cur.idOpt )

    // console.log( 'opt_obj: ', opt_obj )

    if(!opt_obj)
    {
      return acc
    }

    // get price
    return acc + ItemPrice2(opt_obj.price, order_type);
  }, tot_opt ) || tot_opt;

  // console.log( '************* tot_opt 3: ', tot_opt );

  tot_opt = cart_opt.addons?.reduce( (acc, cur) => {
    // return acc + ItemPrice(cur.obj, order_type);
    // find mod
    const addon_obj = item._addons.find( (x: any) => x._id === cur.id )

    if(!addon_obj)
    {
      return acc
    }

    // find opt
    const opt_obj = addon_obj.opts.find( (x: any) => x._id === cur.idOpt )

    if(!opt_obj)
    {
      return acc
    }

    // get price
    return acc + ItemPrice2(opt_obj.price, order_type);
  }, tot_opt ) || tot_opt;

  // console.log( '************* tot_opt 3: ', tot_opt );

  return Number(cart_opt.cnt * tot_opt );
}	// GetTotal_ModEx


/**
 */
export function ItemPrice2(price: any, order_type: EOrderTtype): number
{
  // console.log( 'ItemPrice2: price: ', price )
  // console.log( 'ItemPrice2: order_type: ', order_type )

  switch( order_type )
  {
    case EOrderTtype.Collection:
      // console.log( 'Collection: ', Number(price?.coll) || Number(price?.def) ||  0.0 )
      return Number(price?.coll) || Number(price?.def) ||  0.0

    case EOrderTtype.Delivery:
      // console.log( 'Delivery: ', Number(price?.del) || Number(price?.def) ||  0.0 )
      return Number(price?.del) || Number(price?.def) ||  0.0

    case EOrderTtype.Dinein:
      // console.log( 'Dinein: ', Number(price?.in) || Number(price?.def) ||  0.0 )
      return Number(price?.in) || Number(price?.def) ||  0.0

    default:
      // console.log( 'default: ', Number(price?.def) ||  0.0 )
      return Number(price?.def) ||  0.0
  } // switch order_type
} // ItemPrice


// /**
//  */
// function Numberify( str_val: string )
// {
//   if()
//   {
//     //
//   }
// }
