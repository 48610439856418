/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Container, Button, Form, Alert, Spinner, Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlusSquare,
	faTrashAlt,
	faCheck,
	faCircle,
	faAlignJustify,
	faTimes,
	faTruckMoving,
	faShoppingBag,
	faMoneyBill,
	faArrowDown,
	faCreditCard,
	faPlus,
	faMinus,
	faTicketAlt
} from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import color from '../_common/colors.json'
import { Lambda } from "../../api";
import { Time } from "../../api/Utils";
import { Currency } from "../../api/currency";
import Loader from "react-loader-spinner";
import color from "../_common/colors.json";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Coupon_Code extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			discount: [],
			selectedDiscount: null,
			couponEntered: "",
			errorMessage: "",
			couponValid: "",
			bCApply: false,
			bCAlert: false,
			coupon_apply: {},
			bLoading: false
		};
	}

	/*
	 */
	componentDidMount() {
		//
	}

	/**
	 */
	applyCouponDiscount = () => {
		let disc_obj = this.props.__biz.discount.coupon;

		if (disc_obj.length === 0) {
			this.setState({ errorMessage: "No Coupon available right now!" });
		} else {
			const subtotal = this.props.__checkout.SubTotal();
			const couponEntered = this.state.couponEntered.toLowerCase();
			let couponFound = "";

			let disc_applied = {
				_idx: 0,
				disc_type: "coupon",
				name: "",
				amount: 0.0,
				target: "",
				b_accept_others: false
			};

			for (let i = 0; i < disc_obj.length; i++) {
				couponFound = disc_obj[i].coupon.find(val => val.toLowerCase() === couponEntered);

				if (couponFound) {
					disc_obj = disc_obj[i];
					break;
				}
			}

			if (!couponFound) {
				this.setState({ errorMessage: "Coupon not found!" });

				return;
			} else {
				if (subtotal > disc_obj.minRequirement) {
					if (disc_obj.type === "percent" && disc_obj.discount > 0) {
						disc_applied.amount = parseFloat(subtotal * (disc_obj.discount / 100)).toFixed(2);
						if (disc_obj.discountLimit > 0 && disc_obj.discountLimit < disc_applied.amount) {
							disc_applied.amount = disc_obj.discountLimit;
						}
					} else if (disc_obj.type === "amount") {
						disc_applied.amount = disc_obj.discount;
					}

					disc_applied._idx = 1;
					disc_applied.name = this.state.couponEntered;
					disc_applied.type = "coupon";
					disc_applied.b_accept_others = disc_obj.acceptOther;
				}

				this.props.Rdx_Checkout_SetCouponDiscount({ ...disc_applied });
			}
		}
	};

	/**
	 */
	submitCouponNew = async () => {
		try {
			this.setState({ bLoading: true });
			const coupon_name = this.state.couponEntered.toLowerCase();

			const { __biz } = this.props;
			const cfg = this.props.__cfg;
			const aws_lambda = new Lambda({});

			const params_lambda = {
				stage: cfg.stage,
				usr: "beta",
				actType: "promo",
				act: "coupon:get",
				alpha: { biz_id: __biz.biz_id },
				cpn_code: coupon_name
			};

			const coupon_obj = await aws_lambda.Invoke(params_lambda, cfg.lambda("beta", cfg.stage));

			if (await this.verifyCoupon(coupon_obj.resp)) {
				const { cpn_type } = coupon_obj.resp;
				const p_lambda = {
					stage: cfg.stage,
					usr: "beta",
					actType: "promo",
					act: [cpn_type.type, "get"].join(":"),
					alpha: { biz_id: __biz.biz_id },
					cpn_type: cpn_type
				};

				const offer_obj = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));
				if (await this.verifyOffer(offer_obj.resp)) {
					// const {amount,type} = offer_obj.resp;
					if (!offer_obj.resp.b_accept /* && this.props.__checkout.ListDiscounts().filter(x => x.amount).length */) {
						// this.ApplyCoupon({coupon: coupon_obj.resp, offer: offer_obj.resp});
						this.setState({
							coupon_apply: { coupon: coupon_obj.resp, offer: offer_obj.resp },
							bCAlert: true,
							couponValid: true,
							errorMessage: "",
							bLoading: false
						});
					} else {
						this.ApplyCoupon({ coupon: coupon_obj.resp, offer: offer_obj.resp });
					}
				} else {
					// this.props.updatePopup({type: "error", msg: "Coupon is not available!"});
					this.setState({ bCAlert: true, bLoading: false });
				}
			} else {
				// this.props.updatePopup({type: "error", msg: "Coupon is not available!"});
				this.setState({ bCAlert: true, bLoading: false });
			}
		} catch (e) {
			console.warn(e);
		}
	}; // submitCouponNew
	/**
	 */
	verifyCoupon = async resp => {
		if (resp) {
			return this.authorized(resp) && this.timeMatch(resp) && (await this.usgCount(resp));
		} else {
			this.setState({ errorMessage: "Coupon is not valid! Enter a valid coupon.", bCAlert: true, bLoading: false });
			return false;
		}
	}; //verifyCoupon
	/**
	 */
	verifyOffer = async resp => {
		if (resp) {
			return this.authorized(resp) && this.timeMatch(resp) /*&& await this.usgCount(resp)*/;
		} else {
			this.setState({ errorMessage: "Offer is not available!", bCAlert: true, bLoading: false });
			return false;
		}
	}; //verifyOffer

	authorized = resp => {
		if (resp.b_public) {
			return true;
		} else {
			this.setState({ errorMessage: "Coupon is not authorized!", bCAlert: true, bLoading: false });
			return false;
		}
	}; //authorized

	usgCount = async resp => {
		const { __biz } = this.props;
		const cfg = this.props.__cfg;
		const aws_lambda = new Lambda({});

		const params_lambda = {
			stage: cfg.stage,
			usr: "beta",
			actType: "promo",
			act: "coupon:get_usage",
			alpha: { biz_id: __biz.biz_id },
			cpn_code: resp.cpn_code
		};

		const usage_obj = await aws_lambda.Invoke(params_lambda, cfg.lambda("beta", cfg.stage));

		if (usage_obj.resp) {
			if (parseInt(usage_obj.resp.cnt_use) < parseInt(resp.max_usage)) {
				return true;
			} else {
				this.setState({ errorMessage: "Coupon Already Used!", bCAlert: true, bLoading: false });
				return false;
			}
		} else {
			return true;
		}
	}; //authorized

	timeMatch = resp => {
		const dt_now = Time.Epoch();
		if (dt_now > resp.dt_st && dt_now < resp.dt_end) {
			if (resp.dt_available_type && resp.dt_avail && resp.dt_avail.days && resp.dt_available_type !== "flat" && resp.dt_avail.days.length > 0) {
				const day = resp.dt_avail.days[0];
				if (day) {
					let ret = false;
					day.forEach(val => {
						const dt_st = Time.Epoch(new Date().setHours(val.open.h, val.open.m, 0, 0));
						const dt_end = Time.Epoch(new Date().setHours(val.close.h, val.close.m, 0));
						if (dt_now > dt_st && dt_now < dt_end) {
							ret = true;
						}
					});
					if (!ret) this.setState({ errorMessage: "Time:Coupon is not available!", bCAlert: true, bLoading: false });
					return ret;
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			this.setState({ errorMessage: "Time:Coupon is not available!", bCAlert: true, bLoading: false });
			return false;
		}
	}; //timeMatch

	ApplyCoupon = param => {
		const ts_date = new Date().getTime();
		let disc_to_apply = 0.0;
		let b_applyDis = true;

		const c_sign = Currency.Sign(this.props.__biz.biz_currency);

		if (b_applyDis) {
			const disc_obj = this.CalcDisc(this.props.__checkout.charges.subtotal.amount, param.offer);
			disc_to_apply = disc_obj.amount;

			this.props.Rdx_Checkout_SetCouponDiscount({
				...param,
				amount: disc_to_apply /*, target: coupon.target @todo what is target? */
			});
			this.props.showCoupon(false);

			return {};
		}

		return {};
	}; // ApplyCoupon

	/**
	 */
	CalcDisc = (amount, coupon) => {
		let disc_amount = "percentage" === coupon.type ? (amount * parseInt(coupon.amount)) / 100.0 : parseInt(coupon.amount);
		//@TODO: Max Limit check ;
		// disc_amount = coupon.dis_limit && disc_amount > coupon.dis_limit
		//     ? coupon.dis_limit
		//     : disc_amount
		// ;

		disc_amount = disc_amount > amount ? amount : disc_amount;

		return { amount: disc_amount, msg: null };
	}; // CalcDisc

	confirmCoupon = b => {
		if (b) {
			this.ApplyCoupon(this.state.coupon_apply);
			this.setState({ bCAlert: false, coupon_apply: {} });
		} else {
			this.setState({ bCAlert: false, coupon_apply: {} });
			this.props.showCoupon(false);
		}
	}; // CalcDisc

	/**
	 */
	render() {
		if (this.state.bLoading) {
			return (
				<div className="centerScreen">
					<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={100} width={100} timeout={30000000} />
				</div>
			);
		}

		return (
			<div className="singleItem">
				<div onClick={() => this.props.showCoupon(false)} className="buttonAuthDiv">
					<FontAwesomeIcon className="extraButtonAuth" icon={faTimes} size="lg" color={"red"} />
				</div>
				<Form.Group>
					<div className="extraDivText">Add Coupon/Voucher</div>
					<Form.Control type="text" placeholder="Enter Code" value={this.state.couponEntered} onChange={event => this.setState({ couponEntered: event.target.value })} />
				</Form.Group>
				<div className="processButtons">
					<Button className="confirmButton" style={{ background: this.props.__biz.site_settings.base.colors.primary }} onClick={() => this.submitCouponNew()}>
						Apply
					</Button>
				</div>
				<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.bCAlert} onHide={() => this.setState({ bCAlert: false })} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Coupon Entered</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div>
							{this.state.couponValid ? (
								<div variant="success">
									<div>Coupon code is valid. Applying this coupon will remove other discounts. Do you wish to proceed?</div>

									<Modal.Footer>
										<Button onClick={() => this.confirmCoupon(false)} variant="danger">
											Cancel
										</Button>
										<Button onClick={() => this.confirmCoupon(true)} variant="success">
											Apply
										</Button>
									</Modal.Footer>
								</div>
							) : null}
							{this.state.errorMessage ? (
								<Alert variant="danger">
									{this.state.errorMessage}
								</Alert>
							) : null}
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	} // render
} // class Coupon_Code

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Coupon_Code);
