/**
 * @copyright Elmelo Ltd.
 */
import React, { useState, useEffect } from "react";

import {
	// Image,
	// Button,
	// Row,
	// Col,
	// Form,
	Modal,
	Alert
} from "react-bootstrap";

import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import color from '../_common/colors.json'
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import ContentLoader from "react-content-loader";

import { navigate } from "@reach/router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
	useDispatch
	// useSelector,
} from "react-redux";
import * as actions from "../../rdx/actions";

import elml_cfg from "../../_config/elml_cfg";
// import "../../css/_common.css";
import "./card.css";

import {
	Core
	// Lambda, Utils,
} from "../../api";

// import {TextInput} from "../_common/components"
// import {JS} from "aws-amplify";

// const wv_html = require('./addNewCard_web.html')

/**
 */
const AddNew_Card = props => {
	const dispatch = useDispatch();
	// const RdxCfg = useSelector(state => state.__cfg)

	const [errMsg, ErrMsg] = React.useState("");
	const [isRetreiving, IsRetreiving] = React.useState(true);
	const [intent, Intent] = React.useState(null);
	const [clientName, ClientName] = React.useState(true);
	const [clientSecret, ClientSecret] = React.useState(true);

	/**
	 */
	React.useEffect(() => {
		window.addEventListener("message", OnMsg, false);

		Init().then(data => {});

		return () => {
			window.removeEventListener("message", OnMsg, false);
		};
	}, []);

	// /**
	//  */
	// React.useEffect(() => {
	// 	console.log("intent: ", intent);
	// }, [intent]);

	/**
	 */
	const OnMsg = event => {
		try {
			const resp = JSON.parse(event.data);

			if (resp.err) {
				let err_msg = "Failed to add your card. Please contact your bank.";

				switch (resp.err.type) {
					case "invalid_request_error":
						switch (resp.err.code) {
							case "setup_intent_authentication_failure":
								err_msg = "Card authentication failed. Please contact your bank.";
								break;
							default:
								err_msg = resp.err.message;
								break;
						}
						break;

					default:
						err_msg = resp.err.message;
						break;
				}

				alert(err_msg, { cancelable: false });

				if (props.OnClose) props.OnClose();
				if (props.hideModal) props.hideModal();

				console.error("AddNewCard: OnMsg: err: ", resp);

				return {};
			} else {
				return {};
			}
		} catch (err) {
			console.warn("addNewCard : AddNew_Card : err : ", err);
		}
	}; // OnMsg

	/**
	 */
	const Init = async () => {
		try {
			IsRetreiving(true);

			// const cfg = this.props.__cfg

			const aws_core = new Core();

			const name_obj = await aws_core.Name();
			const email_obj = await aws_core.Email();

			if (!email_obj.addr && (!name_obj.first || !name_obj.last)) {
				throw new Error("Please add email address and name to add a card");
			}

			if (!email_obj.addr) {
				throw new Error("Please add email address and name to add a card");
			}

			if (!name_obj.first || !name_obj.last) {
				throw new Error("Please add name to add a card");
			}

			const name_str = [name_obj.first, name_obj.last].join(" ");

			const resp_addcard = await dispatch(actions.Rdx_AddCard(email_obj.addr, name_str));

			Intent(resp_addcard);
			ClientName(name_str);
			ClientSecret(resp_addcard.client_secret);
			IsRetreiving(false);

			return { msg: "OK" };
		} catch (err) {
			console.warn("AddNewCard: Init: err: ", err);

			IsRetreiving(false);

			Submit(err.message || err);
			props.hideModal();

			return Promise.reject(err);
		}
	}; // Init

	/**
	 */
	const Submit = title => {
		confirmAlert({
			title: title,
			message: "From Profile",
			buttons: [
				{
					label: "Ok",
					onClick: () => navigate("profile")
				}
			]
		});
	}; // Submit

	/**
	 */
	const OnCardAdded = () => {
		props.hideModal(false);
	}; // OnCardAdded

	/**
	 */
	return (
		<div className="overlayModal">
			<Modal
				size="lg"
				// aria-labelledby="contained-modal-title-vcenter"
				centered
				show={true}
				onHide={() => props.hideModal()}
				animation={true}
				style={{ zIndex: 1111111111, marginVertical: 16 }}
				//className="cardViewPhone"
			>
				{isRetreiving ? (
					<div className="overlayContainer">
						<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
							<div className="rainbow">
								Online ordering by <span style={{ color: "#22c55e", fontSize: 24, fontWeight: 700, fontFamily: "Open Sans", marginLeft: 8 }}>QuickFood</span>
							</div>
						</div>
					</div>
				) : intent ? (
					<div style={{ marginVertical: 16 }}>
						<div>
							<div onClick={() => props.hideModal()} className="overlayClose2">
								<FontAwesomeIcon style={{ marginLeft: 4, marginBottom: 4 }} icon={faTimes} size="lg" color="#ef5350" />
							</div>
						</div>

						<>
							{errMsg && (
								<Alert
									variant="danger"
									// onClose={() => this.setState({errMsg: ""})}
									onClose={() => ErrMsg("")}
									dismissible>
									{errMsg}
								</Alert>
							)}
						</>

						<CheckoutWrapper clientSecret={clientSecret} clientName={clientName} OnCardAdded={OnCardAdded} />
					</div>
				) : null}
			</Modal>
		</div>
	);
}; // AddNew_Card

/**
 */
export default AddNew_Card;

/**
 */
const CheckoutWrapper = ({ clientName, clientSecret, OnCardAdded }) => {
	const stripePromise = loadStripe(elml_cfg.stripe_pub_key[elml_cfg.stage]);

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm clientSecret={clientSecret} clientName={clientName} OnCardAdded={OnCardAdded} />
		</Elements>
	);
}; // CheckoutWrapper

/**
 */
const CheckoutForm = ({ clientName, clientSecret, OnCardAdded }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [processing, setProcessing] = React.useState("");
	const [error, setError] = useState(null);
	const [disabled, setDisabled] = useState(true);
	/**
	 */
	const OnSubmit = e => {
		e.preventDefault();
		e.stopPropagation();

		CreateCard().catch(console.warn);
	}; // OnSubmit

	/**
	 */
	const CreateCard = async () => {
		try {
			const card_obj = elements.getElement(CardElement);
			setProcessing(true);

			const aws_core = new Core({});
			const user_email = await aws_core.Email();

			const card_added = await stripe.confirmCardSetup(clientSecret, {
				payment_method: {
					card: card_obj,
					billing_details: {
						name: clientName,
						email: user_email.addr
					}
				}
			});

			OnCardAdded();
			setProcessing(false);
			return {};
		} catch (err) {
			console.error("addNewCard : CheckoutForm : CreteCard : err : ", err);
		}
	}; // CreateCard

	const handleChange = async event => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const cardStyle = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d"
				}
			},
			invalid: {
				fontFamily: "Arial, sans-serif",
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		}
	};
	/**
	 */
	return (
		<form onSubmit={OnSubmit}>
			<CardElement id="card-element" options={cardStyle} onChange={handleChange} />
			{error && (
				<div style={{ color: "#c94b4b", fontSize: 18, padding: 8, fontWeight: 600 }} className="card-error" role="alert">
					{error}
				</div>
			)}
			<button type="submit" id="submit" className="cardAdd" disabled={disabled}>
				<span id="button-text">{processing ? <div className="spinner" id="spinner"></div> : "Add card"}</span>
			</button>
		</form>
	);
}; // IframeCard
