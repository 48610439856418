/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
	Button,
	ButtonGroup,
	Image,
	Carousel,
	Card
	// Container,
} from "react-bootstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import NavHdr from "../_common/nav_hdr";
import Home_Offers from "./offers";
import "../../css/_common.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faMapMarked } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";

import * as dotenv from "dotenv";
dotenv.config();

// import {consent} from 'react-fullstory';
// import CookieConsent, { Cookies } from "react-cookie-consent";
/**
 */
class Home_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		// // console.log( "Home_Home : render : props : ", this.props );

		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr />
				<Home_Slider {...this.props} />
				{/* <Container>
                    <Home_Buttons {...this.props} />
                    <Home_Offers {...this.props} />
                </Container> */}
				{/* <Home_Buttons {...this.props} />
                <Home_Offers {...this.props} /> */}
				{this.props.__biz.homePageMsg.length ? (
					<div className="notification">
						<FontAwesomeIcon className="notificationIcon" icon={faBell} color="#FFFF03" />
						{this.props.__biz.homePageMsg.map(item => (
							<div className="notificationText">
								{"**"} {item}
							</div>
						))}
					</div>
				) : null}
			</div>
		);
	}

	// /**
	//  */
	// componentDidMount()
	// {
	// }
} // class Home_Home

/**
 */
class Home_Slider extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		this.state = {
			height: window.innerHeight - 79,
			width: window.innerWidth
		};

		// // console.log("device widths", this.state);
	}

	componentDidMount() {}

	/**
	 */
	render() {
		// console.log( 'Home_Slider.render: this.props: ', this.props.__biz.imagesFeatured )

		if (this.props.__biz.initStatus === "inprogress") {
			return (
				<div className="centerScreen" style={{ height: this.state.height }}>
					<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={this.state.height / 3} width={this.state.width / 3} timeout={30000000} />
				</div>
			);
		}

		if (this.props.__biz.imagesFeatured) {
			return (
				<div>
					<div className="mainTop">
						<Home_Buttons {...this.props} />
					</div>

					<Carousel fade interval={2500} style={{ flex: 1 }} indicators={false} controls={false}>
						{this.props.__biz.imagesFeatured.map((x, i) => (
							<Carousel.Item key={i}>
								<img
									style={{
										width: this.state.width,
										height: this.state.height,
										overflow: "hidden",
										objectFit: "cover"
									}}
									src={x.url}
									alt="alternative src ..."
								/>
							</Carousel.Item>
						))}
					</Carousel>
				</div>
			);
		} else {
			return <Image fluid src={""} alt="alternative src ..." />;
		}
	} // render
} // Home_Slider

/**
 */
class Home_Buttons extends React.PureComponent {
	/**
	 */
	// constructor( props )
	// {
	//     super( props )
	// }

	/**
	 */
	render() {
		const addr = this.props.__biz;

		return (
			<div className="homeContent">
				<div className="upTitle">Welcome to </div>
				<div className="mainTitle">{addr.title ? addr.title : "Welcome"} </div>
				<div className="bottomTitle">Freshly Prepared meals to Dine in, Take Out & Deliver</div>
				<div className="secTitle">{addr.subTitle ? addr.subTitle : null}</div>
				<div className="secTitle">
					<FontAwesomeIcon style={{ marginRight: 8, color: "#fff" }} icon={faMapMarked} size="lg" />
					{addr.addr.addr_reg
						? [addr.addr.addr_reg.line1, addr.addr.addr_reg.line2, addr.addr.addr_reg.city, addr.addr.addr_reg.postcode, addr.addr.addr_reg.county, addr.addr.addr_reg.country].join(",")
						: ""}
				</div>
				<div className="homeButtonDiv">
					<Button
						className="homeButton"
						size="lg"
						// href='#order'
						variant="warning"
						onClick={() => navigate("/order")}>
						Order Online
					</Button>

					{/* {
                        this.props.__biz.settings && this.props.__biz.settings.services && this.props.__biz.settings.services.reservation
                        ?
                            <Button
                                className="homeButton"
                                size="lg"
                                // href='#reservation'
                                variant="info"
                                onClick={() => navigate('/reserve')}
                            >Reservation</Button>
                        :
                            null
                    } */}
				</div>
				{/*<div className="notification">*/}
				{/*<FontAwesomeIcon className="notificationIcon" icon={faBell} color="#FFFF03"/>*/}
				{/*<div className="notificationText">We are champions</div>*/}
				{/*</div>*/}

				{/* <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    acceptOnScroll={true}
                    onAccept={({ acceptedByScrolling }) => {
                        if (acceptedByScrolling) {
                        // triggered if user scrolls past threshold
                            console.log( "onAccept : ", acceptedByScrolling );
                            consent(acceptedByScrolling);
                        } else {
                            //alert("Accept was triggered by clicking the Accept button");
                        }
                    }}
                >
                    This website uses cookies to enhance the user experience.{" "}
                    <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
                </CookieConsent> */}
			</div>
		);
	} // render
} // Home_Buttons

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Home_Home);
