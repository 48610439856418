/**
 *	@copyright	Elmelo Ltd
 */

import * as dotenv from "dotenv";
import elml from "./elml_cfg";

dotenv.config();

const AwsCfg = (type, stage = process.env.REACT_APP_APP_STAGE) => {
	if (type === "Email") {
		if ("live" === stage) {
			return {
				aws_cognito_region: process.env.REACT_APP_REGION || process.env.REACT_APP_LIVE_EMAIL_REGION,
				aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITYPOOLID || process.env.REACT_APP_LIVE_EMAIL_IDENTITYPOOLID,
				aws_user_pools_id: process.env.REACT_APP_USERPOOLID || process.env.REACT_APP_LIVE_EMAIL_USERPOOLID,
				aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID || process.env.REACT_APP_LIVE_EMAIL_USERPOOLWEBCLIENTID
			};
		} else if ("test" === stage) {
			return {
				aws_cognito_region: process.env.REACT_APP_REGION || process.env.REACT_APP_TEST_EMAIL_REGION,
				aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITYPOOLID || process.env.REACT_APP_TEST_EMAIL_IDENTITYPOOLID,
				aws_user_pools_id: process.env.REACT_APP_USERPOOLID || process.env.REACT_APP_TEST_EMAIL_USERPOOLID,
				aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID || process.env.REACT_APP_TEST_EMAIL_USERPOOLWEBCLIENTID
			};
		} else {
			return {};
		}
	} else {
		if ("live" === stage) {
			return {
				aws_cognito_region: process.env.REACT_APP_REGION || process.env.REACT_APP_LIVE_NOEMAIL_REGION,
				aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITYPOOLID || process.env.REACT_APP_LIVE_NOEMAIL_IDENTITYPOOLID,
				aws_user_pools_id: process.env.REACT_APP_USERPOOLID || process.env.REACT_APP_LIVE_NOEMAIL_USERPOOLID,
				aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID || process.env.REACT_APP_LIVE_NOEMAIL_USERPOOLWEBCLIENTID
			};
		} else if ("test" === stage) {
			return {
				aws_cognito_region: process.env.REACT_APP_REGION || process.env.REACT_APP_TEST_NOEMAIL_REGION,
				aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITYPOOLID || process.env.REACT_APP_TEST_NOEMAIL_IDENTITYPOOLID,
				aws_user_pools_id: process.env.REACT_APP_USERPOOLID || process.env.REACT_APP_TEST_NOEMAIL_USERPOOLID,
				aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID || process.env.REACT_APP_TEST_NOEMAIL_USERPOOLWEBCLIENTID
			};
		} else {
			return {};
		}
	}
}; // AwsCfg

/**
 */
export default AwsCfg;
