/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

/**
 */
export const PaymentRedirect = () =>
{
  /**
   */
  React.useEffect( () => {
    console.log( 'PaymentRedirect' )
    if( !window?.parent )
    {
      console.log( 'parent not available' )
      return undefined
    }

    window.parent.postMessage( '3DS-authentication-complete' )
  }, [] )

  /**
   */
  return (
    <div>
      Payment Redirect
    </div>
  )
}

