import React from "react";
import {
	// Image,
	// Button,
	// Row,
	// Col,
	// Form,
	Modal
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faClock, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../css/_common.css";
import color from "./colors.json";

import * as dotenv from "dotenv";
dotenv.config();

class PickerEx extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.items.find(x => x.val === this.props.val),
			bModal: this.props.bOpen ? true : false
		};

		// console.log( 'this.props.items: ', this.props.items )
	}

	/**
	 */
	render() {
		// console.log( 'ui.Picker: PickerEx: render: this.state: ', this.state , this.props );

		return (
			<div
				className={`${this.props.pickerTitle ? "singleItem" : "pickerBox"}`}
				style={{ background: this.props.__biz?.site_settings.base.colors.secondary, display: "flex", alignItems: "center", maxHeight: 44 }}>
				<div style={{ flex: 1 }} onClick={() => this.setState({ bModal: true })}>
					<div className="commonFlex">
						{this.props.pickerTitle ? (
							<div>
								<FontAwesomeIcon icon={faClock} size="lg" />
							</div>
						) : null}

						<div className="zistText">{this.props.val ? this.props.val : this.props.prompt ? this.props.prompt : "Select an option"}</div>
						<div>
							<FontAwesomeIcon style={{ marginLeft: 12, color: this.props.__biz?.site_settings.base.colors.primary }} icon={faArrowDown} size="sm" />
						</div>
					</div>
				</div>

				<div>
					{this.state.bModal ? (
						<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={true} onHide={() => this.setState({ bModal: false })} animation={true}>
							<div style={{ flex: 1 }}>
								<div onClick={() => this.setState({ bModal: false })}>
									<div style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)" }}></div>
								</div>
								<div style={{ height: "80%", backgroundColor: "#fff", padding: 16 }}>
									<div style={{ display: "flex" }}>
										<div className="zistText" style={{ justifyContent: "center" }}>
											{this.props.ot ? "Select Time" : "Choose an Item"}
										</div>
										<FontAwesomeIcon onClick={() => this.setState({ bModal: false })} style={{ width: 50, cursor: "pointer" }} icon={faTimes} size="lg" color="#ef5350" />
									</div>

									{/* <div>
                                            {
                                                this.props.prompt ?
                                                    <div style={{color: "white", fontSize: 14}}>{this.props.prompt}</div>
                                                    :
                                                    null
                                            }
                                        </div> */}
									{this.props.items.map((item, idx) => this.RenderItem(item, idx))}
								</div>
							</div>
						</Modal>
					) : null}
				</div>
			</div>
		);
	} // render

	/**
	 */
	RenderItem = (item, idx) => {
		return (
			<div
				key={item.val}
				onClick={() => {
					if (this.props.onChange) this.props.onChange(item.val);

					this.setState({ bModal: false, selected: item });
				}}
				className="pickerItem">
				{this.props.ot ? (
					<div>
						<FontAwesomeIcon style={{ marginRight: 16 }} color={"#b7b7b7"} icon={faClock} size="sm" />
					</div>
				) : null}

				<div className="pickerLabel" style={{ flex: 1 }}>
					{item.label}
				</div>
			</div>
		);
	};
} // class PickerEx

export { PickerEx };
