/**
 * @copyright Elmelo Ltd.
 */

import {EOrderTtype} from '../../_types/menu'

export function GetItem( menu: any[], sect_id: string | undefined, item_id: string | undefined )
{
	if( !menu || ! sect_id || !item_id )
	{
		return undefined
	}

  // console.log( 'GetItem: menu: ', menu )
  // console.log( 'GetItem: sect_id: ', sect_id )
  // console.log( 'GetItem: item_id: ', item_id )

  const sect_obj = menu.find( x => x.cat_id === sect_id )

  // console.log( 'CartItem: sect_obj: ', sect_obj )

  if( !sect_obj )
  {
    return undefined
  }

  const item_obj = sect_obj.data.find( (x: any) => x._id === item_id )

  // console.log( 'CartItem: item_obj: ', item_obj )

  if( !item_obj )
  {
    return undefined
  }

  return item_obj
}

/**
 */
export const CheckVisAvail = (vis: any, delivery_type: EOrderTtype) => {
	// console.log( 'vis: ', vis )
	// console.log( 'delivery_type: ', delivery_type )

	// if vis object is not provided or empty, we consider it to be visible by default
	if (!vis || !Object.keys(vis).length) {
		return true
	}

	if ("collection" === delivery_type) {
		if ('t' === vis.coll) {
			return true
		}
		else if ('f' === vis.coll) {
			return false
		}
		else if (undefined === vis.coll && 't' === vis.def) {
			return true
		}
		else if (undefined === vis.coll && 'f' === vis.def) {
			return false
		}
		else {
		}
	}
	else if ("delivery" === delivery_type) {
		if ('t' === vis.del) {
			return true
		}
		else if ('f' === vis.del) {
			return false
		}
		else if (undefined === vis.del && 't' === vis.def) {
			return true
		}
		else if (undefined === vis.del && 'f' === vis.def) {
			return false
		}
		else {
			//
		}
	}
	else {
		if ('t' === vis.def) {
			return true
		}
	}

	// return false for all other cases
	return false
}	// CheckVisAvail

