/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Nav, Navbar, Image } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import { navigate } from "@reach/router";
import "../../css/_common.css";
import color from "../_common/colors.json";

import { Helmet } from "react-helmet";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class NavHdr extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		const bgColor = "background-color:" + this.props.__biz.site_settings.base.colors.secondary;
		const title = this.props.__biz.biz_title + " :: QuickFood";

		return (

			<Navbar sticky="top" fixed="top" expand="lg" variant="light" className="navbarDiv" style={this.props.style}>
				<Helmet bodyAttributes={{ style: bgColor }}>
					<meta charSet="utf-8" />
					<title>{title}</title>
					<link rel="icon" href={this.props.__biz.site_settings.base.favicon} />
					<link rel="apple-touch-icon" href={this.props.__biz.site_settings.base.favicon} />
				</Helmet>
				<Nav.Link href="#" onSelect={() => navigate("order")}>
					{this.props.__biz?.biz_images?.icon_app?.url ? (
						<img className="logoImage" src={this.props.__biz.biz_images.icon_app.url} />
					) : this.props.__biz.biz_title ? (
						<div className="brandNameDiv" style={{ display: this.props.__biz.subTitle ? "block" : "flex", color: this.props.__biz.site_settings.base.colors.primary }}>
							<div className="brandDiv">
								<div className="brandName" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
									{this.props.__biz.biz_title}
								</div>
								{this.props.__biz.subTitle ? <div className="brandSubtitle">{this.props.__biz.subTitle}</div> : null}
							</div>
						</div>
					) : (
						<div className="brandName">QuickFood</div>
					)}
				</Nav.Link>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					{/*<Nav className="mr-auto">*/}
					{/*<Nav.Link href='gallery'>Gallery</Nav.Link>*/}
					{/*<Nav.Link href='menu'>Menu</Nav.Link>*/}
					{/*</Nav>*/}
					<Nav className="mr-auto">
						{/*<Nav.Link href='reserve'>Reserve</Nav.Link>*/}
						{/*<Nav.Link href='mobileOrder'>Mobile Order</Nav.Link>*/}
						{/*<Nav.Link href="#" onSelect={ ()=>navigate('cart') } >Cart</Nav.Link>*/}
						{/*<Nav.Link href='profile'>Profile</Nav.Link>*/}
					</Nav>
					<Nav>
						{/* {
						this.props.__core.authStatus && 'auth' === this.props.__core.authStatus ?
							<Nav.Link href="#" onSelect={ () => navigate('chat') } className="activeNav" style={{position:"relative",color:this.props.chat ? this.props.__biz.site_settings.base.colors.primary :"#b7b7b7"}}>
								Chat
								{
									this.props.__chat.totUnRead.totUnreadMsg ?
										<span className="chatCounter">{this.props.__chat.totUnRead.totUnreadMsg}</span>
										: null
								}
							</Nav.Link>
							: null
					} */}
						{/* <Nav.Link
						href="#"
						onSelect={() => navigate("gallery")}
						className="activeNav"
						style={{ color: this.props.gallery ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}>
						Gallery
					</Nav.Link> */}
						{/* <Nav.Link href="#" onSelect={ () => navigate('offer') } className="activeNav" style={{color:this.props.offer ? this.props.__biz.site_settings.base.colors.primary :"#b7b7b7"}}>Offers</Nav.Link> */}
						{this.props.__biz.settings && this.props.__biz.settings.services && this.props.__biz.settings.services.reservation ? (
							<Nav.Link href="#" onSelect={() => navigate("reserve")} className="activeNav" style={{ color: this.props.reserve ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}>
								Reservation
							</Nav.Link>
						) : null}
						<Nav.Link
							href="#"
							onSelect={() => navigate("information")}
							className="activeNav"
							style={{ color: this.props.information ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}>
							Information
						</Nav.Link>
						<Nav.Link href="#" onSelect={() => navigate("order")} className="activeNav" style={{ color: this.props.order ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}>
							Order Online
						</Nav.Link>
					</Nav>
					{this.props.__core.authStatus ? (
						"auth" === this.props.__core.authStatus ? (
							<Nav>
								<Nav.Link
									href="#"
									onSelect={() => navigate("profile")}
									style={{ color: this.props.profile ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}
									className="activeNav">
									Profile
								</Nav.Link>
							</Nav>
						) : "veri_reqd" === this.props.__core.authStatus ? (
							<Nav>
								{/* <Nav.Link href='verification'>Complete SignUp</Nav.Link> */}
								<Nav.Link
									href="#"
									onSelect={() => navigate("signin")}
									style={{ color: this.props.signin ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}
									className="activeNav">
									Sign In
								</Nav.Link>
							</Nav>
						) : (
							<Nav>
								<Nav.Link
									href="#"
									onSelect={() => navigate("signin")}
									style={{ color: this.props.signin ? this.props.__biz.site_settings.base.colors.primary : "#b7b7b7" }}
									className="activeNav">
									Sign In
								</Nav.Link>
							</Nav>
						)
					) : null}
				</Navbar.Collapse>
			</Navbar>
		);
	}
} // NavHdr

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(NavHdr);
