/**
 * @copyright Elmelo Ltd.
 */

import React from "react";
import {
	Modal,
	// Accordion, Card, Button, Form, Alert,
	Image
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import {ICartOpt} from '../../_types/cart'

import color from "../_common/colors.json";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
// 	// faArrowUp, faSquare, faCheckSquare,
// 	faPlusCircle
// 	// faCheckCircle, faTimes, faTrashAlt, faCheck, faCircle, faCircleNotch
// } from "@fortawesome/free-solid-svg-icons";
import "../../css/_common.css";
import { CheckVisAvail, } from "./_utils_2";
import { Item_Box, } from "./_common_2";
import { ItemPrice } from "./itemPrice";
// import { Rdx_Order_AddToCart } from '../../rdx/actions';
// import ONT from './ont'
import ModEx_Modal from "./items_modex_modal_2";
// import SetMeal_Modal from "./items_setmeal_modal";

import * as dotenv from "dotenv";
dotenv.config();

interface IItems
{
  colors: any,
  item: any,
  sect: any,
} // IItems

interface IItemsComp extends IItems
{
  cnt: number,
} // IItemsComp

/**
 */
// const Items
const Items = ( props: IItems ) => {
	const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxCheckout = useSelector( (state: any) => state.__checkout )
  const rdxCartItems: ICartOpt[] = useSelector((state: any) => state.__cart.items);

	const [isVis, IsVis] = React.useState( true )
	const [isAvail, IsAvail] = React.useState( true )
  const [cntItem, CntItem] = React.useState( 0 )

  /**
	 */
  React.useEffect( () => {
		// console.log( 'rdxCartItems: ', rdxCartItems )

		const cnt_cart_list = rdxCartItems.filter( x => x.item.id === props.item._id )
			.reduce( (a: number, c: ICartOpt) => {
				return a + c.cnt
			}, 0 )

		// console.log( 'cnt_cart_list: ', cnt_cart_list )

		CntItem(cnt_cart_list)

    // const cart_item = rdxCartItems.find( x => x.item.id === props.item._id )

    // if(!cart_item)
    // {
    //   return undefined
    // }

    // console.log( 'Items: cart_item: ', cart_item )

    // CntItem(cart_item.cnt)
  }, [rdxCartItems] )

	/**
	 */
	React.useEffect( () => {
		// console.log( sect )
		// console.log( item )

		IsVis( CheckVisAvail(
			props.item.vis,
			rdxCheckout.delivery.deliveryType
		) )

		IsAvail( CheckVisAvail(
			props.item.avail,
			rdxCheckout.delivery.deliveryType
		) )

		// console.log( 'is_vis: ', is_vis )

		//
	}, [props.item, rdxCheckout] )

	/**
	 */
	if( !isVis || !isAvail )
	{
		return null
	}

	/**
	 */
	return (
		<div>
			<>
				{props.item._type === "basic" ? (
					<Items_Basic
						colors={props.colors}
						item={props.item}
						sect={props.sect}

            cnt={cntItem}
						// addToCart={this.addToCart}
					/>
				) : props.item._type === "mod" ? (
					<Items_Mod
						colors={props.colors}
						item={props.item}
						sect={props.sect}

            cnt={cntItem}
						// addToCart={this.addToCart}
					/>
				) : props.item._type === "mod_ex" ? (
					<Items_ModEx
						colors={props.colors}
						item={props.item}
						sect={props.sect}

            cnt={cntItem}
						// addToCart={this.addToCart}
					/>
				) : props.item._type === "setmeal" ? (
					<Items_SetMeal
						colors={props.colors}
						item={props.item}
						sect={props.sect}

            cnt={cntItem}
						// addToCart={this.addToCart}
					/>
				) : null}
			</>

			<>
				{rdxBiz.showAllergenMsg && (
					<div className="bottomMessage">
						<div className="bottomText" style={{ color: props.colors.primary }}>
							{rdxBiz.showAllergenMsg}
						</div>
					</div>
				)}
			</>
		</div>
	);
}; // Items

/**
 */
const Items_Basic = (props: IItemsComp) => {
	const dispatch = useDispatch<any>();
	const rdxCheckout = useSelector( (state: any) => state.__checkout )

	// const [] = React.useState( false )
	const [priceItem, PriceItem] = React.useState(0.0);
	// const [cntItem, CntItem] = React.useState( item._cnt || 0 )

	// React.useEffect( () => {
	// 	console.log( 'item: ', item )
	// 	// console.log( 'cntItem: ', cntItem )
	// }, [] )

	/**
	 */
	React.useEffect(() => {
		PriceItem(ItemPrice(props.item, rdxCheckout.delivery.deliveryType));
	}, [props.item, rdxCheckout.delivery.deliveryType]);

	// /**
	//  */
	// const AddToCart = () => {
	// 	// console.log( 'Items_Basic: addToCart: 1' )

	// 	// this.props.addToCart( this.props.item, this.props.sect, ++this.props.item._cnt );
	// 	// this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, ++this.props.item._cnt);

	// 	dispatch(actions.Rdx_Order_AddToCart(props.item, props.sect, ++props.item._cnt));
	// 	// item._cnt = cntItem
	// 	// dispatch( actions.Rdx_Order_AddToCart(item, sect, cntItem) )

	// 	// // console.log( 'Items_Basic: addToCart: 2' )
	// };

	// const AddItem = () =>
	// {
	// 	console.log( 'AddItem: ', cntItem )
	// 	CntItem( cntItem+1 )
	// }	// AddItem

	// const RemItem = () =>
	// {
	// 	console.log( 'RemItem: ', cntItem )
	// 	CntItem( cntItem-1 )
	// }	// RemItem

	// /**
	//  */
	// const RemoveFromCart = () => {
	// 	// this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, --this.props.item._cnt);
	// 	dispatch(actions.Rdx_Order_AddToCart(props.item, props.sect, --props.item._cnt));
	// };

	/**
	 */
	return (
		<Item_Box
			item={props.item}
			colors={props.colors}
			// count={props.item._cnt}
      count={props.cnt}
			// count={cntItem}
			itemName={props.item.name}
			description={props.item.desc}
			img_url={props.item.img_url}
			// itemPrice={this.state.col ? item.price.in : item.price.def}
			itemPrice={priceItem}
			// showModal={this.showModal}

			// OnPress={AddToCart}
			// RemoveFromCart={RemoveFromCart}
			// AddItem={AddItem}
			// RemItem={RemItem}

			// item={item}
		/>
	);
};

/**
 */
const Items_Mod = (props: IItemsComp) => {
	const rdxCheckout = useSelector( (state: any) => state.__checkout )

	const [isModal, IsModal] = React.useState(false);
	const [priceItem, PriceItem] = React.useState(0.0);

	/**
	 */
	React.useEffect(() => {
		PriceItem(ItemPrice(props.item, rdxCheckout.delivery.deliveryType));
	}, [props.item, rdxCheckout.delivery.deliveryType]);

	/**
	 */
	const AddToCart = (mod: any) => {
		++mod._cnt;
		++props.item._cnt;

		// this.props.addToCart( this.props.item, this.props.sect, this.props.item._cnt );
		// props.Rdx_Order_AddToCart(props.item, props.sect, props.item._cnt);
    //
	};

	return (
		<div
			className="singleItem"
			style={{
				border: props.cnt ? "2px solid" : 0,
				borderColor: props.cnt ? color.colors.primary : undefined
			}}>
			<>
				{isModal && (
					<Modal size="lg" show={true} onHide={() => IsModal(false)} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Body>
							<ModEx_Modal
								colors={props.colors}
								// {...this.props}
								item={props.item}
								sect={props.sect}
								priceItem={priceItem}
								OnClose={IsModal}

								// AddToCart={AddToCart}
							/>
						</Modal.Body>
					</Modal>
				)}
			</>
			<div onClick={() => IsModal(true)} className="commonFlex">
				<div style={{ flex: 1 }}>
					<div className="commonFlex">
						<div className="itemName">
							{props.cnt ? (
								<span className="itemName" style={{ color: color.colors.primary, marginRight: 8 }}>
									{`${props.cnt} x`}
								</span>
							) : null}
							{props.item.name}
						</div>
					</div>

					<div className="itemDescription">{props.item.desc}</div>

					{priceItem !== 0 ? <>{priceItem && <div className="itemPrice">{"£" + priceItem.toFixed(2)}</div>}</> : null}
				</div>
				<div className="menuRight">
					<div className="imageContainer">
						{props.item.img_url ? <img className="imageStyle" src={props.item.img_url} /> : <Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />}
					</div>
				</div>
				<div className="addtoCart" style={{ background: color.colors.primary }}>
					<span style={{ marginLeft: 6, fontWeight: 700 }}>+</span>
				</div>
			</div>
		</div>
	);
}; // Items_Mod
// class Items_Mod_ extends React.PureComponent {
// 	/**
// 	 */
// 	constructor(props) {
// 		super(props);
// 	}

// 	// /**
// 	//  */
// 	// componentDidMount()
// 	// {
// 	// 	//
// 	// }

// 	/**
// 	 */
// 	addToCart = mod => {
// 		++mod._cnt;
// 		++this.props.item._cnt;

// 		// this.props.addToCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 	};

// 	/**
// 	 */
// 	render() {
// 		// console.log("render mod items", this.props)
// 		const it_p = ItemPrice(this.props.item, this.props.__checkout.delivery.deliveryType);
// 		const bPrice = it_p ? it_p : 0;

// 		return (
// 			<div className="singleItem" style={{ borderColor: color.colors.borderLight }}>
// 				<div className="commonFlex">
// 					<div className="imageContainer">
// 						{this.props.item.img_url ? (
// 							<img className="imageStyle" src={this.props.item.img_url} />
// 						) : (
// 							<Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />
// 						)}
// 					</div>
// 					<div style={{ flex: 1 }}>
// 						<div className="itemName">
// 							{this.props.item.name}
// 							{bPrice ? " ( Base Price £" + bPrice + ")" : null}
// 						</div>
// 						<div className="itemDescription">{this.props.item.desc}</div>
// 					</div>
// 				</div>
// 				<div>
// 					{this.props.item._mods.opts.map((mod, mod_index) => (
// 						<div
// 							onClick={() => this.addToCart(mod)}
// 							className="modItemDiv"
// 							style={{
// 								borderBottom: "1px solid",
// 								borderBottomColor: color.colors.borderLight
// 							}}
// 							key={mod_index}>
// 							{/* {
// 								mod._cnt > 0 ?
// 								<FontAwesomeIcon
// 									style={{margin:"4px 8px"}}
// 									icon={faCheck} size="md" color="#43A047"/>
// 								: null
// 							} */}
// 							<div className="commonFlex">
// 								<div
// 									style={{
// 										flex: 1,
// 										paddingLeft: mod._cnt ? 16 : 0
// 									}}>
// 									{mod.name}
// 								</div>
// 								{mod._cnt ? <div className="orderCount">{mod._cnt}</div> : null}
// 							</div>
// 							<div className="modItemPrice">
// 								<div className="itemPrice">
// 									{bPrice ? "+" : null}£{mod.price.def}
// 								</div>
// 								<div
// 									className="addtoCart"
// 									// onClick={ () => this.addToCart(mod) }
// 								>
// 									<FontAwesomeIcon icon={faPlusCircle} size="2xl" color={color.colors.primary} />
// 								</div>
// 							</div>
// 						</div>
// 					))}
// 				</div>
// 			</div>
// 		);
// 	} // render
// } // Items_Mod

/**
 */
const Items_ModEx = (props: IItemsComp) => {
	const rdxCheckout = useSelector( (state: any) => state.__checkout )

	const [isModal, IsModal] = React.useState(false);
	const [priceItem, PriceItem] = React.useState(0.0);

	/**
	 */
	React.useEffect(() => {
		PriceItem(ItemPrice(props.item, rdxCheckout.delivery.deliveryType));
	}, [props.item, rdxCheckout.delivery.deliveryType]);

	/**
	 */
	return (
		<div
			className="singleItem"
			style={{
				border: props.cnt ? "2px solid" : 0,
				borderColor: props.cnt ? props.colors.primary : undefined
			}}>
			<>
				{isModal && (
					<Modal size="lg" show={true} onHide={() => IsModal(false)} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Body>
							<ModEx_Modal
								colors={props.colors}
								// {...this.props}
								item={props.item}
								sect={props.sect}
								priceItem={priceItem}
								OnClose={IsModal}
							/>
						</Modal.Body>
					</Modal>
				)}
			</>

			<div onClick={() => IsModal(true)} className="commonFlex">
				<div style={{ flex: 1 }}>
					<div className="commonFlex">
						<div className="itemName">
							{props.cnt ? (
								<span className="itemName" style={{ color: props.colors.primary, marginRight: 8 }}>
									{`${props.cnt} x`}
								</span>
							) : null}
							{props.item.name}
						</div>
					</div>
					<div className="itemDescription">{props.item.desc}</div>

					{priceItem !== 0 ? <>{priceItem && <div className="itemPrice">{"£" + priceItem.toFixed(2)}</div>}</> : null}
				</div>
				<div className="menuRight">
					<div className="imageContainer">
						{props.item.img_url ? <img className="imageStyle" src={props.item.img_url} /> : <Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />}
					</div>
				</div>
				<div className="addtoCart" style={{ background: props.colors.primary }}>
					<span style={{ marginLeft: 6, fontWeight: 700 }}>+</span>
				</div>
			</div>
		</div>
	);
}; //	Items_ModEx

const Items_SetMeal = (props: IItemsComp) =>
{
  return null
}

// /**
//  */
// class Items_SetMeal extends React.PureComponent {
// 	/**
// 	 */
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			setMeal: false
// 		};
// 	}

// 	/**
// 	 */
// 	showSetMeal = val => {
// 		this.setState({ setMeal: val });
// 	};

// 	/**
// 	 */
// 	componentDidMount() {
// 		//
// 	}

// 	/**
// 	 */
// 	render() {
// 		// // console.log( "Items_SetMeal : render : props : ", this.props );

// 		return (
// 			<div>
// 				{this.state.setMeal ? <SetMeal_Modal {...this.props} showSetMeal={this.showSetMeal} /> : null}
// 				<div className="singleItem" style={{ borderColor: color.colors.borderLight }}>
// 					<div
// 						className="commonFlex"
// 						// onClick={() => this.showSetMeal(true)}
// 					>
// 						<div className="imageContainer">
// 							{this.props.item.img_url ? (
// 								<img className="imageStyle" src={this.props.item.img_url} />
// 							) : (
// 								<Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />
// 							)}
// 						</div>
// 						<div style={{ flex: 1 }}>
// 							<div className="commonFlex">
// 								<div className="itemName">{this.props.item.name}</div>
// 							</div>
// 							<div className="itemDescription">{this.props.item.desc}</div>
// 						</div>
// 						<div className="menuRight">
// 							<div className="itemPrice">£{this.props.item.price.def}</div>
// 							<div className="addtoCart" style={{ background: this.props.__biz.site_settings.base.colors.primary }} onClick={() => this.showSetMeal(true)}>
// 								<FontAwesomeIcon icon={faPlusCircle} size="2xl" color={this.props.__biz.site_settings.base.colors.primary} />
// 							</div>
// 						</div>
// 						{this.props.item._cnt ? (
// 							<div className="orderCount" style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
// 								{this.props.item._cnt}
// 							</div>
// 						) : null}
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// const mapStateToProps = state => {
// 	return state;
// };

/**
 */
// export default connect(mapStateToProps, actions)(Items);
export default Items;
