/**
 *	@copyright	Elmelo Ltd.
 */

import Geocode from "react-geocode";

import { Core, Lambda } from "../../api/AWS";

// import { Utils } from "../../api/Utils";

/**
 */
export const Rdx_GetAddressList = () => {
	return async (d, gs) => {
		const cfg = gs().__cfg;
		const aws_lambda = new Lambda({});

		let params = {
			actType: "profile",
			act: "address:get",
			stage: cfg.stage,
			usr: "beta",
			b_shared: true
		};

		// // console.log("params", params)

		const resp_lambda = await aws_lambda.Invoke(params, cfg.lambda("beta", cfg.stage));

		if (resp_lambda.errorMessage) {
			return Promise.reject(resp_lambda.errorMessage);
		}

		return resp_lambda;
	};
}; // GetAddressList

/**
 */
export const Rdx_AddAddress = address => {
	return async (d, gs) => {
		const cfg = gs().__cfg;
		const aws_lambda = new Lambda({});

		// const _postcode = (() => {
		//   const postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i;
		//   return address.postal_code.replace(postcodeRegEx,"$1 $2");
		// })();

		const addressString = address.line1 + " " + address.line2 + ", " + address.city + ", " + address.county + ", " + address.postal_code;

		Geocode.setApiKey("AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M");
		Geocode.enableDebug();

		const addr_longlat = await Geocode.fromAddress(addressString);

		// console.log("Rdx_AddAddress: addr_longlat: ", addr_longlat);

		const { lat, lng } = addr_longlat.results[0].geometry.location;

		// console.log("Rdx_AddAddress: lat: ", lat);
		// console.log("Rdx_AddAddress: lng: ", lng);

		const longlat = {
			latitude: lat,
			longitude: lng
		};

		// console.log("Rdx_AddAddress: longlat: ", longlat);

		let params = {
			actType: "profile",
			act: "address:add",
			stage: cfg.stage,
			usr: "beta",
			address: { ...address, longlat },
			b_shared: true
		};

		// // console.log("params", params)
		// console.log("Rdx_AddAddress: params: ", params);

		const resp_lambda = await aws_lambda.Invoke(params, cfg.lambda("beta", cfg.stage));

		// console.log("Rdx_AddAddress: resp_lambda: ", resp_lambda);

		if (resp_lambda.errorMessage) {
			return Promise.reject(resp_lambda.errorMessage);
		}

		return resp_lambda;
	};
}; // Rdx_AddAddress

/**postal_code
 */
export const Rdx_GetCardList = () => {
	return async (d, gs) => {
		const cfg = gs().__cfg;
		const aws_core = new Core();
		const aws_lambda = new Lambda({});

		const name_obj = await aws_core.Name();
		const email_obj = await aws_core.Email();

		const name_str = [name_obj.first, name_obj.last].join(" ");

		const p_lambda = {
			usr: "beta",
			actType: "pay",
			act: "card:shared:list", //Utils.Beta_Act( 'card_list' ),
			stage: cfg.stage,
			email: email_obj.addr,
			name: name_str,
			biz_id: gs().__biz.biz_id
		};

		// console.log("Rdx_GetCardList: p_lambda: ", p_lambda);

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));

		// console.log("Rdx_GetCardList: resp_lambda: ", resp_lambda);

		if (resp_lambda.errorMessage) {
			return Promise.reject(resp_lambda.errorMessage);
		}

		const card_list = resp_lambda.resp && resp_lambda.resp.data ? resp_lambda.resp.data : [];

		return card_list;
	};
}; // GetCardList

/**
 */
export const Rdx_AddCard = (email, name) => {
	return async (d, gs) => {
		const cfg = gs().__cfg;
		const aws_lambda = new Lambda({});

		const p_lambda = {
			usr: "beta",
			actType: "pay",
			act: "card:shared:add",
			stage: cfg.stage,
			email: email,
			name: name
		};

		// console.log("Rdx_AddCard: p_lambda: ", p_lambda);

		const resp_lambda = await aws_lambda.Invoke(p_lambda, cfg.lambda("beta", cfg.stage));

		if (resp_lambda.errorMessage) {
			return Promise.reject(resp_lambda.errorMessage);
		}

		return resp_lambda.resp;
	};
}; // Rdx_AddCard
