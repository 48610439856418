/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMinus,
	faPlus,
	faTimes
	// faTrashAlt, faArrowLeft, faShoppingBasket, faClock
} from "@fortawesome/free-solid-svg-icons";

import { CartCntUpdateAsync } from "../../rdx/cart.slice";
import { ICartAddon, ICartMod, ICartOpt, ICartSecMod } from "../../_types/cart";

import color from "../_common/colors.json";

import { CheckVisAvail } from "./_utils_2";
// import ONT from "./ont";
// import { ItemPrice } from "./itemPrice";

import { GetTotal_ModEx, ItemPrice2 } from "./ont_2";
// import ONT from "./ont";

/**
 */
interface ICartItem {
	cart: ICartOpt;

	summary?: any;
} // ICartItem

/**
 */
interface ICartItemComp {
	item: any;
	sect: any;
	cart: ICartOpt;

	summary?: boolean;
} // ICartItemComp

/**
 */
export const CartItem = (props: ICartItem) => {
	// const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxCoreMenu: any[] = useSelector((state: any) => state.__core.menu.menu);
	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [menuItem, MenuItem] = React.useState<any>(null);
	const [menuSect, MenuSect] = React.useState<any>(null);
	const [isVis, IsVis] = React.useState(true);
	const [isAvail, IsAvail] = React.useState(true);

	/**
	 */
	React.useEffect(() => {
		if (!rdxCoreMenu) {
			return undefined;
		}

		const sect_obj = rdxCoreMenu.find(x => x.cat_id === props.cart.cat.id);

		if (!sect_obj) {
			return undefined;
		}

		const item_obj = sect_obj.data.find((x: any) => x._id === props.cart.item.id);

		if (!item_obj) {
			return undefined;
		}

		MenuSect(sect_obj);
		MenuItem(item_obj);
	}, [rdxCoreMenu]);

	/**
	 */
	React.useEffect(() => {
		if (!menuItem) {
			return undefined;
		}

		// get item based on cart

		//
		IsVis(CheckVisAvail(menuItem.vis, rdxCheckout.delivery.deliveryType));

		IsAvail(CheckVisAvail(menuItem.avail, rdxCheckout.delivery.deliveryType));

		//
	}, [menuItem, rdxCheckout]);

	if (!menuItem) {
		return null;
	}

	/**
	 */
	if (!isVis || !isAvail) {
		return null;
	}

	// return <Cart_Item_ModEx item={props.item} sect={menuSect} key={menuItem.id} summary={props.summary} />
	return (
		<>
			{menuItem._type === "basic" ? (
				<Cart_Item_Basic cart={props.cart} item={menuItem} sect={menuSect} key={menuItem.id} summary={props.summary} />
			) : menuItem._type === "mod" ? (
				<Cart_Item_ModEx cart={props.cart} item={menuItem} sect={menuSect} key={menuItem.id} summary={props.summary} />
			) : menuItem._type === "mod_ex" ? (
				<Cart_Item_ModEx cart={props.cart} item={menuItem} sect={menuSect} key={menuItem.id} summary={props.summary} />
			) : menuItem._type === "setmeal" ? (
				<Cart_Item_SetMeal cart={props.cart} item={menuItem} sect={menuSect} key={menuItem.id} summary={props.summary} />
			) : (
				<div>Null</div>
			)}
		</>
	);
}; // Cart_Item

// const ListViewCartItem = () =>
// {
// 	//
// }

//
export const Cart_Item_Basic = (props: ICartItemComp) => {
	const dispatch = useDispatch();

	const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [totPrice, TotPrice] = React.useState("0.00");

	/**
	 */
	React.useEffect(() => {
		TotPrice((ItemPrice2(props.item.price, rdxCheckout?.delivery?.deliveryType) * props.cart.cnt).toFixed(2));
	}, [props.cart.cnt]);

	/**
	 */
	const updateItemQuantity = (type: any) => {
		const cart_opt: ICartOpt = { ...props.cart };

		if ("+" === type) {
			++cart_opt.cnt;
		} else if ("-" === type) {
			--cart_opt.cnt;
		} else {
			return;
		}

		dispatch(CartCntUpdateAsync({ id: cart_opt.id, cnt: cart_opt.cnt }));
	};

	/**
	 */
	const clearItemFromCart = () => {
		dispatch(CartCntUpdateAsync({ id: props.cart.id, cnt: 0 }));
	};

	/**
	 */
	return (
		<div className="cartItems" style={{ borderColor: color.colors.borderLight }}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div className="cartItemTitle">
					{props.summary && <span style={{ color: rdxBiz.site_settings.base.colors.primary, fontWeight: "600" }}>{props.cart.cnt} x </span>}
					{props.item.name}
				</div>
				{props.summary ? (
					<div className="cartPriceTitle">£{totPrice}</div>
				) : (
					<FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg" onClick={() => clearItemFromCart()} color={color.colors.borderLight} />
				)}
			</div>

			{!props.summary && (
				<div className="cartCrossDiv">
					<FontAwesomeIcon
						className="cartPlus"
						style={{ borderColor: rdxBiz?.site_settings?.base?.colors?.primary }}
						onClick={() => updateItemQuantity("-")}
						icon={faMinus}
						color={rdxBiz?.site_settings?.base?.colors?.primary}
					/>

					<div className="cartItemCount">{props.cart.cnt}</div>

					<FontAwesomeIcon
						className="cartPlus"
						style={{ borderColor: rdxBiz?.site_settings?.base?.colors?.primary }}
						onClick={() => updateItemQuantity("+")}
						icon={faPlus}
						color={rdxBiz?.site_settings?.base?.colors?.primary}
					/>
					<div className="cartPriceTitle">£{totPrice}</div>
				</div>
			)}
		</div>
	);
};

// export class Cart_Item_Basic extends React.PureComponent {
// 	/**
// 	 */
// 	constructor(props) {
// 		super(props);
// 	}

// 	// /**
// 	//  */
// 	// componentDidMount()
// 	// {
// 	//     //
// 	// }

// 	/**
// 	 */
// 	updateItemQuantity = type => {
// 		if ("+" === type) {
// 			++this.props.item._cnt;
// 		} else if ("-" === type) {
// 			if (this.props.item._cnt > 0) --this.props.item._cnt;
// 		} else {
// 			return;
// 		}

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	clearItemFromCart = () => {
// 		this.props.item._cnt = 0;

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	render() {
// 		return (
// 			<div className="cartItems" style={{ borderColor: color.colors.borderLight }}>
// 				<div style={{ display: "flex", flexDirection: "row" }}>
// 					<div className="cartItemTitle">
// 						{this.props.summary && <span style={{ color: this.props.__biz.site_settings.base.colors.primary, fontWeight: "600" }}>{this.props.item._cnt} x </span>}
// 						{this.props.item.name}
// 					</div>
// 					{this.props.summary ? (
// 						<div className="cartPriceTitle">£{(ItemPrice(this.props.item, this.props.__checkout.delivery.deliveryType) * this.props.item._cnt).toFixed(2)}</div>
// 					) : (
// 						<FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg" onClick={() => this.clearItemFromCart()} color={color.colors.borderLight} />
// 					)}
// 				</div>
// 				{!this.props.summary && (
// 					<div className="cartCrossDiv">
// 						<FontAwesomeIcon
// 							className="cartPlus"
// 							style={{ borderColor: this.props.__biz?.site_settings?.base?.colors?.primary }}
// 							onClick={() => this.updateItemQuantity("-")}
// 							icon={faMinus}
// 							color={this.props.__biz?.site_settings?.base?.colors?.primary}
// 						/>

// 						<div className="cartItemCount">{this.props.item._cnt}</div>

// 						<FontAwesomeIcon
// 							className="cartPlus"
// 							style={{ borderColor: this.props.__biz?.site_settings?.base?.colors?.primary }}
// 							onClick={() => this.updateItemQuantity("+")}
// 							icon={faPlus}
// 							color={this.props.__biz?.site_settings?.base?.colors?.primary}
// 						/>
// 						<div className="cartPriceTitle">£{(ItemPrice(this.props.item, this.props.__checkout?.delivery?.deliveryType) * this.props.item._cnt).toFixed(2)}</div>
// 					</div>
// 				)}
// 			</div>
// 		);
// 	} // render
// } // ...

/**
 */
export const Cart_Item_Mod = (props: ICartItemComp) => {
	return null;
};

// export class Cart_Item_Mod extends React.PureComponent {
// 	/**
// 	 */
// 	constructor(props) {
// 		super(props);
// 	}

// 	// /**
// 	//  */
// 	// componentDidMount()
// 	// {
// 	//     //
// 	// }

// 	/**
// 	 */
// 	updateItemQuantity = (type, mod) => {
// 		if ("+" === type) {
// 			++mod._cnt;
// 			++this.props.item._cnt;
// 		} else if ("-" === type) {
// 			--mod._cnt;
// 			--this.props.item._cnt;
// 		} else {
// 			return;
// 		}

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	clearItemFromCart = mod => {
// 		this.props.item._cnt -= mod._cnt;
// 		mod._cnt = 0;

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	render() {
// 		const it_p = ItemPrice(this.props.item, this.props.__checkout.delivery.deliveryType);
// 		const bPrice = it_p ? it_p : 0;

// 		return (
// 			<div className="cartItems" style={{ borderColor: color.colors.borderLight }}>
// 				<div style={{ display: "flex", flexDirection: "row" }}>
// 					<div className="cartItemTitle">
// 						{this.props.item.name}
// 						{/* {bPrice? " (£"+bPrice +")" : null} */}
// 					</div>
// 					{/*{*/}
// 					{/*    bPrice ?*/}
// 					{/*        <div className="itemPrice">£{bPrice}</div>*/}
// 					{/*        : null*/}

// 					{/*}*/}
// 				</div>
// 				<div>
// 					{this.props.item._mods && this.props.item._mods.opts ? (
// 						this.props.item._mods.opts
// 							.map((mod, mod_idx) =>
// 								mod._cnt ? (
// 									<div key={mod_idx} className="cartModDiv">
// 										<div style={{ display: "flex", flexDirection: "row" }}>
// 											<div className="cartModTitle">
// 												{this.props.summary && <span style={{ color: this.props.__biz.site_settings.base.colors.primary, fontWeight: "600" }}>{mod._cnt} x </span>}
// 												{mod.name}
// 											</div>
// 											{this.props.summary ? (
// 												<div className="cartPriceTitle">
// 													£
// 													{(
// 														(ItemPrice(this.props.item, this.props.__checkout.delivery.deliveryType) + ItemPrice(mod, this.props.__checkout.delivery.deliveryType)) *
// 														mod._cnt
// 													).toFixed(2)}
// 												</div>
// 											) : (
// 												<FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg" onClick={() => this.clearItemFromCart(mod)} color={color.colors.borderLight} />
// 											)}
// 										</div>
// 										{!this.props.summary && (
// 											<div className="cartCrossDiv">
// 												<FontAwesomeIcon
// 													className="cartPlus"
// 													style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}
// 													onClick={() => this.updateItemQuantity("-", mod)}
// 													icon={faMinus}
// 													size="xs"
// 													color={this.props.__biz.site_settings.base.colors.primary}
// 												/>
// 												<div className="cartItemCount">{mod._cnt}</div>
// 												<FontAwesomeIcon
// 													className="cartPlus"
// 													style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}
// 													onClick={() => this.updateItemQuantity("+", mod)}
// 													icon={faPlus}
// 													size="xs"
// 													color={this.props.__biz.site_settings.base.colors.primary}
// 												/>
// 												<div className="cartPriceTitle">
// 													£
// 													{(
// 														(ItemPrice(this.props.item, this.props.__checkout.delivery.deliveryType) + ItemPrice(mod, this.props.__checkout.delivery.deliveryType)) *
// 														mod._cnt
// 													).toFixed(2)}
// 												</div>
// 											</div>
// 										)}
// 									</div>
// 								) : null
// 							)
// 							.filter(mod => mod)
// 					) : (
// 						<React.Fragment>Modifier Options are not available.</React.Fragment>
// 					)}
// 				</div>
// 			</div>
// 		);
// 	} // render
// } // ...

/**
 */
export const Cart_Item_ModEx = (props: ICartItemComp) => {
	const dispatch = useDispatch();

	const rdxBiz = useSelector((state: any) => state.__biz);
	const rdxCheckout = useSelector((state: any) => state.__checkout);

	const [totalPrice, TotalPrice] = React.useState("0.00");

	/**
	 */
	React.useEffect(() => {
		const tot_price = GetTotal_ModEx(props.item, props.cart, rdxCheckout.delivery.deliveryType);

		TotalPrice(tot_price.toFixed(2));
	}, [props.item, props.cart]);

	/**
	 */
	const updateItemQuantity = (type: any) => {
		const cart_opt: ICartOpt = { ...props.cart };

		if ("+" === type) {
			++cart_opt.cnt;
		} else if ("-" === type) {
			--cart_opt.cnt;
		} else {
			return;
		}

		// dispatch( CartUpdate(cart_opt) )
		dispatch(CartCntUpdateAsync({ id: cart_opt.id, cnt: cart_opt.cnt }));

		// this.props.Rdx_Order_AddToCart(props.item, props.sect, props.item._cnt);
	};

	/**
	 */
	const clearItemFromCart = () => {
		// props.item._cnt -= optObj._cnt;
		// optObj._cnt = 0;

		dispatch(CartCntUpdateAsync({ id: props.cart.id, cnt: 0 }));

		// this.props.Rdx_Order_AddToCart(props.item, props.sect, props.item._cnt);
	};

	/**
	 */
	return (
		<div className="cartItems" style={{ borderColor: color.colors.borderLight }}>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
					<div className="cartItemTitle">
						{props.summary && <span style={{ color: rdxBiz.site_settings.base.colors.primary, fontWeight: "600" }}>{props.cart.cnt} x </span>}
						{props.item.name}
					</div>
				</div>
				<>
					{props.summary ? (
						<div className="cartPriceTitle">
							{/* £{ONT.GetTotal_ModEx(props.item, optObj, rdxCheckout.delivery.deliveryType).toFixed(2)} */}£{totalPrice}
						</div>
					) : (
						<FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg" onClick={clearItemFromCart} color={color.colors.borderLight} />
					)}
				</>
			</div>

			<>
				{props.cart.mods &&
					props.cart.mods.map((mod: ICartMod, index: number) => (
						<span className="cartModTitle" key={mod.id}>
							{mod.name + (!props.cart.secMods && !props.cart.addons ? "" : ", ")}
						</span>
					))}
			</>

			<>
				{props.cart.secMods &&
					props.cart.secMods.map((secmod: ICartSecMod, index: number) => (
						<span className="cartModTitle" key={secmod.id}>
							{/* {`${secmod.nameOpt} ${secmod.name}` + (props.cart.addons ? ", " : "")} */}
							{`${secmod.nameOpt}` + (props.cart.addons ? ", " : "")}
						</span>
					))}
			</>

			<>
				{props.cart.addons &&
					props.cart.addons.map((addons: ICartAddon, index: number) => (
						<span className="cartModTitle" key={[addons.id, addons.idOpt].join(",")}>
							{/* {(index ? ", " : " ") + addons.name} */}
							{(index ? ", " : " ") + addons.nameOpt}
						</span>
					))}
			</>

			<>
				{!props.summary && (
					<div className="cartCrossDiv">
						<FontAwesomeIcon
							className="cartPlus"
							style={{ borderColor: rdxBiz?.site_settings?.base?.colors?.primary }}
							onClick={() => updateItemQuantity("-")}
							icon={faMinus}
							size="xs"
							color={rdxBiz?.site_settings?.base?.colors?.primary}
						/>
						<div className="cartItemCount">{props.cart.cnt}</div>
						<FontAwesomeIcon
							className="cartPlus"
							style={{ borderColor: rdxBiz?.site_settings?.base?.colors?.primary }}
							onClick={() => updateItemQuantity("+")}
							icon={faPlus}
							size="xs"
							color={rdxBiz?.site_settings?.base?.colors?.primary}
						/>
						<div className="cartPriceTitle">
							{/* £{ONT.GetTotal_ModEx(props.item, optObj, rdxCheckout?.delivery?.deliveryType).toFixed(2)} */}£{totalPrice}
						</div>
					</div>
				)}
			</>
		</div>
	);
};

/**
 */
export const Cart_Item_SetMeal = (props: ICartItemComp) => {
	return null;
};
// export class Cart_Item_SetMeal extends React.PureComponent {
// 	/**
// 	 */
// 	constructor(props) {
// 		super(props);
// 	}

// 	// /**
// 	//  */
// 	// componentDidMount()
// 	// {
// 	//     //
// 	// }

// 	/**
// 	 */
// 	updateItemQuanity = (type, setMealObj) => {
// 		if (type === "+") {
// 			++setMealObj._cnt;
// 			++this.props.item._cnt;
// 		} else {
// 			--setMealObj._cnt;
// 			--this.props.item._cnt;

// 			setMealObj._cnt = setMealObj._cnt > 0 ? setMealObj._cnt : 0;

// 			this.props.item._cnt = this.props.item._cnt > 0 ? this.props.item._cnt : 0;
// 		}

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	clearItemFromCart = setMealObj => {
// 		this.props.item._cnt -= setMealObj._cnt;
// 		setMealObj._cnt = 0;

// 		this.props.Rdx_Order_AddToCart(this.props.item, this.props.sect, this.props.item._cnt);
// 		// this.props.Rdx_Order_UpdateCart( this.props.item, this.props.sect, this.props.item._cnt );
// 		// const _total = ONT.GetTotal(this.props.__order.cart_sects, this.props.__order.type);

// 		// this.props.Rdx_Checkout_SetSubtotal(_total.total);
// 		// this.props.Rdx_Checkout_SetItemCount(_total.item_cnt);
// 	};

// 	/**
// 	 */
// 	render() {
// 		const { item } = this.props;

// 		return Object.keys(item._setm).map(setMealItem => {
// 			const setMealObj = item._setm[setMealItem];

// 			return (
// 				<div className="cartItems" style={{ borderColor: color.colors.borderLight }}>
// 					<div style={{ display: "flex", flexDirection: "row" }}>
// 						<div className="cartItemTitle">
// 							{this.props.summary && <span style={{ color: this.props.__biz.site_settings.base.colors.primary, fontWeight: "600" }}>{setMealObj._cnt} x </span>}
// 							{item.name}
// 						</div>
// 						{this.props.summary ? (
// 							<div className="cartPriceTitle">£{(item.price.def * setMealObj._cnt).toFixed(2)}</div>
// 						) : (
// 							<FontAwesomeIcon className="cartRemove" icon={faTimes} size="lg" color={color.colors.borderLight} onClick={() => this.clearItemFromCart(setMealObj)} />
// 						)}
// 					</div>
// 					{setMealObj.items.map((_item, _idx) => (
// 						<React.Fragment key={_idx}>
// 							<div style={{ display: "flex", flexDirection: "row" }}>
// 								<div className="cartModTitle">
// 									{_item.quantity} x {_item.name}({_item.catName})
// 								</div>
// 							</div>
// 						</React.Fragment>
// 					))}

// 					{!this.props.summary && (
// 						<div className="cartCrossDiv">
// 							<FontAwesomeIcon
// 								className="cartPlus"
// 								style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}
// 								onClick={() => this.updateItemQuanity("-", setMealObj)}
// 								icon={faMinus}
// 								size="xs"
// 								color={this.props.__biz.site_settings.base.colors.primary}
// 							/>
// 							<div className="cartItemCount">{setMealObj._cnt}</div>
// 							<FontAwesomeIcon
// 								className="cartPlus"
// 								style={{ borderColor: this.props.__biz.site_settings.base.colors.primary }}
// 								onClick={() => this.updateItemQuanity("+", setMealObj)}
// 								icon={faPlus}
// 								size="xs"
// 								color={this.props.__biz.site_settings.base.colors.primary}
// 							/>

// 							<div className="cartPriceTitle">£{(item.price.def * setMealObj._cnt).toFixed(2)}</div>
// 						</div>
// 					)}
// 				</div>
// 			);
// 		});
// 	} // render
// } // ...
