/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
	// Container,
	Form,
	Button,
	Alert
} from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import color from "../_common/colors.json";
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "@reach/router";
import { TextInput, Password_SignUp } from "../_common/components";
import { Core } from "../../api";
// import { PickerEx } from "../_common/picker";
// import elml_cfg from '../../_config/elml_cfg'
import elml_biz from "../../_config/elml_biz";
// import countryCode from "./CountryCode";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Auth_SignUp extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			verification: false,
			username: "",
			password: "",
			errMsg: "",
			loading: false,
			verificationView: false,
			cn_code: "+44",
			v_success: false,
			active: false
		};
	}

	/**
	 */
	SignUp = async () => {
		try {
			const aws_core = new Core({});

			const { username, password } = this.state;
			if (!username || !password) throw new Error("Please enter email id and password");

			this.setState({ loading: true });

			const _username = "usr:" + elml_biz.biz_id + ":" + username;

			console.log("Auth_SignUp : SignUp : _username : ", _username);

			const signup_data = await aws_core.SignUp({
				username: _username,
				password,
				email: username
			});

			if (signup_data) {
				//
			}

			this.setState({ loading: false, errMsg: null, v_success: true });

			if (!signup_data.userConfirmed)
				navigate("/verification", {
					state: { username: _username, fromPage: "signup", password }
				});
			else console.warn("SignUp : SignUp : user confirmed.");

			return {};
		} catch (err) {
			console.warn("SignUp: SignUp: err: ", JSON.stringify(err));

			this.setState({ loading: false, errMsg: err.message });

			// return Promise.reject( err )
		}
	};

	username(event) {
		this.setState({ username: event.target.value.toLowerCase() });
	}

	/**
	 */
	render() {
		if (this.state.v_success) {
			return (
				<div>
					<NavHdr signin={true} />
					<div className="authContainer">
						<p style={{ color: "green" }}>Congratulations! You have signed up successfully</p>
						<div className="authLittle">
							<i onClick={() => navigate("signin")} variant="light" className="highlight" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
								Click here
							</i>
							To Sign In.
						</div>
					</div>
				</div>
			);
		}

		return (
			<div>
				<NavHdr signin={true} />
				<div className="signinContainer">
					<div className="authContainer">
						<div className="categoryItemTitle">Sign up with email account </div>
						<Form.Group style={{ flexDirection: "row", display: "flex", marginTop: 24 }}>
							<div style={{ flex: 1 }}>
								<TextInput placeholder="Enter email" type="text" size="lg" val={this.state.username} onChange={val => this.username(val)} className="numberHide" />
							</div>
						</Form.Group>

						<Password_SignUp
							OnChange={val => {
								this.setState({ password: val });
							}}
						/>

						{this.state.errMsg === "User already exists" ? (
							<Button
								onClick={() => navigate("signin")}
								style={{
									background: "rgb(50, 161, 110)",
									display: "flex",
									justifyContent: "center",
									width: "100%",
									marginBottom: 16
								}}
								size="lg"
								variant="info">
								Back to login
							</Button>
						) : null}
						{this.state.errMsg ? (
							<Alert variant="danger" onClose={() => this.setState({ errMsg: "" })} dismissible>
								{this.state.errMsg}
							</Alert>
						) : null}
						<Form.Group>
							<Button
								onClick={this.SignUp}
								style={{
									background: "rgb(50, 161, 110)",
									display: "flex",
									justifyContent: "center",
									width: "100%"
								}}
								size="lg"
								variant="info">
								Continue
							</Button>
						</Form.Group>
						<Form.Group>
							<Form.Group>
								<div className="authLittle">
									By proceeding, you are confirming that you agree to our
									<a href="https://elmelo.com/legal/termsOfServices.html">
										<span className="highlight"> Terms of Use</span>
									</a>{" "}
									and{" "}
									<a href="https://elmelo.com/legal/privacyPolicy.html">
										<span className="highlight"> Privacy Policy</span>
									</a>
								</div>
							</Form.Group>
						</Form.Group>
					</div>
				</div>
			</div>
		);
	}

	/**
	 */
	componentDidMount() {}
} // class Auth_SignUp

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Auth_SignUp);
