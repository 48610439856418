/**
 *	@copyright	Elmelo Ltd
 */

export default ( state = {}, action ) =>
{
    switch( action.type )
    {
    default: return state
    }   // switch
}

