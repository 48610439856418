/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import {
	// Container, Button,
	Modal,
	// Image,
	Alert
} from "react-bootstrap";

import { connect, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";

import NavHdr from "../_common/nav_hdr";
import color from "../_common/colors.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faShoppingCart,
	faListUl,
	faClock,
	faTimes,
	// faPlus, faMinus, faCheckCircle, faCircle,
	faTags
} from "@fortawesome/free-solid-svg-icons";
import "../../css/_common.css";

import { MenuPage_Box as MenuPageBox } from "./_common";
import Item from "./items_2";
import OrderCart from "./cart_2";

import ONT from "./ont";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
const ViewBasket = () => {
	const RdxOrder = useSelector(state => state.__order);
	const rdxCart = useSelector(state => state.__cart);
	const RdxCheckout = useSelector(state => state.__checkout);

	const [basketTotal, BasketTotal] = React.useState(0.0);

	/**
	 */
	React.useEffect(() => {
		const total = ONT.GetTotal(RdxOrder.cart_sects, RdxCheckout.delivery.deliveryType);

		BasketTotal(total.total); // @todo asif vaia which one necessary here?
	}, [RdxCheckout.delivery.deliveryType, RdxOrder]);

	React.useEffect(() => {
		BasketTotal(rdxCart.subTotal.toFixed(2)); // @todo asif vaia which one necessary here?
	}, [rdxCart]);

	/**
	 */
	return (
		<div className="cartTotalDiv">
			View basket
			<span
				style={{
					fontSize: 20,
					marginLeft: 10,
					letterSpacing: 0.5,
					fontWeight: 700
				}}>
				£{basketTotal}
			</span>
		</div>
	);
}; // ViewBasket

/**
 */
class Order_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			height: window.innerHeight - (56 + 200),
			catheight: window.innerHeight - 56,
			showCategory: false,
			showCart: false,
			sHeight: window.innerHeight - 72,
			sWidth: window.innerWidth,
			delopt: "collection",
			navOptions: false,
			errMsg: "",
			bModal: false
		};

		this.refsCreated = false;
		this.subCatRefs = [];

		this.orderType = [
			// { name: "Collection!", val: "collection" },
			// { name: "Delivery", val: "delivery" }
		];
	}

	/**
	 */
	componentDidMount() {
		window.addEventListener("resize", this.updateWidthAndHeight);
		window.addEventListener("scroll", this.changeDelopt);

		// this.getTimes();
	}
	/**
	 */
	componentWillMount() {
		window.removeEventListener("resize", this.updateWidthAndHeight);
	}

	updateWidthAndHeight = () => {
		this.setState({ sWidth: window.innerWidth });
		this.setState({ sHeight: window.innerHeight });
	};

	getOT = async () => {
		const dt = new Date();
		const day = dt.getDay();

		const ot_day = this.props.__biz.ot.days[day].map(x => {
			x.open = x.open.map(val => {
				try {
					val = val.toString();
				} catch (err) {
					console.warn("ot_day : open : err : ", err);
				}

				return val.padStart(2, "0");
			});

			x.close = x.close.map(val => {
				try {
					val = val.toString();
				} catch (err) {
					console.warn("ot_day : close : err : ", err);
				}

				return val.padStart(2, "0");
			});

			const open_n = parseInt(x.open.join(""), 10);
			const close_n = parseInt(x.close.join(""), 10);

			return { ...x, open_n, close_n };
		});

		const hours = dt.getHours().toString().padStart(2, "0");
		const mins = dt.getMinutes().toString().padStart(2, "0");
		const secs = dt.getSeconds().toString().padStart(2, "0");
		const t_in_number = parseInt([hours, mins, secs].join(""), 10);
		const ot_cur = ot_day.find(x => t_in_number >= x.open_n && t_in_number < x.close_n);

		if (ot_cur) {
			// const ot_str = [ot_cur.open[0], ot_cur.open[1]].join(":") + " - " + [ot_cur.close[0], ot_cur.close[1]].join(":");
			return { open: true, msg: "" };
		}

		let ot_day_sorted = [...ot_day];

		ot_day_sorted.sort((a, b) => a.open_n - b.open_n);
		ot_day_sorted = ot_day_sorted.map(x => {
			x._diff = t_in_number - x.open_n;

			return x;
		});

		ot_day_sorted = ot_day_sorted.filter(x => x._diff <= 0);

		if (ot_day_sorted.length) {
			ot_day_sorted.sort((a, b) => b._diff - a._diff);
			const ot_next = ot_day_sorted[0];
			const ot_next_str = [ot_next.open[0], ot_next.open[1]].join(":") + " - " + [ot_next.close[0], ot_next.close[1]].join(":");

			return { open: false, msg: "We are closed right now. Will start service from " + ot_next_str };
		} else {
			return { open: false, msg: "We are closed for today." };
		}
	};

	getTimes = async () => {
		// console.log( "beta_id : ", this.props.__core.betaId );
		// if (this.props.__cfg.stage === "test") {
		//     navigate("checkout");
		//     return
		// }
		console.log("etnerd");
		const ifOpen = await this.getOT();

		if (this.props.__biz.suspend.alpha === "true") {
			this.setState({ errMsg: this.props.__biz.suspend.alpha_msg, bModal: true });
		} else if (this.props.__biz.suspend.beta === "true") {
			this.setState({ errMsg: this.props.__biz.suspend.alpha_msg, bModal: true });
		} else if (!ifOpen.open) {
			this.setState({ errMsg: ifOpen.msg, bModal: true });
			// alert(ifOpen.msg);
		}
	};

	changeDelopt = () => {
		// if (this.state.sWidth <= 992 && window.scrollY >= 80) {
		// 	this.setState({ navOptions: true });
		// }
		if (this.state.sWidth <= 992) {
			this.setState({ navOptions: true });
		} else {
			this.setState({ navOptions: false });
		}
	};
	delSelect = val => {
		this.props.Rdx_Checkout_delivery({ deliveryType: val });

		if (val.toLowerCase() === "collection") {
			this.props.Rdx_Checkout_delivery({ deliveryAddress: "" });
			this.props.setDeliveryCharge(0.0);
		}
	};

	setDeliveryType = () => {
		if (this.props.__biz?.settings?.services?.collection) this.orderType.push({ name: "Collection", val: "collection" });
		if (this.props.__biz?.settings?.services?.delivery) this.orderType.push({ name: "Delivery", val: "delivery" });

		if (this.orderType.length === 1) this.delSelect(this.orderType[0]?.val);
	};

	/**
	 */
	showCategory = val => {
		this.setState({ showCategory: val });
		val ? document.body.classList.add("bodyOf") : document.body.classList.remove("bodyOf");
	};

	/**
	 */
	showCart = val => {
		this.setState({ showCart: val });
		val ? document.body.classList.add("bodyOf") : document.body.classList.remove("bodyOf");
	};

	/**
	 */
	scrollToSubcat = (subcatID, offset) => {
		window.scrollTo({
			behavior: "smooth",
			top: this.subCatRefs[subcatID].current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset
		});
	};

	/**
	 */
	render() {
		if (this.props.__core.initStatus === "inprogress") {
			return (
				<div>
					<NavHdr order={true} />
					<div className="centerScreen" style={{ height: this.state.sHeight }}>
						<div className="rainbow">
							Online ordering by <span style={{ color: "#22c55e", fontSize: 24, fontWeight: 700, fontFamily: "Open Sans", marginLeft: 8 }}>QuickFood</span>
						</div>
					</div>
				</div>
			);
		}

		if (!this.refsCreated && this.props.__core.menu.menu) {
			this.subCatRefs = this.props.__core.menu.menu.reduce((acc, value) => {
				acc[[value.cat_id, value.sub_cat_id].join(":")] = React.createRef();
				return acc;
			}, {});

			this.refsCreated = true;
		}

		if (this.orderType.length === 0) this.setDeliveryType();

		return (
			<div style={{ paddingTop: 0 }}>
				<NavHdr order={true} />
				{this.state.bModal && (
					<Modal centered size="sm" aria-labelledby="contained-modal-title-vcenter" show={true} onHide={() => this.setState({ bModal: false })} animation={true}>
						<div style={{ flex: 1 }}>
							<div onClick={() => this.setState({ bModal: false })}>
								<div style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.5)" }}></div>
							</div>
							<div style={{ height: "80%", backgroundColor: "#fff", padding: 16 }}>
								<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
									<FontAwesomeIcon onClick={() => this.setState({ bModal: false })} style={{ cursor: "pointer" }} icon={faTimes} size="lg" color="#ef5350" />
								</div>
								<div style={{ padding: 20, textAlign: "center" }}>
									<FontAwesomeIcon icon={faClock} size="5x" color={"#5a5a5a"} />
								</div>
								<Alert variant="danger" style={{ textAlign: "center" }}>
									{this.state.errMsg}
								</Alert>
							</div>
						</div>
					</Modal>
				)}
				<div className="orderWrapper">
					<div className="orderContainer" style={{ backgroundColor: this.props.__biz.site_settings.base.colors.secondary }}>
						{/*left categories*/}
						{this.state.showCategory ? (
							<div className="overlayModal" onClick={() => this.showCategory(false)}>
								<div className="phoneCat">
									<div className="commonFlex phoneCatHead" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
										<div>Category</div>
										<div className="catClose" onClick={() => this.showCategory(false)}>
											<FontAwesomeIcon icon={faTimes} color="red" size="lg" />
										</div>
									</div>
									<div style={{ marginTop: 46, paddingLeft: 16, paddingRight: 16 }}>
										{this.props.__core.menu && this.props.__core.menu.menu
											? this.props.__core.menu.menu.map((sect, sect_idx) => (
													<MenuPageBox
														className="categoryDiv"
														style={{ borderColor: color.colors.light }}
														key={[sect.cat_id, sect.sub_cat_id].join(":")}
														onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"), 70)}
														title={sect.title}
													/>
											  ))
											: null}
									</div>
								</div>
							</div>
						) : (
							<div className="categoryContainer" style={{ height: this.state.catheight }}>
								{this.props.__core.menu && this.props.__core.menu.menu
									? this.props.__core.menu.menu.map((sect, sect_idx) => (
											<MenuPageBox
												className="categoryDiv"
												style={{ borderColor: color.colors.light }}
												key={[sect.cat_id, sect.sub_cat_id].join(":")}
												onPress={() => this.scrollToSubcat([sect.cat_id, sect.sub_cat_id].join(":"), 160)}
												title={sect.title}
											/>
									  ))
									: null}
							</div>
						)}

						{/*middle menu items*/}
						<div className="csItemRightAfter">
							<div className="itemContainer" style={{ backgroundColor: this.props.__biz.site_settings.base.colors.secondary }}>
								{/*select delivery or collection*/}
								<div style={{ position: "relative" }}>
									<div className={this.state.sWidth <= 992 ? "topFixedTypeNull" : "topFixedType"}>
										{/* <div className='itemName' style={{width:140, marginTop:2,}}>Order Type</div> */}
										<div className="topFixedInside">
											{this.orderType.map((data, idx) => (
												<div key={idx} onClick={() => this.delSelect(data.val)} style={{ flex: 1 }}>
													{this.props.__checkout.delivery.deliveryType === data.val ? (
														this.orderType.length === 1 ? (
															<div style={{ display: "flex", background: "#9fa5aa", borderRadius: 40 }}>
																<div
																	className="p10"
																	style={{
																		color: "#fff",
																		fontWeight: 600,
																		cursor: "pointer",
																		border: "1px solid",
																		borderColor: this.props.__biz.site_settings.base.colors.primary,
																		background: this.props.__biz.site_settings.base.colors.primary,
																		padding: 8,
																		borderRadius: 40,
																		textAlign: "center",
																		flex: 1,
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center"
																	}}>
																	{this.props.__checkout.delivery.deliveryType.charAt(0).toUpperCase() + this.props.__checkout.delivery.deliveryType.slice(1)}
																</div>
																<div
																	className="modItemTextCenter"
																	style={{
																		color: "#fff",
																		cursor: "disabled",
																		padding: 8,
																		borderRadius: 40,
																		textAlign: "center",
																		flex: 1,
																		fontSize: 10
																	}}>
																	Delivery <br />
																	Unavailable
																</div>
															</div>
														) : (
															<div
																className="modItemTextCenter"
																style={{
																	color: "#fff",
																	fontWeight: 600,
																	cursor: "pointer",
																	border: "1px solid",
																	borderColor: this.props.__biz.site_settings.base.colors.primary,
																	background: this.props.__biz.site_settings.base.colors.primary,
																	padding: 8,
																	borderRadius: 40,
																	textAlign: "center"
																}}>
																{data.name}
															</div>
														)
													) : (
														<div
															className="modItemTextCenter"
															style={{
																color: color.colors.light,
																cursor: "pointer",
																padding: 8,
																borderRadius: 40,
																textAlign: "center"
															}}>
															{data.name}
														</div>
													)}
												</div>
											))}
										</div>
									</div>

									<>
										{this.props.__biz?.site_settings?.itempage?.banner && (
											<div className="pcdiv">
												<div className="banner">
													{this.props.__biz.site_settings.itempage.banner.map((data, key) => (
														<div key={data.text}>
															<span>
																<FontAwesomeIcon icon={faTags} color="#fff" size="sm" />{" "}
															</span>
															{data.text}
														</div>
													))}
													{/* <span>
														<FontAwesomeIcon icon={faTags} color="#fff" size="sm" />{" "}
													</span>
													<b>{this.props.__biz?.discount?.f_order?.web.discount[0]}% </b> <span> </span> off your 1st order. Applies automatically on checkout.
													{this.props.__biz?.discount?.f_order?.web.min_total[0] > 0 ? " Minimum Spend £" + this.props.__biz?.discount?.f_order?.web.min_total[0] + "." : null} */}
												</div>
											</div>
										)}
									</>
								</div>

								{/*vouchers*/}
								{/* <div style={{ paddingTop: 70 }}>
										<div className='categoryItemTitle'>
												Vouchers/Offers
										</div>
										<div className="singleItem"
										>
												<div className="commonFlex">
														<div style={{ flex: 1 }}>
																<div className="commonFlex">
																		<div className="itemName">
																				40% off Pizza, Sides, Chicken + Cookies when you spend £40 or more
																		</div>
																</div>
																<div className="itemDescription">
																		This voucher will be visible in the basket when voucher requirements are met
																</div>

														</div>

														<div className="menuRight">
																<div className="imageContainer">
																		<Image src={require('../assets/img/placeholder/skewer.jpg')} className="imageStyle" />
																</div>
														</div>
														<div className="addtoVoucher " style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
																<span style={{ marginLeft: 6, fontWeight: 700 }}>Voucher applied</span>
														</div>
												</div>
										</div>
								</div> */}

								<>
									{this.props.__core.menu && this.props.__core.menu.menu
										? this.props.__core.menu.menu.map((sect, sect_idx) => (
												// <h4 style={{color: this.props.__biz.site_settings.base.colors.primary, marginBottom: 20}}>{sect.title}</h4>
												<div key={[sect.cat_id, sect.sub_cat_id].join(":")} ref={this.subCatRefs[[sect.cat_id, sect.sub_cat_id].join(":")]}>
													<MenuPageBox
														style={{ borderColor: color.colors.black, paddingTop: 30 }}
														id={sect.sub_cat_id}
														title={sect.title}
														desc={sect.desc}
														key={sect.sub_cat_id}
														item={sect.data.map((item, item_idx) =>
															"t" === (item.vis && item.vis.online ? item.vis.online : item.vis && item.vis.def ? item.vis.def : null) ? (
																"t" === (item.avail && item.avail.online ? item.avail.online : item.avail && item.avail.def ? item.avail.def : null) ? (
																	<div key={item._id}>
																		<Item
																			colors={this.props.__biz.site_settings.base.colors}
																			item={item}
																			item_idx={item_idx}
																			sect={sect}
																			//   addToCart={this.addToCart}
																		/>
																	</div>
																) : null
															) : null
														)}
													/>
												</div>
										  ))
										: null}
								</>

								<div className="footer3">
									Online ordering by{" "}
									<a style={{ color: "#22c55e", fontSize: 24, fontWeight: 700, fontFamily: "Open Sans" }} href="https://quickfood.io/">
										QuickFood
									</a>
								</div>

								{/*phone cart and category */}
								<div onClick={() => this.showCart(true)} className="cartMobile" style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
									{/* <div className="cartTotalDiv">
										View basket
										<span
											style={{
												fontSize: 20,
												marginLeft: 10,
												letterSpacing: 0.5,
												fontWeight: 700
											}}>
											£{this.props.__checkout.charges.subtotal.amount.toFixed(2)}
										</span>
									</div> */}
									<ViewBasket />
									<div className="cartIcon">
										<FontAwesomeIcon icon={faShoppingCart} size="lg" color={this.props.__biz.site_settings.base.colors.primary} />
										<div className="cartCount" style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
											{this.props.__checkout.cntItem}
										</div>
									</div>
								</div>

								<div onClick={() => this.showCategory(true)} className="categoryFloat" style={{ background: this.props.__biz.site_settings.base.colors.primary }}>
									<FontAwesomeIcon style={{ marginRight: 5 }} icon={faListUl} size="xs" color="#fff" />
									<div>Category</div>
								</div>
							</div>
						</div>
						<div style={{ right: this.state.sWidth > 1999 ? this.state.sWidth * 0.15 : 0 }} className={this.state.showCart ? "mobileCart" : "cartContainer"}>
							<OrderCart delopt={this.state.delopt} showCart={this.showCart} mCart={this.state.showCart} />
						</div>
					</div>
				</div>
			</div>
		);
	}
} // class Order_Home

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Order_Home);
