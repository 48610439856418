/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import color from "../_common/colors.json";
import "../../css/_common.css";
import NavHdr from "../_common/nav_hdr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTags } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "@reach/router";
import { Core, DDB } from "../../api";
import { TextInput } from "../_common/components";
import { PickerEx } from "../_common/picker";
// import elml_cfg from '../../_config/elml_cfg'
import elml_biz from "../../_config/elml_biz";
import countryCode from "./CountryCode";
import ChatIoT from "../../api/chat/chat_iot";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Auth_SignIn extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		return (
			<div>
				<NavHdr signin={true} />
				<SignIn_View {...this.props} />
			</div>
		);
	}

	/**
	 */
	componentDidMount() {}
} // class Auth_SignIn

class SignIn_View extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			forgotPassword: false,
			registerView: false,
			username: "",
			password: "",
			signinButton: true,
			cn_code: "+44",
			bHide_PW: true
		};

		this._iot = null;
	}

	/**
	 */
	SignIn = async () => {
		const { username, password } = this.state;
		const _username = "usr:" + elml_biz.biz_id + ":" + username;

		console.log("SignIn : SignIn_View : SignIn : _username : ", _username);

		try {
			const aws_core = new Core({});

			this.setState({ signinButton: false });

			if (!username || !password) throw new Error("Please enter email and password");

			const user = await aws_core.SignIn({ username: _username, password });

			if (!user) {
				console.warn("SignIn_View: SignIn: user is null");
				this.setState({
					errMsg: "Oops, something went wrong",
					signinButton: true
				});
				return;
			}

			this.props.Rdx_Core_AuthStatus("auth");

			this.props.Rdx_BetaId(await aws_core.Id());

			this.setState({ signinButton: true });

			if (this.props.location.state.fromPage) {
				navigate(this.props.location.state.fromPage);
			} else {
				navigate("order");
			}

			// await this.saveFCMToken();

			return {};
		} catch (err) {
			console.warn("SignIn_View: SignIn: err: ", err);

			this.setState({ errMsg: err.message, signinButton: true });

			if (err.message === "User is not confirmed.") {
				navigate("verification", {
					state: { username: _username, bSend_Verification: true, password }
				});
			}

			return { err };
		}
	};

	username(event) {
		this.setState({ username: event.target.value.toLowerCase() });
	}

	password(event) {
		this.setState({ password: event.target.value });
	}

	render() {
		return (
			<div className="screenWrapper">
				{this.props.__biz?.site_settings?.signinpage?.banner && (
					<div style={{ padding: 16, paddingBottom: 0 }}>
						<div className="banner">
							{
								<div>
									<span>
										<FontAwesomeIcon icon={faTags} color="#fff" size="sm" />{" "}
									</span>
									{this.props.__biz?.site_settings?.signinpage?.banner.text}
								</div>
							}
						</div>
					</div>
				)}
				<div className="infoSection">
					<div style={{ flex: 1 }}>
						<div className="infoContainer" style={{ flexDirection: "column" }}>
							<div className="categoryItemTitle">Customer login</div>
							<Form.Group style={{ flexDirection: "row", display: "flex", marginTop: 24 }}>
								<div style={{ flex: 1 }}>
									<label className="itemName">Email Address*</label>
									<TextInput placeholder="Enter email" type="text" size="lg" val={this.state.username} onChange={val => this.username(val)} className="numberHide" />
								</div>
							</Form.Group>
							<Form.Group>
								<label className="itemName">Password*</label>
								<div style={{ display: "flex", position: "relative" }}>
									<TextInput
										placeholder="Enter password"
										size="lg"
										type={!this.state.bHide_PW ? "text" : "password"}
										secureTextEntry={this.state.bHide_PW}
										val={this.state.password}
										onChange={val => this.password(val)}
									/>
									<div className="hideShow">
										<div onClick={() => this.setState({ bHide_PW: !this.state.bHide_PW })}>{!this.state.bHide_PW ? "HIDE" : "SHOW"}</div>
									</div>
								</div>
							</Form.Group>
							{this.state.errMsg ? (
								<Alert variant="danger" onClose={() => this.setState({ errMsg: "" })} dismissible>
									{this.state.errMsg}
								</Alert>
							) : null}
							<div className="authLittle" style={{ marginBottom: 24 }}>
								<i onClick={() => navigate("forgotten")} variant="light" className="highlight" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
									Forgotten your Password?
								</i>
							</div>
							<Form.Group>
								{!this.state.signinButton ? (
									<div
										style={{
											backgroundColor: "#ddd",
											display: "flex",
											justifyContent: "center",
											width: "100%",
											padding: 12,
											color: this.props.__biz.site_settings.base.colors.primary
										}}>
										Processing
										<span style={{ marginLeft: 8 }}>
											<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="primary" />
											<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="secondary" />
											<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" variant="success" />
										</span>
									</div>
								) : this.state.username === "" || this.state.password.length < 8 ? (
									<Button
										// onClick={() => this.SignIn()}
										style={{
											background: "#aaa",
											display: "flex",
											justifyContent: "center",
											width: "100%"
										}}
										size="lg"
										variant="info">
										Sign In
									</Button>
								) : (
									<Button
										onClick={() => this.SignIn()}
										style={{
											background: "rgb(50, 161, 110)",
											display: "flex",
											justifyContent: "center",
											width: "100%"
										}}
										size="lg"
										variant="info">
										Sign In
									</Button>
								)}
							</Form.Group>
						</div>
					</div>

					<div style={{ flex: 1 }}>
						<div className="infoContainer" style={{ flexDirection: "column" }}>
							<div className="categoryItemTitle">New to {this.props.__biz.biz_name ? this.props.__biz.biz_name : "Restaurant"} ? </div>
							{/* <div style={{paddingTop:16, paddingBottom:16}}>Create account to order.</div> */}
							<Form.Group>
								<Button
									onClick={() => navigate("signup")}
									style={{
										background: this.props.__biz.site_settings.base.colors.primary,
										display: "flex",
										justifyContent: "center",
										width: "100%",
										marginTop: 16
									}}
									size="lg"
									variant="info">
									Create account
								</Button>
							</Form.Group>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class Verification extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div>
				<div className="authTitle">Verification Process</div>
				<div className="authContainer">
					<div onClick={() => this.props.showVerification(false)} className="buttonAuthDiv">
						<FontAwesomeIcon className="buttonAuth" icon={faTimes} size="lg" color={"red"} />
					</div>
					<Form.Group>
						<div className="authLittle">
							We will send an <i className="highlight">Elmelo</i> code to your mobile. That code will be used to verify your mobile number and you will be able to enjoy services of
							Elmelo.
						</div>
					</Form.Group>
					<Form.Group>
						<Form.Control size="lg" type="number" placeholder="Enter Verification code" />
					</Form.Group>
					<Form.Group>
						<Button
							style={{
								backgroundColor: this.props.__biz.site_settings.base.colors.primary,
								display: "flex",
								justifyContent: "center"
							}}
							size="lg"
							href="#reservation"
							variant="info">
							Submit Code
						</Button>
					</Form.Group>
				</div>
			</div>
		);
	}
}
/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Auth_SignIn);
