/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Button, ButtonGroup,
    Image,
    Row,
    Col,
    Card,
    Modal,
    Carousel,
    Container,
}   from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import color from '../_common/colors.json'
import {Offer} from './_common'

/**
 */
class Info_Offer extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)
    }

    /**
     */
    render()
    {
        // // console.log( 'Info_Offer : render : props : ', this.props )

        if( !this.props.__biz.offers )
            return null

        return (
            <div className="offerContainer">
                {this.props.__biz.offersFeatured.length > 0 ?
                    <div>
                        <div>
                            <div className="headerTitle">Featured Offer</div>
                        </div>
                        <div>
                            {
                                this.props.__biz.offersFeatured && this.props.__biz.offersFeatured.map(id => {
                                    const x = this.props.__biz.offers.find(x => x._id === id)

                                    // // console.log( 'Info_Offer: render: x: ', x )

                                    if (!x)
                                        return null

                                    return (
                                        <Row key={x._id}>
                                            <Offer
                                                img={x.url}
                                                title={x.title}
                                                subTitle={x.subtitle}
                                                description={x.desc}
                                                price={x.price}
                                            />
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    null
                }

                <div>
                    <div className="headerTitle">Current Offers</div>
                </div>
                {
                    this.props.__biz.offers.length > 0 ?
                        <Row>
                            {
                                this.props.__biz.offers.map( x => (
                                    <Offer
                                        key={x._id}
                                        img={ x.url }
                                        title={ x.title }
                                        subTitle={ x.subtitle }
                                        description={ x.desc }
                                        price={ x.price }
                                    />
                                ) )
                            }
                        </Row> :
                        <div className="imageText">
                            <Image
                                className="orderCompleteImage"
                                style={{height:250,width:"auto"}}
                                src={require('../assets/img/placeholder/noOffer.png')}
                                rounded
                            />
                            <div className="alertMessage">
                                No Offers Available Now
                            </div>
                        </div>
                }

            </div>
        )
    }   // render

    // /**
    //  */
    // componentDidMount()
    // {
    // }
}   // Info_Offer

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(Info_Offer)





