/**
 * @copyright Elmelo Ltd.
 */

// import { type } from 'os'
import { ICoreId } from "./core";

// export interface IMenuId
// {
//   id: string,
//   name: string,
// }

export enum EItemType {
	Basic = "basic",
	Mod = "mod",
	Modex = "modex",
	Setmeal = "setmeal"
} //

// export interface IOrderType = 'delivery' | ''
export enum EOrderTtype {
	Delivery = "delivery",
	Collection = "collection",
	Dinein = "dinein"
} // EOrderTtype

export interface IMenuCat extends ICoreId {
	parent?: IMenuCat;
} // IMenuCat

export interface IMenuSubcat extends ICoreId {
	// parent: IMenuCat,
} // IMenuSubcat

export interface IMenuItem extends ICoreId {
	// cat: IMenuCat,
	// subcat: IMenuSubcat,

	type: EItemType;
} // IMenuItem
export interface IMenuItemPrice extends ICoreId {
	coll: number | string | null;
	def: number | string | null;
	del: number | string | null;
	in: number | string | null;
} // IMenuItem

export class MenuCat implements IMenuCat {
	id: string;
	name?: string | undefined;

	constructor(props: ICoreId) {
		this.id = props.id;
		this.name = props.name;
	}
} // MenuCatId

export class MenuSubcat implements IMenuSubcat {
	id: string;
	name?: string | undefined;

	constructor(props: ICoreId) {
		this.id = props.id;
		this.name = props.name;
	}
} // MenuSubcatId

export class MenuItem implements IMenuItem {
	id: string;
	name?: string | undefined;
	type: EItemType;

	constructor(props: IMenuItem) {
		this.id = props.id;
		this.name = props.name;
		this.type = props.type;
	}
} // MenuItemId
