/**
 * @copyright Elmelo Ltd.
 */
import React from "react";
import {
	Button,
	ButtonGroup,
	Image,
	Carousel,
	Modal
	// Container,
} from "react-bootstrap";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import NavHdr from "../_common/nav_hdr";
import Info_Gallery from "./gallery";
import Loader from "react-loader-spinner";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Gallery_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
		// console.log("gallery_home props", props)
		this.state = {
			bModal: false,
			idxModal: 0,
			imgModal: null,
			height: window.innerHeight - 72,
			width: window.innerWidth
		};
	}

	/**
	 */
	render() {
		// if( !this.props.__biz.imagesGallery )
		//     return null

		return (
			<div>
				<NavHdr gallery={true} />
				{this.props.__biz.initStatus === "inprogress" ? (
					<div className="centerScreen" style={{ height: this.state.height }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={this.state.height / 3} width={this.state.width / 3} timeout={30000000} />
					</div>
				) : null}
				<Info_Gallery {...this.props} />

				{/*<div>*/}
				{/*{*/}
				{/*this.props.__biz.imagesGallery.map( (x, i) => (*/}
				{/*<div className="col-sm-4"*/}
				{/*onClick={() => {*/}
				{/*// console.log( 'gallery: image: i: ', i )*/}
				{/*this.setState( {bModal: true, idxModal: i, imgModal: x} )*/}
				{/*}}*/}
				{/*>*/}
				{/*<img src={x.url} />*/}
				{/*{// console.log("image urls",x.url)}*/}
				{/*</div>*/}
				{/*) )*/}
				{/*}*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*{*/}
				{/*this.state.bModal*/}
				{/*?   <Modal show={this.state.bModal} onHide={()=>this.setState({bModal: false, idxModal: 0, imgModal: null})}>*/}
				{/*<Modal.Body>*/}
				{/*<img src={this.state.imgModal.url} />*/}
				{/*</Modal.Body>*/}
				{/*<Modal.Footer>*/}
				{/*{*/}
				{/*this.state.imgModal.info && this.state.imgModal.info.title*/}
				{/*?   <h3>{this.state.imgModal.info.title}</h3>*/}
				{/*:   null*/}
				{/*}*/}
				{/*{*/}
				{/*this.state.imgModal.info && this.state.imgModal.info.desc*/}
				{/*?   <a>{this.state.imgModal.info.desc}</a>*/}
				{/*:   null*/}
				{/*}*/}
				{/*</Modal.Footer>*/}
				{/*</Modal>*/}

				{/*:   null*/}
				{/*}*/}
				{/*</div>*/}
			</div>
		);
	} // render

	/**
	 */
	componentDidMount() {}
} // class Gallery_Home

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Gallery_Home);
