/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Container, Button, Form, Image, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentLoader from "react-content-loader";

import {
	faPlusSquare,
	faTrashAlt,
	faCheck,
	faCircle,
	faAlignJustify,
	faTimes,
	faTruckMoving,
	faShoppingBag,
	faMoneyBill,
	faArrowDown,
	faCreditCard,
	faPlus,
	faMinus,
	faTicketAlt,
	faDotCircle
} from "@fortawesome/free-solid-svg-icons";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

// import { Lambda, Utils } from "../../api";
// import elml_cfg from '../../_config/elml_cfg'

import color from "../_common/colors.json";
import "../../css/_common.css";

import AddNewCardModal from "../profile/addNewCard";
// import { Profile } from "../profile/profile";

import * as dotenv from "dotenv";
dotenv.config();

//
const Checkout_Payment = props => {
	const dispatch = useDispatch();
	const RdxBiz = useSelector(state => state.__biz);
	const RdxCheckout = useSelector(state => state.__checkout);

	const [isLoading, IsLoading] = React.useState(false);
	const [isSave, IsSave] = React.useState(false);
	const [isAddNewCard, IsAddNewCard] = React.useState(false);
	const [cardList, CardList] = React.useState([]);
	const [errMsg, ErrMsg] = React.useState("");

	/**
	 */
	React.useEffect(() => {
		LoadCardList().catch(console.warn);
	}, []);

	/**
	 */
	const LoadCardList = async () => {
		try {
			IsLoading(true);

			const card_list = await dispatch(actions.Rdx_GetCardList());

			CardList(card_list);

			if (card_list.length) {
				dispatch(actions.Rdx_Checkout_Payment({ creditInfo: card_list[0] }));
			}

			IsLoading(false);

			return {};
		} catch (err) {
			console.warn("cardList: err: ", err);
			IsLoading(false);
			return Promise.reject(err);
		}
	}; // LoadCardList

	/**
	 */
	const ShowInvoice = () => {
		if (!RdxCheckout.payment.paymentType) {
			ErrMsg("Please select payment type");
			return;
		}

		if (RdxCheckout.payment.paymentType === "card" && !RdxCheckout.payment.creditInfo) {
			ErrMsg("Please select a card and press next");
			return;
		}

		IsSave(true);
		props.showPayment(false);
	};

	/**
	 */
	return (
		<div>
			{isSave || isLoading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						paddingTop: 80
					}}>
					<div className="rainbow">
						Online ordering by{" "}
						<span
							style={{
								color: "#22c55e",
								fontSize: 24,
								fontWeight: 700,
								fontFamily: "Open Sans",
								marginLeft: 8
							}}>
							QuickFood
						</span>
					</div>
				</div>
			) : (
				<div className="orderProcessBody">
					<div>
						<div>
							{errMsg && (
								<Alert variant="danger" onClose={() => ErrMsg("")} dismissible>
									{errMsg}
								</Alert>
							)}
						</div>

						<div>
							{isAddNewCard && (
								<AddNewCardModal
									{...props}
									hideModal={() => {
										IsAddNewCard(false);
										LoadCardList();
									}}
									LoadCardList={LoadCardList}
								/>
							)}
						</div>

						{RdxCheckout.payment.paymentType === "card" ? (
							<div>
								<div className="processDetailsDiv">
									<div className="itemName">Select Card</div>
									<div className="processDetailsButton" style={{ background: RdxBiz.site_settings.base.colors.primary }} onClick={() => IsAddNewCard(true)}>
										Add card
									</div>
								</div>

								{cardList.map((x, idx) => {
									const cr_info = RdxCheckout.payment.creditInfo;

									return (
										<div key={idx}>
											<div onClick={() => dispatch(actions.Rdx_Checkout_Payment({ creditInfo: x }))}>
												{
													<div
														className="pickerItem"
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center"
														}}>
														{cr_info &&
														cr_info.card &&
														cr_info.card.last4 === x.card.last4 &&
														cr_info.card.exp_month === x.card.exp_month &&
														cr_info.card.exp_year === x.card.exp_year &&
														cr_info.card.brand === x.card.brand ? (
															<FontAwesomeIcon style={{ marginRight: 8 }} size="lg" icon={faCheck} color="green" />
														) : (
															<FontAwesomeIcon style={{ marginRight: 8 }} size="lg" icon={faCircle} color={"#C4C4C4"} />
														)}

														<div
															style={{
																color: color.colors.light,
																marginLeft: 8
															}}>
															{x.card.brand} - Ends with {x.card.last4}
														</div>
														<div style={{ marginLeft: "auto" }}>
															{x.card.exp_month}/{x.card.exp_year}
														</div>
													</div>
												}
											</div>
										</div>
									);
								})}
							</div>
						) : null}

						{cardList.length ? (
							<div style={{ display: "flex" }}>
								<div className="processButtons">
									<Button className="confirmButton" style={{ background: RdxBiz.site_settings.base.colors.primary }} onClick={() => props.showDelivery(true, true)}>
										Back
									</Button>
								</div>
								<div className="processButtons" style={{ flex: 1 }}>
									{RdxCheckout.payment.paymentType ? (
										<Button className="confirmButton" style={{ background: RdxBiz.site_settings.base.colors.primary }} onClick={() => ShowInvoice()}>
											Next
										</Button>
									) : (
										<Button disabled className="confirmButton" style={{ backgroundColor: "#ddd" }}>
											Next
										</Button>
									)}
								</div>
							</div>
						) : (
							<div>
								<Alert variant="danger">
									<Alert.Link onClick={() => IsAddNewCard(true)}> No saved card found. Add card </Alert.Link>
								</Alert>
								<div style={{ display: "flex" }}>
									<div className="processButtons">
										<Button style={{ background: RdxBiz.site_settings.base.colors.primary }} className="confirmButton" onClick={() => props.showDelivery(true, true)}>
											Back
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}; // Checkout_Payment

export default Checkout_Payment;
