/**
 *	@copyright	Elmelo Ltd
 */

/**
 */
export default ( state = {bInit: false, status: 'new',
		sb: null,
		elml: null,
		// nUnRead: {}, msgLast: {}
		totUnRead: {},
	 	totUnRead_ReqCnt: 0,
	 	totUnRead_ReqStatus: 'stale',
	 	chat_channel: null,
		user_id: null
	},
	action ) =>
{
	switch( action.type )
	{
	case 'chat:init_inprogress':
		return {...state, bInit: false, status: 'init_inprogress'}

	case 'chat:init_complete':
		return {...state, bInit: true, status: 'init_complete'}

	case 'chat:elml':
		return { ...state, elml: action.payload }

	case 'chat:tot_unread_upd':
		return { ...state, totUnRead: action.payload }

	case 'chat:tot_unread_req_cnt':
		return { ...state, totUnRead_ReqCnt: action.payload }

	case 'chat:tot_unread_req_status':
		return { ...state, totUnRead_ReqStatus: action.payload }

	case 'chat:channel':
		return { ...state, chat_channel: action.payload }
	case 'chat:user-id':
		return { ...state, user_id: action.payload }



	default:
		return state;
	}	// switch action.type
}	// ...



