/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import InfoHome from './home'

import P404 from '../_common/404'

/**
 */
class App_Info extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <InfoHome path='home'/>

                <P404 default />
            </Router>
            )
    }   // render
}   // class App_Info

/**
 */
export {App_Info, InfoHome}
