/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Modal, Button, Image } from "react-bootstrap";

// import {connect} from 'react-redux'
// import * as actions from '../../rdx/actions'

// import NavHdr from '../_common/nav_hdr'
import color from "../_common/colors.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMinus,
	faPlus,
	faPlusCircle,
	faTimes
	// faTimes,faTrashAlt,faCheck
} from "@fortawesome/free-solid-svg-icons";

import "../../css/_common.css";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
export const CheckVisAvail = (vis, delivery_type) => {
	// console.log( 'vis: ', vis )
	// console.log( 'delivery_type: ', delivery_type )

	// if vis object is not provided or empty, we consider it to be visible by default
	if (!vis || !Object.keys(vis).length) {
		return true
	}

	if ("collection" === delivery_type) {
		if ('t' === vis.coll) {
			return true
		}
		else if ('f' === vis.coll) {
			return false
		}
		else if (undefined === vis.coll && 't' === vis.def) {
			return true
		}
		else if (undefined === vis.coll && 'f' === vis.def) {
			return false
		}
		else {
		}
	}
	else if ("delivery" === delivery_type) {
		if ('t' === vis.del) {
			return true
		}
		else if ('f' === vis.del) {
			return false
		}
		else if (undefined === vis.del && 't' === vis.def) {
			return true
		}
		else if (undefined === vis.del && 'f' === vis.def) {
			return false
		}
		else {
			//
		}
	}
	else {
		if ('t' === vis.def) {
			return true
		}
	}

	// return false for all other cases
	return false
}	// CheckVisAvail

/**
 */
export class MenuPage_Box extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const items = this.props.title.split(":")[1];
		const main = this.props.title.split(":")[0];
		// // console.log("aaaaa",a);
		// // console.log("bbbbb",b);
		return (
			<div
				className={this.props.className}
				style={this.props.style}
				// key={this.props.key}
				onClick={this.props.onPress}
				id={this.props.id}>
				{this.props.id ? (
					<div style={{ marginBottom: 32 }}>
						<div className="categoryItemTitle">
							{items}
						</div>
						{/* <div className="categoryItemLittle">{main}</div> */}
						<div className="itemDescription">{this.props.desc}</div>
					</div>
				) : (
					<div>
						<div className="categoryTitle" style={{ color: color.colors.light }}>
							{items}
						</div>
						<div className="itemDescription">{this.props.desc}</div>
					</div>
				)}
				<div>{this.props.item}</div>
			</div>
		);
	}
}

/**
 */
export const Item_Box = ({
	colors,
	itemName,
	description,
	count,
	itemPrice,
	modItem,
	img_url,
	OnPress,
	RemoveFromCart,
	// AddItem, RemItem,

	// item,
}) => {
	const [isModal, IsModal] = React.useState(false);

	/**
	 */
	const handelModal = () => {
		IsModal(true);
		// onPress();
	}

	// React.useEffect( () => {
	// 	console.log( 'item: ', item )
	// } )

	/**
	 */
	return (
		<div>
			<div
				className="singleItem"
				style={{
					border: count ? "2px solid" : 0,
					borderColor: count ? colors.primary : 0
				}}
				onClick={handelModal}>
				<div className="commonFlex">
					<div style={{ flex: 1 }}>
						<div className="commonFlex">
							<div className="itemName">
								<>
									{count !== 0 && (
										<span className="itemName" style={{ color: colors.primary, marginRight: 8 }}>
											{count}x
										</span>
									)}
								</>

								<>{itemName}</>
							</div>
						</div>

						<div className="itemDescription">{description}</div>

						<>{itemPrice && <div className="itemPrice">£{itemPrice.toFixed(2)}</div>}</>
					</div>

					<>{modItem && <div>{modItem}</div>}</>

					<div className="menuRight">
						<div className="imageContainer">
							{img_url ? <img className="imageStyle" src={img_url} /> : <Image src={require("../assets/img/placeholder/skewer.jpg")} className="imageStyle" />}
						</div>
					</div>
					<div className="addtoCart" style={{ background: colors.primary }}>
						<span style={{ marginLeft: 6, fontWeight: 700 }}>+</span>
					</div>
				</div>
			</div>
			<>
				{isModal && (
					<Modal size="lg" show={true} centered onHide={() => IsModal(false)} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Body>
							<Item_Box_Modal
								colors={colors}
								itemName={itemName}
								description={description}
								count={count}
								itemPrice={itemPrice}
								img_url={img_url}
								OnPress={OnPress}
								RemoveFromCart={RemoveFromCart}
								IsModal={IsModal}
							/>
						</Modal.Body>
					</Modal>
				)}
			</>
		</div>
	);
}; // Item_Box

/**
 */
const Item_Box_Modal = ({ colors, itemName, description, count, itemPrice, img_url, OnPress, RemoveFromCart, IsModal }) => {
	const [cntLocal, CntLocal] = React.useState(count || 1);

	/**
	 */
	const AddToCart = () => {
		// OnPress();
		if (cntLocal > count) {
			for (let i = count; i < cntLocal; ++i) {
				OnPress();
			}
		} else if (cntLocal < count) {
			for (let i = cntLocal; i < count; ++i) {
				RemoveFromCart();
			}
		} else {
			// not needed
		}

		IsModal(false);
	}; // AddToCart

	/**
	 */
	return (
		<div>
			<FontAwesomeIcon onClick={() => IsModal(false)} className="modalClose" icon={faTimes} size="lg" color={"red"} />

			<>
				{img_url ? (
					<img style={{ width: "100%", height: 200, objectFit: "cover" }} src={img_url} />
				) : (
					<Image src={require("../assets/img/placeholder/skewer.jpg")} style={{ width: "100%", height: 250, objectFit: "cover" }} />
				)}
			</>

			<div
				className="singleItem"
			// onClick={addToCart}
			>
				<div className="commonFlex">
					<div style={{ flex: 1 }}>
						<div className="commonFlex">
							<div className="itemName">
								<>
									{count !== 0 && (
										<span className="itemName" style={{ color: colors.primary, marginRight: 8 }}>
											{count}x
										</span>
									)}
								</>

								<>{itemName}</>
							</div>
						</div>

						<div className="itemDescription2">{description}</div>

						<>{itemPrice && <div className="itemPrice">£{itemPrice}</div>}</>
					</div>
				</div>
			</div>

			<div className="cartCrossDiv">
				<>
					{cntLocal > 0 ? (
						<FontAwesomeIcon
							className="cartPlus"
							style={{ borderColor: colors.primary }}
							// onClick={RemoveFromCart}
							onClick={() => CntLocal(cntLocal - 1)}
							icon={faMinus}
							color={colors.primary}
						/>
					) : (
						<FontAwesomeIcon className="cartPlus" style={{ borderColor: colors.primary }} icon={faMinus} color={"#ddd"} />
					)}
				</>

				<div className="cartItemCount" style={{ fontSize: 20, fontWeight: "700" }}>
					{cntLocal}
				</div>

				<FontAwesomeIcon
					className="cartPlus"
					style={{ borderColor: colors.primary }}
					// onClick={OnPress}
					onClick={() => CntLocal(cntLocal + 1)}
					// onClick={AddItem}
					icon={faPlus}
					color={colors.primary}
				/>
			</div>

			<div>
				<div className="buttonPadding">
					<Button
						disabled={count === cntLocal}
						onClick={AddToCart}
						variant={count === cntLocal ? "secondary" : "success"}
						style={{ width: "100%", background: count === cntLocal ? null : colors.primary }}>
						{count ? "Update basket " : "Add to basket "}
						(£{(itemPrice * cntLocal).toFixed(2)})
					</Button>
				</div>
				{/* <button
          disabled={count === cntLocal}
          onClick={AddToCart}
        >
          {
            count ? 'Update basket' : 'Add to basket'
          }
        </button> */}
			</div>
		</div>
	);
}; // Item_Box_Modal

/**
 */
// export {MenuPage_Box,Item_Box}
