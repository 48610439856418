/**
 *	@copyright	Elmelo Ltd
 */

import * as dotenv from "dotenv";

dotenv.config();

/**
 */
const elml = {
	stage: process.env.REACT_APP_APP_STAGE,
	_lambda_endPoint: "https://lambda." + "eu-west-2" + ".amazonaws.com",
	_region: "eu-west-2",
	cn_code: "44",
	delivery_opt: ["Delivery", "Collection"],
	ddb: {
		biz_info: "elmelo_rat_1",
		pay_disc: "elmelo_pay_discount_1",
		soc_event: "elmelo_event_0",
		soc_cal: "elmelo_cal_0",
		soc_rsrv: "elmelo_rsrv_0",
		notify: "elmelo_notify_1"
	},

	elmelo_b_shared: true,
	email_address: "no-reply@histreet-app.com",
	elmelo_v_iot_order: "2",
	stripe_pub_key: {
		live: process.env.REACT_APP_LIVE_STRIPE_PUB_KEY, //	"pk_live_CsZHj0WJH3LXOllagfsTQVJO",
		test: process.env.REACT_APP_TEST_STRIPE_PUB_KEY //	"pk_test_g9kLXsq7bwsQjllLFWMpJqEp"
	},
	platform: "hs",

	rest: (name, stage = elml.stage) => {
		switch (stage) {
			case "test":
				switch (name) {
					case "url":
						return "https://20fi5a0bf0.execute-api.eu-west-1.amazonaws.com";
					default:
						return "";
				}
			case "live":
			default:
				switch (name) {
					case "url":
						return "";
					default:
						return "";
				}
		} // switch stage
	},

	lambda: (name, stage = elml.stage) => {
		switch (stage) {
			case "test":
				switch (name) {
					case "beta":
						return "qf-backend-test-fnBeta-od0NMiuW3QZw";
					case "chat":
						return "qf-backend-test-fnChat-OyyjqRPGr1Or";

					case "push":
						return "elmelo-push-app-H6T5Q7BEGLO1";

					case "social":
						return "elmeloScanPayment";
					case "pay":
						return "test_elmeloPay_Stripe_v2";
					case "payv3":
						return "elmeloPay_Stripe_v3";
					case "notify":
						return "test_elmeloPay_Notify_v2";

					default:
						return null;
				} // // switch

			case "live":
			default:
				switch (name) {
					case "beta":
						return "qf-backend-live-fnBeta-Wza05fbY9t1x";
					case "chat":
						return "qf-backend-live-fnChat-xV4Cd12v62FB";

					case "push":
						return "elmelo-push-app-H6T5Q7BEGLO1";

					case "social":
						return "elmeloScanPayment";
					case "pay":
						return "test_elmeloPay_Stripe_v2";
					case "payv3":
						return "elmeloPay_Stripe_v3";
					case "notify":
						return "test_elmeloPay_Notify_v2";

					default:
						return null;
				} // // switch
		} // switch
	},

	db: (name, stage = elml.stage) => {
		switch (stage) {
			case "test":
				switch (name) {
					case "biz":
						return "qf-backend-test-dbBiz-1JUDXH1P7Q8MX";
					case "menu":
						return "qf-backend-test-dbMenu-19O36LZ54279R";

					case "geos":
						return "test_elmelo_geos_2";
					case "images":
						return "test_elmelo_images_1";
					case "serv":
						return "test_elmelo_serv_1";

					case "order":
						return "test_elmelo_order_3";

					case "biz_info":
						return "test_elmelo_rat_1";
					case "pay_disc":
						return "test_elmelo_pay_discount_1";
					case "soc_event":
						return "test_elmelo_event_0";
					case "soc_cal":
						return "test_elmelo_cal_0";
					case "soc_rsrv":
						return "test_elmelo_rsrv_0";
					case "notify":
						return "test_elmelo_notify_2";
					case "wallet":
						return "test_elmelo_wallet_3";

					default:
						return null;
				} // // switch

			case "live":
			default:
				switch (name) {
					case "biz":
						return "qf-backend-live-dbBiz-L1T1TXZJG7EV";
					case "menu":
						return "qf-backend-live-dbMenu-RH4FEPHY7UVK";

					case "geos":
						return "elmelo_geos_2";
					case "images":
						return "elmelo_images_1";
					case "serv":
						return "elmelo_serv_1";

					case "order":
						return "elmelo_order_3";

					case "biz_info":
						return "elmelo_rat_1";
					case "pay_disc":
						return "elmelo_pay_discount_1";
					case "soc_event":
						return "elmelo_event_0";
					case "soc_cal":
						return "elmelo_cal_0";
					case "soc_rsrv":
						return "elmelo_rsrv_0";
					case "notify":
						return "elmelo_notify_2";
					case "wallet":
						return "elmelo_wallet_3";

					default:
						return null;
				} // // switch
		} // switch
	},
	chat: {
		bPush: true,
		bDb: true,
		bIoT: true
	},

	iot: {
		platform: "hs",
		prefix: "a2gb0bhen4igg6-ats",
		policy: "elml_chat"
	},
	aws: {
		roi: "eu-west-2"
	},
	push: {
		platform: "hs"
	}
};

/**
 */
export default elml;
