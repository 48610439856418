/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import { Core, Lambda, Time } from "../../api";

import "../../css/_common.css";
import "./reserve.css";
import Rsrv_Hist from "./history";

import NavHdr from "../_common/nav_hdr";
import { Form, Row, Col, Image } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import { navigate } from "@reach/router";

import { Msg_Send } from "../../api/chat/chat_engine";
import cuid from "cuid";
// import ContentLoader from "react-content-loader";
import Loader from "react-loader-spinner";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/_common.css";
import * as dotenv from "dotenv";
dotenv.config();

/**
 */
class Rsrv_Home extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			height: window.innerHeight - 80,
			width: window.innerWidth,
			newRes: true,
			resHis: false,

			service: true,
			timing: false,
			other: false,

			serviceName: null,
			serviceId: null,

			res_time: null,
			res_duration: null,
			res_guest: "1",

			res_title: "",
			res_subtitle: "",
			res_desc: "",

			serviceIcon: null,

			openType: "close",
			bInvite: false,

			selectedCard: null,
			serviceDuration: null,

			_ch: null,

			service_obj: {},

			dt_duration: null,
			bSubmit: false,
			completeRsrv: false
		};
		this.ot_arr = [];
	}

	/**
	 */
	render() {
		// console.log( "Rsrv_Home : render : props : ", this.props );
		// console.log( "Rsrv_Home : render : state : ", this.state );

		return (
			<div>
				<NavHdr reserve={true} />
				{this.props.__rsrv.initStatus === "inprogress" ? (
					<div className="centerScreen" style={{ height: this.state.height }}>
						<Loader type="Bars" color={this.props.__biz.site_settings.base.colors.primary} height={this.state.height / 3} width={this.state.width / 3} timeout={30000000} />
					</div>
				) : (
					<div style={{ marginLeft: 16, marginRight: 32 }}>
						<div className="commonFlex">
							<div onClick={() => this.setState({ newRes: true, resHis: false })} className={this.state.newRes ? "rvSelect" : "rvNotSelect"}>
								New Reservation
							</div>
							<div onClick={() => this.setState({ newRes: false, resHis: true })} className={this.state.resHis ? "rvSelect" : "rvNotSelect"}>
								Reservation History
							</div>
						</div>

						{this.state.completeRsrv ? (
							<div className="imageText">
								<Image className="orderCompleteImage" style={{ height: 250, width: "auto" }} src={require("../assets/img/placeholder/orderComplete.png")} rounded />
								<div className="alertMessage">Reservation Placed Successfully</div>
							</div>
						) : (
							<div>
								{this.state.newRes ? (
									<div>
										<div className="commonFlex" style={{ margin: 16 }}>
											<div className={this.state.service ? "resTitleSelect" : "resTitle"}>Select Service</div>
											<div className={this.state.timing ? "resTitleSelect" : "resTitle"}>Select Timing</div>
											<div className={this.state.other ? "resTitleSelect" : "resTitle"}>Optional Info</div>
										</div>
										{this.state.service ? (
											this.props.__rsrv.servMenu ? (
												<>
													{this.props.__rsrv.servMenu.menu.map((cat, catIdx) => (
														<div>
															<div className="commonFlex">
																<Image
																	style={{
																		height: 32,
																		width: 32,
																		marginRight: 10
																	}}
																	fluid
																	src={require("../assets/img/placeholder/skewer.jpg")}
																/>
																<div className="resTitle" style={{ color: this.props.__biz.site_settings.base.colors.primary }}>
																	{cat.title}
																</div>
															</div>
															{cat.data.map((item, itemIdx) => (
																<div className="itemSection">
																	<div className={item._cnt === 0 ? "itemBox" : "itemBoxSelect"} onClick={() => this.addService(item)}>
																		<div className="commonFlex">
																			{item._cnt === 0 ? null : (
																				<FontAwesomeIcon
																					style={{ marginRight: 8 }}
																					icon={faCheckCircle}
																					color={this.props.__biz.site_settings.base.colors.primary}
																					size="lg"
																				/>
																			)}
																			<div className="itemName">{item.name}</div>
																			{item.mod && Object.keys(item.mod).length !== 0 ? (
																				<div className="addBtn">
																					<div className="addText">^</div>
																				</div>
																			) : (
																				<div className="itemPrice">{item.price.def !== 0 ? <>£{item.price.def}</> : null}</div>
																			)}
																		</div>
																		{item.desc ? <div className="itemDescription">{item.desc}</div> : null}
																		<div className="commonFlex">
																			<div>
																				{item.tags.length !== 0 ? (
																					<div className="tags">
																						<div className="tagsText">{this.props.tags}</div>
																					</div>
																				) : null}
																				{item.allergens ? (
																					<div className="allergies">
																						<div className="allergyText">{item.allergens}</div>
																					</div>
																				) : null}
																			</div>

																			<div
																				className="addBtn"
																				//  onClick={() => this.addService(item)}
																			>
																				<div className="addText">{item._cnt === 0 ? "ADD" : "REMOVE"}</div>
																			</div>

																			{/*addbtn*/}

																			{/*
                                                            {this.props.addBtn ?
                                                            <div className="addBtn">
                                                            <div className="addText">ADD</div>
                                                            </div> :

                                                            <div className="commonFlex" style={{marginLeft:"auto"}}>
                                                            <div className="addBtn">
                                                            <div className="addText">+</div>
                                                            </div>
                                                            <div className="addNumber">
                                                            <div className="itemName">
                                                            {this.props.count}
                                                            </div>
                                                            </div>
                                                            <div className="addBtn">
                                                            <div className="addText">-</div>
                                                            </div>
                                                            </div>
                                                        } */}
																		</div>
																	</div>
																</div>
															))}
														</div>
													))}
													{"auth" === this.props.__core.authStatus ? (
														<div onClick={() => this.serviceNext()} className="reserButton">
															Next
														</div>
													) : (
														<div onClick={() => navigate("signin", { state: { fromPage: "reserve" } })} className="reserButton">
															Signin to Proceed
														</div>
													)}
												</>
											) : null
										) : this.state.timing ? (
											<Row>
												<Col lg="12" md="12">
													<Form>
														<Form.Group>
															<Form.Text className="text-muted">Reservation For</Form.Text>
															<Form.Control className="textBox" type="text" disabled value={this.state.serviceName} />
														</Form.Group>
														<Form.Group>
															<Form.Text className="text-muted">Date & Time</Form.Text>

															<DatePicker
																selected={this.state.res_time ? this.state.res_time : new Date()}
																minDate={new Date()}
																onChange={date => this.onDateChange(date)}
																dateFormat={"dd/MM/yyyy"}
															/>
															<DatePicker
																selected={this.state.res_time}
																includeTimes={this.ot_arr.map(x => setHours(setMinutes(new Date(), x.min), x.hr))}
																onChange={date => this.onTimeChange(date)}
																onChangeRaw={this.handleDateChangeRaw}
																showTimeSelect
																showTimeSelectOnly
																timeIntervals={15}
																timeCaption="Time"
																dateFormat="h:mm"
																timeFormat="HH:mm"
															/>
														</Form.Group>

														<Form.Group>
															<Form.Text className="text-muted">Reservation Duration</Form.Text>
															<Form.Control className="textBox" type="text" disabled value={this.state.res_duration} />
														</Form.Group>
														<Form.Group>
															<Form.Text className="text-muted">No. of guests</Form.Text>
															<Form.Control
																className="textBox"
																type="number"
																value={this.state.res_guest}
																onChange={event => this.setState({ res_guest: event.target.value })}
															/>
														</Form.Group>
														<div className="commonFlexR">
															<div
																style={{ marginLeft: "inherit" }}
																onClick={() =>
																	this.setState({
																		service: true,
																		timing: false,
																		other: false
																	})
																}
																className="reserButton">
																Back
															</div>
															{!this.state.res_time ? (
																<div className="reserButton">Pick a Date & Time first.</div>
															) : (
																<div
																	onClick={() =>
																		this.setState({
																			service: false,
																			timing: false,
																			other: true
																		})
																	}
																	className="reserButton">
																	Next
																</div>
															)}
														</div>
													</Form>
												</Col>
											</Row>
										) : this.state.other ? (
											<Row>
												<Col lg="12" md="12">
													<Form>
														<Form.Group>
															<Form.Text className="text-muted">Reservation Title</Form.Text>
															<Form.Control
																className="textBox"
																type="text"
																placeHolder="Title(Optional)"
																value={this.state.res_title}
																onChange={event => this.setState({ res_title: event.target.value })}
															/>
														</Form.Group>
														<Form.Group>
															<Form.Text className="text-muted">Reservation Subtitle</Form.Text>
															<Form.Control
																className="textBox"
																type="text"
																placeHolder="Subtitle(Optional)"
																value={this.state.res_subtitle}
																onChange={event => this.setState({ res_subtitle: event.target.value })}
															/>
														</Form.Group>
														<Form.Group>
															<Form.Text className="text-muted">Reservation Description</Form.Text>
															<Form.Control
																className="textBox"
																type="text"
																placeHolder="Description(Optional)"
																value={this.state.res_desc}
																onChange={event => this.setState({ res_desc: event.target.value })}
															/>
														</Form.Group>
														<div className="commonFlexR">
															<div
																style={{ marginLeft: "inherit" }}
																onClick={() =>
																	this.setState({
																		service: false,
																		timing: true,
																		other: false
																	})
																}
																className="reserButton">
																Back
															</div>

															{this.state.bSubmit ? (
																<div className="reserProcessButton">Processing...</div>
															) : (
																<div onClick={this.onSubmit} className="reserButton">
																	Request For Reservation
																</div>
															)}
														</div>
													</Form>
												</Col>
											</Row>
										) : null}
									</div>
								) : null}
							</div>
						)}

						{this.state.resHis ? <Rsrv_Hist /> : null}
					</div>
				)}
			</div>
		);
	}

	handleDateChangeRaw = e => {
		e.preventDefault();
	};
	/**
	 */
	componentDidMount() {
		this.props.Rdx_RsrvInit();

		this.InitOt()
			.then(data => {})
			.catch(err => {});

		if (!this.props.__chat.chat_channel) {
			this.chatInit()
				.then(data => {})
				.catch(err => {});
		}
	}

	/**
	 */
	serviceNext = () => {
		try {
			if (!Object.entries(this.state.service_obj).length) {
				alert("please select a service.");
				return null;
			}

			const tmp_service = this.state.service_obj;
			let service_time = 0,
				service_name = "",
				service_id = "";

			Object.keys(tmp_service).map((key, index) => {
				service_name += "," + tmp_service[key].name;
				service_id += "," + tmp_service[key]._id;
				service_time += parseInt(tmp_service[key].duration ? tmp_service[key].duration : 60);
			});

			const hour = service_time ? Number(service_time / 60) : " ";
			const min = service_time ? service_time % (hour * 60) : " ";

			this.state.dt_duration = { h: hour, m: min };

			this.setState({
				serviceName: service_name.substr(1),
				serviceID: service_id.substr(1),
				res_duration: service_time,
				service: false,
				timing: true,
				other: false
			});
		} catch (err) {
			console.warn("service next err", err);
			return err;
		}
	};

	/**
	 */
	onDateChange = async date => {
		try {
			// console.log("onDateChange date", date)

			await this.InitOt(date);

			date.setHours(this.ot_arr[0].hr, this.ot_arr[0].min);

			this.setState({
				res_time: date
			});
		} catch (err) {}
	};
	/**
	 */
	onTimeChange = async date => {
		try {
			console.log("onTimeChange date", date);

			this.setState({
				res_time: date
			});
		} catch (err) {}
	};
	/**
	 */
	InitOt = async date => {
		try {
			this.ot_arr = [];

			let _date = date ? date : new Date();

			const ot_obj = this.props.__biz.ot;

			console.log("ot_obj", ot_obj);

			ot_obj.days[_date.getDay()].map(x => {
				if (x.if_open) {
					let count = 0;
					let start = new Date(),
						end = new Date();

					start.setHours(x.open[0], x.open[1]);
					end.setHours(x.close[0], x.close[1]);

					while (end > start) {
						const interval_time = { hr: start.getHours(), min: start.getMinutes() };

						this.ot_arr.push(interval_time);

						start = new Date(start.getTime() + 15 * 60 * 1000);
						++count;
					}
				}
			});

			console.log("this.ot_arr", this.ot_arr);

			return { msg: "OK" };
		} catch (err) {
			console.warn("Res_Today: InitOt: err: ", err);
			return Promise.reject(err);
		}
	}; // InitOt

	/**
	 */
	addService = item => {
		try {
			if (item._cnt) item._cnt = 0;
			else item._cnt = 1;

			let tmp_service_obj = this.state.service_obj;

			if (!item._cnt) {
				delete tmp_service_obj[item._id];
			} else {
				tmp_service_obj[item._id] = { ...item };
			}

			this.setState({ service_obj: { ...tmp_service_obj }, ts_change: Date.now() });

			// // console.log("service_obj", tmp_service_obj);

			return {};
		} catch (err) {
			console.warn("Rsrv_Home : addService : err : ", err);

			return { err };
		}
	};

	/**
	 */
	Dur2Epoch = dur_obj => {
		if (!dur_obj) return 0;

		let epoch_val = 0;

		if (dur_obj.d) epoch_val += dur_obj.d * 24 * 60;

		if (dur_obj.h) epoch_val += dur_obj.h * 60;

		if (dur_obj.m) epoch_val += dur_obj.m;

		return epoch_val;
	};

	/**
	 */
	onSubmit = async () => {
		try {
			this.setState({ bSubmit: true });

			let tmp_service_arr = [];
			let tmp_service_obj = this.state.service_obj;

			let tot_price = 0;

			if (Object.entries(tmp_service_obj).length) {
				Object.keys(tmp_service_obj).map((key, index) => {
					if (tmp_service_obj[key]._type === "basic") {
						tot_price += tmp_service_obj[key]._cnt * tmp_service_obj[key].price.def;
					} else if (tmp_service_obj[key]._type === "mod") {
						tot_price += tmp_service_obj[key]._mods.opts.reduce((acc, cur) => {
							return acc + parseFloat(cur._cnt * cur.price.def);
						}, 0);
					}

					tmp_service_arr.push(tmp_service_obj[key]);
				});
			}

			const aws_core = new Core({});

			const name_obj = await aws_core.Name();
			const phone_obj = await aws_core.Phone();
			const email_obj = await aws_core.Email();

			const dt_st_epoch = Time.Epoch(this.state.res_time.getTime()); // @todo

			/**
			 * Event
			 */
			const params_event = {
				in_dt_event: dt_st_epoch,
				in_duration: this.Dur2Epoch(this.state.dt_duration),
				in_guest_count: this.state.res_guest,

				in_title: this.state.res_title ? this.state.res_title : " ",
				in_subtitle: this.state.res_subTitle ? this.state.res_subTitle : " ",
				in_details: this.state.res_desc ? this.state.res_desc : " ",

				in_opentype: this.state.openType,
				in_type: this.state.serviceName,
				in_typeid: this.state.serviceId,

				in_bizname: this.props.__biz.title,
				in_bizid: this.props.__biz.biz_id,

				in_name: [name_obj.title, name_obj.first, name_obj.last].join(" "),
				in_email: email_obj.addr,
				in_phone: phone_obj.number
			};

			const event = await this.Res_Event(params_event);

			// console.log( 'Rsrv_Home : OnSubmit: event: ', event );

			/**
			 * Calender
			 */
			const params_cal = {
				in_dt_event: dt_st_epoch,
				in_duration: this.Dur2Epoch(this.state.dt_duration),
				event_id: event
			};

			const cal = await this.Res_Cal(params_cal);

			// console.log( 'Rsrv_Home : OnSubmit: cal: ', cal );

			/**
			 * Rsrv
			 */
			const params_rsrv = {
				in_dt_event: dt_st_epoch,
				in_duration: this.Dur2Epoch(this.state.dt_duration),
				in_guest_count: this.state.res_guest,

				in_title: this.state.title && this.state.title.length ? this.state.title : " ",
				in_subtitle: this.state.subTitle && this.state.subTitle.length ? this.state.subTitle : " ",
				in_details: this.state.desc && this.state.desc.length ? this.state.desc : " ",

				in_opentype: this.state.openType,
				in_type: this.state.serviceName,
				in_typeid: this.state.serviceId,

				in_bizname: this.props.__biz.title,
				in_bizid: this.props.__biz.biz_id,
				in_name: [name_obj.title, name_obj.first, name_obj.last].join(" "),
				in_email: email_obj.addr,
				in_phone: phone_obj.number,
				reservation_data: tmp_service_arr, // tmp_service_arr  // @todo
				event_id: event,

				pub_id: this.props.__chat.user_id,
				chat_id: this.props.__chat.chat_channel.ch_id,
				paid_status: "not-paid",
				price: tot_price
			};
			// console.log('Rsrv_Home : OnSubmit: params_rsrv: ', params_rsrv);

			const rsrv = await this.Res_Rsrv(params_rsrv);

			// console.log('Rsrv_Home : OnSubmit: rsrv: ', rsrv);

			if (!this.props.__chat.chat_channel) await this.chatInit();

			if (rsrv) {
				await this.Notify_Beta(params_rsrv);
				await this.send_chat_msg(params_rsrv);

				if (this.props.__biz.contact && this.props.__biz.contact.email) {
					this.SES_Email_Alpha(this.props.__biz.contact.email.def, dt_st_epoch, params_rsrv.in_name, params_rsrv.in_phone).then().catch();
				}
			}
			this.setState({
				bSubmit: false,
				newRes: false,
				resHis: false,
				service: false,
				timing: false,
				other: false,
				completeRsrv: true
			});

			await this.ClearRsrv();
		} catch (err) {
			console.warn("Rsrv_Home : onSubmit : err : ", err);
			this.setState({ bSubmit: false });

			return { err };
		}
	};

	/**
	 */
	ClearRsrv = async p => {
		try {
			this.setState({
				serviceName: null,
				serviceId: null,

				res_time: null,
				res_duration: null,
				res_guest: "1",

				res_title: "",
				res_subtitle: "",
				res_desc: "",

				serviceIcon: null,

				openType: "close",
				bInvite: false,

				selectedCard: null,
				serviceDuration: null,

				service_obj: {},
				dt_duration: null
			});

			this.props.Rdx_RsrvInit(true);
		} catch (err) {
			console.warn("ClearRsrv: err: ", err);
			return Promise.reject(err);
		}
	}; // ClearRsrv

	/**
	 */
	Res_Event = async p => {
		try {
			const cfg = this.props.__cfg;

			const aws_lambda = new Lambda({});

			const params_event = {
				stage: cfg.stage,
				usr: "beta",
				actType: "reservation",
				act: "event:create",
				keys: { event_dt_st: p.in_dt_event },
				event_details: {
					title: p.in_title ? p.in_title : "__na__",
					sub_title: p.in_subtitle ? p.in_subtitle : "__na__",
					desc: p.in_details ? p.in_details : "__na__",
					type: p.in_type ? p.in_type : "__na__",
					typeid: p.in_typeid ? p.in_typeid : "__na__"
				},
				event_place: {
					name: p.in_bizname ? p.in_bizname : "__na__",
					biz_id: p.in_bizid ? p.in_bizid : "__na__",
					addr: p.in_bizaddr ? p.in_bizaddr : "__na__"
				},
				event_dt_end: p.in_dt_event + p.in_duration,
				event_repeat: "no",
				event_price: {},
				event_open_type: "close",
				event_custom: p.custom ? p.custom : {},
				event_orderid: p.orderid ? p.orderid : "__na__"
			};

			return await aws_lambda.Invoke(params_event, cfg.lambda("beta", cfg.stage));
		} catch (err) {
			console.warn("Res_New: Res_Event: err: ", err);
			return Promise.reject(err);
		}
	}; // Res_Event

	/**
	 */
	Res_Cal = async p => {
		try {
			const cfg = this.props.__cfg;

			const aws_lambda = new Lambda({});

			const params_cal = {
				stage: cfg.stage,
				usr: "beta",
				actType: "reservation",
				act: "cal:create",
				attrs: {
					cal_dt_st: p.in_dt_event,
					cal_dt_end: p.in_dt_event + p.in_duration,
					cal_event_id: p.event_id,
					cal_custom: p.custom ? p.custom : {}
				}
			};

			return await aws_lambda.Invoke(params_cal, cfg.lambda("beta", cfg.stage));
		} catch (err) {
			console.warn("Res_New: Res_Cal: err: ", err);
			return Promise.reject(err);
		}
	}; // Res_Cal
	/**
	 in_dt_event: dt_st_tmp.getTime()
	 ,    in_duration: this.Dur2Epoch( this.state.dt_duration )
	 ,    in_guest_count: this.state.nPpl

	 ,    in_title: this.state.title && this.state.title.length ? this.state.title : ' '
	 ,    in_subtitle: this.state.subTitle && this.state.subTitle.length ? this.state.subTitle : ' '
	 ,    in_details: this.state.msg && this.state.msg.length ? this.state.msg : ' '

	 ,    in_type: this.state.serviceName
	 ,    in_typeid: this.state.serviceId

	 ,    in_bizname: this.props.__core.bizInfo.rat_name
	 ,    in_bizid: this.props.__core.bizInfo.rat_id

	 ,    in_name: [name_obj.title, name_obj.first, name_obj.last].join( ' ' )
	 ,    in_email: email_obj.addr
	 ,    in_phone: phone_obj.number

	 ,    in_opentype: this.state.openType
	 */
	Res_Rsrv = async p => {
		try {
			const cfg = this.props.__cfg;
			const aws_lambda = new Lambda({});

			const params_rsrv = {
				stage: cfg.stage,
				usr: "beta",
				actType: "reservation",
				act: "rsrv:create",
				keys: { rsrv_dt_st: p.in_dt_event },
				attrs: {
					rsrv_event_id: p.event_id,

					rsrv_duration: p.in_duration,
					rsrv_dt_end: p.in_dt_event + p.in_duration,
					rsrv_nppl: p.in_guest_count,

					rsrv_title: p.in_title,
					rsrv_subtitle: p.in_subtitle,
					rsrv_desc: p.in_details,
					rsrv_bizid: p.in_bizid,
					rsrv_type: p.in_type,
					rsrv_typeid: p.in_typeid,
					rsrv_name: p.in_name,
					rsrv_email: p.in_email,
					rsrv_phone: p.in_phone,

					rsrv_bizname: p.in_bizname,
					rsrv_custom: p.custom ? p.custom : {},
					rsrv_status: "requested",
					reservation_data: JSON.stringify(p.reservation_data),
					pub_id: p.pub_id,
					ch_id: p.chat_id, //@todo,
					paid_status: p.paid_status,
					price: p.price
				}
			};

			// // console.log('Res_New: Res_Rsrv: params_rsrv: ', params_rsrv);

			return await aws_lambda.Invoke(params_rsrv, cfg.lambda("beta", cfg.stage));
		} catch (err) {
			console.warn("Res_New: Res_Rsrv: err: ", err);
			return Promise.reject(err);
		}
	}; // Res_Rsrv

	/**
	 */
	chatInit = async () => {
		try {
			const aws_core = new Core({});
			const cfg = this.props.__cfg;

			if (!this.props.__chat.user_id) await this.props.Rdx_Pubid_Init();

			const aws_lambda = new Lambda();

			const { title, biz_id } = this.props.__biz;

			let ch_id = [biz_id, this.props.__chat.user_id].join("<>");

			const name_obj = await aws_core.Name();
			const name_str = [name_obj.first, name_obj.last].join(" ");

			const ch_t = "ind";

			const p_new = {
				stage: cfg.stage,
				pf: cfg.platform,
				usr: cfg.usr_t ? cfg.usr_t : "beta",

				actType: "channels",
				act: "new:chnl",

				topic: "chat",
				ch_id: ch_id,
				ch_t: ch_t,
				// ch_grp_t: ch_grp_t,

				//
				b_db: cfg.chat.bDb,
				b_iot: cfg.chat.bIoT,
				b_push: cfg.chat.bPush
			};

			if (ch_t === "ind") {
				p_new.meta = {
					[this.props.__chat.user_id]: { name: name_str },
					[biz_id]: { name: title ? title : "N/A" }
				};

				p_new.usr_list = [
					{
						ch_id: ch_id,
						usr_id: this.props.__chat.user_id,
						usr_t: "beta",
						mship_t: "admin"
					},
					{
						ch_id: ch_id,
						usr_id: biz_id,
						usr_t: "alpha",
						mship_t: "admin"
					}
				];
			} else {
				p_new.meta = {};
				p_new.usr_list = [];
			}

			// console.log( "Chat_Inst: Init: p_get: ", p_new )

			const resp_get = await aws_lambda.Invoke(p_new, cfg.lambda("chat", cfg.stage));

			// console.log( "Chat_Inst: Init: resp_get: ", resp_get )

			// localStorage.setItem( 'channel:'+cfg.stage+user.attributes.email , JSON.stringify(resp_get.resp) )

			this.props.Rdx_Chat_Channel(resp_get.resp);
		} catch (err) {
			this.setState({ bInit: true, bRefreshing: false });

			console.warn("Checkout_Summary: send_chat_msg: err: ", err);

			// return Promise.reject( err )
			return { err };
		}
	}; // send_chat_msg

	/**
	 */
	send_chat_msg = async p => {
		try {
			const cfg = this.props.__cfg;

			if (!this.props.__chat.chat_channel) return;

			let rsrv_data = {};

			if (p) {
				rsrv_data = {
					rsrv_dt_st: p.in_dt_event,
					rsrv_nppl: p.in_guest_count,
					rsrv_status: "req_new",
					rsrv_event_id: p.event_id,
					rsrv_biz_id: p.in_bizid

					// rsrv_event_id: p.event_id,
					// rsrv_duration: p.in_duration,
					// rsrv_dt_end: p.in_dt_event + p.in_duration,
					// rsrv_nppl: p.in_guest_count,
					// rsrv_title: p.in_title,
					// rsrv_subtitle: p.in_subtitle,
					// rsrv_desc: p.in_details,
					// rsrv_type: p.in_type,
					// rsrv_typeid: p.in_typeid,
					// rsrv_name: p.in_name,
					// rsrv_email: p.in_email,
					// rsrv_phone: p.in_phone,
					// rsrv_dt_st: p.in_dt_event,
					// rsrv_biz_id: p.in_bizid,
					// rsrv_bizname: p.in_bizname,
					// rsrv_custom: p.custom ? p.custom : {},
					// rsrv_status: 'req_new',
					// reservation_data: p.reservation_data,
					// pub_id: p.pub_id,
					// section: p.section,
					// block: p.block,
				};
			}

			const p_msg = {
				msg: "Place a New reservation from " + this.props.__biz.title,
				ch_id: this.props.__chat.chat_channel.ch_id,
				meta: rsrv_data,
				type: "rsrv",
				pub_id_list: Object.keys(this.props.__chat.chat_channel.meta),

				sender: this.props.__chat.chat_channel.meta[this.props.__chat.user_id].name,
				usr_id: this.props.__chat.user_id,
				dev_id: cuid(),
				l_id: Date.now()
			};

			// console.log( 'Checkout_Summary: send_chat_msg: Chat_Engine: p_msg: ', p_msg )

			const msg_obj = await Msg_Send(p_msg, cfg);

			// console.log( 'Checkout_Summary: send_chat_msg: Chat_Engine: msg_obj: ', msg_obj )

			return { msg: "OK" };
		} catch (err) {
			this.setState({ bInit: true, bRefreshing: false });

			console.warn("Checkout_Summary: send_chat_msg: err: ", err);

			// return Promise.reject( err )
			return { err };
		}
	}; // send_chat_msg

	/**
	 */
	Notify_Beta = async rsrv_details => {
		const cfg = this.props.__cfg;

		let params = {
			usr: "beta",
			act: "send_reservation_mail",

			email_from: cfg.email_address,
			order_from: "histreet",
			email_to: [rsrv_details.in_email],
			msg_sub: "You've placed a Reservation at" + this.props.__biz.title.toUpperCase(),
			alpha: {
				biz_name: this.props.__biz.title,
				cuisine_name: this.props.__biz.cuisine_name,
				service_name: this.props.__biz.service_name,
				biz_name_disp: this.props.__biz.title
			},
			msg_body: rsrv_details,
			stage: cfg.stage
		};

		// // console.log("SES_Email Params", params);

		await this.SES_Email(params);
	};

	/**
	 */
	SES_Email = async params => {
		try {
			const cfg = this.props.__cfg;

			const aws_lambda = new Lambda();

			return await aws_lambda.Invoke(params, cfg.lambda("notify", cfg.stage));
		} catch (err) {
			return Promise.reject(err);
		}
	};

	/**
	 */
	SES_Email_Alpha = async (biz_email, dt_st_epoch, name, phone) => {
		try {
			const cfg = this.props.__cfg;

			const dt_now = Date.now();

			const rsrv_time = new Date(Time.Epoch2Time(dt_st_epoch));

			let params = {
				usr: "beta",
				actType: "notify",
				act: "order",
				dt: dt_now,
				email_from: cfg.email_address,
				order_from: "web",
				email_to: ["skywalker.ryan@gmail.com", biz_email],
				msg_sub: "New Reservation",
				msg_body: `You've a new Reservation at ${[rsrv_time.getHours(), rsrv_time.getMinutes()].join(":")} on ${[rsrv_time.getMonth() + 1, rsrv_time.getDate(), rsrv_time.getFullYear()].join(
					"/"
				)} 
                 from Name: ${name} & contact no: ${phone} Please Check it out in HiStreet app`,
				stage: cfg.stage
			};

			const aws_lambda = new Lambda({});

			const resp_lambda = await aws_lambda.Invoke(params, cfg.lambda("beta", cfg.stage));

			if (resp_lambda.errorMessage) {
				throw new Error(resp_lambda.errorMessage);
			}

			return resp_lambda;
		} catch (err) {
			console.warn("Checkout_Summary: SES_Email_Alpha: err: ", err);

			return Promise.reject(err);
		}
	}; // SES_Email_Alpha
} // class Rsrv_Home

/**
 */
const mapStateToProps = state => {
	return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Rsrv_Home);
