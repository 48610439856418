/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import OrderHome from './home'
import OrderInfo from './info'
import OrderCart from './cart_2'
import OrderDetails from './details'
// import Mobile_Order from './mobileOrder'

import P404 from '../_common/404'

/**
 */
class App_Order extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <OrderHome path='order' />
                {/*<Mobile_Order path='mobileOrder' />*/}
                {/* <OrderInfo path='info' /> */}
                <OrderCart path='cart' />
                {/* <OrderDetails path='details' /> */}

                <P404 default />
            </Router>
        )
    }   // render
}   // class App_Order

/**
 */
export {App_Order, OrderHome, OrderInfo, OrderCart, OrderDetails}



