/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import NavHdr from '../_common/nav_hdr'

/**
 */
class Auth_Logout extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div>
                <NavHdr />
                <h1>Logging out ...</h1>
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Auth_Logout

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Auth_Logout )


