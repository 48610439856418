/**
 * @copyright Elmelo Ltd.
 */

import React from "react";

import { Container, Button, Alert, Form } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import color from "../_common/colors.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faChevronRight, faMapMarked, faTruckMoving, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import "../../css/_common.css";
import { Lambda, Utils, Currency } from "../../api";
import ContentLoader from "react-content-loader";
import AddNew_Address from "../profile/addNewAddress";
import { Profile } from "../profile/profile";
import { TextInput } from "../_common/components";
import { PickerEx } from "../_common/picker";
import { Time } from "../../api";
import { Core } from "../../api";
import countryCode from "../auth/CountryCode";

import * as dotenv from "dotenv";
dotenv.config();

/**
 */
const Checkout_Delivery = props => {
	const dispatch = useDispatch();

	const RdxCfg = useSelector(state => state.__cfg);
	const RdxBiz = useSelector(state => state.__biz);
	const RdxCheckout = useSelector(state => state.__checkout);

	const [isInit, IsInit] = React.useState(true);
	const [isSave, IsSave] = React.useState(false);

	const [errMsg, ErrMsg] = React.useState("");
	const [nameTitle, NameTitle] = React.useState("");
	const [nameFirst, NameFirst] = React.useState("");
	const [nameLast, NameLast] = React.useState("");
	const [otArr, OtArr] = React.useState([]);
	const [selTime, SelTime] = React.useState("");
	const [cnCode, CnCode] = React.useState("+44");
	const [phoneNumber, PhoneNumber] = React.useState(RdxCheckout.info.phone ? RdxCheckout.info.phone.slice(3) : "");

	/**
	 */
	React.useEffect(() => {
		LoadUserInfo().then().catch();
	}, []);

	/**
	 */
	React.useEffect(() => {
		InitOt().catch(console.warn);

		SetGenericDiscount(); //	@todo fix this so that first order discount doesn't overwrite this
		SetFirstOrderDiscount().then().catch(console.error);
	}, [RdxBiz.ot]);

	/**
	 */
	const LoadUserInfo = async () => {
		try {
			IsInit(true);

			const aws_core = new Core({});

			const user = await aws_core.Name();

			const user_email = await aws_core.Email();
			dispatch(actions.Rdx_Checkout_info({ email: user_email.addr }));

			if (user) {
				NameFirst(user.first || "");
				NameLast(user.last || "");
				dispatch(actions.Rdx_Checkout_info({ firstName: user.first, lastName: user.last }));
			}

			const user_phone = await aws_core.Phone();

			if (user_phone.number) {
				const _user_phone = user_phone.number.substring(3);
				PhoneNumber(_user_phone);
				dispatch(actions.Rdx_Checkout_info({ phone: user_phone.number }));
			}

			IsInit(false);

			return {};
		} catch (err) {
			console.warn(err);
			IsInit(false);
			return Promise.reject(err);
		}
	}; // LoadUserInfo

	/**
	 */
	const SetFirstOrderDiscount = async () => {
		try {
			const aws_lambda = new Lambda({});

			const params_lambda = {
				stage: RdxCfg.stage,
				usr: "beta",
				actType: "order",
				act: "get:hist_biz",
				bizId: RdxBiz.biz_id
			};

			let resp_lambda = await aws_lambda.Invoke(params_lambda, RdxCfg.lambda("beta", RdxCfg.stage));

			resp_lambda = resp_lambda.filter(item => item.respond === "ready" || item.respond === "accepted");

			let b_found = true;
			let disc_applied = {
				_idx: 0,
				disc_type: "generic",
				type: "",
				name: "",
				amount: 0.0,
				b_accept_others: false
			};

			const subtotal = RdxCheckout.SubTotal();

			if (RdxBiz?.discount?.f_order?.web.discount.length) {
				const discount_obj = RdxBiz.discount.f_order.web;

				for (let i = 0; i < discount_obj.discount.length; i++) {
					if (resp_lambda.length < discount_obj.count[i] && subtotal > discount_obj.min_total[i]) {
						if (discount_obj.type === "percent" && discount_obj.discount[i] > 0) {
							disc_applied.amount = parseFloat(subtotal * (discount_obj.discount[i] / 100)).toFixed(2);
							if (discount_obj.max_discount_static[i] > 0 && discount_obj.max_discount_static[i] < disc_applied.amount) {
								disc_applied.amount = discount_obj.max_discount_static[i];
							}
						} else if (discount_obj.type === "amount") {
							disc_applied.amount = discount_obj.discount[i];
						} else {
							//
						}

						disc_applied._idx = 1;
						disc_applied.name = "First Order";
						disc_applied.type = "f_order";

						b_found = true;
					}
				}
			}

			if (b_found) {
				dispatch(actions.Rdx_Checkout_SetSpecialDiscount({ ...disc_applied }));
			} else {
				return;
			}
		} catch (err) {
			console.error("Checkout_Delivery : SetFirstOrderDiscount : err : ", err);
			return err;
		}
	};

	/**
	 */
	const SetGenericDiscount = () => {
		try {
			if (RdxCheckout.discounts.coupon.length) return;

			let b_found = false;

			let disc_applied = {
				_idx: 0,
				disc_type: "generic",
				type: "",
				name: "",
				amount: 0.0,
				b_accept_others: false
			};

			const subtotal = RdxCheckout.SubTotal();

			if (RdxCheckout.delivery.deliveryType && RdxCheckout.delivery.deliveryType.toLowerCase() === "delivery") {
				const discount_p = RdxBiz.discount;
				const discount_obj = discount_p && discount_p.delivery ? discount_p.delivery : { discount: [] };

				for (let i = 0; i < discount_obj.discount.length; i++) {
					if (subtotal > discount_obj.minRequirement[i]) {
						if (discount_obj.type === "percentage" && discount_obj.discount[i] > 0) {
							disc_applied.amount = parseFloat(subtotal * (discount_obj.discount[i] / 100)).toFixed(2);
							if (discount_obj.discountLimit[i] > 0 && discount_obj.discountLimit[i] < disc_applied.amount) {
								disc_applied.amount = discount_obj.discountLimit[i];
							}
						} else if (discount_obj.type === "amount") {
							disc_applied.amount = discount_obj.discount[i];
						}

						disc_applied._idx = 1;
						disc_applied.name = "Delivery";
						disc_applied.type = "delivery";

						b_found = true;
					}
				}
			} else if (RdxCheckout.delivery.deliveryType && RdxCheckout.delivery.deliveryType.toLowerCase() === "collection") {
				const discount_p = RdxBiz.discount;
				const discount_obj = discount_p && discount_p.collection ? discount_p.collection : { discount: [] };

				for (let i = 0; i < discount_obj.discount.length; i++) {
					if (subtotal > discount_obj.minRequirement[i]) {
						if (discount_obj.type === "percentage" && discount_obj.discount[i] > 0) {
							disc_applied.amount = parseFloat(subtotal * (discount_obj.discount[i] / 100)).toFixed(2);
							if (discount_obj.discountLimit[i] > 0 && discount_obj.discountLimit[i] < disc_applied.amount) {
								disc_applied.amount = discount_obj.discountLimit[i];
							}
						} else if (discount_obj.type === "amount") {
							disc_applied.amount = discount_obj.discount[i];
						}

						disc_applied._idx = 2;
						disc_applied.name = "Collection";
						disc_applied.type = "collection";

						b_found = true;
					}
				}
			}

			if (b_found) {
				dispatch(actions.Rdx_Checkout_SetGenericDiscount({ ...disc_applied }));
			} else {
				return;
			}
		} catch (err) {
			console.error("Checkout_Delivery : setGenericDiscount : err : ", err);
			return err;
		}
	};

	/**
	 */
	const ShowPayment = async () => {
		try {
			IsSave(true);

			if (!nameFirst || !nameLast) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});

				throw new Error("Please enter name");
			}

			const aws_core = new Core({});

			const new_name = { title: nameTitle, first: nameFirst, last: nameLast };

			if (!RdxCheckout.firstName || !RdxCheckout.lastName) {
				const resp_n = await aws_core.SetName(new_name);
				if (resp_n && resp_n.err) {
					throw new Error(resp_n.err.message);
				}

				dispatch(actions.Rdx_Checkout_info({ title: nameTitle, firstName: nameFirst, lastName: nameLast }));
			}

			if (RdxCheckout.delivery.deliveryType.toLowerCase() === "delivery" && !RdxCheckout.phone) {
				const resp = await aws_core.SetPhoneNo(cnCode + phoneNumber);

				if (resp && resp.err) {
					ErrMsg(resp.err.message);
					IsSave(false);
					return;
				}
				dispatch(actions.Rdx_Checkout_info({ phone: cnCode + phoneNumber }));
			}

			if (!RdxCheckout.delivery.deliveryType) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});

				throw new Error("Please select a type");
			}

			if (RdxCheckout.delivery.deliveryType.toLowerCase() === "delivery" && !RdxCheckout.delivery.deliveryAddress) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});

				throw new Error("Please select an address and press next");
			}

			props.showDelivery(false);

			IsSave(false);

			return {};
		} catch (err) {
			console.warn(err);

			ErrMsg(err.message || err);

			IsSave(false);

			// return Promise.reject( err )
			return { err };
		}
	};

	const InitOt = async date => {
		try {
			let ot_arr = [];

			let _date = date ? date : new Date();

			const ot_obj = RdxBiz.ot;

			if (Object.keys(ot_obj).length) {
				ot_obj.days[_date.getDay()].map(x => {
					if (x.if_open) {
						let count = 0;
						let start = new Date(),
							end = new Date();

						start.setHours(x.open[0], x.open[1]);
						end.setHours(x.close[0], x.close[1]);

						while (end > start) {
							if (start >= _date) {
								const interval_time = {
									label: [start.getHours(), String(start.getMinutes()).padStart(2, "0")].join(":"),
									val: [start.getHours(), String(start.getMinutes()).padStart(2, "0")].join(":"),
									key: [start.getHours(), start.getMinutes()].join(":")
								};
								ot_arr.push(interval_time);
							}
							start = new Date(start.getTime() + 15 * 60 * 1000);
							count++;
						}
					}
				});
			} else {
				//
			}

			ot_arr.splice(0, 0, {
				label: "ASAP",
				val: "ASAP",
				key: "ASAP"
			});

			OtArr(ot_arr);
			if (ot_arr?.[0]?.val) {
				OnSelectTime(ot_arr?.[0]?.val);
			}

			return ot_arr;
		} catch (err) {
			console.warn("Res_Today: InitOt: err: ", err);
			return Promise.reject(err);
		}
	}; // InitOt

	/**
	 */
	const OnSelectTime = val => {
		try {
			SelTime(val);

			if (val === "Now") {
				dispatch(actions.Rdx_Set_Delivery_Time({ adv_order: false }));
			} else {
				let today = new Date();

				const obj = val.split(":");

				today.setHours(obj[0], obj[1], 0);

				dispatch(actions.Rdx_Set_Delivery_Time({ adv_order: true, delivery_time: Time.Epoch(today) }));
			}
		} catch (err) {
			console.error("EPOS_Cart OnSelectTime: err:", err);
			return { err };
		}
	}; // OnSelectTime

	/**
	 */
	const OnPhoneNumber = event => {
		PhoneNumber(event.target.value.replace(/\s+/g, "").replace(/^(0{1,})/, ""));
	}; // OnPhoneNumber

	/**
	 */
	return (
		<div>
			<div className="orderProcessBody">
				{isSave ? (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 80 }}>
						<div className="rainbow">
							Online ordering by <span style={{ color: "#22c55e", fontSize: 24, fontWeight: 700, fontFamily: "Open Sans", marginLeft: 8 }}>QuickFood</span>
						</div>
					</div>
				) : (
					<div>
						<div>
							{errMsg ? (
								<Alert variant="danger" onClose={() => ErrMsg("")} dismissible>
									{errMsg}
								</Alert>
							) : null}
						</div>

						<div className="itemName">Customer name*</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							<div style={{ flexDirection: "row", display: "flex" }}>
								<Form.Group className="processInputR">
									<TextInput
										placeholder="First Name"
										type="text"
										val={nameFirst}
										onChange={val => NameFirst(val.target.value)}
										className=""
										disabled={RdxCheckout.firstName ? true : false}
									/>
								</Form.Group>
								<Form.Group className="processInputL">
									<TextInput
										placeholder="Last Name"
										type="text"
										val={nameLast}
										onChange={val => NameLast(val.target.value)}
										className=""
										disabled={RdxCheckout.lastName ? true : false}
									/>
								</Form.Group>
							</div>
							{RdxCheckout.delivery.deliveryType === "delivery" ? (
								<div>
									<div className="itemName">Phone number*</div>
									<Form.Group style={{ flexDirection: "row", display: "flex", marginTop: 12 }}>
										<PickerEx items={countryCode} prompt={"Country"} val={cnCode} bSearch={true} onChange={val => CnCode(val)} className="textBoxProfile" />

										<div style={{ flex: 1, marginLeft: 16 }}>
											<input
												placeholder="Enter phone number"
												type="text"
												name="phoneNumber"
												// size="lg"
												val={phoneNumber}
												defaultValue={phoneNumber}
												onChange={OnPhoneNumber}
												className="numberHide"
											/>
											{/* <TextInput
												placeholder="Enter phone number"
												type="text"
												name="phoneNumber"
												// size="lg"
												val={phoneNumber}
												defaultValue={phoneNumber}
												onChange={OnPhoneNumber}
												className="numberHide"
											/> */}
										</div>
									</Form.Group>
								</div>
							) : null}
						</div>
						<div style={{ flexDirection: "row", display: "flex", gap: 16 }}>
							{/* {
													RdxBiz.settings.services.delivery
													?
															<div style={{flex: 1}} key={"Delivery"}>
																	<div onClick={()=>this.delSelect("Delivery")}>
																			<div
																					className="selectionContainer"
																					style={{backgroundColor: RdxCheckout.delivery.deliveryType === "Delivery" ? RdxBiz.site_settings.base.colors.primary : '#ffffff'}}>
																					<div style={{marginBottom: 5}}>
																							<FontAwesomeIcon icon={faTruckMoving} size="2x"
																																					color={RdxCheckout.delivery.deliveryType === "Delivery" ? "#fff" : color.colors.light}/>
																					</div>
																					<div>
																							<div className="infoSubTitle"
																											style={{color: RdxCheckout.delivery.deliveryType === "Delivery" ? '#ffffff' : RdxBiz.site_settings.base.colors.primary}}>{"Delivery"}</div>
																					</div>
																			</div>
																	</div>
															</div>
													:
													null
											}
											{
													RdxBiz.settings.services.collection
													?
															<div style={{flex: 1}} key={"Collection"}>
																	<div onClick={()=>this.delSelect("Collection")}>
																			<div
																					className="selectionContainer"
																					style={{backgroundColor: RdxCheckout.delivery.deliveryType === "Collection" ? RdxBiz.site_settings.base.colors.primary : '#ffffff'}}>
																					<div style={{marginBottom: 5}}>
																							<FontAwesomeIcon icon={faShoppingBag} size="2x"
																																					color={RdxCheckout.delivery.deliveryType === "Collection" ? "#fff" : color.colors.light}/>
																					</div>
																					<div>
																							<div className="infoSubTitle"
																									style={{color: RdxCheckout.delivery.deliveryType === "Collection" ? '#ffffff' : RdxBiz.site_settings.base.colors.primary}}
																							>
																											{"Collection"}
																							</div>
																					</div>
																			</div>
																	</div>
															</div>
													:
													null
											} */}
							{
								// __cfg.delivery_opt.map((x) => {
								//     return (
								//         <div style={{flex: 1}} key={x}>
								//             <div onClick={()=>this.delSelect(x)}>
								//                 <div
								//                     className="selectionContainer"
								//                     style={{backgroundColor: RdxCheckout.delivery.deliveryType === x ? RdxBiz.site_settings.base.colors.primary : '#ffffff'}}>
								//                     <div style={{marginBottom: 5}}>
								//                         {
								//                             'Delivery' === x
								//                                 ? <FontAwesomeIcon icon={faTruckMoving} size="2x"
								//                                                     color={RdxCheckout.delivery.deliveryType === x ? "#fff" : color.colors.light}/>
								//                                 :
								//                                 <FontAwesomeIcon icon={faShoppingBag} size="2x"
								//                                                     color={RdxCheckout.delivery.deliveryType === x ? "#fff" : color.colors.light}/>
								//                         }
								//                     </div>
								//                     <div>
								//                         <div className="infoSubTitle"
								//                                 style={{color: RdxCheckout.delivery.deliveryType === x ? '#ffffff' : RdxBiz.site_settings.base.colors.primary}}>{x}</div>
								//                     </div>
								//                 </div>
								//             </div>
								//         </div>
								//     )
								// })
							}
						</div>

						<div>
							<div>
								{
									//  @todo
									RdxCheckout.delivery.deliveryType === "delivery" ? (
										<div>
											<div className="itemName">Order information</div>
											{/* <div
												className="singleItem"
												style={{ borderBottom: "none", display: "flex", alignItems: "center" }}>
												<FontAwesomeIcon
													icon={faTruckMoving}
													size="lg"
													color={color.colors.light}
												/>
												<span className="zistText">{RdxCheckout.delivery.deliveryType}</span>
											</div> */}

											{/* <DeliveryInfo delivery={RdxBiz.delivery} /> */}

											<AddrList {...props} setErrMessage={err_msg => ErrMsg(err_msg)} />
										</div>
									) : null
								}

								{RdxCheckout.delivery.deliveryType === "collection" ? (
									<div>
										<div>
											<div className="itemName">Collect from</div>

											<div style={{ display: "flex", position: "relative", marginTop: 16 }}>
												<FontAwesomeIcon style={{ color: "#2e3333" }} icon={faMapMarked} size="lg" />

												<div className="zistText">
													{RdxBiz.addr.addr
														? RdxBiz.addr.addr.line1 +
														  (RdxBiz.addr.addr.line2 ? ", " + RdxBiz.addr.addr.line2 : "") +
														  (RdxBiz.addr.addr.city ? ", " + RdxBiz.addr.addr.city : "") +
														  (RdxBiz.addr.addr.postcode ? ", " + RdxBiz.addr.addr.postcode : "") +
														  (RdxBiz.addr.addr.county ? ", " + RdxBiz.addr.addr.county : "") +
														  (RdxBiz.addr.addr.country ? ", " + RdxBiz.addr.addr.country : "")
														: ""}
												</div>
											</div>

											{/* <div className="itemName" style={{ marginTop: 16 }}>
												Collection time
											</div>
											<PickerEx items={otArr} prompt={"Collection Time"} val={selTime} bSearch={true} onChange={OnSelectTime} pickerTitle={true} ot={true} /> */}
										</div>
									</div>
								) : null}
								<div style={{ display: "flex" }}>
									{/* <div className="processButtons" >
										
										<Button
											className="confirmButton"
											onClick={()=> this.props.onClose()}>
											Back
										</Button>                                 
										
									</div> */}
									{RdxCheckout.delivery.deliveryType === "collection" ? (
										<div className="processButtons" style={{ flex: 1 }}>
											{RdxCheckout.delivery.deliveryType && selTime && nameFirst && nameLast ? (
												<Button className="confirmButton" style={{ background: RdxBiz.site_settings.base.colors.primary }} onClick={ShowPayment}>
													Next
												</Button>
											) : (
												<Button
													disabled
													className="confirmButton"
													style={{ backgroundColor: "#ddd" }}
													// onClick={ShowPayment}
												>
													Next
												</Button>
											)}
										</div>
									) : RdxCheckout.delivery.deliveryType && RdxCheckout.delivery.deliveryAddress ? (
										<Button className="confirmButton" style={{ background: RdxBiz.site_settings.base.colors.primary, marginLeft: "auto" }} onClick={ShowPayment}>
											Next
										</Button>
									) : (
										<Button
											disabled
											className="confirmButton"
											style={{ backgroundColor: "#ddd", marginLeft: "auto" }}
											// onClick={ShowPayment}
										>
											Next
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}; // Checkout_Delivery

/**
 */
// const DeliveryInfo = ({ delivery }) => {
// 	const RdxBiz = useSelector(state => state.__biz);
// 	const c_sign = Currency.Sign(RdxBiz.biz_currency);

// 	return (
// 		<Alert variant="light">
// 			<div>
// 				<table>
// 					<tr>
// 						<td>{delivery.method === "range" ? "Delivery Range" : "Postcodes"}</td>
// 						<td>Charge</td>
// 						<td>Minimum Order Amount</td>
// 						<td>Free Delivery Amount</td>
// 					</tr>
// 					{delivery.charges &&
// 						delivery.charges.map((charge, idx) => (
// 							<tr>
// 								{delivery.method === "range" ? <td>{delivery.ranges[idx]}</td> : <td>{delivery.postcodes[idx].join(", ")}</td>}
// 								<td>{charge}</td>
// 								<td>{delivery.min_total[idx] === 0 ? "Not applicable" : delivery.min_total[idx]}</td>
// 								<td>{delivery.min_free[idx] === 0 ? "Not applicable" : delivery.min_free[idx]}</td>
// 							</tr>
// 						))}
// 				</table>
// 			</div>
// 		</Alert>
// 	);
// }; // DeliveryInfo

/**
 */
const AddrList = props => {
	const dispatch = useDispatch();
	const RdxBiz = useSelector(state => state.__biz);
	const RdxCheckout = useSelector(state => state.__checkout);

	const [isLoading, IsLoading] = React.useState(false);

	const [selected, Selected] = React.useState(RdxCheckout.delivery.deliveryAddress);
	const [listAddr, ListAddr] = React.useState([]);
	const [inRange, InRange] = React.useState([]);
	const [outRange, OutRange] = React.useState([]);
	const [addNewAddress, AddNewAddress] = React.useState(false);

	/**
	 */
	React.useEffect(() => {
		LoadUserAddress().catch(console.warn);
	}, []);

	/**
	 */
	React.useEffect(() => {
		// const list_addr = listAddr.map( addr => Addr.Prep(addr, RdxBiz.delivery) )

		let { in_range, out_range } = listAddr.reduce(
			(a, c) => {
				if (c.fees.b_delivery) a.in_range.push(c);
				else a.out_range.push(c);

				return a;
			},
			{ in_range: [], out_range: [] }
		);

		// sort in_range
		in_range.sort((x, y) => {
			return x.fees.charge > y.fees.charge ? 1 : x.fees.charge < y.fees.charge ? -1 : 0;
		});

		// check selected
		if (selected) {
			const selected_id = selected._id;
			// find the selected address and make b_selected = true

			in_range = in_range.map(addr => {
				if (addr._id === selected_id) addr.b_selected = true;
				else addr.b_selected = false;

				return addr;
			});
		} // if selected

		InRange(in_range);
		OutRange(out_range);
	}, [selected, listAddr]);

	/**
	 */
	const LoadUserAddress = async () => {
		try {
			IsLoading(true);

			const resp_list = await dispatch(actions.Rdx_GetAddressList());

			const list_addr = resp_list.map(addr => Addr.Prep(addr, RdxBiz.delivery));

			ListAddr(list_addr);
			IsLoading(false);

			return { msg: "OK" };
		} catch (err) {
			console.warn("LoadUserAddress: err: ", err);

			IsLoading(false);
			return Promise.reject(err);
		}
	}; // LoadUserAddress

	/**
	 */
	const OnSelect = addr => {
		Selected(addr);

		const total = RdxCheckout.Total();

		if (addr && addr.fees.b_delivery) {
			if (addr.fees.min_total && addr.fees.min_total > total) {
				props.setErrMessage("We don't deliver for less than £" + addr.fees.min_total + " to your address.");
			} else {
				dispatch(actions.Rdx_Checkout_delivery({ deliveryAddress: addr }));
				if (addr.fees.min_free == 0 || addr.fees.min_free > total) dispatch(actions.setDeliveryCharge(addr.fees.charge ? addr.fees.charge : 0.0));
			}
		}
	}; // OnSelect

	// /**
	//  */
	// function __InRange(in_range, on_select)
	// {
	// 	return in_range.map(addr => {
	// 		return (
	// 			<div>
	// 				{in_range && in_range.length ? null : <div>null</div>}
	// 				<Addr
	// 					addr={addr}
	// 					OnSelect={on_select}
	// 					key={addr._id}
	// 					b_selected={addr.b_selected}
	// 				/>
	// 			</div>
	// 		);
	// 	});
	// }	// __InRange

	// /**
	//  */
	// function __OutRange(out_range)
	// {
	// 	return out_range.map(addr => {
	// 		return (
	// 			<Addr
	// 				addr={addr}
	// 				key={addr._id}
	// 			/>
	// 		)
	// 	})
	// }	// __OutRange

	/**
	 */
	return (
		<div>
			<>
				{addNewAddress && (
					<AddNew_Address
						HideModal={() => AddNewAddress(false)}
						ListAddr={list_addr => {
							const addr_list = list_addr.map(addr => Addr.Prep(addr, RdxBiz.delivery));

							ListAddr(addr_list);
						}}
					/>
				)}
			</>

			<>
				{isLoading && (
					<div
						style={{
							padding: 32,
							marginLeft: "auto",
							marginRight: "auto",
							height: 300,
							width: 600
						}}>
						<ContentLoader height={80} width={200} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
							<rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
							<rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
							<rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
							<rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
							<rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
							<rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
						</ContentLoader>
					</div>
				)}
			</>

			<div className="processDetailsDiv">
				<div className="itemName" style={{ flex: 1 }}>
					Select delivery address*
				</div>
				<div className="processDetailsButton" style={{ background: RdxBiz.site_settings.base.colors.primary }} onClick={() => AddNewAddress(true)}>
					Add Address
				</div>
			</div>
			{!listAddr.length && (
				<Alert variant="danger">
					<Alert.Link href="#"> No saved address found. Add address </Alert.Link>
				</Alert>
			)}

			{/* { this._p.inRange  && this._p.inRange.length ?
									null:
									<Alert  variant= 'danger'>
											<Alert.Link href="#"> No Address Found inside delivery range </Alert.Link>
									</Alert>
							} */}

			{/* {__InRange(this._p.inRange, this.OnSelect)} */}
			<>
				{inRange.map(addr => (
					<div key={addr._id}>
						{/* {in_range && in_range.length ? null : <div>null</div>} */}
						<Addr addr={addr} OnSelect={OnSelect} key={addr._id} b_selected={addr.b_selected} />
					</div>
				))}
			</>

			{/* {
					this._p.outRange  && this._p.outRange.length
			?   <div className="processDetailText" style={{color:"#ddd"}}>Out of Range Address</div>

			:   null
			} */}

			{/* {__OutRange(this._p.outRange)} */}
			<>
				{outRange.map(addr => (
					<Addr addr={addr} key={addr._id} />
				))}
			</>
		</div>
	);
}; // AddrList
class AddrList_ extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.__checkout.delivery.deliveryAddress,
			addrList: [],
			bloading: false,
			addNewAddress: false
		};

		this._p = {
			inRange: [],
			outRange: [],
			last: null
		};
	}

	/**
	 */
	componentDidMount() {
		this.loadUserAddress().then().catch();
	}

	/**
	 */
	loadUserAddress = async () => {
		try {
			const { __cfg } = this.props;
			this.setState({ bloading: true });

			const profile = new Profile({});

			const add_list = await profile.GetAddressList(__cfg);

			this.setState({ bloading: false, addrList: add_list });

			return { msg: "OK" };
		} catch (err) {
			this.setState({ bloading: false });
			return Promise.reject(err);
		}
	};

	/**
	 */
	render() {
		const { __biz } = this.props;

		this.state.addrList = this.state.addrList.map(addr => {
			return Addr.Prep(addr, __biz.delivery);
		});

		this._p.inRange = [];
		this._p.outRange = [];

		this.state.addrList.forEach(addr => {
			if (addr.fees.b_delivery) this._p.inRange.push(addr);
			else this._p.outRange.push(addr);
		});

		// sort in_range
		this._p.inRange.sort((a, b) => {
			return a.fees.charge - b.fees.charge;
		});

		// check selected
		if (this.state.selected) {
			const selected_id = this.state.selected._id;
			// find the selected address and make b_selected = true

			this._p.inRange = this._p.inRange.map(addr => {
				if (addr._id === selected_id) addr.b_selected = true;
				else addr.b_selected = false;

				return addr;
			});
		}

		return this.UI_List();
	} // render

	/**
	 */
	UI_List = () => {
		// return null;

		return (
			<div>
				{this.state.addNewAddress ? <AddNew_Address {...this.props} hideModal={() => this.setState({ addNewAddress: false })} loadUserAddress={this.loadUserAddress} /> : null}

				{this.state.bloading ? (
					<div
						style={{
							padding: 32,
							marginLeft: "auto",
							marginRight: "auto",
							height: 300,
							width: 600
						}}>
						<ContentLoader height={80} width={200} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
							<rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
							<rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
							<rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
							<rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
							<rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
							<rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
						</ContentLoader>
					</div>
				) : null}

				<div className="processDetailsDiv">
					<div className="itemName" style={{ flex: 1 }}>
						Select delivery address
					</div>
					<div className="processDetailsButton" style={{ background: this.props.__biz.site_settings.base.colors.primary }} onClick={() => this.setState({ addNewAddress: true })}>
						Add Address
					</div>
				</div>
				{this.state.addrList && this.state.addrList.length > 0 ? null : (
					<Alert variant="danger">
						<Alert.Link href="#"> No saved address found. Add address </Alert.Link>
					</Alert>
				)}

				{/* { this._p.inRange  && this._p.inRange.length ?
                    null:
                    <Alert  variant= 'danger'>
                        <Alert.Link href="#"> No Address Found inside delivery range </Alert.Link>
                    </Alert>
                } */}

				{__InRange(this._p.inRange, this.OnSelect)}

				{/* {
                    this._p.outRange  && this._p.outRange.length
                ?   <div className="processDetailText" style={{color:"#ddd"}}>Out of Range Address</div>

                :   null
                } */}

				{__OutRange(this._p.outRange)}
			</div>
		);

		function __InRange(in_range, on_select) {
			return in_range.map(addr => {
				return (
					<div>
						{in_range && in_range.length ? null : <div>null</div>}
						<Addr addr={addr} OnSelect={on_select} key={addr._id} b_selected={addr.b_selected} />
					</div>
				);
			});
		}

		function __OutRange(out_range) {
			return out_range.map(addr => {
				return <Addr addr={addr} key={addr._id} />;
			});
		}
	};

	/**
	 */
	OnSelect = addr => {
		this.setState({ selected: addr });

		if (addr && addr.fees.b_delivery) {
			if (addr.fees.min_total && addr.fees.min_total > this.props.__checkout.Total()) {
				this.props.setErrMessage("We don't deliver for less than £" + addr.fees.min_total + " to your address.");
			} else {
				this.props.Rdx_Checkout_delivery({ deliveryAddress: addr });
				this.props.setDeliveryCharge(addr.fees.charge ? addr.fees.charge : 0.0);
			}
		}
	};
} // class AddrList

/**
 */
class Addr extends React.PureComponent {
	/**
	 */
	constructor(props) {
		super(props);
	}

	/**
	 */
	render() {
		if (this.props.addr.fees.b_delivery) {
			return (
				<div className="pickerItem" key={this.props.addr._id} onClick={this.OnSelect}>
					{this.UI_Addr()}
				</div>
			);
		} else {
			return this.UI_Addr();
		}
	}

	/**
	 */
	UI_Addr = () => {
		let style_addr = "";

		if (this.props.addr.fees.b_delivery) {
			if (0 === this.props.addr.fees.charge) style_addr = "addressSelect";
			else style_addr = "addressSelect";
		} else {
			style_addr = "addressSelect address_out_range";
		}

		return (
			<div>
				{this.props.b_selected ? (
					<div className="commonFlex" style={{ alignItems: "center" }}>
						<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCheck} size="lg" color="green" />
						<div
							className={style_addr}
							style={{
								color: color.colors.black,
								fontWeight: 700
								// border: "1px solid",
								// borderRadius: 10,
								// borderColor: this.props.__biz.site_settings.base.colors.primary
							}}>
							{this.props.addr.line1 +
								(this.props.addr.line2 ? ", " + this.props.addr.line2 : "") +
								(this.props.addr.city ? ", " + this.props.addr.city : "") +
								(this.props.addr.postal_code ? ", " + this.props.addr.postal_code : "") +
								(this.props.addr.county ? ", " + this.props.addr.county : "") +
								(this.props.addr.country ? ", " + this.props.addr.country : "")}
							{/* {this.props.addr.line1} {this.props.addr.line2}, {this.props.addr.city}, {this.props.addr.postal_code} */}
						</div>
					</div>
				) : (
					<div className={style_addr} style={{ color: color.colors.light }}>
						<FontAwesomeIcon style={{ marginRight: 8 }} icon={faCircle} size="lg" color="#C4C4C4" />
						{this.props.addr.line1 +
							(this.props.addr.line2 ? ", " + this.props.addr.line2 : "") +
							(this.props.addr.city ? ", " + this.props.addr.city : "") +
							(this.props.addr.postal_code ? ", " + this.props.addr.postal_code : "") +
							(this.props.addr.county ? ", " + this.props.addr.county : "") +
							(this.props.addr.country ? ", " + this.props.addr.country : "")}
						{/* {this.props.addr.line1} {this.props.addr.line2}, {this.props.addr.city}, {this.props.addr.postal_code} */}
						{!this.props.addr.fees.b_delivery ? <div className="oor">Out of range</div> : null}
					</div>
				)}
			</div>
		);
	}; // UI_Addr

	/**
	 */
	OnSelect = () => {
		this.props.OnSelect(this.props.addr);
	};

	/**
	 */
	static Prep = (addr, delivery_obj) => {
		// return null;
		if (!addr._id) {
			addr._id = Utils.MD5([addr.line1, addr.line2, addr.city, addr.county, addr.country, addr.postcode].join(":"));
		}

		if (!addr.ts_prep) {
			addr.fees = Addr.Delivery_Fees(addr, delivery_obj);
			// addr.min_total = ;

			// addr.ts_prep = Date.now();
		}

		return addr;
	};

	static Delivery_Fees = (addr, delivery_obj) => {
		let fees_tmp = 0;

		if ("range" === delivery_obj.method) fees_tmp = Addr.Delivery_Fees_Range(addr, delivery_obj);
		else if ("postcode" === delivery_obj.method) fees_tmp = Addr.Delivery_Fees_Postcode(addr, delivery_obj);
		else fees_tmp = -1;

		return fees_tmp;
	};

	/**
	 */
	static Delivery_Fees_Range = (addr, delivery_obj) => {
		const user_distance = Addr.Delivery_Dist(addr, delivery_obj);

		let idx_range = -1;

		//
		for (
			let i = 0;
			i < delivery_obj.ranges.length;
			++i // iterate through all the delivery ranges check for different minimum order amount
		) {
			if (user_distance < delivery_obj.ranges[i]) {
				idx_range = i;
				break;
			}
		}

		if (-1 === idx_range) {
			const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

			return { b_delivery: false, msg: msg_ };
		} else {
			return {
				b_delivery: true,
				min_total: delivery_obj.min_total[idx_range],
				min_free: delivery_obj.min_free[idx_range],
				charge: delivery_obj.charges[idx_range]
			};
		}
	}; // Delivery_Fees_Range

	/**
	 */
	static Delivery_Fees_Postcode = (addr, delivery_obj) => {
		if (delivery_obj.range_max && 0 < delivery_obj.range_max) {
			const user_distance = Addr.Delivery_Dist(addr, delivery_obj);

			if (user_distance > delivery_obj.range_max) {
				const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

				return { b_delivery: false, msg: msg_ };
			}
		}

		const post_code = Addr.Postcode_Split(addr);

		if (!post_code) {
			const msg_ = "Wrong PostCode provided.";

			return { b_inrange: false, msg: msg_ };
		}

		let idx_found = -1;

		for (let i = 0; i < delivery_obj.postcodes.length; i++) {
			for (let j = 0; j < delivery_obj.postcodes[i].length; j++) {
				if (delivery_obj.postcodes[i][j].pre && delivery_obj.postcodes[i][j].pre === post_code.pre) {
					if (!delivery_obj.postcodes[i][j].post) {
						idx_found = i;
						break;
					}

					for (let k = 0; k < delivery_obj.postcodes[i][j].post.length; ++k) {
						if (post_code.post.startsWith(delivery_obj.postcodes[i][j].post[k])) {
							idx_found = i;
							break;
						}
					} // for k
				} else if (delivery_obj.postcodes[i][j] === post_code.pre) {
					idx_found = i;
					break;
				} else {
					//
				}

				if (-1 < idx_found) {
					break;
				}
			} // for j

			if (-1 < idx_found) {
				break;
			}
		} // ofr i

		if (-1 === idx_found) {
			const msg_ = "We don't deliver at your address. Please call the restaurant directly to enquire!";

			return { b_delivery: false, msg: msg_ };
		} else {
			return {
				b_delivery: true,
				min_total: delivery_obj.min_total[idx_found],
				min_free: delivery_obj.min_free[idx_found],
				charge: delivery_obj.charges[idx_found]
			};
		}
	}; // Delivery_Fees_Postcode

	/**
	 */
	static Postcode_Split = p => {
		let postcode_pre = "",
			postcode_post;

		// p = typeof addr === "string" ? JSON.parse(addr) : addr;

		p.postal_code = p.postal_code.replace(/\s/g, "");

		if (6 === p.postal_code.length) {
			postcode_pre = p.postal_code.substr(0, 3).toUpperCase();
			postcode_post = p.postal_code.substr(4, 7).toUpperCase();
		} else if (7 === p.postal_code.length) {
			postcode_pre = p.postal_code.substr(0, 4).toUpperCase();
			postcode_post = p.postal_code.substr(5, 8).toUpperCase();
		} else {
			// const msg_ = 'Wrong PostCode provided.';

			// this.props.updatePopup({type: "err", msg: 'Wrong PostCode provided.'});
			// this.props.setDeliveryCharge(home_delivery_charge);

			return null;
		}

		return {
			pre: postcode_pre,
			post: postcode_post
		};
	};

	/**
	 */
	static Delivery_Dist = (addr, delivery_obj) => {
		let user_distance = parseFloat(Addr.Distance_inKM(delivery_obj.restaurant_geocode.latitude, delivery_obj.restaurant_geocode.longitude, addr.longlat.latitude, addr.longlat.longitude)).toFixed(
			2
		);

		if (delivery_obj.dist_unit == "miles") {
			user_distance = Addr.KM2Mile(user_distance);
		}

		return user_distance;
	};

	/**
	 */
	static KM2Mile = src_km => {
		return src_km * 0.621371;
	};

	/**
	 */
	static Distance_inKM = (lat1, lon1, lat2, lon2) => {
		const R = 6371;
		const dLat = Addr.Deg2Rad(lat2 - lat1);
		const dLon = Addr.Deg2Rad(lon2 - lon1);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(Addr.Deg2Rad(lat1)) * Math.cos(Addr.Deg2Rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		return R * c; // Distance in km
	};

	/**
	 */
	static Deg2Rad = deg => {
		return deg * (Math.PI / 180);
	};
} // class Addr

// /**
//  */
// const mapStateToProps = state => {
// 	return state;
// }; //

/**
 */
// export default connect(mapStateToProps, actions)(Checkout_Delivery);
export default Checkout_Delivery;
