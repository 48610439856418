/**
 * @copyright Snapon Equipment
 */

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { CartOpt, ICartOpt } from "../_types/cart";

import { GetSubTotal } from "../components/order/ont_2";

import { Rdx_Checkout_SetSubtotal, Rdx_Checkout_SetItemCount, Rdx_Checkout_Clear } from "./actions/checkout";

/**
 */
export interface ICartState {
	items: ICartOpt[];

	subTotal: number;
	cntItems: number;
}

export interface TCartCntUpdate {
	id?: string;
	cnt: number;
}

/**
 */
const defaultState: ICartState = {
	items: [],

	subTotal: 0.0,
	cntItems: 0
};

/**
 */
export const CartCalcAsync: any = createAsyncThunk("cart/calc/async", async (items_list: ICartOpt[], { getState, dispatch }) => {
	const stateGlobal: any = getState();
	// const state: ICartState = stateGlobal.__cart
	const stateCheckout: any = stateGlobal.__checkout;
	const stateCore: any = stateGlobal.__core;

	const tmp_list = items_list;

	const total_obj = GetSubTotal(tmp_list, stateCheckout.delivery.deliveryType, stateCore.menu.menu);

	dispatch(Rdx_Checkout_SetSubtotal(total_obj.total));
	dispatch(Rdx_Checkout_SetItemCount(total_obj.cnt));

	return {
		subTotal: total_obj.total,
		cntItems: total_obj.cnt
	};
}); // CartCalcAsync

/**
 */
export const CartUpdateAsync: any = createAsyncThunk("cart/update/async", async (cart_opt: ICartOpt, { getState, dispatch }) => {
	const stateGlobal: any = getState();
	const state: ICartState = stateGlobal.__cart;

	const items_list = _CartUpdate(cart_opt, state.items);

	return {
		items: items_list
	};
}); // CartUpdateAsync

/**
 */
export const CartCntUpdateAsync: any = createAsyncThunk("cart/cntupdate/async", async (cart_cnt_upd: TCartCntUpdate, { getState, dispatch }) => {
	// try{
	const stateGlobal: any = getState();
	const state: ICartState = stateGlobal.__cart;

	const items_list = _CartCntUpdate(cart_cnt_upd, state.items);

	return {
		items: items_list
	};
}); // CartCntUpdateAsync

/**
 */
const slice = createSlice({
	name: "Cart",
	initialState: defaultState,
	reducers: {
		// CartItems: (state: ICartState, action: PayloadAction<ICartOpt[]>) =>
		// {
		//   state.items = action.payload
		// },
		// CartUpdate: (state: ICartState, action: PayloadAction<ICartOpt>) =>
		// {
		//   state.items = _CartUpdate( action.payload, state.items )
		// },
		// CartCntUpdate: (state: ICartState, action: PayloadAction<TCartCntUpdate>) =>
		// {
		//   state.items = _CartCntUpdate( action.payload, state.items )
		// },  // CntUpdate
		CartClear: (state: ICartState) => {
			state.items = [];

			// state.subTotal = 0.0;
			// state.cntItems = 0;
		},
	}, // CartUpdate
	extraReducers: builder =>
		builder
			.addCase(CartCalcAsync.fulfilled, (state: ICartState, action) => {
				state.cntItems = action.payload.cntItems;
				state.subTotal = action.payload.subTotal;
			})
			.addCase(CartUpdateAsync.fulfilled, (state: ICartState, action) => {
				state.items = action.payload.items;
			})
			.addCase(CartCntUpdateAsync.fulfilled, (state: ICartState, action) => {
				state.items = action.payload.items;
			})
});

/**
 */
export const {
	// CartUpdate,
	// CartCntUpdate,
	CartClear
} = slice.actions;

/**
 */
export default slice.reducer;

/**
 */
function _CartUpdate(cart_opt: ICartOpt, items_list: ICartOpt[]): ICartOpt[] {
	const tmp_items = [...items_list];
	const tmp_obj: ICartOpt = { ...cart_opt };

	// console.log( 'tmp_obj: ', tmp_obj )

	const f_idx = tmp_items.findIndex(x => x.id === tmp_obj.id);

	// console.log( 'f_idx: ', f_idx )

	if (-1 === f_idx && tmp_obj.cnt) {
		tmp_items.push(tmp_obj);
	} else if (!tmp_obj.cnt) {
		tmp_items.splice(f_idx, 1);
	} else {
		tmp_items[f_idx] = tmp_obj;
	}

	return tmp_items;
} // _CartUpdate

/**
 */
function _CartCntUpdate(cart_cnt_upd: TCartCntUpdate, items_list: ICartOpt[]): ICartOpt[] {
	// console.log( '_CartCntUpdate: cart_cnt_upd: ', cart_cnt_upd )

	if (!cart_cnt_upd.id) {
		return items_list;
	}

	// console.log( '_CartCntUpdate: items_list: ', items_list )

	// const tmp_items = [...items_list]
	const tmp_items = structuredClone(items_list);

	const f_idx = tmp_items.findIndex(x => x.id === cart_cnt_upd.id);

	if (-1 === f_idx) {
		// don't do anything
	} else if (0 >= cart_cnt_upd.cnt) {
		tmp_items.splice(f_idx, 1);
	} else {
		tmp_items[f_idx].cnt = cart_cnt_upd.cnt;
	}

	return tmp_items;
} // _CartCntUpdate
