/**
 *	@copyright	Elmelo Ltd.
 */

// import { AStorage } from "../../api/Utils";
class Checkout {
	constructor() {
		this.cart = [];

		this.info = {
			title: "",
			firstName: "",
			lastName: "",
			email: "",
			phone: ""
		};

		this.comment = "";
		this.cntItem = 0;

		this.delivery = {
			deliveryType: "collection",
			deliveryAddress: "",
			validAddress: true //todo - will be false
		};

		this.payment = {
			paymentType: "card",
			creditInfo: ""
		};

		/**
		 */
		this.charges = {
			subtotal: { _idx: 0, type: "subtotal", name: "Subtotal", amount: 0.0 },
			elmelo: { _idx: 1, type: "quickfood", name: "Service fee", amount: 0.5 },
			admin: { _idx: 2, type: "admin", name: "Service fee", amount: 0.0 },
			delivery: { _idx: 3, type: "delivery", name: "Delivery fees", amount: 0.0 },
			collection: { _idx: 4, type: "collection", name: "Collection fees", amount: 0.0 },
			card: { _idx: 5, type: "card", name: "Internation card issuer", amount: 0.0 }
		};

		this.type = "";
		this.addr = "";

		/**
		 *  Discounts: We have four types of discounts. They are:
		 *      1. Generic
		 *      2. Combo
		 *      3. Personal
		 *      4. Coupon
		 *
		 *  Generic: ...
		 *
		 *  Combo: ...
		 *
		 *  Personal: ...
		 *
		 *  Coupon: ...
		 */
		this.discounts = {
			/**
			 *  Generic offers and discounts.
			 */
			generic: [
				/*
						{_idx: 0, type: 'f_usage', name: 'First Use', amount: 0.0, b_accept_others: true}
				,   {_idx: 1, type: 'delivery', name: 'Delivery', amount: 0.0, b_accept_others: true}
				,   {_idx: 2, type: 'collection', name: 'Collection', amount: 0.0, b_accept_others: true}
				,   {_idx: 3, type: 'elmelo', name: 'Elmelo', amount: 0.0, b_accept_others: true}
				,   {_idx: 3, type: 'loyalty', name: 'Student/Staff/NHS/...', amount: 0.0, b_accept_others: true}
				,   {_idx: 4, type: 'generic', name: 'Generic', amount: 0.0, b_accept_others: true}
				*/
			],

			/**
			 *  Combo offers / discounts. E.g. Buy two get one free, etc.
			 */
			combo: [
				/*
						{_idx: 0, combo_id: '', name: '', amount: 0.0, b_accept_others: true}
				*/
			],

			/**
			 *  Personalised discounts. Received via shares on social media and other means
			 */
			influencer: [
				/*
						{_idx: 0, type: 'fb_share', name: 'combo', amount: 0.0, b_accept_others: true}
				*/
			],

			loyalty: [
				/*
						{_idx: 0, type: 'staff', name: 'Staff', group: 'na', amount: 0.0, b_accept_others: false}
				,   {_idx: 1, type: 'student', name: 'Student', group: 'na', amount: 0.0, b_accept_others: false}
				*/
			],

			/**
			 *  Coupons can be off three differnet types: They are:
			 *      1. Generic:
			 *      2. Combo
			 *      3. Personal
			 *
			 *  Coupons can also be used to target a specific charge. For this we use "TARGET" in the coupon.
			 *  Default for 'TARGET' is 'na' when no target is provided. Targets are mainly provided for discounts
			 *  on generic charges.
			 */
			coupon: [
				/*
						{_idx: 0, code: 'CODE', type: 'generic', target: 'na', amount: 0.0, b_accept_others: true}
						{_idx: 0, code: 'CODE', type: 'generic', target: 'delivery', amount: 0.0, b_accept_others: true}
				,   {_idx: 1, code: 'CODE', type: 'combo', amount: 0.0, b_accept_others: true}
				,   {_idx: 2, code: 'CODE', type: 'personal', amount: 0.0, b_accept_others: true}
				*/
			]
		};

		/**
		 *  A way to keep track of changes in checkout...
		 */
		this.tsUpd = 0;

		this.advanced_order = false;

		this.delivery_time = 0;
	} // cstr

	/**
	 */
	Info = info_obj => {
		this.info = { ...this.info, ...info_obj };
	};

	Comment = comment => {
		this.comment = comment;
	};

	/**
	 */
	Delivery = del_obj => {
		this.delivery = { ...this.delivery, ...del_obj };
	};

	/**
	 */
	Payment = pay_obj => {
		if (pay_obj.paymentType === "cash") {
			this.payment.creditInfo = "";
		}
		this.payment = { ...this.payment, ...pay_obj };
	};

	/**
	 */
	Cart = cart_arr => {
		this.cart = cart_arr;
	};

	/**
	 */
	CalculateDiscount_Price = acceptOther => {
		if (acceptOther) {
			return this.charges.subtotal.amount - this.Discount();
		} else {
			return this.charges.subtotal.amount;
		}
	};

	/**
	 */
	IsApplied_Disc = (disc_obj, type) => {
		if (type)
		{
			let _discount = null;

			if (this.discounts[disc_obj].length)
			{
				if (
					this.discounts[disc_obj].some(x => {
						if (x.type === type) _discount = x;
					})
				)
				{
					return _discount;
				}
			}

			return _discount;
		}
		else
		{
			if (this.discounts[disc_obj.disc_type].some(x => x.type === disc_obj.type)) {
				return true;
			}

			return false;
		}
	};

	/**
	 *  //
	 */
	ApplyCharge = (ch_type, ch_obj) => {
		const ch_obj_2 = { ...ch_obj, type: ch_type };

		if (this.charges[ch_type])
		{
			const tmp_charge = this.charges[ch_type] || {}
			const new_charges = { ...tmp_charge, ...ch_obj_2 };

			Object.keys(new_charges).map( x => {
				this.charges[ch_type][x] = new_charges[x]
			} )
		}
		else
		{
			this.charges[ch_type] = ch_obj_2;
		}
	}; // UpdCharge

	/**
	 */
	ApplyDisc = (disc_type, disc_obj) => {
		if (this.Discount() > disc_obj.amount)
		{
			return;
		}
		else
		{
			this.ClearDiscounts();
		}

		// if (false === disc_obj.b_accept_others) {
		// 	this.ClearDiscounts();
		// }

		// check if exists ...

		// verify ...

		// add to discounts ...
		if (disc_type === "loyalty")
		{
			this.discounts[disc_type] = disc_obj;
		}
		else
		{
			this.discounts[disc_type].push({ ...disc_obj, _idx: this.discounts[disc_type].length, disc_type: disc_type });
		}
	};

	/**
	 */
	ListCharges = () => {
		let ch_arr = Object.keys(this.charges).map(k => this.charges[k]);

		return ch_arr.sort((a, b) => a._idx - b._idx);
	};

	/**
	 */
	ListDiscounts = () => {
		const { generic, combo, influencer, loyalty, coupon } = this.discounts;

		return [...generic, ...combo, ...influencer, ...loyalty, ...coupon];
	};

	/**
	 */
	Discounts_isLocked = () => {
		const list_disc = this.ListDiscounts();

		if (list_disc.some(x => !x.b_accept_others)) return true;

		return false;
	};

	// SubTotal = () =>
	// {
	//     return this.charges[0].amount;
	// }

	/**
	 */
	Total = () => {
		const total = this.ListCharges().reduce((acc, cur) => {
			return acc + cur.amount;
		}, 0);

		return total - this.Discount();
	};

	/**
	 */
	SubTotal = () => {
		return this.charges.subtotal.amount;

		// return this.ListCharges().reduce( (acc, cur) => {
		// 	return acc + cur.amount;
		// }, 0 );
	};

	/**
	 */
	Discount = () => {
		return this.ListDiscounts().reduce((acc, cur) => {
			return acc + cur.amount;
		}, 0);
	};

	/**
	 */
	ClearCharges = () => {
		Object.keys(this.charges).forEach(k => {
			if (this.charges[k].type !== "subtotal") this.charges[k].amount = 0.0;
		});

		this.charges.elmelo.amount = 0.5;
	};

	/**
	 */
	ClearDiscounts = () => {
		this.discounts.generic = [];
		this.discounts.combo = [];
		this.discounts.influencer = [];
		this.discounts.loyalty = [];
	};

	/**
	 */
	ClearWithoutCoupon = val => {
		this.delivery = {
			...this.delivery,
			deliveryAddress: ""
		};
		// this.payment = {
		// 	paymentType: "",
		// 	creditInfo: ""
		// };

		this.ClearCharges();
		this.ClearDiscounts();
	};

	/**
	 */
	ClearAll = val => {
		this.delivery = {
			deliveryType: "collection",
			deliveryAddress: ""
		};
		this.payment = {
			paymentType: "",
			creditInfo: ""
		};

		this.discounts.coupon = [];
		this.ClearCharges();
		this.ClearDiscounts();
	};

	/**
	 */
	Update = () => {
		return { ...this, tsUpd: Date.now() };
	};
	CountItem = cntItem => {
		this.cntItem = cntItem;
	};

	/**
	 */
	SetDeliveryTime = obj => {
		this.advanced_order = obj.adv_order;
		if (obj.adv_order) this.delivery_time = obj.delivery_time;
		else this.delivery_time = 0;
	}; // UpdCharge
} // class checkout

/**
 */

export default (state = new Checkout(), action) => {
	switch (action.type) {
		// Used in code

		case "checkout:set-subtotal":
			state.ApplyCharge("subtotal", { amount: action.payload });
			return state.Update();

		case "checkout:set-item-count":
			state.CountItem(action.payload);
			return state.Update();

		/*
				 Cant find usage in code yet
		 */
		case "checkout:confirm":
			switch (action.payload) {
				case "biz":
					return { ...state, bConfirmed_Biz: true };

				case "personal":
					return { ...state, bConfirmed_Personal: true };

				case "payment":
					return { ...state, bConfirmed_Payment: true };

				case "summary":
					return { ...state, bConfirmed_Summary: true };

				default:
					return state;
			}

		case "checkout:update-user-info":
			state.Info(action.payload);
			return state.Update();

		case "checkout:update-delivery-info":
			state.Delivery(action.payload);
			return state.Update();

		case "checkout:update-comment":
			state.Comment(action.payload);
			return state.Update();

		case "checkout:set-generic-discount":
			state.ApplyDisc(action.payload.disc_type, action.payload);
			return state.Update();

		case "checkout:set-special-discount":
			state.ApplyDisc(action.payload.disc_type, action.payload);
			return state.Update();

		case "checkout:set-coupon-discount":
			state.ApplyDisc("coupon", {
				type: "coupon",
				coupon: action.payload.coupon,
				target: action.payload.offer.in,
				amount: action.payload.amount,
				b_accept_others: action.payload.offer.b_accept
			});
			return state.Update();

		case "checkout:set-first-usage-discount":
			state.ApplyDisc("generic", {
				type: "f_usage",
				name: "First Usage",
				amount: action.payload.amount,
				b_accept_others: action.payload.acceptOther
			});
			return state.Update();

		case "checkout:initial-checkout":
			return {
				// ...dummy,
				...new Checkout(),
				comment: state.comment ? state.comment : "",
				advanced_order: state.advanced_order,
				delivery_time: state.delivery_time
			};

		case "checkout:add-comment-to-order":
			return {
				...state,
				comment: action.payload
			};

		case "checkout:set-delivery-to-asap":
			return {
				...state,
				advanced_order: false,
				delivery_time: {}
			};

		case "checkout:set-delivery-to-adv":
			return {
				...state,
				advanced_order: true,
				delivery_time: action.payload
			};

		case "checkout:set-delivery-charge":
			state.ApplyCharge("delivery", { amount: action.payload });
			return state.Update();

		case "checkout:set-payment":
			state.Payment(action.payload);
			return state.Update();

		case "checkout:set-loyalty":
			state.ApplyDisc("loyalty", action.payload);
			return state.Update();

		case "checkout:clear-info-without-coupon":
			state.ClearWithoutCoupon();
			return state.Update();

		case "checkout:clear-info":
			state.ClearAll();
			return state.Update();

		case "set_delivery_time":
			state.SetDeliveryTime(action.payload);
			return state.Update();

		default:
			return state;
	} // switch
};
