/**
 * @copyright Snapon
 */

import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistReducer, persistStore,
  // FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage'
// import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';

// import createSagaMiddleware from '@redux-saga/core';

import cartSlice from '../rdx/cart.slice'

import Cfg from '../rdx/reducers/cfg'
import Biz from '../rdx/reducers/biz'
import Core from '../rdx/reducers/core'
import Rsrv from '../rdx/reducers/rsrv'
import Order from '../rdx/reducers/order'
import checkout from "../rdx/reducers/checkout";
import Chat from '../rdx/reducers/Chat'

import Profile from '../rdx/reducers/profile'

/**
 */
// const sagaMiddleware = createSagaMiddleware();

/**
 */
const persist_config = {
  key: 'root',
  storage: storage,
  whitelist: [
    // 'intl',
    // 'setup',
    '__cart',
  ],
  // blacklist: [],
  stateReconciler: autoMergeLevel2,
};

/**
 */
const combined_reducers = combineReducers( {
  __cart: cartSlice,

  __cfg: Cfg,

  __biz: Biz,
  __core: Core,

  __order: Order,
  __checkout: checkout,
  __rsrv: Rsrv,

  __chat: Chat,

  __prof: Profile,
} );

/**
 */
const persisted_reducer = persistReducer<any, any>(persist_config, combined_reducers);

/**
 */
export const store = configureStore( {
  middleware: (getMiddlewares) => getMiddlewares({
    serializableCheck: false,
    immutableCheck: false,
  }),

  reducer: persisted_reducer,
  // reducer: combined_reducers,
} );

export const persistor = persistStore( store )

/**
 */
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


